import { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { cloneDeep, uniqueId } from "lodash";
import { useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import type * as Yup from "yup";

import type { PutPresInsallValvesValvesInner } from "@eisox/backend_webapp_api";
import { HeatingNetwork1TypeEnum, PutPresInsallValvesValvesInnerStatusEnum } from "@eisox/backend_webapp_api";
import { ActionButton, Modal } from "@eisox/design-system";
import { PencilIcon, ValveIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { Gateway, RoomFC } from "~/UI/components";
import type { ItemType } from "~/UI/layouts/PlanV2/components";
import { Origin } from "~/UI/layouts/PlanV2/components";
import { Valve } from "~/UI/layouts/Valve";
import type { houseLoader } from "~/UI/screens";
import type { ValvesWithProblem } from "~/UI/screens/House";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToPreinstallValves, routeToValves } from "~/routes/utils";
import { API } from "~/types/API";

import { schemaErrors as macSchema } from "../../Add/Valves/utils/schema";
import { ConfirmCancelPopup } from "../components/ConfirmCancelPopup";
import { Plan } from "../components/Plan";
import { getItemBiggerUid, getItemBiggerUidWithMac } from "../utils";
import { offsetUid, reOrderItemsUid, schemaUid } from "../utils/uid";
import { ValveForm } from "./ValveForm";
import { prefixId, valvesMessageInnerArrayToPreinstallValvesDto } from "./utils";
import { schemaErrors } from "./utils/schema";

import styles from "./Valves.module.scss";

export type ValveFormDto = Omit<PutPresInsallValvesValvesInner, "status"> &
  Required<Pick<ValvesWithProblem, "uid" | "id">> & { mac?: string };

export interface PreinstallValvesDto {
  valves: ValveFormDto[];
}

export const Valves: React.FC<{ open: boolean; onClose: VoidFunction; className?: string; toAdd?: boolean }> = ({
  open,
  onClose,
  className,
  toAdd = false,
}) => {
  const { formatMessage } = useIntl();

  const { houseId, planId } = useParams() as { houseId: string; planId: string };

  const { valves, rooms, gateways, heatingNetworks } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    typeof houseLoader
  >;

  const heatingNetworksRadiators = heatingNetworks.filter(
    h => h.parentName && h.type === HeatingNetwork1TypeEnum.Radiator,
  );

  const existingSerialNumber: string[] = valves.filter(iv => iv.mac).flatMap(iv => iv.mac!);
  const nextUid = useMemo(() => (toAdd ? getItemBiggerUid(valves) : getItemBiggerUidWithMac(valves)) + 1, []);
  const initalState = useMemo(
    () =>
      toAdd ? { valves: [] } : valvesMessageInnerArrayToPreinstallValvesDto(valves.filter(v => v.uid! > nextUid - 1)),
    [],
  );

  const { Form, submit } = useAction({
    onSuccess: () => onClose && onClose(),
  });

  /* GLOBAL FORM */
  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
  } = useForm<PreinstallValvesDto>({
    values: initalState,
  });

  const { fields, update, append, replace } = useFieldArray({ control, name: "valves", keyName: "fieldId" });

  existingSerialNumber.push(...fields.filter(f => !!f.mac).map(f => f.mac!));

  /* VALVE FORM */
  const formValve = useForm<ValveFormDto>({
    defaultValues: {},
    resolver: yupResolver(
      schemaErrors(rooms, heatingNetworksRadiators.length > 0)
        .concat(schemaUid(nextUid, fields))
        .concat(macSchema(existingSerialNumber, toAdd)) as Yup.ObjectSchema<ValveFormDto>,
    ),
  });

  const currentValve = formValve.watch();
  const isCurrentValveIsOnPlan = !!fields.find(f => f.id === currentValve.id);

  const currentValveIndex = existingSerialNumber.findIndex(sn => sn === currentValve.mac);
  currentValveIndex &&
    isCurrentValveIsOnPlan &&
    !formValve.formState.dirtyFields.mac &&
    existingSerialNumber.splice(currentValveIndex, 1);

  const bem = useBem(styles);
  const containerStyle = bem("container");
  const contentStyle = bem("content");

  const [confirmCancelPopup, setConfirmCancelPopup] = useState(false);

  useEffect(() => {
    const newFields = offsetUid(initalState.valves, nextUid);
    replace(newFields);
    formValve.reset({
      uid: newFields[newFields.length - 1]?.uid + 1 || nextUid,
      plan: { planId },
    });
  }, []);

  const disabledValvesItems: ItemType[] = useMemo(
    () =>
      valves
        .filter(v => v.plan?.x && v.plan.y && v.plan.planId === currentValve.plan?.planId && v.uid! < nextUid)
        .map(v => ({
          id: v.id!,
          x: v.plan?.x!,
          y: v.plan?.y!,
          disabled: true,
          renderItem: () => <Valve {...v} errors={[]} warnings={[]} problemStatus={undefined} disabled displayUid />,
        })),
    [currentValve.plan?.planId],
  );

  const selectablesValvesItems: ItemType[] = fields
    .filter(v => v.plan?.planId === currentValve.plan?.planId && v.plan?.x && v.plan.y)
    .map(v => {
      const disabled = isCurrentValveIsOnPlan && formValve.formState.isDirty && v.id !== currentValve.id;
      return {
        id: v.id,
        x: v.plan?.x!,
        y: v.plan?.y!,
        draggable: formValve.formState.isDirty ? v.id === currentValve.id : true,
        disabled,
        renderItem: () => (
          <Valve
            {...v}
            errors={[]}
            warnings={[]}
            problemStatus={undefined}
            displayUid
            selected={v.id === currentValve.id}
            disabled={disabled}
            onClick={() => v.id !== currentValve.id && formValve.reset(v)}
          />
        ),
      };
    });

  const items: ItemType[] = [
    ...gateways
      .filter(g => g.id && g.plan?.x && g.plan.y && g.plan.planId === currentValve.plan?.planId)
      .map(g => ({
        id: g.id!,
        x: g.plan?.x!,
        y: g.plan?.y!,
        renderItem: () => (
          <Gateway
            problemStatus={undefined}
            uid={g.uid}
            gatewayName={g.gatewayName ?? "--"}
            expanded
            selected={currentValve.gatewayId === g.id}
            onClick={() => formValve.setValue("gatewayId", g.id, { shouldDirty: true })}
          />
        ),
      })),
    ...rooms
      .filter(r => r.id && r.plan?.x && r.plan.y && r.plan.planId === currentValve.plan?.planId)
      .map(r => ({
        id: r.id!,
        x: r.plan?.x!,
        y: r.plan?.y!,
        origin: Origin.BOTTOM_CENTER,
        renderItem: () => (
          <RoomFC
            name={r.name ?? "--"}
            selected={currentValve.roomId === r.id}
            onClick={() => formValve.setValue("roomId", r.id, { shouldDirty: true })}
          />
        ),
      })),
    ...disabledValvesItems,
    ...selectablesValvesItems,
  ];

  const onSubmit = handleSubmit(() => {
    const body = toAdd
      ? {
          valves: fields.map(({ id, fieldId, ...rest }) => ({
            ...rest,
          })),
        }
      : {
          startUid: nextUid,
          ...(fields.length > 0 && {
            valves: fields.map(({ id, fieldId, mac, ...rest }) => ({
              ...rest,
              status: PutPresInsallValvesValvesInnerStatusEnum.ToAutoInstall,
            })),
          }),
        };
    submit(
      body,
      toAdd ? API.HTTP_METHOD.POST : API.HTTP_METHOD.PUT,
      toAdd ? routeToValves(houseId) : routeToPreinstallValves(houseId),
    );
  });

  const setToNextValve = (lastAddedValve: ValveFormDto) => {
    formValve.reset({
      id: uniqueId(prefixId),
      uid: lastAddedValve.uid + 1,
      plan: { planId: lastAddedValve.plan?.planId },
      gatewayId: lastAddedValve.gatewayId,
      roomId: lastAddedValve.roomId,
      ...(heatingNetworksRadiators.length > 0 && { heatingNetworkId: lastAddedValve.heatingNetworkId }),
      ...(toAdd && { mac: "" }),
    });
  };

  const handleAddValve = (data: ValveFormDto) => {
    append(data);
    setToNextValve(data);
  };

  const handleUpdateValve = (data: ValveFormDto) => {
    let updateFields = fields;
    const index = fields.findIndex(f => f.id === data.id);
    const valve = fields[index];

    updateFields[index] = { ...updateFields[index], ...data, uid: valve.uid };

    if (formValve.formState.dirtyFields.uid) {
      const fieldsUpdate = reOrderItemsUid(updateFields, valve.uid, data.uid);
      fieldsUpdate && (updateFields = fieldsUpdate);
    }

    replace(updateFields);
    setToNextValve({ ...data, uid: updateFields[updateFields.length - 1].uid });
  };

  const handleDeleteValve = (data: ValveFormDto) => {
    const index = fields.findIndex(f => f.id === data.id);
    const copyArray = cloneDeep(fields);
    copyArray.splice(index, 1);
    copyArray.forEach(f => f.uid > fields[index].uid && f.uid--);
    replace(copyArray);
    setToNextValve(copyArray[copyArray.length - 1]);
  };

  const handleClickOnPlan = (x: number, y: number) => {
    if (isCurrentValveIsOnPlan) {
      if (formValve.formState.isDirty)
        toast.warn(formatMessage({ id: "plan.actions.preinstall.valves.warn.changeButHaveModifications" }));
      else setToNextValve(fields[fields.length - 1]);
    } else {
      formValve.setValue("plan", { ...currentValve.plan, x, y });
      formValve.handleSubmit(handleAddValve)();
    }
  };

  const handleOnChange = (items: ItemType[]) => {
    items.forEach(i => {
      const f = fields.find(f => f.id === i.id);
      if (f && (f.plan?.x !== i.x || f.plan.y !== i.y)) {
        update(
          fields.findIndex(f => f.id === i.id),
          { ...f, plan: { planId: f.plan?.planId, x: i.x, y: i.y } },
        );
      }
    });
  };

  return (
    <Modal.Root open={open} onOpenChange={open => !open && onClose()}>
      <Modal.Content className={clsx(containerStyle(), className)}>
        <Form onSubmit={onSubmit}>
          <Modal.Header
            title={formatMessage({ id: `plan.actions.${toAdd ? "add" : "preinstall"}.valves.title` })}
            subtitle={formatMessage({ id: "plan.actions.preinstall.valves.subtitle" })}
            icon={<ValveIcon />}
          >
            <ActionButton
              variant="cancel"
              text={formatMessage({ id: "plan.actions.preinstall.valves.cancel" })}
              onClick={e => {
                e.preventDefault();
                if (isDirty) setConfirmCancelPopup(true);
                else onClose && onClose();
              }}
            />
            <ActionButton
              rounded
              text={formatMessage({ id: "plan.actions.preinstall.valves.save" })}
              icon={<PencilIcon />}
              disabled={!isDirty || Object.keys(errors).length > 0}
              type="submit"
            />
          </Modal.Header>
        </Form>

        <div className={contentStyle()}>
          <ValveForm
            isCurrentValveIsOnPlan={isCurrentValveIsOnPlan}
            heatingNetworksRadiator={heatingNetworksRadiators}
            className={contentStyle("valve-form")}
            form={formValve}
            handleUpdateValve={handleUpdateValve}
            handleSetToNextValve={() => setToNextValve(fields[fields.length - 1])}
            handleDeleteValve={handleDeleteValve}
            toAdd={toAdd}
          />
          <div className={contentStyle("plan")}>
            {currentValve.plan?.planId && (
              <Plan
                items={items}
                planId={currentValve.plan.planId}
                onClickPlan={handleClickOnPlan}
                onChangeOnPlan={handleOnChange}
              />
            )}
          </div>
        </div>

        <ConfirmCancelPopup
          open={confirmCancelPopup}
          onClose={() => setConfirmCancelPopup(false)}
          onValidate={onClose}
        />
      </Modal.Content>
    </Modal.Root>
  );
};
