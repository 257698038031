import bcrypt from "bcryptjs";
import sha256 from "crypto-js/sha256";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  ActionButtonV2,
  PasswordInput,
  PhoneInput,
  TextField,
  UnProtectedRouteResponsiveCard,
} from "@eisox/design-system";
import { CheckedIcon } from "@eisox/icons";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { FieldContainer } from "~/UI";
import type { EnrollAccountRequestBody, EnrollAccountRequestParams } from "~/apiV2";
import { env } from "~/configuration";
import { usePasswordConditions } from "~/hooks";
import { i18next } from "~/i18n";
import { accountSchema, passwordSchema } from "~/utils";

import styles from "./EnrollAccount.module.scss";

type ActivateAccountSchema = z.infer<typeof schema>;
const schema = accountSchema
  .extend({
    password: passwordSchema,
    confirmPassword: z.string(),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: i18next.t("enRollAccount.errors.matchPassword"),
    path: ["confirmPassword"],
  });

interface PageProps {
  token: string;
  submit: (body: EnrollAccountRequestParams & EnrollAccountRequestBody) => void;
}

const EnrollAccountPage: React.FC<PageProps> = ({ token, submit }) => {
  const { t } = useTranslation();

  const { validatePassword, passwordErrors } = usePasswordConditions();

  const {
    register,
    formState: { errors, isDirty },
    control,
    handleSubmit,
  } = useForm<ActivateAccountSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      company: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = (data: ActivateAccountSchema) => {
    const body = {
      token,
      firstName: data.firstName,
      lastName: data.lastName,
      company: data.company,
      ...(!!data.phoneNumber?.length && { phoneNumber: data.phoneNumber }),
      hashedPassword: bcrypt.hashSync(sha256(data.password).toString(), 10),
    };
    submit(body);
  };

  return (
    <UnProtectedRouteResponsiveCard className={styles.enrollAccount} version={env.VERSION}>
      <form className={styles.enrollAccount__container} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fieldsContainer}>
          {(Object.keys(schema._def.schema.shape) as (keyof ActivateAccountSchema)[])
            .filter(k => k !== "password" && k !== "confirmPassword")
            .map((k, i) => (
              <Controller
                key={i}
                control={control}
                name={k}
                render={({ field }) => (
                  <FieldContainer
                    key={i}
                    label={t(`enRollAccount.${k}`)}
                    error={errors[k]}
                    classNames={{ container: styles[`fieldsContainer__${k}`] }}
                  >
                    {k === "phoneNumber" ? (
                      <PhoneInput
                        {...field}
                        countrySelectProps={{ disabled: true }}
                        defaultCountry="FR"
                        error={!!errors[k]}
                      />
                    ) : (
                      <TextField.Root {...field} error={!!errors[k]} />
                    )}
                  </FieldContainer>
                )}
              />
            ))}
          <Controller
            control={control}
            name="password"
            render={({ field: { value, onChange } }) => (
              <FieldContainer
                label={t(`enRollAccount.password`)}
                error={errors.password}
                classNames={{ container: styles.fieldsContainer__password }}
              >
                <PasswordInput
                  error={!!errors.password}
                  value={value}
                  onChange={e => {
                    onChange(e);
                    validatePassword(e.target.value);
                  }}
                />
              </FieldContainer>
            )}
          />
          <FieldContainer
            label={t("enRollAccount.confirmPassword")}
            error={errors.confirmPassword}
            classNames={{ container: styles.fieldsContainer__confirmPassword }}
          >
            <PasswordInput {...register("confirmPassword")} />
          </FieldContainer>
          <div className={styles.fieldsContainer__passwordRules}>
            {Object.entries(passwordErrors).map(([key, { isValid, translation }]) => (
              <div>
                <CheckedIcon aria-checked={isValid} aria-invalid={errors.password?.message === key} />
                <p>{translation}</p>
              </div>
            ))}
          </div>
        </div>
        <ActionButtonV2 type="submit" disabled={!isDirty}>
          {t("enRollAccount.submit")}
        </ActionButtonV2>
      </form>
    </UnProtectedRouteResponsiveCard>
  );
};

export { EnrollAccountPage };
