import { UserRole, WebappRolePermission } from "../User";

export const transformTemperature = (temperature: number): number => temperature / 100;

// -1, -2, -3, -4, -5 are special values for errors
export const transformCorrectedTemp = (correctedTemp: number, role?: WebappRolePermission): number =>
  [-1, -2, -3, -4, -5].includes(correctedTemp) && role === UserRole.INSTALLER ? correctedTemp : correctedTemp / 100;

export const transformHumidity = (humidity: number): number => humidity / 100;

export const openning = (closing: number): number => 100 - closing;

export const transformLight = (light: number): number => light / 10;
