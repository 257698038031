import { env } from "~/configuration";
import { useUserStore } from "~/stores";

import { DELETE_METHOD, GET_METHOD, PATCH_METHOD } from "../constants/fetchConstants";
import { internalFetchByUrl } from "../helpers/communication/FetchApi";
import { positionsHousesExtension, routeHouses } from "../routesStatic/routesExpress";

const ServerUrl = env.SERVER_URL;

export function editHouse(
  idHouse,
  frostFreeTemperature,
  absenceTemperature,
  houseName,
  isFrostFree,
  longitude,
  latitude,
  isRemoteAccess,
  preComfortTemperature,
  summerModePeriod,
  winterModePeriod,
  midSeason,
  isSwitchEnabled,
  comfortTemperature,
  isPreComfRoom,
) {
  let fetchingResponse = {};
  const body = {};
  if (frostFreeTemperature !== undefined && frostFreeTemperature !== null) {
    body.frostFreeTemperature = frostFreeTemperature * 100;
  }
  if (absenceTemperature !== undefined && absenceTemperature !== null) {
    body.absenceTemperature = absenceTemperature * 100;
  }
  if (houseName !== undefined && houseName !== null) {
    body.houseName = houseName;
  }
  if (isFrostFree !== undefined && isFrostFree !== null) {
    body.isFrostFree = isFrostFree;
  }
  if (longitude !== undefined && longitude !== null) {
    body.longitude = longitude;
  }
  if (latitude !== undefined && latitude !== null) {
    body.latitude = latitude;
  }
  if (isRemoteAccess !== undefined && isRemoteAccess !== null) {
    body.isRemoteAccess = isRemoteAccess;
  }
  if (preComfortTemperature !== undefined && preComfortTemperature !== null) {
    body.preComfortTemperature = preComfortTemperature * 100;
  }
  if (comfortTemperature !== undefined && comfortTemperature !== null) {
    body.comfortTemperature = comfortTemperature * 100;
  }
  if (isSwitchEnabled !== undefined && isSwitchEnabled !== null) {
    body.isSwitchEnabled = isSwitchEnabled;
  }
  if (summerModePeriod !== undefined && summerModePeriod !== null) {
    body.summerModePeriod = summerModePeriod;
  }
  if (winterModePeriod !== undefined && winterModePeriod !== null) {
    body.winterModePeriod = winterModePeriod;
  }
  if (midSeason !== undefined && midSeason !== null) {
    body.midSeason = midSeason;
  }
  if (isPreComfRoom !== undefined && isPreComfRoom !== null) {
    body.isPreComfRoom = isPreComfRoom;
  }
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteEditHouse = ServerUrl + routeHouses + "/" + idHouse;
  if (body.length !== 0) {
    fetchingResponse = internalFetchByUrl(RouteEditHouse, PATCH_METHOD, customHeaders, body);
  }
  return fetchingResponse;
}

export function getHousesPositions() {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteGetHouse = ServerUrl + routeHouses + positionsHousesExtension;
  return internalFetchByUrl(RouteGetHouse, GET_METHOD, customHeaders);
}

export function getHouseWithId(idHouse) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteEditHouse = ServerUrl + routeHouses + "/" + idHouse;
  if (idHouse !== undefined) {
    fetchingResponse = internalFetchByUrl(RouteEditHouse, GET_METHOD, customHeaders);
  }
  return fetchingResponse;
}

export function removeHouse(idHouse) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RoutePlanRemove = ServerUrl + routeHouses + "/" + idHouse;
  fetchingResponse = internalFetchByUrl(RoutePlanRemove, DELETE_METHOD, customHeaders);
  return fetchingResponse;
}
