import { forwardRef } from "react";

import { Circle } from "@eisox/design-system";
import { PencilIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Gateway } from "~/UI/components";
import { Valve } from "~/UI/layouts";
import type { GatewayProblemType, ValveProblemType } from "~/features/InterventionPlanTool";
import { upperCaseToKebabCase } from "~/utils/stringUtils";

import styles from "./ItemPreviewProblem.module.scss";

interface ItemPrewiewValveProblem {
  type: "valve";
  problemType?: ValveProblemType;
  isUnclogging: boolean;
}

interface ItemPreviewGatewayProblem {
  type: "gateway";
  problemType?: GatewayProblemType;
  isUnclogging?: never;
}

type CommonItemPreviewProblemProps = {
  mac: string;
  isSolved: boolean;
  isForced: boolean;
  gatewayName: string;
  uid: number;
  errors: string[];
  warnings: string[];
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClickLogo?: () => void;
} & (ItemPrewiewValveProblem | ItemPreviewGatewayProblem);

export const ItemPreviewProblem = forwardRef<HTMLDivElement, CommonItemPreviewProblemProps>(
  (
    {
      type,
      isSolved,
      isForced,
      problemType,
      mac,
      uid,
      gatewayName,
      errors,
      warnings,
      isUnclogging,
      onMouseEnter,
      onMouseLeave,
      onClickLogo,
    },
    ref,
  ) => {
    const bem = useBem(styles);
    const itemPreviewProblemStyle = bem("item-preview-problem");

    const itemType = problemType ? upperCaseToKebabCase(problemType) : "no-problem";

    return (
      <div className={itemPreviewProblemStyle()} ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {type === "valve" ? (
          <Valve
            size={40}
            errors={[]}
            onClick={onClickLogo}
            warnings={[]}
            problemStatus={undefined}
            className={itemPreviewProblemStyle("item", { type: itemType, unclogging: isUnclogging ? itemType : false })}
          />
        ) : (
          <Gateway
            gatewayName="-"
            uid={0}
            size={40}
            problemStatus={undefined}
            displayTooltip={false}
            onClick={onClickLogo}
            className={itemPreviewProblemStyle("item", { type: itemType })}
          />
        )}
        <div className={itemPreviewProblemStyle("list")}>
          <p>
            <b>
              {uid} - {mac}
            </b>{" "}
            - {gatewayName}
          </p>

          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
          <ul>
            {warnings.map((warning, index) => (
              <li key={index}>{warning}</li>
            ))}
          </ul>
        </div>
        {isForced && <PencilIcon className={itemPreviewProblemStyle("edit")} />}
        {isSolved && <Circle size={10} className={itemPreviewProblemStyle("solved")} />}
      </div>
    );
  },
);
