import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";

import { PatchHouse } from "@eisox/backend_webapp_api";
import { ActionButton, Switch } from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { houseLoader, usePermissionsContext } from "~/UI/screens";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToHouse } from "~/routes/utils";
import { API } from "~/types/API";

import styles from "./RemoteAccessV1.module.scss";

interface RemoteAccessProps {}

export const RemoteAccessV1: React.FC<RemoteAccessProps> = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const remoteAccessStyle = bem("remote-access");

  const { permissions } = usePermissionsContext();
  if (!permissions.house?.isRemoteAccess?.update) throw new Error("Unauthorized");

  const { house } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { houseId } = useParams() as { houseId: string };

  const { Form, submit, state } = useAction({
    onSuccess: () => {
      reset({}, { keepValues: true });
      toast(formatMessage({ id: "settings.content.menu.houses.remoteAccess.success" }), { type: "success" });
    },
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<PatchHouse>({
    defaultValues: {
      isRemoteAccess: !!house.isRemoteAccess,
    },
  });

  const isRemoteAccess = watch("isRemoteAccess");

  const onSubmit = (data: PatchHouse) => {
    const body: PatchHouse = { isRemoteAccess: data.isRemoteAccess };
    submit(body, API.HTTP_METHOD.PATCH, routeToHouse(houseId));
  };

  return (
    <Form className={remoteAccessStyle()} onSubmit={handleSubmit(onSubmit)}>
      <h3 className={remoteAccessStyle("title")}>
        {formatMessage({ id: "settings.content.menu.houses.remoteAccess.title" })}
      </h3>
      <label className={remoteAccessStyle("label")}>
        {formatMessage(
          { id: "settings.content.menu.houses.remoteAccess.value" },
          {
            isRemoteAccess: isRemoteAccess,
          },
        )}
        <Controller
          control={control}
          name="isRemoteAccess"
          render={({ field: { value, onChange } }) => (
            <Switch
              checked={!!value}
              onCheckedChange={onChange}
              disabled={!permissions.house?.isRemoteAccess?.update}
            />
          )}
        />
      </label>
      <ActionButton
        type="submit"
        className={remoteAccessStyle("submit-button")}
        text={formatMessage({ id: "settings.content.menu.houses.remoteAccess.save" })}
        icon={<ArrowRightIcon />}
        disabled={!isDirty || ["submitting", "loading"].includes(state)}
      />
    </Form>
  );
};
