import React, { useCallback } from "react";

import { cx } from "class-variance-authority";
import type { FieldError, Merge } from "react-hook-form";

import * as AKSelect from "@ariakit/react/select";
import { ChevronDownIcon } from "@eisox/icons";
import { useControllableState } from "@eisox/tools";

import { Radio } from "../Radio";
import { Typography } from "../Typography";

import styles from "./Select.module.scss";

interface OptionV2 {
  name: string;
  value: string;
  disabled?: boolean;
}

interface SelectV2Props {
  options: OptionV2[];
  label?: string;
  placeholder?: string;
  error?: Merge<FieldError, (FieldError | undefined)[]> | FieldError | undefined;
  value?: string | string[];
  onChange?: (value: string | string[]) => void;
  defaultValue?: string | string[];
  disabled?: boolean;
  contentProps?: Omit<AKSelect.SelectPopoverProps, "className" | "styles">;
  renderValue?: (value: string | string[] | undefined) => React.ReactNode;
  renderOption?: (name: string, value: string | string[] | undefined, disabled?: boolean) => React.ReactNode;
  unscrollableContent?: React.ReactNode;
  classNames?: {
    select?: string;
    trigger?: string;
    content?: string;
    unscrollableContent?: string;
    item?: string;
  };
  style?: {
    trigger?: React.CSSProperties;
    content?: React.CSSProperties;
    item?: React.CSSProperties;
  };
}

const SelectV2: React.FC<SelectV2Props> = ({
  options,
  label,
  placeholder,
  error,
  value: valueProp,
  onChange,
  defaultValue,
  disabled = false,
  contentProps,
  renderOption: renderOptionProps,
  renderValue: renderValueProps,
  unscrollableContent,
  classNames,
  style,
}) => {
  const [value, setValue] = useControllableState<string | string[]>({
    prop: valueProp,
    onChange,
    defaultProp: defaultValue ?? "",
  });

  const renderValue = useCallback(
    (value?: string | string[]) => {
      if (renderValueProps) return renderValueProps(value);
      if (typeof value === "string") {
        if (value === "") return <Typography as="span">{placeholder}</Typography>;
        return <Typography as="span">{options.find(option => option.value === value)?.name}</Typography>;
      }
      if (Array.isArray(value)) {
        if (value.length === 0) return <Typography as="span">{placeholder}</Typography>;
        return (
          <Typography as="span">
            {value.map(v => options.find(option => option.value === v)?.name).join(", ")}
          </Typography>
        );
      }
      return <Typography as="span">{placeholder}</Typography>;
    },
    [renderValueProps, placeholder, options],
  );

  const renderOption = useCallback(
    (value: string, name: string, disabled?: boolean) => {
      if (renderOptionProps) return renderOptionProps(name, value, disabled);
      return <Typography>{name}</Typography>;
    },
    [renderOptionProps],
  );

  return (
    <div className={cx(styles.select, classNames?.select)}>
      <AKSelect.SelectProvider defaultValue={defaultValue} value={value} setValue={setValue}>
        {label && <AKSelect.SelectLabel className={styles.select__label}>{label}</AKSelect.SelectLabel>}
        <AKSelect.Select
          className={cx(
            styles.select__trigger,
            (value === "" || value?.length === 0) && styles.select__trigger_placeholder,
            error && styles.select__trigger_error,
            classNames?.trigger,
          )}
          style={style?.trigger}
          disabled={disabled}
        >
          {renderValue(value)}
          <ChevronDownIcon className={styles.select__arrow} />
        </AKSelect.Select>
        <AKSelect.SelectPopover
          unmountOnHide
          sameWidth
          gutter={5}
          {...contentProps}
          className={cx(styles.popover, classNames?.content)}
          style={style?.content}
        >
          <div className={classNames?.unscrollableContent}>{unscrollableContent}</div>
          <div className={styles.popover__scrollableContent}>
            {options.map(option => (
              <AKSelect.SelectItem
                key={option.value}
                value={option.value}
                className={cx(styles.select__item, classNames?.item)}
                style={style?.item}
                disabled={option.disabled}
                // autoFocus={option.value === value || value === ""}
              >
                {Array.isArray(value) && (
                  <Radio
                    className={styles.select__radio}
                    multiple
                    checked={value.includes(option.value)}
                    disabled={option.disabled}
                  />
                )}
                {renderOption(option.value, option.name, option.disabled)}
              </AKSelect.SelectItem>
            ))}
          </div>
          <AKSelect.SelectItem hidden value={""} /> {/* Hidden item when no value selected */}
        </AKSelect.SelectPopover>
      </AKSelect.SelectProvider>
    </div>
  );
};

export { SelectV2 };
export type { OptionV2, SelectV2Props };
