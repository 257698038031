import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useBlocker, useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import type { GetProxyUrlByModuleId200Response, PatchModule } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import { ActionButton, PasswordInput, Select, Switch, TextInput, Typography } from "@eisox/design-system";
import { RefreshIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { FieldContainer, Tooltip } from "~/UI";
import { DeletePopup, ModificationPopup } from "~/UI/layouts";
import type { houseLoader } from "~/UI/screens";
import { usePermissionsContext } from "~/UI/screens";
import { getProxy } from "~/api/modules";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";
import type { FetchResponse } from "~/helpers/communication/fetchType";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToModule } from "~/routes/utils";
import { API } from "~/types/API";
import { concatBoilerroomNamesFromModule } from "~/utils";

import { getInitialState } from "../../../Season/helpers";
import { Mode } from "../../../Season/schemas";
import { isPLCMaintenanceEnabled, moduleDtoToMatchModuleMapper, moduleToModuleDtoMapper } from "../../utils";

import styles from "./Module.module.scss";

export interface ModuleDto {
  gatewayId: string;
  eclypseIP: string;
  user?: string;
  password?: string;
  gtbEnabled: boolean;
  eclypseOperatorUser?: string;
  eclypseOperatorPassword?: string;
  PLCMaintenanceEnabled: boolean;
  PLCMaintenanceAccessDuration?: string;
}

export const Module: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const moduleStyle = bem("module");
  const leftStyle = bem("left");
  const rightStyle = bem("right");

  const { house, boilerroomPos, modules, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    typeof houseLoader
  >;
  const { houseId, moduleId } = useParams() as { houseId: string; moduleId: string };

  const { permissions } = usePermissionsContext();

  const pos = boilerroomPos.find(p => p.moduleId === moduleId);
  const module = modules.find(m => m.id === moduleId);

  if (!module || !pos) throw Error();

  const { Form, submit, state } = useAction({
    onSuccess: () => {
      reset({ ...moduleToModuleDtoMapper(module) });
      toast(formatMessage({ id: "settings.content.menu.gtb.modules.module.success" }), { type: "success" });
      setPLCMaintenanceLink(null);
    },
  });

  const schema = yup.object({
    gatewayId: yup.string().required(formatMessage({ id: "error.emptyField" })),
    eclypseIP: yup.string().required(formatMessage({ id: "error.emptyField" })),
    gtbEnabled: yup.boolean().required(),
    eclypseOperatorUser: yup.string().when("gtbEnabled", (gtbEnabled, schema) => {
      return gtbEnabled[0] && !module.isProxyEnabled
        ? schema.required(formatMessage({ id: "error.emptyField" }))
        : schema;
    }),
    eclypseOperatorPassword: yup.string().when("gtbEnabled", (gtbEnabled, schema) => {
      return gtbEnabled[0] && !module.isProxyEnabled
        ? schema.required(formatMessage({ id: "error.emptyField" }))
        : schema;
    }),
    PLCMaintenanceEnabled: yup.boolean().required(),
    PLCMaintenanceAccessDuration: yup.string().when("PLCMaintenanceEnabled", (PLCMaintenanceEnabled, schema) => {
      return PLCMaintenanceEnabled[0] && !isPLCMaintenanceEnabled(module)
        ? schema.required(formatMessage({ id: "error.emptyField" }))
        : schema;
    }),
  });

  const moduleDto = moduleToModuleDtoMapper(module);

  const {
    register,
    control,
    formState: { isDirty, dirtyFields, errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<ModuleDto>({
    resolver: yupResolver(schema),
    values: moduleDto,
  });

  const blocker = useBlocker(isDirty);

  const gtbEnabled = watch("gtbEnabled");
  const PLCMaintenanceEnabled = watch("PLCMaintenanceEnabled");

  const [PLCMaintenanceLink, setPLCMaintenanceLink] = useState<string | null>(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getPlcMaintenanceLink = () => {
    setLoading(true);
    getProxy(module.id)
      .then((response: FetchResponse<GetProxyUrlByModuleId200Response>) => {
        if (response.type === SUCCESS_FETCH) {
          setPLCMaintenanceLink(response.result.message?.url!);
          setLoading(false);
          return;
        }
        throw new Error();
      })
      .catch(_ =>
        setTimeout(() => {
          setPLCMaintenanceLink(null);
          setLoading(false);
        }, 3000),
      );
  };

  useEffect(() => {
    setPLCMaintenanceLink(null);
    if (moduleDto.PLCMaintenanceEnabled) {
      getPlcMaintenanceLink();
    }
  }, [moduleId]);

  const onSubmit = (data: ModuleDto) => {
    const body: PatchModule = moduleDtoToMatchModuleMapper(data, dirtyFields);
    submit(body, API.HTTP_METHOD.PATCH, routeToModule(houseId, moduleId));
  };

  const PLCMaintenanceAccessDurationOption = [
    {
      value: "30 minutes",
      name: formatMessage({ id: "settings.content.menu.gtb.modules.module.plcMaintenance.duration.minute" }, { m: 30 }),
    },
    {
      value: "1 hour",
      name: formatMessage({ id: "settings.content.menu.gtb.modules.module.plcMaintenance.duration.hour" }, { h: 1 }),
    },
    {
      value: "6 hours",
      name: formatMessage({ id: "settings.content.menu.gtb.modules.module.plcMaintenance.duration.hour" }, { h: 6 }),
    },
    {
      value: "24 hours",
      name: formatMessage({ id: "settings.content.menu.gtb.modules.module.plcMaintenance.duration.hour" }, { h: 24 }),
    },
    {
      value: "48 hours",
      name: formatMessage({ id: "settings.content.menu.gtb.modules.module.plcMaintenance.duration.hour" }, { h: 48 }),
    },
    {
      value: "7 days",
      name: formatMessage({ id: "settings.content.menu.gtb.modules.module.plcMaintenance.duration.day" }, { d: 7 }),
    },
    {
      value: "1 month",
      name: formatMessage({ id: "settings.content.menu.gtb.modules.module.plcMaintenance.duration.month" }, { m: 1 }),
    },
  ];

  const deletionDisabled = getInitialState(house).mode === Mode.SUMMER_WINTER_WITH_HALF_SEASON;

  return (
    <Form className={moduleStyle()} onSubmit={handleSubmit(onSubmit)}>
      <div className={leftStyle()}>
        <Controller
          control={control}
          name="gatewayId"
          render={({ field: { value, onChange } }) => (
            <Select
              label={formatMessage({ id: "settings.content.menu.gtb.modules.module.gateway.label" })}
              value={value}
              options={gateways.map(g => {
                return {
                  name: g.gatewayName!,
                  value: g.id!,
                };
              })}
              renderValue={(value?: string | string[]) => <p>{gateways.find(g => g.id === value)?.gatewayName}</p>}
              onChange={(value: string) => onChange(value)}
              disabled={!permissions.module?.update}
            />
          )}
        />
        <TextInput
          {...register("eclypseIP")}
          label={formatMessage({ id: "settings.content.menu.gtb.modules.module.IP.label" })}
          placeholder={formatMessage({ id: "settings.content.menu.gtb.modules.module.IP.placeholder" })}
          error={errors.eclypseIP}
          disabled={!permissions.module?.update}
        />
        <TextInput
          {...register("user")}
          autoComplete="new-password"
          label={formatMessage({ id: "settings.content.menu.gtb.modules.module.user.label" })}
          placeholder={"*****"}
          disabled={!permissions.module?.update}
        />
        <FieldContainer
          label={formatMessage({ id: "settings.content.menu.gtb.modules.module.password.label" })}
          error={errors.password}
        >
          <PasswordInput
            {...register("password")}
            autoComplete="new-password"
            placeholder={"*****"}
            disabled={!permissions.module?.update}
          />
        </FieldContainer>
      </div>
      <div className={rightStyle()}>
        <h3 className={rightStyle("title")}>
          {formatMessage({ id: "settings.content.menu.gtb.modules.module.gtb.label" })}
        </h3>
        <label className={rightStyle("label")}>
          {formatMessage({ id: `settings.content.menu.gtb.modules.module.gtb.${gtbEnabled}` })}
          <Controller
            control={control}
            name="gtbEnabled"
            render={({ field: { value, onChange } }) => (
              <Switch
                className={moduleStyle("label")}
                checked={value}
                onCheckedChange={onChange}
                disabled={!permissions.module?.update}
              />
            )}
          />
        </label>
        {gtbEnabled && (
          <>
            <TextInput
              {...register("eclypseOperatorUser")}
              autoComplete="new-password"
              label={formatMessage({ id: "settings.content.menu.gtb.modules.module.gtb.user.label" })}
              placeholder={
                module.isProxyEnabled
                  ? "*****"
                  : formatMessage({ id: "settings.content.menu.gtb.modules.module.gtb.user.placeholder" })
              }
              error={errors.eclypseOperatorUser}
              disabled={!permissions.module?.update}
            />
            <FieldContainer
              label={formatMessage({ id: "settings.content.menu.gtb.modules.module.gtb.password.label" })}
              error={errors.eclypseOperatorPassword}
            >
              <PasswordInput
                {...register("eclypseOperatorPassword")}
                autoComplete="new-password"
                placeholder={
                  module.isProxyEnabled
                    ? "*****"
                    : formatMessage({ id: "settings.content.menu.gtb.modules.module.gtb.password.placeholder" })
                }
                error={!!errors.eclypseOperatorPassword}
                disabled={!permissions.module?.update}
              />
            </FieldContainer>
          </>
        )}
        <h3 className={rightStyle("title")}>
          {formatMessage({ id: "settings.content.menu.gtb.modules.module.plcMaintenance.label" })}
        </h3>
        <label className={rightStyle("label")}>
          {formatMessage({ id: `settings.content.menu.gtb.modules.module.gtb.${PLCMaintenanceEnabled}` })}
          <Controller
            control={control}
            name="PLCMaintenanceEnabled"
            render={({ field: { value, onChange } }) => (
              <Switch checked={value} onCheckedChange={onChange} disabled={!permissions.module?.update} />
            )}
          />
        </label>
        {PLCMaintenanceEnabled && (
          <>
            <Controller
              control={control}
              name="PLCMaintenanceAccessDuration"
              render={({ field: { value, onChange } }) => (
                <Select
                  labelClassName={rightStyle("label", { select: true })}
                  label={formatMessage({
                    id: "settings.content.menu.gtb.modules.module.plcMaintenance.duration.label",
                  })}
                  value={value}
                  options={PLCMaintenanceAccessDurationOption}
                  renderValue={(value?: string) => (
                    <p>{PLCMaintenanceAccessDurationOption.find(o => o.value === value)?.name || "---"}</p>
                  )}
                  onChange={onChange}
                  error={errors.PLCMaintenanceAccessDuration}
                  disabled={!permissions.module?.update}
                />
              )}
            />
            {module.sshOperatorExpireAt && (
              <p className={rightStyle("expiration-date")}>
                {dayjs(module.sshOperatorExpireAt).format("DD/MM/YYYY - HH:mm:ss")}
              </p>
            )}
            <p className={rightStyle("link")}>
              {formatMessage(
                { id: "settings.content.menu.gtb.modules.module.plcMaintenance.link" },
                {
                  l: !!PLCMaintenanceLink && (
                    <Typography
                      as="a"
                      className={rightStyle("link", { italic: true })}
                      href={PLCMaintenanceLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      keepHoverOpen
                    >
                      {PLCMaintenanceLink}
                    </Typography>
                  ),
                },
              )}
              {!PLCMaintenanceLink && isPLCMaintenanceEnabled(module) && (
                <RefreshIcon
                  className={rightStyle("refresh", { loading })}
                  onClick={() => !loading && getPlcMaintenanceLink()}
                />
              )}
            </p>
          </>
        )}
      </div>
      <div className={moduleStyle("buttons")}>
        {permissions.module?.delete && (
          <Tooltip
            content={formatMessage({ id: "settings.content.menu.gtb.modules.module.delete.tooltip" })}
            openingCondition={deletionDisabled}
          >
            <ActionButton
              text={formatMessage({ id: "settings.content.menu.gtb.modules.module.delete.delete" })}
              variant="danger"
              onClick={e => {
                e.preventDefault();
                setDeleteOpen(true);
              }}
              disabled={deletionDisabled}
            />
          </Tooltip>
        )}

        <ActionButton
          text={formatMessage({ id: "settings.content.menu.gtb.modules.module.save" })}
          type="submit"
          disabled={!isDirty || ["submitting", "loading"].includes(state)}
        />
      </div>

      {deleteOpen && (
        <DeletePopup
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          elementToDelete={concatBoilerroomNamesFromModule(pos)}
          text={formatMessage({ id: "settings.content.menu.gtb.modules.module.delete.text" })}
          route={routeToModule(houseId, moduleId)}
        />
      )}
      {blocker.state === "blocked" && (
        <ModificationPopup
          onClickContinue={() => blocker.proceed()}
          open={blocker.state === "blocked"}
          onClose={() => blocker.reset()}
        />
      )}
    </Form>
  );
};
