import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Link, useLocation, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import { $enum } from "ts-enum-util";

import type { PatchRooms, PatchRoomsRoomTypeEnum } from "@eisox/backend_webapp_api";
import { ActionButton, Alert, ButtonV2, Select, Slider, Steps, Switch } from "@eisox/design-system";
import { isFrostFree } from "@eisox/houses";
import { ArrowRightIcon, CrossIcon, HeatIcon, LeafIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { RoomRoomType } from "@eisox/webapp-api-specification";

import type { houseLoader } from "~/UI/screens";
import {
  DEFAULT_TEMPERATURES,
  GATEWAY_MIN_SOFTWARE_VERSIONS,
  ROOM_TYPES,
  TEMPERATURE_LIMITS,
} from "~/constants/appConstantV2";
import { useAction } from "~/hooks";
import {
  idLoaderHouse,
  routeToFrostFreeSettings,
  routeToRoomsAction,
  routeToRoomsFrostFreeSettings,
} from "~/routes/utils";
import { API } from "~/types/API";
import { isVersionIsUpper } from "~/utils";

import { Mode } from "../../../FrostFree";
import type { ParametersType, SelectorsType } from "../../layouts";
import { RoomsSelectors } from "../../layouts";
import { PlanningsGenerationPopup, UnusualAbsencePopup } from "./layouts";
import {
  frostFreeMode,
  getComfortTemperature,
  getNightTemperature,
  getType,
  isSwitchEnabled,
  preComfortTimeslotGenerationOn,
  unusualAbsenceDetectionOn,
} from "./utils";

import styles from "./Function.module.scss";

export interface FunctionDto {
  mode?: Mode;
  unusualAbsenceDetection: boolean | null;
  preComfortTimeslotGeneration: boolean | null;
  comfortTemperature?: number;
  nightTemperature?: number;
  switchEnabled: boolean | null;
  type?: RoomRoomType;
}

export const Function: React.FC = () => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const bem = useBem(styles);
  const functionStyle = bem("function");
  const parametersStyle = bem("parameters");
  const selectStyle = bem("select");
  const saveStyle = bem("save");

  const { house, rooms, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { houseId } = useParams() as { houseId: string };
  const { state: locationState } = useLocation() as {
    state: (SelectorsType & { parameters: ParametersType }) | undefined;
  };

  const [selectedRoomIds, setSelectedRoomIds] = useState<string[]>([]);
  const [planningGenerationPopupOpen, setPlanningGenerationPopupOpen] = useState(false);
  const [unsualAbsencePopupOpen, setUnsualAbsencePopupOpen] = useState(false);
  const [blured, setBlured] = useState(false);

  const selectedRooms = rooms.filter(r => selectedRoomIds.includes(r.id!));

  const { Form, state, submit } = useAction({
    onSuccess: () => {
      toast(formatMessage({ id: "settings.content.menu.rooms.function.success" }), { type: "success" });
    },
  });

  const frostFreeModeDefaultValues = frostFreeMode(selectedRooms);
  const unusualAbsenceDetectionDefaultValues = unusualAbsenceDetectionOn(selectedRooms);
  const preComfortTimeslotGenerationDefaultValues = preComfortTimeslotGenerationOn(selectedRooms);
  const comfortTemperatureDefaultValue = getComfortTemperature(selectedRooms);
  const nightTemperatureDefaultValue = getNightTemperature(selectedRooms);
  const switchEnabledDefaultValues = isSwitchEnabled(selectedRooms);
  const typeDefaultValue = getType(selectedRooms);

  const {
    control,
    watch,
    formState: { isDirty, dirtyFields },
    resetField,
    handleSubmit,
  } = useForm<FunctionDto>({
    values: {
      mode: frostFreeModeDefaultValues,
      unusualAbsenceDetection: unusualAbsenceDetectionDefaultValues,
      preComfortTimeslotGeneration: preComfortTimeslotGenerationDefaultValues,
      comfortTemperature: comfortTemperatureDefaultValue,
      nightTemperature: nightTemperatureDefaultValue,
      switchEnabled: switchEnabledDefaultValues,
      type: typeDefaultValue,
    },
  });
  const mode = watch("mode");
  const type = watch("type");
  const unusualAbsenceDetection = watch("unusualAbsenceDetection");
  const preComfortTimeslotGeneration = watch("preComfortTimeslotGeneration");
  const switchEnabled = watch("switchEnabled");
  const comfortTemperature = watch("comfortTemperature");
  const nightTemperature = watch("nightTemperature");

  const onSubmit = (data: FunctionDto) => {
    const body: PatchRooms = {
      roomIds: selectedRoomIds,
    };
    if (dirtyFields.comfortTemperature && data.comfortTemperature)
      body.comfortTemperature = data.comfortTemperature * 100;
    if (dirtyFields.nightTemperature && data.nightTemperature) body.nightTemperature = data.nightTemperature * 100;
    if (dirtyFields.preComfortTimeslotGeneration) body.isPreComfRoom = data.preComfortTimeslotGeneration!;
    if (dirtyFields.unusualAbsenceDetection) body.isAutoPrecomf = data.unusualAbsenceDetection!;
    if (dirtyFields.switchEnabled) body.isSwitchEnabled = data.switchEnabled!;
    if (dirtyFields.mode) body.isFrostFree = data.mode === Mode.ON;
    if (dirtyFields.type) body.roomType = data.type as PatchRoomsRoomTypeEnum;
    submit([body], API.HTTP_METHOD.PATCH, routeToRoomsAction(houseId));
  };

  const inputsDisabled = selectedRooms.length === 0;
  const hasNightTemperature =
    type === RoomRoomType.Room ||
    rooms.filter(r => selectedRoomIds.includes(r.id!)).some(r => r.roomType === RoomRoomType.Room);

  const isFrostFreePeriodsAllowed = gateways.every(g =>
    isVersionIsUpper(g.softwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.FROST_FREE_PERIODS),
  );

  return (
    <>
      <Form className={functionStyle()} onSubmit={handleSubmit(onSubmit)}>
        <Steps>
          <Steps.Step title={formatMessage({ id: "settings.content.menu.rooms.function.steps.roomSelector" })}>
            <RoomsSelectors functionPage onChange={rooms => setSelectedRoomIds(rooms)} initialState={locationState} />
          </Steps.Step>
          <Steps.Step title={formatMessage({ id: "settings.content.menu.rooms.function.steps.parameters" })}>
            <div className={parametersStyle()}>
              <div className={parametersStyle("left")}>
                <h3 className={parametersStyle("title", { blured: blured && !dirtyFields.unusualAbsenceDetection })}>
                  {formatMessage({
                    id: "settings.content.menu.rooms.function.parameters.unusualAbsenceDetection.title",
                  })}
                  <LeafIcon className={parametersStyle("logo", { color: "green" })} />
                </h3>
                <p className={parametersStyle("text", { blured: blured && !dirtyFields.unusualAbsenceDetection })}>
                  {formatMessage(
                    { id: "settings.content.menu.rooms.function.parameters.unusualAbsenceDetection.text" },
                    {
                      l: (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          className={parametersStyle("text", { link: true })}
                          onClick={() => setUnsualAbsencePopupOpen(true)}
                        >
                          {formatMessage({
                            id: "settings.content.menu.rooms.function.parameters.unusualAbsenceDetection.more",
                          })}
                        </a>
                      ),
                    },
                  )}
                </p>
                <label className={parametersStyle("label", { blured: blured && !dirtyFields.unusualAbsenceDetection })}>
                  {formatMessage(
                    { id: "settings.content.menu.rooms.function.parameters.unusualAbsenceDetection.label" },
                    {
                      activate: unusualAbsenceDetection,
                    },
                  )}
                  <Controller
                    control={control}
                    name="unusualAbsenceDetection"
                    render={({ field: { value, onChange } }) => (
                      // When selection gives default value that is true or false
                      // we can just select true or false
                      <Switch
                        key={`${unusualAbsenceDetectionDefaultValues}`}
                        checked={
                          unusualAbsenceDetectionDefaultValues !== null && value === null
                            ? unusualAbsenceDetectionDefaultValues
                            : value
                        }
                        onCheckedChange={onChange}
                        disabled={inputsDisabled}
                      />
                    )}
                  />
                </label>
                <h3
                  className={parametersStyle("title", {
                    blured: blured && !dirtyFields.preComfortTimeslotGeneration,
                  })}
                >
                  {formatMessage({
                    id: "settings.content.menu.rooms.function.parameters.precomfortTimeslotGeneration.title",
                  })}
                  <HeatIcon className={parametersStyle("logo", { color: "comfort" })} />
                </h3>
                <p className={parametersStyle("text", { blured: blured && !dirtyFields.preComfortTimeslotGeneration })}>
                  {formatMessage(
                    { id: "settings.content.menu.rooms.function.parameters.precomfortTimeslotGeneration.text" },
                    {
                      l: (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          className={parametersStyle("text", { link: true })}
                          onClick={() => setPlanningGenerationPopupOpen(true)}
                        >
                          {formatMessage({
                            id: "settings.content.menu.rooms.function.parameters.precomfortTimeslotGeneration.more",
                          })}
                        </a>
                      ),
                    },
                  )}
                </p>
                <label
                  className={parametersStyle("label", {
                    blured: blured && !dirtyFields.preComfortTimeslotGeneration,
                  })}
                >
                  {formatMessage(
                    {
                      id: "settings.content.menu.rooms.function.parameters.precomfortTimeslotGeneration.label",
                    },
                    {
                      activate: preComfortTimeslotGeneration,
                    },
                  )}
                  <Controller
                    control={control}
                    name="preComfortTimeslotGeneration"
                    render={({ field: { value, onChange } }) => (
                      // When selection gives default value that is true or false
                      // we can just select true or false
                      <Switch
                        key={`${preComfortTimeslotGenerationDefaultValues}`}
                        checked={
                          preComfortTimeslotGenerationDefaultValues !== null && value === null
                            ? preComfortTimeslotGenerationDefaultValues
                            : value
                        }
                        onCheckedChange={onChange}
                        disabled={inputsDisabled}
                      />
                    )}
                  />
                </label>
                <Controller
                  control={control}
                  name="comfortTemperature"
                  render={({ field: { value, onChange } }) => (
                    <>
                      <Slider
                        className={parametersStyle("slider", {
                          blured: blured && !dirtyFields.comfortTemperature,
                        })}
                        label={formatMessage({
                          id: "settings.content.menu.rooms.function.parameters.comfortTemperature.label",
                        })}
                        value={value}
                        defaultValue={DEFAULT_TEMPERATURES.COMFORT}
                        valueLabelDisplay={
                          selectedRooms.map(r => r.roomType).includes(RoomRoomType.Room) ? "auto" : "off"
                        }
                        valueLabelFormat={formatMessage(
                          {
                            id: "error.temperature.comfUpperNight",
                          },
                          {
                            c: value,
                            n: nightTemperature,
                          },
                        )}
                        min={TEMPERATURE_LIMITS.MIN}
                        max={TEMPERATURE_LIMITS.MAX}
                        step={TEMPERATURE_LIMITS.STEP}
                        onValueChange={value => {
                          if (typeof value === "number") {
                            const minTemp = nightTemperature;
                            if (hasNightTemperature && minTemp !== undefined && value <= minTemp)
                              value = minTemp + TEMPERATURE_LIMITS.STEP;
                            onChange(value);
                          }
                        }}
                        disabled={inputsDisabled}
                      />
                      {value === undefined && (
                        <p className={parametersStyle("text", { blured: blured && !dirtyFields.comfortTemperature })}>
                          {formatMessage({
                            id: "settings.content.menu.rooms.function.parameters.noUniformTemperature",
                          })}
                        </p>
                      )}
                    </>
                  )}
                />
                {hasNightTemperature && (
                  <Controller
                    control={control}
                    name="nightTemperature"
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Slider
                          className={parametersStyle("slider", {
                            blured: blured && !dirtyFields.nightTemperature,
                          })}
                          label={formatMessage({
                            id: "settings.content.menu.rooms.function.parameters.nightTemperature.label",
                          })}
                          value={value}
                          defaultValue={DEFAULT_TEMPERATURES.NIGHT}
                          min={TEMPERATURE_LIMITS.MIN}
                          max={TEMPERATURE_LIMITS.MAX}
                          step={TEMPERATURE_LIMITS.STEP}
                          valueLabelDisplay="auto"
                          valueLabelFormat={formatMessage(
                            {
                              id: "error.temperature.nightLowerComf",
                            },
                            {
                              c: comfortTemperature,
                              n: value,
                            },
                          )}
                          onValueChange={value => {
                            if (typeof value === "number") {
                              const maxTemp = comfortTemperature;
                              if (maxTemp !== undefined && value >= maxTemp) value = maxTemp - TEMPERATURE_LIMITS.STEP;
                              onChange(value);
                            }
                          }}
                          disabled={inputsDisabled}
                        />
                        {value === undefined && (
                          <p className={parametersStyle("text", { blured: blured && !dirtyFields.nightTemperature })}>
                            {formatMessage({
                              id: "settings.content.menu.rooms.function.parameters.noUniformTemperature",
                            })}
                          </p>
                        )}
                      </>
                    )}
                  />
                )}
              </div>
              <div className={parametersStyle("right")}>
                <h3 className={parametersStyle("title", { blured: blured && !dirtyFields.switchEnabled })}>
                  {formatMessage({ id: "settings.content.menu.rooms.function.parameters.buttonSettings.title" })}
                </h3>
                <label className={parametersStyle("label", { blured: blured && !dirtyFields.switchEnabled })}>
                  {formatMessage(
                    { id: "settings.content.menu.rooms.function.parameters.buttonSettings.label" },
                    {
                      activate: switchEnabled,
                    },
                  )}
                  <Controller
                    control={control}
                    name="switchEnabled"
                    render={({ field: { value, onChange } }) => (
                      // When selection gives default value that is true or false
                      // we can just select true or false
                      <Switch
                        key={`${switchEnabledDefaultValues}`}
                        checked={
                          switchEnabledDefaultValues !== null && value === null ? switchEnabledDefaultValues : value
                        }
                        onCheckedChange={onChange}
                        disabled={inputsDisabled}
                      />
                    )}
                  />
                </label>

                <h3 className={parametersStyle("title", { blured: blured && !dirtyFields.mode })}>
                  {formatMessage({ id: "settings.content.menu.rooms.function.parameters.frostFree.title" })}
                </h3>
                {isFrostFreePeriodsAllowed ? (
                  <ButtonV2
                    className={parametersStyle("frostFreePeriod")}
                    onClick={() => navigate(routeToRoomsFrostFreeSettings(houseId))}
                  >
                    {formatMessage({ id: "settings.content.menu.rooms.function.parameters.frostFree.seeSetings" })}
                    <ArrowRightIcon />
                  </ButtonV2>
                ) : (
                  <>
                    <Controller
                      control={control}
                      name="mode"
                      render={({ field: { value, onChange } }) => (
                        <div className={selectStyle({ blured: blured && !dirtyFields.mode })}>
                          <Select
                            triggerClassName={selectStyle("select")}
                            value={value}
                            options={$enum(Mode)
                              .getValues()
                              .filter(m => m !== Mode.PERIODS)
                              .map(m => ({
                                value: m,
                                name: formatMessage({
                                  id: `settings.content.menu.rooms.function.parameters.frostFree.mode.${m}`,
                                }),
                              }))}
                            renderValue={(value?: string) => (
                              <p>
                                {formatMessage({
                                  id: value
                                    ? `settings.content.menu.rooms.function.parameters.frostFree.mode.${value}`
                                    : "settings.content.menu.rooms.function.parameters.frostFree.placeholder",
                                })}
                              </p>
                            )}
                            onChange={onChange}
                            disabled={inputsDisabled}
                          />
                          {!frostFreeModeDefaultValues && dirtyFields.mode && (
                            <CrossIcon className={selectStyle("cross")} onClick={() => onChange(undefined)} />
                          )}
                        </div>
                      )}
                    />
                    {!mode && !inputsDisabled && (
                      <p className={parametersStyle("label", { blured: blured && !dirtyFields.mode })}>
                        {formatMessage({ id: "settings.content.menu.rooms.function.parameters.frostFree.notUniform" })}
                      </p>
                    )}
                    {isFrostFree({ ...house }).isFrostFree && (
                      <Alert
                        className={parametersStyle("alert", { blured: blured && !dirtyFields.mode })}
                        severity="warning"
                      >
                        {formatMessage(
                          { id: "settings.content.menu.rooms.function.parameters.frostFree.alert" },
                          {
                            l: (
                              <Link to={routeToFrostFreeSettings(houseId)}>
                                {formatMessage({
                                  id: "settings.content.menu.rooms.function.parameters.frostFree.link",
                                })}
                              </Link>
                            ),
                          },
                        )}
                      </Alert>
                    )}
                  </>
                )}

                <h3 className={parametersStyle("title", { blured: blured && !dirtyFields.type })}>
                  {formatMessage({ id: "settings.content.menu.rooms.function.parameters.type.title" })}
                </h3>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { value, onChange } }) => (
                    <div className={selectStyle({ blured: blured && !dirtyFields.type })}>
                      <Select
                        triggerClassName={selectStyle("select")}
                        value={value}
                        multiple={false}
                        options={ROOM_TYPES.map(t => ({
                          value: t,
                          name: formatMessage({
                            id: `room.type.${t}`,
                          }),
                        }))}
                        renderValue={(value?: string) => (
                          <p>
                            {formatMessage({
                              id: value
                                ? `room.type.${value}`
                                : "settings.content.menu.rooms.function.parameters.type.placeholder",
                            })}
                          </p>
                        )}
                        onChange={value => {
                          if (value !== RoomRoomType.Room) resetField("nightTemperature");
                          onChange(value);
                        }}
                      />
                      {!typeDefaultValue && dirtyFields.type && (
                        <CrossIcon className={selectStyle("cross")} onClick={() => onChange(undefined)} />
                      )}
                    </div>
                  )}
                />
                {!type && !inputsDisabled && (
                  <p className={parametersStyle("label", { blured: blured && !dirtyFields.type })}>
                    {formatMessage({ id: "settings.content.menu.rooms.function.parameters.type.notUniform" })}
                  </p>
                )}
              </div>
            </div>
          </Steps.Step>
          <Steps.Step title={formatMessage({ id: "settings.content.menu.rooms.function.steps.save" })}>
            <div className={saveStyle()}>
              {rooms.length === selectedRoomIds.length && isDirty && (
                <Alert severity="warning">{formatMessage({ id: "settings.content.menu.rooms.function.alert" })}</Alert>
              )}
              <ActionButton
                type="submit"
                text={formatMessage({ id: "settings.content.menu.rooms.function.save" })}
                icon={<ArrowRightIcon />}
                disabled={!isDirty || inputsDisabled || ["submitting", "loading"].includes(state)}
                onMouseEnter={() => setBlured(isDirty)}
                onMouseLeave={() => setBlured(false)}
              />
            </div>
          </Steps.Step>
        </Steps>
      </Form>
      {planningGenerationPopupOpen && (
        <PlanningsGenerationPopup
          open={planningGenerationPopupOpen}
          onClose={() => setPlanningGenerationPopupOpen(false)}
          absenceTemperature={house.absenceTemperature}
          preComfortTemperature={house.preComfortTemperature}
          comfortTemperature={getComfortTemperature(rooms)}
        />
      )}
      {unsualAbsencePopupOpen && (
        <UnusualAbsencePopup
          open={unsualAbsencePopupOpen}
          onClose={() => setUnsualAbsencePopupOpen(false)}
          absenceTemperature={house.absenceTemperature}
          preComfortTemperature={house.preComfortTemperature}
          comfortTemperature={getComfortTemperature(rooms)}
        />
      )}
    </>
  );
};
