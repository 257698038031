import { idLoaderHouse, routeToPlan, routeToRoom } from "~/routes/utils";

import { loader as houseLoader } from "../House";

import { Navigate, useParams, useRouteLoaderData } from "react-router-dom";

export const Rooms: React.FC = () => {
  const { houseId, planId } = useParams();
  const { rooms } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const room = rooms.find(r => r.id);

  return (
    <Navigate
      to={
        room
          ? routeToRoom(houseId as string, planId as string, room.id as string)
          : routeToPlan(houseId as string, planId as string)
      }
      replace
    />
  );
};
