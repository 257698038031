import { useTranslation } from "react-i18next";
import { $enum } from "ts-enum-util";

import { ButtonV2, Divider, Dropdown, SelectV2 as Select, Toggle } from "@eisox/design-system";
import { ArrowUpDownIcon, ChevronDownIcon, MenuIcon, WindowIcon } from "@eisox/icons";
import { useControllableState } from "@eisox/tools";

import { GroupField, Item, SortField, SortOrder } from "../../types";

import styles from "./Display.module.scss";

interface DisplayProps {
  groupedBy?: GroupField;
  onGroupChange?: (groupBy: GroupField) => void;
  sortBy?: SortField;
  sortOrder?: SortOrder;
  onSortByChange?: (field: SortField) => void;
  onSortOrderChange?: (order: SortOrder) => void;
  itemsDisplayedOnPlan?: Item[];
  onItemDisplayedOnPlanChange?: (items: Item[]) => void;
}

const Display: React.FC<DisplayProps> = props => {
  const { t } = useTranslation();

  const {
    groupedBy: groupedByProp,
    onGroupChange,
    sortBy: sortByProp,
    onSortByChange,
    sortOrder: sortOrderProp,
    onSortOrderChange,
    itemsDisplayedOnPlan: itemsDisplayedOnPlanProp,
    onItemDisplayedOnPlanChange,
  } = props;

  const [groupedBy = GroupField.plan, setGroupBy] = useControllableState({
    prop: groupedByProp,
    onChange: onGroupChange,
  });
  const [sortBy = SortField.hslope, setSortBy] = useControllableState({
    prop: sortByProp,
    onChange: onSortByChange,
  });
  const [sortOrder = SortOrder.asc, setSortOrder] = useControllableState({
    prop: sortOrderProp,
    onChange: onSortOrderChange,
  });
  const [itemsDisplayedOnPlan = [Item.valves], setItemsDisplayedOnPlan] = useControllableState({
    prop: itemsDisplayedOnPlanProp,
    onChange: onItemDisplayedOnPlanChange,
  });

  const handleChangeGroup = (groupBy: string | string[]) => {
    setGroupBy(groupBy as GroupField);
  };

  const hnandleChangeSortBy = (sortBy: string | string[]) => {
    setSortBy(sortBy as SortField);
  };

  const handleChangeSortOrder = () => {
    setSortOrder(prev => (prev === SortOrder.asc ? SortOrder.desc : SortOrder.asc));
  };

  const handleChangeItemDisplayedOnPlan = (item: Item) => {
    if (itemsDisplayedOnPlan.includes(item)) {
      setItemsDisplayedOnPlan(itemsDisplayedOnPlan.filter(i => i !== item));
    } else {
      setItemsDisplayedOnPlan([...itemsDisplayedOnPlan, item]);
    }
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <ButtonV2>
          <WindowIcon />
          {t("manualBalancing.display.trigger")}
        </ButtonV2>
      </Dropdown.Trigger>
      <Dropdown.Content className={styles.display} align="start">
        <p className={styles.display__label}>{t("manualBalancing.display.list")}</p>
        <div className={styles.display__row}>
          <MenuIcon />
          {t("manualBalancing.display.group.label")}
          <Select
            classNames={{ select: styles.display__group }}
            options={$enum(GroupField)
              .getValues()
              .map(value => ({
                name: t(`manualBalancing.display.group.${value}`),
                value,
              }))}
            value={groupedBy}
            onChange={handleChangeGroup}
          />
        </div>
        <div className={styles.display__row}>
          <ArrowUpDownIcon />
          {t("manualBalancing.display.sort.label")}
          <Select
            options={$enum(SortField)
              .getValues()
              .map(value => ({
                name: t(`manualBalancing.display.sort.${value}`),
                value,
              }))}
            value={sortBy}
            onChange={hnandleChangeSortBy}
          />
          <button data-sort={sortOrder} className={styles.display__sort} onClick={handleChangeSortOrder}>
            <ChevronDownIcon />
          </button>
        </div>
        <Divider className={styles.display__divider} />
        <p className={styles.display__label}>{t("manualBalancing.display.plan.label")}</p>
        <div className={styles.display__plan}>
          {$enum(Item)
            .getValues()
            .map(v => (
              <Toggle
                key={v}
                pressed={itemsDisplayedOnPlan.includes(v)}
                onPressedChange={() => handleChangeItemDisplayedOnPlan(v)}
              >
                {t(`manualBalancing.display.plan.${v}`)}
              </Toggle>
            ))}
        </div>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

Display.displayName = "ManualBalancing.Display";

export { Display };
