import { Link } from "react-router-dom";

import { NetworkIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { routeToNetwork } from "~/routes/utils";
import type { Pumps as PumpsType, Valve } from "~/socketio/types";

import { useBoilerRoomContextWithCurrentBoilerRoom } from "../../providers";
import { Card } from "../Card";
import { useHeatingNetworkContext } from "../HeatingNetwork";
import { NetworkDialog } from "../NetworkDialog";
import { Pumps } from "../Pumps";
import { Tank } from "../Tank";
import { Temperatures } from "../Temperatures";

import styles from "./ECS.module.scss";

const NAME = "ECS";

interface ECSProps {
  name?: string;
  startingTemperaturesECS?: number[];
  returnTemperaturesECS?: number[];
  recyclePumpsECS?: PumpsType;
  tankSanitaryHotWaterTemperature?: number;
  threeWayValve?: Valve;
}

export const ECS: React.FC<ECSProps> = ({
  name,
  recyclePumpsECS,
  startingTemperaturesECS,
  returnTemperaturesECS,
  tankSanitaryHotWaterTemperature,
  threeWayValve,
}) => {
  const bem = useBem(styles);
  const ecsStyle = bem("ecs");

  const { houseId, currentBoilerRoom } = useBoilerRoomContextWithCurrentBoilerRoom(NAME);
  const { heatingNetwork, orientation } = useHeatingNetworkContext(NAME);

  const boilerRoomId = currentBoilerRoom.id;
  const networkId = heatingNetwork.id;

  const startingTemperature = startingTemperaturesECS && parseFloat(Math.min(...startingTemperaturesECS).toFixed(1));
  const returningRemperature = returnTemperaturesECS && parseFloat(Math.min(...returnTemperaturesECS).toFixed(1));

  return (
    <div className={ecsStyle({ orientation })}>
      <Link to={routeToNetwork(houseId, boilerRoomId, networkId)}>
        <Card className={ecsStyle("card")} title={name?.toUpperCase() ?? "-"} icon={<NetworkIcon />} />
      </Link>
      <Temperatures
        className={ecsStyle("temperatures")}
        startingTemperature={startingTemperature}
        returnTemperature={returningRemperature}
      />
      {recyclePumpsECS && (
        <NetworkDialog
          dialogTitle={name ?? "-"}
          networkId={networkId}
          pumps={recyclePumpsECS}
          recyclePumpsECS={recyclePumpsECS}
          threeWaysValve={threeWayValve}
        >
          <Pumps className={ecsStyle("recycle-pump")} {...recyclePumpsECS} />
        </NetworkDialog>
      )}
      <Tank
        className={ecsStyle("tank")}
        orientation={orientation}
        tankSanitaryHotWaterTemperature={tankSanitaryHotWaterTemperature}
      />
    </div>
  );
};
