import { VALVE_ERRORS_ENUM, VALVE_WARNINGS_ENUM } from "@eisox/problems-handler";

import type { ValvesWithProblem } from "~/UI/screens/House";

const getNetworkDemand = (valves: ValvesWithProblem[], networkId: string): number | undefined => {
  const networkValves = valves.filter(v => v.heatingNetworkId === networkId);

  const connectedValves = networkValves.filter(
    v =>
      !v.errors.includes(VALVE_ERRORS_ENUM.DISCONNECTED) &&
      !v.warnings.includes(VALVE_WARNINGS_ENUM.DISCONNECTED_WARNING),
  );

  const closingDefined = (valve: ValvesWithProblem): valve is ValvesWithProblem & { closing: number } =>
    valve.closing !== undefined;
  const valvesWithClosing = connectedValves.filter(closingDefined);

  const openningAverage =
    valvesWithClosing.filter(v => v.closing).reduce((acc, cur) => (acc += 100 - cur.closing), 0) /
    valvesWithClosing.length;

  return Number.isNaN(openningAverage) ? undefined : Math.round(openningAverage);
};

export { getNetworkDemand };
