import { useIntl } from "react-intl";

import { Sidebar as DSSidebar } from "@eisox/design-system";
import { MapIcon } from "@eisox/icons";

import type { SidebarFooterProps } from "~/UI/components";
import { SidebarFooter } from "~/UI/components";

interface SidebarProps extends Omit<DSSidebar.FooterProps, "version">, SidebarFooterProps {
  canAccessPreprod?: boolean;
  displayChristmasHat?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({
  email,
  role,
  firstName,
  lastName,
  company,
  phone,
  lastNewsId,
  news,
  canAccessPreprod = false,
  displayChristmasHat = false,
}) => {
  const { formatMessage } = useIntl();

  const user = {
    email,
    role,
    firstName,
    phone,
    lastName,
    company,
  };

  return (
    <DSSidebar.Root
      style={{ zIndex: 1300 }}
      overlay={{ style: { zIndex: 1300 } }}
      displayChristmasHat={displayChristmasHat}
    >
      <DSSidebar.Header />
      {/* because of leaflet map default zIndex */}
      <DSSidebar.Content>
        <DSSidebar.Item isActive>
          <MapIcon />
          {formatMessage({ id: "houses.title" })}
        </DSSidebar.Item>
      </DSSidebar.Content>
      <SidebarFooter {...user} news={news} lastNewsId={lastNewsId} canAccessPreprod={canAccessPreprod} />
    </DSSidebar.Root>
  );
};
