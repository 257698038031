import { useState } from "react";

import { useIntl } from "react-intl";
import { NavLink, Navigate, Outlet, useParams, useRouteLoaderData } from "react-router-dom";

import { PostModuleTypeEnum } from "@eisox/backend_webapp_api";
import { ButtonV2 as Button, Typography } from "@eisox/design-system";
import { PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { usePermissionsContext } from "~/UI/screens";
import type { houseLoader } from "~/UI/screens";
import { idLoaderHouse, routeToModuleSetting } from "~/routes/utils";
import { concatBoilerroomNamesFromModule } from "~/utils";

import { AddDialog } from "./components";

import styles from "./Modules.module.scss";

export const Modules: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const modulesStyle = bem("modules");
  const selectorStyle = bem("selector");
  const addButtonStyle = bem("add-button");

  const { permissions } = usePermissionsContext();

  const { boilerroomPos, modules, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { houseId, moduleId } = useParams() as { houseId: string; moduleId?: string };

  const [addDialogOpen, setAddDialogOpen] = useState(false);

  if (!permissions.module?.read) throw new Error("Unauthorized");

  const eclypseBoilerRooms = modules.filter(
    m =>
      m.type &&
      [PostModuleTypeEnum.EclypseBoilerRoom as string, PostModuleTypeEnum.NiagaraBoilerRoom as string].includes(m.type),
  );

  if (modules.length > 0 && eclypseBoilerRooms.length > 0 && !addDialogOpen) {
    if (!moduleId) {
      return <Navigate to={routeToModuleSetting(houseId, eclypseBoilerRooms[0].id!)} replace />;
    }
  }

  const modulesWithBoilerroom = boilerroomPos.filter(bPos => bPos.boilerRooms && bPos.boilerRooms.length > 0);

  return (
    <>
      <div className={modulesStyle()}>
        <div className={selectorStyle()}>
          <h3 className={selectorStyle("title")}>
            {formatMessage({ id: "settings.content.menu.gtb.modules.subtitle" })}
          </h3>
          <div className={selectorStyle("list")}>
            {eclypseBoilerRooms.map((eclypseBoilerRoom, index) => {
              const module = modulesWithBoilerroom.find(m => m._id === eclypseBoilerRoom.id);
              const boilerRoomNames = module && concatBoilerroomNamesFromModule(module);
              return (
                <NavLink
                  key={index}
                  className={({ isActive }) => selectorStyle("module", { active: isActive })}
                  to={routeToModuleSetting(houseId, eclypseBoilerRoom.id!)}
                >
                  <Typography>{boilerRoomNames ?? eclypseBoilerRoom.url}</Typography>
                </NavLink>
              );
            })}
            {gateways.length > 0 && permissions.module.create && (
              <Button className={addButtonStyle()} onClick={() => setAddDialogOpen(true)}>
                {formatMessage({ id: "settings.content.menu.gtb.modules.add" })}
                <PlusIcon />
              </Button>
            )}
          </div>
        </div>
        <Outlet />
      </div>
      {addDialogOpen && <AddDialog open onClose={() => setAddDialogOpen(false)} />}
    </>
  );
};
