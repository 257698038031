import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import { RoomRoomTypeEnum } from "@eisox/backend_webapp_api";
import { Select, Typography } from "@eisox/design-system";

import { houseLoader } from "~/UI/screens";
import { idLoaderHouse } from "~/routes/utils";

export type SelectorsType = {
  types: RoomRoomTypeEnum[];
  plans: string[];
  groups: string[];
};

interface RoomsSelectsProps {
  filters: SelectorsType;
  onChange: (selectors: SelectorsType) => void;
  disabled?: boolean;
}

export const RoomsSelects: React.FC<RoomsSelectsProps> = ({ filters, onChange, disabled = false }) => {
  const { formatMessage } = useIntl();

  const { plans, groups, rooms } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const SELECTOR_ENUM = {
    types: (values: string[]) =>
      values.map(v => formatMessage({ id: `settings.content.menu.rooms.groups.select.filters.types.${v}` })).join(", "),
    plans: (values: string[]) => values.map(v => plans.find(p => p.id === v)?.name ?? "-").join(", "),
    groups: (values: string[]) => values.map(v => groups.find(g => g.name === v)?.name).join(", "),
  };

  const renderValue = (filter: "types" | "plans" | "groups") => (values?: string[]) => (
    <Typography>
      {values && values.length > 0
        ? SELECTOR_ENUM[filter](values)
        : formatMessage({ id: `settings.content.menu.rooms.groups.select.filters.${filter}.placeholder` })}
    </Typography>
  );

  const roomTypeOptions = rooms.map(r => r.roomType).filter((v, i, a) => a.indexOf(v) === i);

  return (
    <>
      <Select
        multiple
        value={filters.types}
        options={roomTypeOptions.map(t => ({
          value: t!,
          name: formatMessage({ id: `settings.content.menu.rooms.groups.select.filters.types.${t}` }),
        }))}
        renderValue={renderValue("types")}
        onChange={(values: string[]) => onChange({ ...filters, types: values as RoomRoomTypeEnum[] })}
        disabled={disabled}
      />
      <Select
        multiple
        value={filters.plans}
        options={plans.map(p => ({ value: p.id as string, name: p.name ?? "-" }))}
        renderValue={renderValue("plans")}
        onChange={(values: string[]) => onChange({ ...filters, plans: values })}
        disabled={disabled}
      />
      <Select
        multiple
        value={filters.groups}
        options={groups.map(g => ({ value: g.name as string, name: g.name ?? "-" }))}
        renderValue={renderValue("groups")}
        onChange={(values: string[]) => onChange({ ...filters, groups: values })}
        disabled={disabled}
      />
    </>
  );
};
