import type { HttpResponseResolver } from "msw";
import { HttpResponse } from "msw";

import type { DownloadPlan } from "@eisox/backend_webapp_api";

import plan from "~/assets/img/tests/plan.png";

const CLEVER_CLOUD_CELLAR_URL = "http://plan-eisox-dev.cellar-c2.services.clever-cloud.com/:planId.png";

const cleverCloudCellarUrl = (planId: string) => CLEVER_CLOUD_CELLAR_URL.replace(":planId", planId);

const getPlanByHouseIdAndPlanIdResolver: HttpResponseResolver<
  {
    houseId: string;
    planId: string;
  },
  never,
  DownloadPlan
> = ({ params }) => HttpResponse.json({ message: cleverCloudCellarUrl(params.planId) });

const getCleverCloudPlanResolver = async () => {
  const imageBuffer = await fetch(plan).then(res => res.arrayBuffer());
  return HttpResponse.arrayBuffer(imageBuffer, {
    headers: {
      "Content-Type": "image/png",
      "Content-Length": imageBuffer.byteLength.toString(),
    },
  });
};

export { CLEVER_CLOUD_CELLAR_URL, getPlanByHouseIdAndPlanIdResolver, getCleverCloudPlanResolver };
