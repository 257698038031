import { Fragment } from "react";

import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import { PlanningPlanningTypeEnum } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import { Card, RoundIcon } from "@eisox/design-system";
import { isSummerModeActivated } from "@eisox/houses";
import { QuestionMarkIcon } from "@eisox/icons";
import { isRoomFrostFree } from "@eisox/rooms";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import type { houseLoader } from "~/UI/screens";
import { GATEWAY_MIN_SOFTWARE_VERSIONS } from "~/constants";
import { idLoaderHouse } from "~/routes/utils";
import type { TempType } from "~/utils";
import { getCurrentDayForPlanning, getCurrentTimeSlot, getTemperature, mergeTimePeriods } from "~/utils";
import { isVersionIsUpper } from "~/utils/versionUtils";

import { useRoomContext } from "../../context";
import { getRoomData } from "../../utils/getRoomData";
import { ContentTooltip } from "./ContentTooltip";

import styles from "./ConsignTemp.module.scss";

export const ConsignTemp: React.FC = () => {
  const { formatMessage } = useIntl();
  const { plannings, valves, room } = useRoomContext();
  const { house, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const bem = useBem(styles);
  const consignTempStyle = bem("consignTemp");

  const { isAutoPrecomf = false, isSwitchEnabled = false, swOffset, correction } = room;

  const valvesOfTheRoomConnected = valves.filter(v => v.updatedAt && dayjs().diff(v.updatedAt, "minute") <= 12);
  const { anticipation, autoConf, autoPreConf, openWin, sun } = getRoomData(room, valvesOfTheRoomConnected);

  const mergedPlanningsToday = mergeTimePeriods(plannings.filter(p => p.day === getCurrentDayForPlanning()));
  const currentTimeSlot = getCurrentTimeSlot(mergedPlanningsToday);

  const isSummerMode = isSummerModeActivated(house.summerModePeriod?.begin, house.summerModePeriod?.end);
  const { isFrostFree } = isRoomFrostFree({ ...room }, { ...house });
  const disabledRoomData = isSummerMode || isFrostFree || openWin.selected;

  const autoPreConfEnabled = isAutoPrecomf && autoPreConf.selected;

  /**
   * Calculates the consign temperature based on the current time slot, house, room, and other factors.
   * @returns The consign temperature.
   */
  const getConsignTemp = () => {
    const tempCurrentTimeSlot = getTemperature(house, room, currentTimeSlotTypeForConsign as PlanningPlanningTypeEnum);
    const swOffsetTemp = swOffsetEnabled && swOffset ? swOffset : 0;
    const correctionTemp = correctionEnabled && correction ? correction : 0;
    return tempCurrentTimeSlot + swOffsetTemp + correctionTemp;
  };

  /**
   * Retrieves the current planning type based on the room data.
   * @returns The current planning type.
   */
  const getCurrentPlanningType = () => {
    switch (true) {
      case isFrostFree:
        return currentTimeSlot.planningType!;
      case autoConf.selected:
        return PlanningPlanningTypeEnum.Comfort;
      case anticipation.selected:
        const firstValveAnticipation = valvesOfTheRoomConnected.find(
          v => v.stateData?.anticipation && v.correctedTemp !== undefined && v.correction !== undefined,
        );
        if (firstValveAnticipation) {
          const consignTempAnticipation = firstValveAnticipation.correctedTemp! - firstValveAnticipation.correction!;
          [PlanningPlanningTypeEnum.Comfort, PlanningPlanningTypeEnum.PreComfort].forEach(type => {
            if (consignTempAnticipation === getTemperature(house, room, type)) return type;
          });
          return currentTimeSlot.planningType!;
        } else return currentTimeSlot.planningType!;
      case autoPreConfEnabled:
        return PlanningPlanningTypeEnum.PreComfort;
      default:
        return currentTimeSlot.planningType!;
    }
  };

  /**
   * @returns {boolean} Returns True if there are several boxes associated with the list of heads in the room,
   * and if the box exists and there is at least one where the software version is lower than 5.0.0,
   *  because no data is shared between the boxes (software < 5.0.0).
   */
  const isShowBoxNote = () => {
    const gatewayIds = [...new Set(valvesOfTheRoomConnected.map(item => item.gatewayId))];
    return (
      gatewayIds.length > 1 &&
      gatewayIds.some(gatewayId => {
        const findGateway = gateways.find(g => g.id === gatewayId);
        return findGateway && !isVersionIsUpper(findGateway.softwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.SHARE_DATA);
      })
    );
  };

  const currentTimeSlotTypeForConsign = getCurrentPlanningType();

  const swOffsetEnabled =
    !isFrostFree &&
    (currentTimeSlotTypeForConsign === PlanningPlanningTypeEnum.Comfort ||
      currentTimeSlotTypeForConsign === PlanningPlanningTypeEnum.PreComfort ||
      currentTimeSlotTypeForConsign === PlanningPlanningTypeEnum.Night) &&
    isSwitchEnabled;

  const correctionEnabled =
    !isFrostFree &&
    (currentTimeSlotTypeForConsign === PlanningPlanningTypeEnum.Comfort ||
      currentTimeSlotTypeForConsign === PlanningPlanningTypeEnum.PreComfort);

  return (
    <Tooltip
      className={consignTempStyle("tooltip")}
      openingCondition={!disabledRoomData}
      content={
        <ContentTooltip
          currentTimeSlot={{
            temperature: getTemperature(house, room, currentTimeSlotTypeForConsign as TempType),
            type: currentTimeSlotTypeForConsign as PlanningPlanningTypeEnum,
          }}
          room={room}
          roomData={{ anticipation, autoConf, autoPreConf, openWin, sun }}
          isShowBoxNote={isShowBoxNote()}
          isShowCorrection={correctionEnabled}
          isShowSwOffset={swOffsetEnabled}
        />
      }
    >
      <Card className={consignTempStyle()}>
        <p>
          {isSummerMode
            ? formatMessage({ id: "room.ConsignTemp.summerMode" })
            : openWin.selected
              ? formatMessage({ id: "room.ConsignTemp.openWindow" })
              : formatMessage({ id: "room.ConsignTemp.title" }, { t: getConsignTemp().toFixed(1) })}
        </p>
        {!disabledRoomData && (
          <>
            <div className={consignTempStyle("room-data-active")}>
              {Object.values({ anticipation, autoConf, autoPreConf, openWin, sun }).map((item, index) => (
                <Fragment key={index}>{item.selected && !item.disabled && item.icon}</Fragment>
              ))}
            </div>
            <RoundIcon size={20} backgroundColor="gray" iconColor="darkGray" className={consignTempStyle("help")}>
              <QuestionMarkIcon style={{ width: 10, height: 10 }} />
            </RoundIcon>
          </>
        )}
      </Card>
    </Tooltip>
  );
};
