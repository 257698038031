import { useTranslation } from "react-i18next";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { $enum } from "ts-enum-util";

import { useSuspenseQueries, useSuspenseQuery } from "@tanstack/react-query";

import { Page, usePermissionsContext } from "~/UI";
import type { loader as houseLoader } from "~/UI/screens/House";
import { queries } from "~/apiV2";
import { AddUserDrawer, Content, HelpPopup } from "~/features/Users";
import { idLoaderHouse } from "~/routes/utils";
import { UserRole } from "~/utils";

import type { loader } from "./loader";

export default () => {
  const { t } = useTranslation();

  const { permissions } = usePermissionsContext();
  if (!permissions.page?.users) throw new Error("Unauthorized");

  const { house, boilerroomPos } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { houseId } = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;
  const { data: roles } = useSuspenseQuery(queries.user.roles);

  const houseName = house.houseName!;

  const [{ data: rolesInHouses }, { data: houseUsers }, { data: creators }] = useSuspenseQueries({
    queries: [queries.user.rolesInHouses, queries.user.houseUsers(houseId), queries.user.creators],
  });

  const connectedUserRole =
    $enum(UserRole)
      .getValues()
      .find(k => roles[k]?.includes(houseId)) ?? UserRole.VISITOR;
  const boilerRoom = boilerroomPos.length > 0;

  return (
    <>
      <Page.Header
        title={t("users.title")}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <HelpPopup />
        <AddUserDrawer
          boilerRoom={boilerRoom}
          houseId={houseId}
          connectedUserRole={connectedUserRole}
          rolesInHouse={rolesInHouses}
          creators={creators}
        />
      </Page.Header>
      <Content
        houseId={houseId}
        houseName={houseName}
        connectedUserRole={connectedUserRole}
        rolesInHouses={rolesInHouses}
        houseUsers={houseUsers}
        creators={creators}
      />
    </>
  );
};
