import type { ChangeEvent } from "react";
import { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { formatPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { $enum } from "ts-enum-util";

import { primary } from "@eisox/colors";
import dayjs from "@eisox/dayjs";
import { ActionButtonV2 as ActionButton, SelectV2 as Select, TextField, Typography } from "@eisox/design-system";
import { BinIcon, CheckedIcon, MagnifyingGlassIcon, PencilIcon, RejectIcon } from "@eisox/icons";
import type { PathsHousesHouseIdNotificationsPutRequestBodyApplicationJsonPeriodicity as ReplaceEmailPeriodicity } from "@eisox/webapp-api-specification";
import {
  PathsHousesHouseIdNotificationsPutRequestBodyApplicationJsonPeriodicity as MaintenanceEmailPeriodicity,
  PathsHousesHouseIdNotificationsDeleteRequestBodyApplicationJsonType as RemoveAlertType,
  PathsHousesHouseIdNotificationsPutRequestBodyApplicationJsonMethods as ReplaceAlertMethod,
  PathsHousesHouseIdNotificationsPutRequestBodyApplicationJsonType as ReplaceAlertType,
} from "@eisox/webapp-api-specification";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ColumnDef } from "@tanstack/react-table";

import { Page } from "~/UI/components";
import type {
  Creators,
  DeleteNotificationRequestBody,
  HouseUsers,
  PutNotificationRequestBody,
  RolesInHouses,
} from "~/apiV2";
import { queries } from "~/apiV2";
import { putNotification, removeNotification } from "~/apiV2/mutations/notification";
import { UserRole } from "~/utils/User";

import type { Filters, User } from "../../helpers";
import { filterUsers, getUsers } from "../../helpers";
import { DeleteUserDialog } from "../DeleteUserDialog";
import { Role } from "../Role";
import { UpdateUserDialog } from "../UpdateUserDialog";
import { Table } from "../UserTable";

import styles from "./Content.module.scss";

interface ContentProps {
  houseId: string;
  houseName: string;
  connectedUserRole: UserRole;
  rolesInHouses: RolesInHouses;
  houseUsers: HouseUsers;
  creators: Creators;
}

const Content: React.FC<ContentProps> = ({
  houseId,
  houseName,
  connectedUserRole,
  rolesInHouses,
  houseUsers,
  creators,
}) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<Filters>({ roles: [], search: "" });
  const users: User[] = getUsers(houseId, rolesInHouses, houseUsers);
  const filteredUsers = filterUsers(users, filters);

  const [userToDeleteId, setUserToDeleteId] = useState<string>();
  const userToDelete = filteredUsers.find(u => u.id === userToDeleteId);

  const [userToUpdateId, setUserToUpdateId] = useState<string>();
  const userToUpdate = filteredUsers.find(u => u.id === userToUpdateId);

  const queryClient = useQueryClient();
  const { mutate: removeAlert } = useMutation({
    mutationFn: (body: DeleteNotificationRequestBody) => removeNotification(houseId, body),
    onSuccess: async () => await queryClient.invalidateQueries({ queryKey: queries.user.houseUsers(houseId).queryKey }),
    onError: () => toast.error(t("error.unKnowError.message")),
  });
  const { mutate: replaceAlert } = useMutation({
    mutationFn: (body: PutNotificationRequestBody) => putNotification(houseId, body),
    onSuccess: async () => await queryClient.invalidateQueries({ queryKey: queries.user.houseUsers(houseId).queryKey }),
    onError: () => toast.error(t("error.unKnowError.message")),
  });

  const roleOptions = useMemo(
    () =>
      $enum(UserRole)
        .getValues()
        .filter(r => r !== UserRole.MANAGER_BOILERROOM)
        .map(r => ({ value: r, name: t(`users.role.${r}`) })),
    [t],
  );
  const maintenanceEmailOptions = useMemo(
    () =>
      [...$enum(MaintenanceEmailPeriodicity).getValues(), "disabled"].map(v => ({
        value: v,
        name: t(`users.table.maintenanceEmail.${v}`),
      })),
    [t],
  );

  const handleChangeMaintenanceEmail = useCallback(
    (value: string | string[], userId: string) => {
      if (value !== "disabled") {
        replaceAlert([
          {
            type: ReplaceAlertType.maintenance,
            userId,
            periodicity: value as ReplaceEmailPeriodicity,
            methods: [ReplaceAlertMethod.email],
          },
        ]);
      } else {
        removeAlert([{ type: RemoveAlertType.maintenance, userId }]);
      }
    },
    [removeAlert, replaceAlert],
  );
  const handleChangeAlert = useCallback(
    (value: string | string[], userId: string) => {
      if (value.length) {
        replaceAlert([{ type: ReplaceAlertType.gtb, userId, methods: value as ReplaceAlertMethod[] }]);
      } else {
        removeAlert([{ type: RemoveAlertType.gtb, userId }]);
      }
    },
    [removeAlert, replaceAlert],
  );
  const handleUpdateUser = useCallback((userId: string) => setUserToUpdateId(userId), []);
  const handleDeleteUser = useCallback((userId: string) => setUserToDeleteId(userId), []);

  const columns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        accessorKey: "firstName",
        header: t("users.table.firstName"),
        cell: ({ getValue }) => <Typography>{getValue<User["firstName"]>()}</Typography>,
      },
      {
        accessorKey: "lastName",
        header: t("users.table.lastName"),
        cell: ({ getValue }) => <Typography>{getValue<User["lastName"]>()}</Typography>,
      },
      {
        accessorKey: "email",
        header: t("users.table.email"),
        cell: ({ getValue }) => <Typography>{getValue<User["email"]>()}</Typography>,
      },
      {
        accessorKey: "phone",
        header: t("users.table.phone"),
        cell: ({ getValue }) => {
          const phone = getValue<User["phone"]>();
          const { number } = parsePhoneNumber(phone?.number ?? "") ?? {};
          return (
            <div className={styles.table__phone}>
              <Typography>{number ? formatPhoneNumber(number) : "-"}</Typography>
              {phone?.verified !== undefined &&
                (phone.verified ? (
                  <CheckedIcon color={primary.green} width={20} height={20} />
                ) : (
                  <RejectIcon color={primary.red} width={20} height={20} />
                ))}
            </div>
          );
        },
      },
      {
        accessorKey: "roles",
        header: t("users.table.role"),
        cell: ({ getValue }) => {
          const roles = getValue<User["roles"]>();
          return roles.map(r => <Role key={r} role={r} displayTooltip />);
        },
      },
      {
        accessorKey: "additionDate",
        header: t("users.table.additionDate"),
        cell: ({ getValue }) => {
          const date = getValue<User["additionDate"]>();
          return <Typography>{date ? dayjs(date).format("L") : "-"}</Typography>;
        },
      },
      {
        accessorKey: "maintenanceEmail",
        header: t("users.table.maintenanceEmail.header"),
        enableSorting: false,
        cell: ({ row, getValue }) => {
          const value = getValue<User["maintenanceEmail"]>();
          const userId = row.original.id;
          return (
            <Select
              options={maintenanceEmailOptions}
              value={value}
              onChange={value => handleChangeMaintenanceEmail(value, userId)}
            />
          );
        },
      },
      {
        accessorKey: "alert",
        header: t("users.table.alert.header"),
        enableSorting: false,
        cell: ({ row, getValue }) => {
          const value = getValue<User["alert"]>();
          const phone = row.getValue<User["phone"]>("phone");
          const userId = row.original.id;
          return (
            <Select
              placeholder={t("users.table.alert.placeholder")}
              options={["sms", "email"].map(a => ({
                value: a,
                name: t(`users.table.alert.${a}`),
                disabled: !phone && a === "sms",
              }))}
              value={value}
              onChange={value => handleChangeAlert(value, userId)}
            />
          );
        },
      },
      {
        id: "update",
        enableSorting: false,
        header: "",
        cell: ({ row }) => {
          const userId = row.original.id;
          return (
            !filteredUsers.find(u => u.id === userId)?.roles.includes(UserRole.OWNER) && (
              <ActionButton className={styles.table__edit} rounded onClick={() => handleUpdateUser(userId)}>
                {t("users.table.update")} <PencilIcon />
              </ActionButton>
            )
          );
        },
      },
      {
        id: "delete",
        header: "",
        enableSorting: false,
        cell: ({ row }) => {
          const userId = row.original.id;
          return (
            !filteredUsers.find(u => u.id === userId)?.roles.includes(UserRole.OWNER) && (
              <BinIcon className={styles.table__delete} onClick={() => handleDeleteUser(userId)} />
            )
          );
        },
      },
    ],
    [
      filteredUsers,
      handleChangeAlert,
      handleChangeMaintenanceEmail,
      handleDeleteUser,
      handleUpdateUser,
      maintenanceEmailOptions,
      t,
    ],
  );

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setFilters(prev => ({ ...prev, search: e.target.value }));

  return (
    <>
      <Page.Content className={styles.content}>
        <div className={styles.filters}>
          <Select
            classNames={{ trigger: styles.role, content: styles.role__content }}
            placeholder={t("users.fitlers.role")}
            options={roleOptions}
            renderOption={(name, value) => {
              const roleCount = users.filter(u => u.roles.includes(value as UserRole)).length;
              return (
                <div className={styles.role__option}>
                  <Typography>{name}</Typography>
                  <Role role={value as UserRole} size={8} />
                  <span>{roleCount < 10 ? `0${roleCount}` : roleCount}</span>
                </div>
              );
            }}
            value={filters.roles}
            onChange={value => setFilters(prev => ({ ...prev, roles: value as UserRole[] }))}
          />
          <TextField.Root
            className={styles.filters__search}
            placeholder={t("users.fitlers.search")}
            value={filters.search}
            onChange={handleChangeSearch}
          >
            <TextField.Slot>
              <MagnifyingGlassIcon />
            </TextField.Slot>
          </TextField.Root>
        </div>
        <Table options={{ data: filteredUsers, columns: columns }} />
      </Page.Content>
      {userToDelete && (
        <DeleteUserDialog
          user={userToDelete}
          houseId={houseId}
          houseName={houseName}
          open
          onOpenChange={() => setUserToDeleteId(undefined)}
        />
      )}
      {userToUpdate && (
        <UpdateUserDialog
          user={userToUpdate}
          houseId={houseId}
          connectedUserRole={connectedUserRole}
          rolesInHouses={rolesInHouses}
          creators={creators}
          open
          onOpenChange={() => setUserToUpdateId(undefined)}
        />
      )}
    </>
  );
};

export { Content };
export type { ContentProps };
