import * as React from "react";

import { IconProps } from "./types";

export const TwoWayValveIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M13.3091 4.40662L8.27266 7.3197C8.14924 7.3925 8.04694 7.4964 7.97589 7.62114C7.90479 7.74589 7.86738 7.88714 7.86738 8.03088C7.86738 8.17462 7.90479 8.31587 7.97589 8.44062C8.04694 8.56536 8.14924 8.66926 8.27266 8.74206L13.3091 11.6552C13.4284 11.7252 13.5642 11.7621 13.7024 11.7621C13.8406 11.7621 13.9763 11.7252 14.0957 11.6552C14.2198 11.5833 14.3227 11.4796 14.3938 11.3546C14.4648 11.2296 14.5015 11.0879 14.5 10.9439V5.11781C14.5014 4.97401 14.4647 4.83242 14.3936 4.70756C14.3226 4.5827 14.2198 4.47907 14.0957 4.40729C13.9764 4.33714 13.8407 4.30012 13.7025 4.3C13.5643 4.29988 13.4285 4.33668 13.3091 4.40662ZM13.8301 4.86865C13.8732 4.89404 13.9089 4.9305 13.9333 4.97429C13.9578 5.01807 13.9702 5.06761 13.9693 5.11781V10.9439C13.9702 10.9941 13.9578 11.0437 13.9333 11.0875C13.9089 11.1312 13.8732 11.1677 13.8301 11.1931C13.7913 11.2161 13.7471 11.2282 13.7021 11.2282C13.657 11.2282 13.6128 11.2161 13.574 11.1931L8.53764 8.28005C8.49472 8.25416 8.45917 8.21749 8.43455 8.17372C8.40987 8.12994 8.39693 8.08049 8.39693 8.03019C8.39693 7.97989 8.40987 7.93049 8.43455 7.88671C8.45917 7.84294 8.49472 7.80627 8.53764 7.78038L13.574 4.86731C13.6129 4.84451 13.6572 4.83261 13.7022 4.83284C13.7473 4.83308 13.7914 4.84544 13.8301 4.86865Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M2.69092 11.6575L7.72734 8.74439C7.85076 8.67164 7.95307 8.56769 8.02417 8.44295C8.09522 8.3182 8.13262 8.17695 8.13262 8.03321C8.13262 7.88947 8.09522 7.74822 8.02417 7.62347C7.95307 7.49873 7.85076 7.39483 7.72734 7.32203L2.69092 4.40896C2.57157 4.33892 2.43584 4.30201 2.29761 4.30201C2.15939 4.30201 2.02365 4.33892 1.9043 4.40896C1.78014 4.4808 1.67725 4.58454 1.60621 4.70952C1.53516 4.83451 1.49852 4.97624 1.50005 5.12016V10.9463C1.49864 11.0901 1.53534 11.2317 1.60637 11.3565C1.67742 11.4814 1.78024 11.585 1.9043 11.6568C2.02359 11.7269 2.1593 11.764 2.29752 11.7641C2.43574 11.7642 2.57151 11.7274 2.69092 11.6575ZM2.16993 11.1954C2.1268 11.1701 2.09118 11.1336 2.0667 11.0898C2.04223 11.046 2.02979 10.9965 2.03065 10.9463V5.12016C2.02979 5.06995 2.04223 5.02041 2.0667 4.97663C2.09118 4.93284 2.1268 4.89638 2.16993 4.87099C2.2087 4.84798 2.25291 4.83585 2.29794 4.83585C2.34297 4.83585 2.38718 4.84798 2.42595 4.87099L7.46241 7.78404C7.50528 7.80998 7.54084 7.8466 7.56546 7.89037C7.59013 7.93415 7.60308 7.9836 7.60308 8.0339C7.60308 8.0842 7.59013 8.1336 7.56546 8.17743C7.54084 8.22121 7.50528 8.25782 7.46241 8.28371L2.42595 11.1968C2.38706 11.2196 2.34279 11.2315 2.29776 11.2313C2.25273 11.231 2.20859 11.2187 2.16993 11.1954Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default TwoWayValveIcon;
