import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";

const getHousePositions = async () => {
  const { data, error } = await client.GET("/houses/pos");
  // @ts-expect-error : error in open-api specification
  if (error) throw new Error(error);
  return data?.message ?? [];
};

export const house = createQueryKeys("house", {
  pos: {
    queryKey: null,
    queryFn: getHousePositions,
  },
});
