/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useState } from "react";

import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import { Drawer, DropdownMenu, Radio, SearchInput } from "@eisox/design-system";
import { GatewayIcon, OptionIcon } from "@eisox/icons";
import { GATEWAY_ERRORS_ENUM } from "@eisox/problems-handler";
import { useBem, useKeyPress } from "@eisox/tools";

import type { houseLoader } from "~/UI";
import { Tooltip } from "~/UI";
import { ConnectionGatewayHistory } from "~/UI/components/ConnectionGatewayHistory";
import { DropdownDetails } from "~/UI/layouts/ListDrawer/components/DropdownDetails/DropdownDetails";
import { usePermissionsContext } from "~/UI/screens";
import type { GatewaysWithProblem } from "~/UI/screens/House";
import { MoveDialog } from "~/UI/screens/Plans/components/Actions";
import { useFilteredItems } from "~/hooks/useFilteredItems";
import { idLoaderHouse } from "~/routes/utils";
import { getGatewayProblemTranslation, getMeshsName } from "~/utils";

import type { DataInformationProps } from "../components/DataInformation";
import { DataInformation } from "../components/DataInformation";
import { ItemInfo } from "../components/ItemInfo";
import { ReplacePopUp } from "../components/ReplacePopUp";
import { EditGatewayDrawer } from "./EditGatewayDrawer";

import styles from "./GatewaysListDrawer.module.scss";

interface GatewaysListDrawerProps {
  open?: boolean;
  onClose?: () => void;
  choosenGatewayId?: string;
  gateways: GatewaysWithProblem[];
}

type SortFilterBy = ("gatewayName" | "uid" | "meshs" | "mac") & keyof GatewaysWithProblem;

export const GatewaysListDrawer: React.FC<GatewaysListDrawerProps> = ({
  open = false,
  onClose,
  gateways,
  choosenGatewayId,
}) => {
  const { formatMessage } = useIntl();
  const { meshs } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { permissions } = usePermissionsContext();

  useKeyPress({ code: "ArrowUp" }, () => onKeyPress("up"));
  useKeyPress({ code: "ArrowDown" }, () => onKeyPress("down"));
  //! Bug les raccourcis clavier ne s'exécutent dans tout les niveaux de div
  /*   useKeyPress(
    { code: "KeyE", shiftKey: true },
    () => !edit.disabled && edit.isAuthorized && setEditGatewayDrawerOpen(true),
  );
  useKeyPress(
    { code: "KeyD", shiftKey: true },
    () => !move.disabled && move.isAuthorized && setMoveGatewayDrawerOpen(true),
  );
  useKeyPress(
    { code: "KeyR", shiftKey: true },
    () => !replace.disabled && replace.isAuthorized && setReplaceGatewayDrawerOpen(true),
  ); */

  const bem = useBem(styles);
  const gatewaysListDrawerStyle = bem("gateways-list-drawer");
  const gatewaysInfoStyle = bem("gateway-info");
  const searchContainerStyle = bem("search-container");
  const dropdownStyle = bem("dropdown");

  const [sortBy, setSortBy] = useState<SortFilterBy>("uid");
  const [search, setSearch] = useState<string>("");
  const [editGatewayDrawerOpen, setEditGatewayDrawerOpen] = useState<boolean>(false);
  const [replaceGatewayPopupOpen, setReplaceGatewayPopupOpen] = useState<boolean>(false);
  const [moveGatewayPopupOpen, setMoveGatewayPopupOpen] = useState<boolean>(false);
  const [connectionGatewayHistory, setConnectionGatewayHistory] = useState<boolean>(false);
  const [selectedGatewayId, setSelectedGatewayId] = useState<string | undefined>(choosenGatewayId ?? gateways[0]?.id);

  const gatewaysToDisplay = useFilteredItems<GatewaysWithProblem>(
    gateways,
    search,
    [sortBy === "meshs" ? g => g.meshs?.[0]?.id : sortBy],
    [
      { key: "gatewayName" },
      { key: "mac" },
      {
        key: "meshs",
        function: (g: GatewaysWithProblem, filterValue: string) => {
          return getMeshsName(g, meshs)
            .map(m => m.toUpperCase())
            .join(", ")
            .includes(filterValue);
        },
      },
      { key: "uid" },
    ],
  );

  // when delete gateway
  if (!gateways.find(gateway => gateway.id === selectedGatewayId) && gateways.length > 0) {
    setSelectedGatewayId(gateways[0]?.id);
  } // when delete last gateway
  else if (gateways.length === 0) {
    onClose?.();
  }

  const selectedGateway = gateways.find(gateway => gateway.id === selectedGatewayId);
  const { problemStatus } = selectedGateway ?? {};
  const translation = selectedGateway && getGatewayProblemTranslation(selectedGateway);

  const onKeyPress = (key: string) => {
    const currentIndex = gatewaysToDisplay.findIndex(gateway => gateway.id === selectedGateway?.id);
    const nextIndex = key === "up" ? currentIndex - 1 : currentIndex + 1;
    const nextGateway = gatewaysToDisplay[nextIndex];
    setSelectedGatewayId(nextGateway.id);
  };

  const resetFilters = () => {
    setSearch("");
    setSortBy("uid");
  };

  const gatewayInformations: DataInformationProps[] = [
    {
      label: formatMessage({ id: "drawer.listDrawer.boxsDrawer.detailGateway.version" }),
      value: `${selectedGateway?.hardwareVersion ?? "-"} (${selectedGateway?.softwareVersion ?? "-"})`,
    },
    {
      label: formatMessage({ id: "drawer.listDrawer.boxsDrawer.detailGateway.ip" }),
      value: selectedGateway?.ip,
    },
    {
      label: formatMessage({ id: "drawer.listDrawer.boxsDrawer.detailGateway.wifi.label" }),
      value:
        selectedGateway?.isWifiEnabled !== undefined
          ? formatMessage(
              { id: "drawer.listDrawer.boxsDrawer.detailGateway.wifi.value" },
              { w: selectedGateway.isWifiEnabled },
            )
          : undefined,
    },
    {
      label: formatMessage({ id: "drawer.listDrawer.boxsDrawer.detailGateway.ethernet.label" }),
      value:
        selectedGateway?.isEthUp !== undefined
          ? formatMessage(
              { id: "drawer.listDrawer.boxsDrawer.detailGateway.ethernet.value" },
              { e: selectedGateway.isEthUp },
            )
          : undefined,
    },
    {
      label: formatMessage(
        { id: "drawer.listDrawer.boxsDrawer.detailGateway.mesh" },
        { m: selectedGateway?.meshs?.length },
      ),
      value: (selectedGateway && getMeshsName(selectedGateway, meshs).join(", ")) ?? "-",
      auth: permissions.gateway?.meshs?.read,
    },
    {
      label: formatMessage({ id: "drawer.listDrawer.boxsDrawer.detailGateway.borderRouter.label" }),
      value: (
        <Tooltip
          content={selectedGateway?.borderRouterStatus.join(", ")}
          openingCondition={
            permissions.gateway?.borderRouterStatus?.read &&
            selectedGateway?.errors.includes(GATEWAY_ERRORS_ENUM.BORDER_ROUTER_STATUS)
          }
        >
          <p>
            {permissions.gateway?.borderRouterStatus?.read &&
            selectedGateway?.errors.includes(GATEWAY_ERRORS_ENUM.BORDER_ROUTER_STATUS)
              ? "Erreur"
              : selectedGateway?.borderRouterStatus[0]}{" "}
            {permissions.gateway?.brModRf?.read && (
              <>
                ({formatMessage({ id: "drawer.listDrawer.boxsDrawer.detailGateway.borderRouter.modeRF" })}:{" "}
                {selectedGateway?.brModRf &&
                  formatMessage({
                    id: `drawer.listDrawer.boxsDrawer.detailGateway.borderRouter.brModRf.${selectedGateway.brModRf}`,
                  })}
                )
              </>
            )}
          </p>
        </Tooltip>
      ),
      auth: permissions.gateway?.brModRf?.read || permissions.gateway?.borderRouterStatus?.read,
    },
  ];

  const sortFilterGatewaysBy: SortFilterBy[] = ["gatewayName", "uid", "meshs", "mac"];

  const edit = {
    isAuthorized:
      permissions.gateway?.gatewayName?.update ||
      permissions.gateway?.expireAt?.update ||
      permissions.gateway?.delete ||
      permissions.gateway?.brModRf?.update ||
      permissions.gateway?.isInternetGateway?.update,
    onClick: () => setEditGatewayDrawerOpen(true),
    disabled: !selectedGateway,
  };

  const move = {
    isAuthorized: permissions.gateway?.plan?.update,
    onClick: () => setMoveGatewayPopupOpen(true),
    disabled: !selectedGateway,
  };

  const replace = {
    isAuthorized: permissions.gateway?.mac?.update,
    onClick: () => setReplaceGatewayPopupOpen(true),
    disabled: !selectedGateway?.mac,
  };

  const lastActivityHistory = {
    isAuthorized: permissions.history?.gateway?.lastGatewayActivity?.read,
    onClick: () => setConnectionGatewayHistory(true),
  };

  return (
    <Drawer
      title={formatMessage({ id: "drawer.listDrawer.boxsDrawer.title" })}
      open={open}
      onOpenChange={() => {
        resetFilters();
        onClose?.();
      }}
    >
      <>
        <div className={gatewaysListDrawerStyle()}>
          {gateways.length >= 5 && !choosenGatewayId && (
            <div className={searchContainerStyle()}>
              <SearchInput
                iconPosition="left"
                placeholder={sortFilterGatewaysBy
                  .map(s => formatMessage({ id: `drawer.listDrawer.boxsDrawer.sortFilterBy.${s}` }))
                  .join(", ")}
                value={search}
                onChange={value => setSearch(value)}
                className={searchContainerStyle("search")}
              />
              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <OptionIcon style={{ height: 20, width: 20, cursor: "pointer" }} />
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="end" sideOffset={10} style={{ zIndex: 100 }}>
                  <DropdownMenu.RadioGroup value={sortBy} onValueChange={value => setSortBy(value as SortFilterBy)}>
                    {sortFilterGatewaysBy.map((s, i) => (
                      <DropdownMenu.RadioItem key={i} value={s}>
                        <DropdownMenu.ItemIndicator asChild forceMount>
                          <Radio style={{ marginRight: 10 }} checked={sortBy === s} />
                        </DropdownMenu.ItemIndicator>
                        <p>{formatMessage({ id: `drawer.listDrawer.boxsDrawer.sortFilterBy.${s}` })}</p>
                      </DropdownMenu.RadioItem>
                    ))}
                  </DropdownMenu.RadioGroup>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </div>
          )}
          {!choosenGatewayId && (
            <div className={gatewaysInfoStyle()}>
              {gatewaysToDisplay.map((gateway, i) => {
                const selected = gateway.id === selectedGateway?.id;
                return (
                  <ItemInfo
                    error={gateway.problemStatus}
                    selected={selected}
                    label={`${gateway.uid} - ${gateway.gatewayName || "-"}`}
                    subLabel={gateway.mac || "-"}
                    icon={<GatewayIcon style={{ height: 20, width: 20 }} />}
                    key={i}
                    onClick={() => setSelectedGatewayId(gateway.id)}
                    className={gatewaysInfoStyle("gateway")}
                  />
                );
              })}
            </div>
          )}

          <DropdownDetails
            full={!!choosenGatewayId}
            header={
              <ItemInfo
                error={problemStatus}
                label={`${selectedGateway?.uid} - ${selectedGateway?.gatewayName || "-"}`}
                subLabel={selectedGateway?.mac || "-"}
                icon={<GatewayIcon style={{ height: 20, width: 20 }} />}
              />
            }
            lastCom={selectedGateway?.lastGatewayActivity}
            errors={translation?.errors || []}
            warnings={(permissions.gateway?.warning && translation?.warnings) || []}
            move={move}
            replace={replace}
            edit={edit}
            lastActivityHistory={lastActivityHistory}
          >
            {gatewayInformations.map((e, i) => (
              <DataInformation {...e} key={i} />
            ))}
          </DropdownDetails>
        </div>

        {editGatewayDrawerOpen && !!selectedGateway && (
          <EditGatewayDrawer
            open={editGatewayDrawerOpen}
            onClose={reason => {
              if (reason === "edit" || reason === "delete") {
                resetFilters();
              }
              setEditGatewayDrawerOpen(false);
            }}
            gateway={selectedGateway}
          />
        )}

        {replaceGatewayPopupOpen && !!selectedGateway && (
          <ReplacePopUp
            open={replaceGatewayPopupOpen}
            onClose={() => setReplaceGatewayPopupOpen(false)}
            isForReplaceBox
            elementToReplace={selectedGateway}
          />
        )}

        {moveGatewayPopupOpen && (
          <MoveDialog
            ressource={"gateway"}
            items={gateways}
            initialSelectedItemId={selectedGatewayId}
            open={moveGatewayPopupOpen}
            onClose={() => setMoveGatewayPopupOpen(false)}
          />
        )}

        {selectedGateway?.mac && connectionGatewayHistory && (
          <ConnectionGatewayHistory
            gatewayName={selectedGateway.gatewayName ?? "-"}
            uid={selectedGateway.uid}
            mac={selectedGateway.mac}
            open={connectionGatewayHistory}
            onOpenChange={setConnectionGatewayHistory}
          />
        )}
      </>
    </Drawer>
  );
};
