import { useState } from "react";

import { useTranslation } from "react-i18next";

import dayjs from "@eisox/dayjs";
import { ButtonV2 as Button, Modal, SelectV2 as Select } from "@eisox/design-system";
import { ArrowRightIcon, CrossIcon, PointChartIcon } from "@eisox/icons";
import type { PostHistovalvesStatsFields } from "@eisox/webapp-api-specification";
import { useQuery } from "@tanstack/react-query";

import { HistoryChart } from "~/UI/components";
import type { ValvesWithProblem } from "~/UI/screens/House";
import { usePermissionsContext } from "~/UI/screens/House";
import { queries } from "~/apiV2";
import { useHistoryStore } from "~/stores/history";
import { getDateOptions } from "~/utils/historyUtils";

import { formatUnit, getDataTypeByPermission, getUnitAxesMapping, transformData } from "../../helpers";
import { DataExportPopup } from "../DataExportPopup";

import styles from "./ValveHistoryDialog.module.scss";

interface ValveHistoryDialogProps extends Modal.RootProps {
  valves: ValvesWithProblem[];
  valveId?: string;
}

const ValveHistoryDialog: React.FC<ValveHistoryDialogProps> = ({ valves, valveId, ...props }) => {
  const { t } = useTranslation();

  const { role, permissions } = usePermissionsContext();

  const valvesWithMac = valves.filter(valve => valve.mac);
  const selectedValve = valvesWithMac.find(v => v.id === valveId);

  const data = useHistoryStore.use.valve();
  const setData = useHistoryStore.use.setValves();

  const [valve, setValve] = useState(selectedValve ?? valvesWithMac[0]);
  const [startDate, setStartDate] = useState(dayjs().startOf("day").toISOString());
  const endDate = dayjs(startDate).endOf("day").toISOString();

  const fields = getDataTypeByPermission(permissions, valve.mac!, valvesWithMac);

  const { data: response } = useQuery(
    queries.valve.history({ fields: [...fields], startDate, endDate, valves: [valve.mac!] }),
  );

  const fieldOptions = fields.map(f => ({
    value: f,
    name: `${t(`room.historyDialog.data.${f}`)} (${formatUnit(f)})`,
    disabled: data.length >= 4 && !data.includes(f),
  }));
  const valveOptions = valves.map(v => ({ value: v.id!, name: `${v.uid} - ${v.mac ?? "--"}` }));
  const dateOptions = getDateOptions();

  const unitAxesMapping = getUnitAxesMapping(data);
  const chartData = transformData(response, fields, role);

  const handleChangeFields = (value: string | string[]) => setData(value as PostHistovalvesStatsFields[]);
  const handleChangeValve = (value: string | string[]) =>
    setValve(valvesWithMac.find(v => v.id === (value as string))!);
  const handleChangeDate = (value: string | string[]) => setStartDate(value as string);

  return (
    <>
      <Modal.Root {...props}>
        <Modal.Content className={styles.valveHistoryDialog}>
          <Modal.Header title={t("room.historyDialog.title")} subtitle="" icon={<PointChartIcon />}>
            <Modal.Close asChild>
              <CrossIcon className={styles.valveHistoryDialog__cross} />
            </Modal.Close>
          </Modal.Header>
          <div className={styles.selectors}>
            <Select
              placeholder={t("room.historyDialog.data.placeholder")}
              value={data}
              options={fieldOptions}
              onChange={handleChangeFields}
            />
            {selectedValve ? (
              <p>
                {selectedValve.uid} - {selectedValve.mac ?? "--"}
              </p>
            ) : (
              <Select options={valveOptions} value={valve.id} onChange={handleChangeValve} />
            )}
            <Select value={startDate} options={dateOptions} onChange={handleChangeDate} />
            <DataExportPopup data={fields} valves={valves} date={startDate} valveId={valve.id!}>
              <Button className={styles.selectors__exportButton}>
                {t("room.historyDialog.export")}
                <ArrowRightIcon />
              </Button>
            </DataExportPopup>
          </div>
          <div className={styles.chart}>
            <HistoryChart
              data={chartData}
              unitAxesMapping={unitAxesMapping}
              translationKey={"room.historyDialog.data"}
            />
          </div>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export { ValveHistoryDialog };
export type { ValveHistoryDialogProps as ValveHistoryProps };
