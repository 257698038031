import { forwardRef, useEffect, useRef } from "react";

import { cva } from "class-variance-authority";

import { useBem, useControllableState } from "@eisox/tools";
import * as RadixSwitch from "@radix-ui/react-switch";

import styles from "./styles.module.scss";

const switchVariants = cva(styles.switch, {
  variants: {
    state: {
      checked: styles.checked,
      unchecked: styles.unchecked,
      indeterminate: styles.indeterminate,
    },
    disabled: {
      true: styles.disabled,
    },
  },
  defaultVariants: {
    state: "unchecked",
  },
});

type SwitchProps<T> = Omit<RadixSwitch.SwitchProps, "checked" | "onCheckedChange"> & {
  checked: T;
  onCheckedChange: (value: T extends boolean ? boolean : boolean | null) => void;
  forceThreeState?: boolean;
};

const Switch = forwardRef(
  <T extends boolean | null>(
    { checked, onCheckedChange, disabled = false, forceThreeState = false, className, ...props }: SwitchProps<T>,
    forwardedRef: React.Ref<HTMLButtonElement>,
  ) => {
    const bem = useBem(styles);
    const thumbStyle = bem("thumb");

    const isThreeState = useRef(checked === null);

    const [state, setState] = useControllableState<boolean | null>({
      prop: checked,
      defaultProp: false,
      onChange: onCheckedChange as (value: boolean | null) => void,
    });

    const isIndeterminate = state === null;
    const currentVisualState = state === true ? "checked" : isIndeterminate ? "indeterminate" : "unchecked";

    const handleToggle = () => {
      if (disabled) return;
      setState(prevState => {
        if (prevState === true) return false;
        if (prevState === false) return isThreeState.current || forceThreeState ? null : true;
        return true;
      });
    };

    return (
      <RadixSwitch.Root
        {...props}
        ref={forwardedRef}
        checked={state === true}
        className={switchVariants({ state: currentVisualState, disabled: disabled, className })}
        onCheckedChange={handleToggle}
        disabled={disabled}
      >
        <RadixSwitch.Thumb className={thumbStyle()} />
      </RadixSwitch.Root>
    );
  },
);
Switch.displayName = "Switch";

export { Switch };
export type { SwitchProps };
