import { env } from "~/configuration";
import { useUserStore } from "~/stores";

import { DELETE_METHOD, GET_METHOD, PATCH_METHOD, POST_METHOD } from "../constants/fetchConstants";
import { internalFetchByUrl } from "../helpers/communication/FetchApi";
import { roomsRouteConcat, routeHouses } from "../routesStatic/routesExpress";

const ServerUrl = env.SERVER_URL;

export function updateRoom(houseId, roomId, body) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteEditRoom = ServerUrl + routeHouses + "/" + houseId + roomsRouteConcat + roomId;
  fetchingResponse = internalFetchByUrl(RouteEditRoom, PATCH_METHOD, customHeaders, body);
  return fetchingResponse;
}

export function getRooms(idHouse) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteRooms = ServerUrl + routeHouses + "/" + idHouse + roomsRouteConcat;
  fetchingResponse = internalFetchByUrl(RouteRooms, GET_METHOD, customHeaders);
  return fetchingResponse;
}

export function deleteRoom(idRoom, idHouse) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteRooms = ServerUrl + routeHouses + "/" + idHouse + roomsRouteConcat + idRoom;
  fetchingResponse = internalFetchByUrl(RouteRooms, DELETE_METHOD, customHeaders);
  return fetchingResponse;
}

export function patchRooms(idHouse, rooms) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteRooms = ServerUrl + routeHouses + "/" + idHouse + roomsRouteConcat;
  fetchingResponse = internalFetchByUrl(RouteRooms, PATCH_METHOD, customHeaders, rooms);
  return fetchingResponse;
}

export function postRooms(idHouse, rooms) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteRooms = ServerUrl + routeHouses + "/" + idHouse + roomsRouteConcat;
  fetchingResponse = internalFetchByUrl(RouteRooms, POST_METHOD, customHeaders, rooms);
  return fetchingResponse;
}
