import { http } from "msw";

import type { DownloadPlan } from "@eisox/backend_webapp_api";

import { getCleverCloudPlanResolver, getPlanByHouseIdAndPlanIdResolver } from "../resolvers";
import { withAuth } from "../utils";

export const handlers = [
  http.get<
    {
      houseId: string;
      planId: string;
    },
    never,
    DownloadPlan,
    "/api/v3/houses/:houseId/plans/:planId"
  >("/api/v3/houses/:houseId/plans/:planId", withAuth(getPlanByHouseIdAndPlanIdResolver)),
  http.get("http://plan-eisox-dev.cellar-c2.services.clever-cloud.com/:planId.png", getCleverCloudPlanResolver),
];
