import { useRouteLoaderData } from "react-router-dom";

import type { houseLoader } from "~/UI/screens";
import { GATEWAY_MIN_SOFTWARE_VERSIONS } from "~/constants";
import { idLoaderHouse } from "~/routes/utils";
import { isVersionIsUpper } from "~/utils";

import { RemoteAccessV1 } from "./RemoteAccessV1";
import { RemoteAccessV2 } from "./RemoteAccessV2";

export const RemoteAccess: React.FC = () => {
  const { gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  if (
    gateways
      .filter(g => g.softwareVersion)
      .every(g => isVersionIsUpper(g.softwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.REMOTE_ACCESS))
  ) {
    return <RemoteAccessV2 />;
  }
  return <RemoteAccessV1 />;
};
