import { useEffect } from "react";

import { useIntl } from "react-intl";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";

import { Error404 as Error } from "@eisox/design-system";
import { captureException } from "@sentry/react";

import { UnauthorizedException } from "~/apiV2/exceptions";
import { routeToHouses, routeToSignIn } from "~/routes/utils";

export const Error404: React.FC = () => {
  const { formatMessage } = useIntl();

  const error = useRouteError() as Error | undefined;
  const location = useLocation();
  const navigate = useNavigate();

  const isLogin = !(error instanceof UnauthorizedException);

  useEffect(() => {
    captureException(error, { level: "error" });
  }, [error]);

  const handleClick = () => {
    navigate(`${isLogin ? routeToHouses() : routeToSignIn()}`, { state: { from: location }, replace: true });
  };

  return (
    <Error
      text={formatMessage({ id: "extraPages.404Msg" })}
      buttonText={formatMessage({ id: `${isLogin ? "sidebar.buildings" : "appModule.signin"}` })}
      onClick={handleClick}
    />
  );
};
