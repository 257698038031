import type { HouseMessage, Module, PatchHouse } from "@eisox/backend_webapp_api";
import { PostModuleTypeEnum } from "@eisox/backend_webapp_api";

import type { GatewaysWithProblem } from "~/UI/screens/House";
import {
  DEFAULT_MID_SEASON_DELAY,
  DEFAULT_MID_SEASON_HEATING_TEMPERATURE,
  DEFAULT_MID_SEASON_NO_HEATING_TEMPERATURE,
  GATEWAY_MIN_SOFTWARE_VERSIONS,
} from "~/constants";
import { isVersionIsUpper } from "~/utils";

import type { SeasonType } from "../schemas";
import { Mode } from "../schemas";

const isSummerWinterWithHalfSeasonAvailable = (gateways: GatewaysWithProblem[], modules: Module[]) => {
  const moduleGatways = gateways.filter(g =>
    modules
      .filter(
        m =>
          m.type &&
          [PostModuleTypeEnum.EclypseBoilerRoom as string, PostModuleTypeEnum.NiagaraBoilerRoom as string].includes(
            m.type,
          ),
      )
      .map(m => m.gatewayId)
      .includes(g.id),
  );
  return (
    moduleGatways.length > 0 &&
    moduleGatways.every(g =>
      isVersionIsUpper(g.softwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.SUMMER_WINTER_HALF_SEASON),
    )
  );
};

const isSummerMode = (summerModePeriod: HouseMessage["summerModePeriod"]) =>
  summerModePeriod?.begin === "01-01" && summerModePeriod.end === "31-12";

const isWinterMode = (winterModePeriod: HouseMessage["winterModePeriod"]) =>
  winterModePeriod?.begin === "01-01" && winterModePeriod.end === "31-12";

const addOneDay = (date: string) => {
  const [day, month] = date.split("-");
  const longMonths = ["01", "03", "05", "07", "08", "10", "12"];
  const shortMonths = ["04", "06", "09", "11"];
  const newDay = Number(day) + 1;
  if (month === "02" && (newDay === 29 || newDay === 30)) return "01-03";
  if (longMonths.includes(month) && newDay === 32) return `01-${(Number(month) + 1).toString().padStart(2, "0")}`;
  if (shortMonths.includes(month) && newDay === 31) return `01-${(Number(month) + 1).toString().padStart(2, "0")}`;
  return `${newDay.toString().padStart(2, "0")}-${month}`;
};

const isSummerWinterMode = (
  summerModePeriod: NonNullable<HouseMessage["summerModePeriod"]>,
  winterModePeriod: NonNullable<HouseMessage["winterModePeriod"]>,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  winterModePeriod?.end &&
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  summerModePeriod?.end &&
  summerModePeriod.begin === addOneDay(winterModePeriod.end) &&
  addOneDay(summerModePeriod.end) === winterModePeriod.begin;

const formatDateForWebApp = (date: string) => date.replace("-", "/");
const formatDateForBackend = (date: string) => date.replace("/", "-");

const getInitialState = (house: HouseMessage): SeasonType => {
  if (isSummerMode(house.summerModePeriod)) return { mode: Mode.SUMMER };
  if (isWinterMode(house.winterModePeriod)) return { mode: Mode.WINTER };
  if (
    house.summerModePeriod?.begin &&
    house.summerModePeriod.end &&
    house.winterModePeriod?.begin &&
    house.winterModePeriod.end
  ) {
    if (isSummerWinterMode(house.summerModePeriod, house.winterModePeriod)) {
      return {
        mode: Mode.SUMMER_WINTER,
        summerPeriods: {
          begin: formatDateForWebApp(house.summerModePeriod.begin),
          end: formatDateForWebApp(house.summerModePeriod.end),
        },
        winterPeriods: {
          begin: formatDateForWebApp(house.winterModePeriod.begin),
          end: formatDateForWebApp(house.winterModePeriod.end),
        },
      };
    }
    return {
      mode: Mode.SUMMER_WINTER_WITH_HALF_SEASON,
      summerPeriods: {
        begin: formatDateForWebApp(house.summerModePeriod.begin),
        end: formatDateForWebApp(house.summerModePeriod.end),
      },
      winterPeriods: {
        begin: formatDateForWebApp(house.winterModePeriod.begin),
        end: formatDateForWebApp(house.winterModePeriod.end),
      },
      midSeason: {
        heatingTemperature: house.midSeason?.heatingTemperature ?? DEFAULT_MID_SEASON_HEATING_TEMPERATURE,
        noHeatingTemperature: house.midSeason?.noHeatingTemperature ?? DEFAULT_MID_SEASON_NO_HEATING_TEMPERATURE,
        delay: house.midSeason?.tempo ?? DEFAULT_MID_SEASON_DELAY,
      },
    };
  }
  return { mode: Mode.WINTER };
};

const getBody = (season: SeasonType): PatchHouse => {
  if (season.mode === Mode.SUMMER_WINTER) {
    return {
      summerModePeriod: {
        begin: formatDateForBackend(season.summerPeriods.begin),
        end: formatDateForBackend(season.summerPeriods.end),
      },
      winterModePeriod: {
        begin: formatDateForBackend(season.winterPeriods.begin),
        end: formatDateForBackend(season.winterPeriods.end),
      },
    };
  }
  if (season.mode === Mode.SUMMER_WINTER_WITH_HALF_SEASON) {
    return {
      summerModePeriod: {
        begin: formatDateForBackend(season.summerPeriods.begin),
        end: formatDateForBackend(season.summerPeriods.end),
      },
      winterModePeriod: {
        begin: formatDateForBackend(season.winterPeriods.begin),
        end: formatDateForBackend(season.winterPeriods.end),
      },
      midSeason: {
        heatingTemperature: season.midSeason.heatingTemperature,
        noHeatingTemperature: season.midSeason.noHeatingTemperature,
        tempo: season.midSeason.delay,
      },
    };
  }
  if (season.mode === Mode.SUMMER) {
    return { summerModePeriod: { begin: "01-01", end: "31-12" } };
  }
  return { winterModePeriod: { begin: "01-01", end: "31-12" } };
};

export { getBody, getInitialState, isSummerWinterMode, isSummerWinterWithHalfSeasonAvailable };
