import { Fragment, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";
import * as yup from "yup";

import { GatewaysMessageGatewaysInnerBrModRfEnum } from "@eisox/backend_webapp_api";
import type { ManipulateType } from "@eisox/dayjs";
import { ActionButton, Drawer, SelectV2 as Select, Switch, TextInput } from "@eisox/design-system";
import { PencilIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { FieldContainer, Tooltip } from "~/UI/components";
import { DeletePopup } from "~/UI/layouts/DeletePopup";
import type { houseLoader } from "~/UI/screens";
import { usePermissionsContext } from "~/UI/screens";
import type { GatewaysWithProblem } from "~/UI/screens/House";
import { GATEWAY_MIN_SOFTWARE_VERSIONS } from "~/constants";
import { FORMAT_DATE } from "~/constants/timeConstants";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToGateway } from "~/routes/utils";
import { API } from "~/types/API";
import { getDirtyValues, toggleGatewaySuffix } from "~/utils";
import { addTime, formatedDate, isExpiredDate } from "~/utils/timeUtils";
import { isVersionIsUpper } from "~/utils/versionUtils";

import styles from "./EditGatewayDrawer.module.scss";

interface EditGatewayDrawerProps {
  open?: boolean;
  onClose?: (reason: "close" | "edit" | "delete") => void;
  gateway: GatewaysWithProblem;
}

export const EditGatewayDrawer: React.FC<EditGatewayDrawerProps> = ({ open = false, onClose, gateway }) => {
  const { formatMessage } = useIntl();
  const { permissions } = usePermissionsContext();
  const { houseId } = useParams() as { houseId: string };

  const { valves, gateways, modules } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const { Form, submit } = useAction({
    onSuccess: () => onClose && onClose("edit"),
  });

  const bem = useBem(styles);
  const editGatewayDrawerStyle = bem("edit-gateway-drawer");
  const elementsContainerStyle = bem("elements-container");
  const tooltipValvesNotCompatibleWithModuleRF = bem("tooltip-valves-not-compatible-with-module-rf");
  const remoteAccessStyle = bem("remote-access");
  const isInternetGatewayStyle = bem("is-internet-gateway");
  const buttonsContainerStyle = bem("buttons-container");
  const deletionTooltipStyle = bem("deletion-tooltip");

  const isRemoteAccessValid = gateway.expireAt ? !isExpiredDate(gateway.expireAt) : false;
  const defaultDuration = "30 minutes";

  const schema = yup.object({
    gatewayName: yup.string().required(formatMessage({ id: "error.emptyField" })),
    isRemoteAccess: yup.boolean().required(formatMessage({ id: "error.emptyField" })),
    exprireAt: yup.string().test("isRemoteAccess", formatMessage({ id: "error.emptyField" }), function (value) {
      if (this.parent.isRemoteAccess) return !!value;
      return true;
    }),
    isInternetGateway: yup.boolean().required(formatMessage({ id: "error.emptyField" })),
    uid: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .test(
        "unique uid",
        formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.error.uniqueUid" }),
        uid => !gateways.some(g => g.uid === uid && g.id !== gateway.id),
      )
      .required(formatMessage({ id: "error.required" })),
    brModRf: yup
      .string()
      .oneOf(Object.values(GatewaysMessageGatewaysInnerBrModRfEnum))
      .required(formatMessage({ id: "error.emptyField" })),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, dirtyFields },
    control,
    watch,
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      gatewayName: gateway.gatewayName,
      isRemoteAccess: isRemoteAccessValid,
      exprireAt: gateway.expireAt,
      isInternetGateway: !!gateway.isInternetGateway,
      brModRf: gateway.brModRf,
      uid: gateway.uid,
    },
  });

  const { gatewayName, exprireAt, isInternetGateway, isRemoteAccess } = watch();

  const isRemoteAccessSoftwareVersionCompatible = isVersionIsUpper(
    gateway.softwareVersion,
    GATEWAY_MIN_SOFTWARE_VERSIONS.REMOTE_ACCESS,
  );
  const isModeRfSoftwareVersionCompatible =
    isRemoteAccessSoftwareVersionCompatible &&
    isVersionIsUpper(gateway.borderRouterSoftwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.MODE_RF_BORDER_ROUTER);

  const valvesOfGateway = valves.filter(v => v.gatewayId === gateway.id);
  const valvesNotCompatibleWithModuleRF = valvesOfGateway.filter(
    v => v.softwareVersion && !isVersionIsUpper(v.softwareVersion, "4.50.0"),
  );

  const disabledSelectBrModRf =
    gateway.brModRf === GatewaysMessageGatewaysInnerBrModRfEnum.OqpskRc200 &&
    valvesNotCompatibleWithModuleRF.length > 0;

  const [duration, setDuration] = useState<string | undefined>(isRemoteAccessValid ? undefined : defaultDuration);
  const [deletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);

  const durationOptions: { value: `${number} ${ManipulateType}`; name: string }[] = [
    {
      value: "30 minutes",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.minute" }, { m: 30 }),
    },
    {
      value: "1 hour",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.hour" }, { h: 1 }),
    },
    {
      value: "6 hours",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.hour" }, { h: 6 }),
    },
    {
      value: "24 hours",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.hour" }, { h: 24 }),
    },
    {
      value: "48 hours",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.hour" }, { h: 48 }),
    },
    {
      value: "7 days",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.day" }, { d: 7 }),
    },
    {
      value: "1 month",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.month" }, { m: 1 }),
    },
  ];

  const onSubmit = handleSubmit((data: yup.InferType<typeof schema>) => {
    const dirtyValues = getDirtyValues(dirtyFields, data as any) as unknown as Partial<yup.InferType<typeof schema>>;
    submit({ ...dirtyValues }, API.HTTP_METHOD.PATCH, routeToGateway(houseId, gateway.id!));
  });

  const valvesAssociatedWithGateway = valvesOfGateway.length > 0;
  const lastMasterBox = !!gateway.isInternetGateway && !gateways.some(g => g.id !== gateway.id && g.isInternetGateway);
  const moduleAssociatedWithGateway = modules.some(m => m.gatewayId === gateway.id);

  const isDeleteButtonDisabled = valvesAssociatedWithGateway || lastMasterBox || moduleAssociatedWithGateway;

  return (
    <Drawer
      title={formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.title" })}
      open={open}
      onOpenChange={() => onClose && onClose("close")}
    >
      <>
        <Form className={editGatewayDrawerStyle()} onSubmit={onSubmit}>
          <div className={editGatewayDrawerStyle("content")}>
            <FieldContainer label={formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.mac" })}>
              <p>{gateway.mac ?? "-"}</p>
            </FieldContainer>

            <TextInput
              {...register("uid")}
              type="number"
              label={formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.uid" })}
              error={errors.uid}
            />

            <div className={elementsContainerStyle()}>
              <TextInput
                {...register("gatewayName")}
                label={formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.name" })}
                type="text"
                disabled={!permissions.gateway?.gatewayName?.update}
                error={errors.gatewayName}
              />
            </div>

            {permissions.gateway?.brModRf?.update && (
              <Tooltip
                content={
                  !isModeRfSoftwareVersionCompatible ? (
                    <p>
                      {formatMessage(
                        {
                          id: "drawer.listDrawer.editGatewayDrawer.brModRf.boxVersionNotCompatible",
                        },
                        {
                          v: GATEWAY_MIN_SOFTWARE_VERSIONS.REMOTE_ACCESS,
                          brv: GATEWAY_MIN_SOFTWARE_VERSIONS.MODE_RF_BORDER_ROUTER,
                        },
                      )}
                    </p>
                  ) : (
                    disabledSelectBrModRf && (
                      <div className={tooltipValvesNotCompatibleWithModuleRF()}>
                        <div className={tooltipValvesNotCompatibleWithModuleRF("info")}>
                          <p>
                            {formatMessage({
                              id: "drawer.listDrawer.editGatewayDrawer.brModRf.tootlipVavleNotCompatible.title",
                            })}
                          </p>
                          <span>
                            {formatMessage({
                              id: "drawer.listDrawer.editGatewayDrawer.brModRf.tootlipVavleNotCompatible.note",
                            })}
                          </span>
                        </div>

                        <div className={tooltipValvesNotCompatibleWithModuleRF("list")}>
                          {valvesNotCompatibleWithModuleRF.map((v, i) => (
                            <Fragment key={i}>
                              <p>{v.uid}</p>
                              <p>{v.mac}</p>
                              <p>{v.softwareVersion}</p>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    )
                  )
                }
                position="left"
              >
                <div className={elementsContainerStyle()}>
                  <Controller
                    control={control}
                    name="brModRf"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        label={formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.brModRf.label" })}
                        value={value}
                        disabled={!isModeRfSoftwareVersionCompatible || disabledSelectBrModRf}
                        error={errors.brModRf}
                        options={Object.values(GatewaysMessageGatewaysInnerBrModRfEnum).map(v => ({
                          value: v,
                          name: formatMessage({ id: `drawer.listDrawer.editGatewayDrawer.brModRf.value.${v}` }),
                        }))}
                        onChange={onChange}
                        renderValue={value => (
                          <p>{formatMessage({ id: `drawer.listDrawer.editGatewayDrawer.brModRf.value.${value}` })}</p>
                        )}
                      />
                    )}
                  />
                </div>
              </Tooltip>
            )}

            {permissions.gateway?.expireAt?.update && isRemoteAccessSoftwareVersionCompatible && (
              <div className={elementsContainerStyle()}>
                <label>
                  <p>{formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.label" })}</p>
                  <div className={remoteAccessStyle()}>
                    <p>
                      {formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.switchValue" }, { e: isRemoteAccess })}{" "}
                      {isRemoteAccess &&
                        isRemoteAccessValid &&
                        formatMessage(
                          { id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.activateUntil" },
                          { d: formatedDate(FORMAT_DATE.remoteAccessActivateUntil, gateway.expireAt) },
                        )}
                    </p>
                    <Controller
                      control={control}
                      name="isRemoteAccess"
                      render={({ field: { value, onChange } }) => <Switch checked={value} onCheckedChange={onChange} />}
                    />
                  </div>
                </label>

                {isRemoteAccess && (
                  <>
                    <div>
                      <Controller
                        control={control}
                        name="exprireAt"
                        render={({ field: { onChange } }) => (
                          <Select
                            label={formatMessage(
                              { id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.label" },
                              { e: isRemoteAccess && isRemoteAccessValid },
                            )}
                            value={duration}
                            error={errors.exprireAt}
                            options={durationOptions}
                            onChange={value => {
                              setDuration(value as string);
                              onChange(
                                addTime(
                                  parseInt((value as string).split(" ")[0]),
                                  (value as string).split(" ")[1] as ManipulateType,
                                ).toISOString(),
                              );
                            }}
                            renderValue={value => <p>{durationOptions.find(v => v.value === value)?.name || "---"}</p>}
                          />
                        )}
                      />
                    </div>

                    {dirtyFields.exprireAt && (
                      <p className={remoteAccessStyle("will-be-activate")}>
                        {formatMessage(
                          { id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.willBeActivatedUntil" },
                          {
                            d: formatedDate(FORMAT_DATE.remoteAccessWillBeActivateUntil, exprireAt),
                          },
                        )}
                      </p>
                    )}
                  </>
                )}
              </div>
            )}

            {permissions.gateway?.isInternetGateway?.update && (
              <div className={elementsContainerStyle()}>
                <label>
                  <p>{formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.isInternetGateway" })}</p>
                  <div className={isInternetGatewayStyle()}>
                    <p>
                      {formatMessage(
                        { id: "drawer.listDrawer.editGatewayDrawer.switchValue" },
                        { e: isInternetGateway },
                      )}
                    </p>
                    <Controller
                      control={control}
                      name="isInternetGateway"
                      render={({ field: { value, onChange } }) => (
                        <Switch
                          checked={value}
                          onCheckedChange={checked => {
                            gatewayName &&
                              setValue("gatewayName", toggleGatewaySuffix(gatewayName, checked!), {
                                shouldDirty: true,
                              });
                            onChange(checked);
                          }}
                        />
                      )}
                    />
                  </div>
                </label>
              </div>
            )}
          </div>
          <div className={buttonsContainerStyle()}>
            <ActionButton
              disabled={!isDirty}
              rounded
              text={formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.save" })}
              type="submit"
              icon={<PencilIcon />}
            />

            {permissions.gateway?.delete && (
              <Tooltip
                content={
                  <div>
                    <p>{formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.delete.disabled" })}</p>
                    <ul className={deletionTooltipStyle("list")}>
                      {valvesAssociatedWithGateway && (
                        <li>
                          {formatMessage({
                            id: "drawer.listDrawer.editGatewayDrawer.delete.valvesAssociatedWithGateway",
                          })}
                        </li>
                      )}
                      {lastMasterBox && (
                        <li>{formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.delete.lastMasterBox" })}</li>
                      )}
                      {moduleAssociatedWithGateway && (
                        <li>
                          {formatMessage({
                            id: "drawer.listDrawer.editGatewayDrawer.delete.moduleAssociatedWithGateway",
                          })}
                        </li>
                      )}
                    </ul>
                  </div>
                }
                position="left"
                openingCondition={isDeleteButtonDisabled}
                keepHoverOpen={false}
              >
                <ActionButton
                  className={buttonsContainerStyle("delete-button", { disabled: isDeleteButtonDisabled })}
                  text={formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.delete.button" })}
                  onClick={() => !isDeleteButtonDisabled && setDeletePopupOpen(true)}
                />
              </Tooltip>
            )}
          </div>
        </Form>

        {deletePopupOpen && (
          <DeletePopup
            open={deletePopupOpen}
            onClose={() => {
              setDeletePopupOpen(false);
              onClose && onClose("delete");
            }}
            elementToDelete={gateway.gatewayName || gateway.mac || gateway.uid?.toString()!}
            route={routeToGateway(houseId, gateway.id!)}
          />
        )}
      </>
    </Drawer>
  );
};
