import React, { useEffect, useState } from "react";

import clsx from "clsx";
import type { FieldError, Merge } from "react-hook-form";
import { useIntl } from "react-intl";

import { ChevronDownIcon } from "@eisox/icons";
import { useBem, useClickOutside, useKeyPress, useScroll } from "@eisox/tools";

import { Button } from "../Button";
import { Card } from "../Card";
import { Radio } from "../Radio";
import { Typography } from "../Typography";
import { getPositionStyles } from "./utils";

import styles from "./styles.module.scss";

export interface Option {
  name: string;
  value: string;
  disabled?: boolean;
}

export interface SelectOrigin {
  vertical: "top" | "center" | "bottom";
  horizontal: "left" | "center" | "right";
}

export type ErrorType = Merge<FieldError, (FieldError | undefined)[]> | FieldError | undefined;

interface CommonSelectProps {
  disabled?: boolean;
  shouldNotDisableIfNoOptions?: boolean;
  options: Option[];
  displayIcon?: boolean;
  renderOption?: (option: Option, selected: boolean) => React.ReactElement;
  label?: string;
  className?: string;
  labelClassName?: string;
  triggerClassName?: string;
  cardClassName?: string;
  error?: ErrorType;
  unscrollableContent?: React.ReactNode;
  onClose?: VoidFunction;
  anchorOrigin?: SelectOrigin;
  transformOrigin?: SelectOrigin;
}

export interface SingleSelectProps extends CommonSelectProps {
  multiple?: false;
  value?: string;
  onChange?: (value: string) => void;
  renderValue: (value?: string, error?: ErrorType) => React.ReactElement;
  displaySelectAllButton?: never;
  displayRadio?: never;
}

interface MultipleSelectProps extends CommonSelectProps {
  multiple?: true;
  value?: string[];
  onChange?: (value: string[]) => void;
  renderValue: (value?: string[], error?: ErrorType) => React.ReactElement;
  displaySelectAllButton?: boolean;
  displayRadio?: boolean;
}

export type SelectProps = SingleSelectProps | MultipleSelectProps;

export const Select: React.FC<SelectProps> = ({
  disabled = false,
  shouldNotDisableIfNoOptions = false,
  options,
  label,
  multiple = false,
  displayIcon = true,
  value,
  onChange,
  renderValue,
  renderOption,
  className,
  labelClassName,
  triggerClassName,
  cardClassName,
  error,
  unscrollableContent,
  displaySelectAllButton = false,
  displayRadio = true,
  onClose,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const selectStyle = bem("select");
  const itemsStyle = bem("items");

  const ref = useClickOutside(prevState => {
    if (prevState) onClose && onClose();
    setOpen(false);
  }) as React.RefObject<HTMLDivElement>;
  const { scrollableRef, targetRef, handleScrollToElement } = useScroll();

  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [position, setPosition] = useState({ transform: "none" });
  const [focusId, setFocusId] = useState(
    multiple ? options.findIndex(o => o.value === value?.[0]) : value ? options.findIndex(o => o.value === value) : 0,
  );
  const [considerMouse, setConsiderMouse] = useState(true);

  useKeyPress({ code: "ArrowDown" }, () => keyPressHandler("down"));
  useKeyPress({ code: "ArrowUp" }, () => keyPressHandler("up"));
  useKeyPress({ code: "Enter" }, () => keyPressHandler("enter"));

  const everyOptionsSelected = multiple && options.map(o => o.value).every(o => selectedOptions?.includes(o));

  const selectDisabled = disabled || (!shouldNotDisableIfNoOptions && options.length === 0);

  const handleOptionClick = (value: string) => {
    if (multiple) {
      if (selectedOptions?.includes(value)) {
        (onChange as (value: string[]) => void)((selectedOptions as string[]).filter(val => val !== value));
        (setSelectedOptions as React.Dispatch<React.SetStateAction<string[]>>)(prevSelectedOptions =>
          prevSelectedOptions.filter(val => val !== value),
        );
      } else {
        (onChange as (value: string[]) => void)([...(selectedOptions as string[]), value]);
        (setSelectedOptions as React.Dispatch<React.SetStateAction<string[]>>)(prevSelectedOptions => [
          ...prevSelectedOptions,
          value,
        ]);
      }
    } else {
      (onChange as (value: string) => void)(value);
      (setSelectedOptions as React.Dispatch<React.SetStateAction<string>>)(value);
    }
  };

  const handleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    !selectDisabled &&
      setOpen(prevState => {
        prevState && onClose && onClose();
        return !prevState;
      });
  };

  const handleSelectDeselectAll = () => {
    setSelectedOptions(everyOptionsSelected ? [] : options.map(o => o.value));
    onChange && (onChange as (value: string[]) => void)(everyOptionsSelected ? [] : options.map(o => o.value));
  };

  const handleClickOnOption = (option?: Option) => {
    if (option && !option.disabled) {
      handleOptionClick(option.value);
      !multiple && setOpen(false);
    }
  };

  const keyPressHandler = (key: "up" | "down" | "enter") => {
    if (open) {
      let id = focusId;
      if (key === "up") id = (id - 1 + options.length) % options.length;
      else if (key === "down") id = (id + 1) % options.length;
      else if (key === "enter") handleClickOnOption(options[focusId]);
      handleScrollToElement(id);
      setFocusId(id);
      setConsiderMouse(false);
    }
  };

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  useEffect(() => {
    ref.current && setPosition(getPositionStyles(ref.current, anchorOrigin, transformOrigin));
  }, [ref]);

  useEffect(() => {
    handleScrollToElement(focusId);
  }, [open]);

  return (
    <div ref={ref} className={clsx(selectStyle(), className)}>
      {label && <label className={clsx(selectStyle("label"), labelClassName)}>{label}</label>}
      <div
        className={clsx(
          selectStyle("trigger", {
            disabled: selectDisabled,
            error: !!error,
            placeholder: value === undefined || (Array.isArray(value) && value.length === 0),
          }),
          triggerClassName,
        )}
        onClick={handleOpen}
      >
        {multiple
          ? (renderValue as (value: string[], error: ErrorType) => React.ReactElement)(
              selectedOptions as string[],
              error,
            )
          : (renderValue as (value: string, error: ErrorType) => React.ReactElement)(selectedOptions as string, error)}
        {displayIcon && <ChevronDownIcon />}
      </div>
      {error?.message && <p className={selectStyle("error")}>{error.message}</p>}
      {open && (shouldNotDisableIfNoOptions ? true : options.length > 0) && (
        <div className={selectStyle("position")} style={{ transform: position.transform }}>
          <Card className={clsx(selectStyle("card"), cardClassName)} onMouseMove={() => setConsiderMouse(true)}>
            {unscrollableContent}
            {multiple && displaySelectAllButton && (
              <Button
                className={selectStyle("select-all-button")}
                text={formatMessage({
                  id: `select.${everyOptionsSelected ? "deselectAll" : "selectAll"}`,
                })}
                onClick={handleSelectDeselectAll}
              />
            )}
            <div ref={scrollableRef} className={itemsStyle()}>
              {options.map((option, index) => {
                const selected = selectedOptions?.includes(option.value) || false;
                return (
                  <div
                    key={index}
                    onClick={e => {
                      e.preventDefault();
                      handleClickOnOption(option);
                    }}
                    onMouseEnter={() => considerMouse && setFocusId(index)}
                    onMouseLeave={() => considerMouse && setFocusId(-1)}
                    ref={el => (targetRef[index] = el)}
                    className={itemsStyle("item", {
                      disabled: !!option.disabled,
                      focused: !option.disabled && focusId === index,
                    })}
                  >
                    {multiple && displayRadio && <Radio multiple checked={selected} disabled={option.disabled} />}
                    {renderOption ? (
                      renderOption(
                        {
                          name: option.name,
                          value: option.value,
                          disabled: option.disabled ?? false,
                        },
                        selected,
                      )
                    ) : (
                      <Typography className={itemsStyle("option", { disabled: !!option.disabled })}>
                        {option.name}
                      </Typography>
                    )}
                  </div>
                );
              })}
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
