import { useState } from "react";

import { isEmpty } from "lodash";
import { useIntl } from "react-intl";

import { ActionButton, Button, Modal } from "@eisox/design-system";
import { ArrowRightIcon, NetworkIcon, PencilIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { usePermissionsContext } from "~/UI/screens";
import LoaderApp from "~/components/LoaderApp";
import { useBoilerRoomContext } from "~/features/BoilerRooms";
import type { HeatingNetwork } from "~/socketio/types";

import { ActionType, NetworkContext, useNetworkReducer } from "../../providers";
import { NetworkForm } from "../NetworkForm";

import styles from "./NetworksDialog.module.scss";

const NAME = "NetworksDialog";

interface NetworksDialogProps {
  currentHeatingNetworkId?: string;
  children?: React.ReactNode;
}

export interface HeatingNetworkWithBoilerroomName extends HeatingNetwork {
  boilerroomName?: string;
}

export const NetworksDialog: React.FC<NetworksDialogProps> = ({ currentHeatingNetworkId, children }) => {
  const { formatMessage } = useIntl();

  const { permissions } = usePermissionsContext();

  const bem = useBem(styles);
  const networksDialogStyle = bem("networks-dialog");
  const content = bem("content");
  const heatingNetworksStyle = bem("heating-networks");

  const { boilerRooms: boilerrooms, useUpdateBoilerRoom } = useBoilerRoomContext(NAME);

  const { state: networksState, dispatch: dispatchNetworkContext } = useNetworkReducer(boilerrooms);

  const [open, setOpen] = useState(false);

  const { mutate } = useUpdateBoilerRoom({ onSuccess: () => handleOpenChange(false) });

  const heatingNetworks: HeatingNetworkWithBoilerroomName[] = (boilerrooms ?? []).flatMap(
    b =>
      b.heatingNetworks
        ?.filter(hn => hn.type === "radiator")
        .map(hn => {
          return {
            ...hn,
            boilerroomName: b.name,
          };
        }) ?? [],
  );

  const [currentNetwork, setCurrentNetwork] = useState<HeatingNetworkWithBoilerroomName | undefined>(
    heatingNetworks.find(hn => hn.id === currentHeatingNetworkId) ?? heatingNetworks.at(0),
  );

  const handleChangeHeatingNetworks = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCurrentNetwork(heatingNetworks.find(hn => hn.id === e.currentTarget.value));
  };

  const handleChangeConfiguration = () => {
    mutate(networksState.transformedState);
  };

  const handleOpenChange = (open: boolean) => {
    dispatchNetworkContext({ type: ActionType.RESET });
    setOpen(open);
  };

  const isDirty = !isEmpty(networksState.transformedState);

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content className={networksDialogStyle()}>
        <Modal.Header
          title={formatMessage({ id: "boilerRoom.header.actions.networks.dialog.title" })}
          subtitle={formatMessage({ id: "boilerRoom.header.actions.networks.dialog.subtitle" })}
          icon={<NetworkIcon />}
        >
          <Modal.Close asChild>
            <ActionButton variant="cancel" text={formatMessage({ id: "boilerRoom.dialog.network.header.cancel" })} />
          </Modal.Close>
          <ActionButton
            rounded
            icon={<ArrowRightIcon />}
            text={formatMessage({ id: "boilerRoom.dialog.network.header.save" })}
            disabled={!isDirty || !permissions.valve?.heatingNetworkId?.update}
            onClick={handleChangeConfiguration}
          />
        </Modal.Header>
        {!heatingNetworks || !currentNetwork ? (
          <LoaderApp />
        ) : (
          <div className={content()}>
            <div className={heatingNetworksStyle()}>
              <h4 className={heatingNetworksStyle("title")}>
                {formatMessage({ id: "boilerRoom.header.actions.networks.dialog.network" })}
              </h4>
              <div className={heatingNetworksStyle("network")}>
                {heatingNetworks.map((hn, index) => (
                  <Button
                    key={index}
                    className={heatingNetworksStyle("button", { active: hn.id === currentNetwork.id })}
                    text={boilerrooms?.length === 1 ? (hn.name ?? "-") : `${hn.boilerroomName} / ${hn.name}`}
                    value={hn.id}
                    onClick={handleChangeHeatingNetworks}
                    icon={
                      networksState.transformedState.some(update => {
                        return update.heatingNetworks?.some(network => network.id === hn.id);
                      }) ? (
                        <PencilIcon />
                      ) : undefined
                    }
                  />
                ))}
              </div>
            </div>
            <NetworkContext.Provider value={{ state: networksState, dispatch: dispatchNetworkContext }}>
              <NetworkForm heatingNetwork={currentNetwork} />
            </NetworkContext.Provider>
          </div>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};
