import { useEffect, useState } from "react";

import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import socketIOClient from "socket.io-client";

import dayjs from "@eisox/dayjs";
import {
  ActionButtonV2 as ActionButton,
  Dialog as DSDialog,
  Loader,
  Modal,
  SelectV2 as Select,
} from "@eisox/design-system";
import { ArrowRightIcon, CrossIcon, GatewayIcon, NutIcon } from "@eisox/icons";

import type { houseLoader } from "~/UI";
import { env } from "~/configuration";
import { INTERVAL_WIFI_MESH_DISCONNECTED } from "~/constants";
import { idLoaderHouse, routeToDashboard } from "~/routes/utils";
import { useUserStore } from "~/stores";

import { showInfo } from "../../helpers";

import styles from "./Dialog.module.scss";

let socket: any;
let socketNameSpace: any;

type DialogProps = Modal.RootProps;

const Dialog: React.FC<DialogProps> = props => {
  const { t } = useTranslation();

  const userToken = useUserStore.use.token();
  const userId = useUserStore.use.id();

  const { houseId } = useParams() as { houseId: string };
  const navigate = useNavigate();
  const { meshs, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const gatewayOptions = sortBy(gateways, "gatewayName")
    .filter(g => g.isInternetGateway)
    .map(g => ({ value: g.id, name: g.gatewayName }));

  const [isLoading, setIsLoading] = useState(false);
  const [isDisconnectedSocket, setIsDisconnectedSocket] = useState(false);
  const [tryingToReconnect, setTryingToReconnect] = useState(false);
  const [closeAfterTiming, setCloseAfterTiming] = useState(false);
  const [socketIOConnected, setSocetIOConnected] = useState(false);

  const [selectedGatewayId, setSelectedGatewayId] = useState(gatewayOptions[0].value);
  const selectedGateway = gateways.find(g => g.id === selectedGatewayId);
  const [selectedMeshId, setSelectedMeshId] = useState(selectedGateway?.meshs?.[0]?.id);
  const selectedMesh = meshs.find(m => m.id === selectedGatewayId);

  const meshOptions = sortBy(meshs, "name")
    .map(m => ({ value: m.id!, name: m.name! }))
    .filter(m => selectedGateway?.meshs?.map(m => m.id).includes(m.value));

  const handleChangeGateway = (value: string | string[]) => setSelectedGatewayId(value as string);

  const handleChangeMesh = (value: string | string[]) => setSelectedMeshId(value as string);

  const connectSocket = () => {
    socket = socketIOClient(env.WEBSOCKET_URL, {
      secure: true,
      query: { token: userToken, userId: userId },
      transports: ["websocket"],
    });
    socketNameSpace = socketIOClient(env.WEBSOCKET_URL + "/" + houseId, {
      secure: true,
      query: { token: userToken, userId },
      transports: ["websocket"],
    });
    socket.on("connect", function () {
      setSocetIOConnected(true);

      setIsDisconnectedSocket(false);
      setTryingToReconnect(false);
      setCloseAfterTiming(false);
      setIsLoading(false);
    });
    socket.on("disconnect", async function () {
      if (!isLoading) {
        setIsDisconnectedSocket(true);
      }
    });
  };

  const closeSocketIo = () => {
    if (socket !== undefined) {
      socket.close();
    }
    if (socketNameSpace !== undefined) {
      socketNameSpace.close();
    }
  };

  const timerDisconnectSocket = () => {
    if (socket.connected) {
      setCloseAfterTiming(true);
      closeSocketIo();
    }
  };

  const showInfoMesh = () => {
    showInfo(selectedMeshId, selectedGateway?.meshs?.find(m => m.id === selectedMeshId)?.mac, socket, socketNameSpace);
  };

  const launchTestMesh = async () => {
    const objectToSend = {
      idHouse: houseId,
      idMesh: selectedMeshId,
    };
    socket.emit("testMesh/global", objectToSend);
    showInfoMesh();
  };

  const closeAlertAndGoToMyBuildings = () => {
    setIsDisconnectedSocket(false);
    setTryingToReconnect(false);
    navigate(routeToDashboard(houseId));
  };

  const onRestartConnection = () => {
    setTryingToReconnect(true);
    if (!socket.connected && closeAfterTiming) {
      connectSocket();
      showInfoMesh();
    }
  };

  useEffect(() => {
    connectSocket();
    const intervalId = setInterval(() => timerDisconnectSocket(), INTERVAL_WIFI_MESH_DISCONNECTED);
    setIsLoading(true);
    return () => {
      closeSocketIo();
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Modal.Root {...props}>
        <Modal.Content className={styles.testMesh}>
          <Modal.Header
            title={t("wifiMeshTest.title")}
            subtitle={t("wifiMeshTest.subtitle", { date: dayjs().format("L - LT") })}
            icon={<NutIcon />}
          >
            <Modal.Close asChild>
              <CrossIcon />
            </Modal.Close>
          </Modal.Header>
          <div className={styles.selectors}>
            <label className={styles.selectors__label}>
              {t("wifiMeshTest.chooseGateway.placeholder")}
              <Select
                classNames={{ trigger: styles.selectors__select }}
                options={gatewayOptions}
                value={selectedGatewayId}
                onChange={handleChangeGateway}
              />
            </label>
            <label className={styles.selectors__label}>
              {t("wifiMeshTest.selectNetwork.label")}
              <Select
                classNames={{ trigger: styles.selectors__select }}
                placeholder={t("wifiMeshTest.selectNetwork.placeholder")}
                options={meshOptions}
                value={selectedMeshId}
                onChange={handleChangeMesh}
              />
            </label>
            <ActionButton
              className={styles.selectors__button}
              onClick={() => launchTestMesh()}
              disabled={!socketIOConnected}
            >
              {t("wifiMeshTest.communicationTest")}
            </ActionButton>
          </div>
          {isLoading ? (
            <div className={styles.loading}>
              <Loader />
            </div>
          ) : (
            <div className={styles.result}>
              <div id="connected" />
              <div id="noMesh" />
              <div id="noResp" />
              <div id="path" />
            </div>
          )}
        </Modal.Content>
      </Modal.Root>
      <DSDialog.Root open={isDisconnectedSocket} onOpenChange={open => !open && closeAlertAndGoToMyBuildings()}>
        <DSDialog.Content
          title={t(tryingToReconnect ? "wifiMeshTest.reconnecting" : "wifiMeshTest.socketConnectionError")}
          icon={<GatewayIcon />}
          className={styles.connectionErrorDialog}
        >
          {tryingToReconnect && <Loader />}
          <ActionButton onClick={onRestartConnection}>
            {t("wifiMeshTest.restart")}
            <ArrowRightIcon />
          </ActionButton>
        </DSDialog.Content>
      </DSDialog.Root>
    </>
  );
};

export { Dialog };
export type { DialogProps };
