import { http } from "msw";

import type {
  CheckValidationCodePath,
  CheckValidationCodeRequestBody,
  CheckValidationCodeResponseBody,
  ValidationPhoneNumberPath,
  ValidationPhoneNumberResponseBody,
} from "~/apiV2";

import { checkValidationCodeResolver, validationPhoneNumberResolver } from "../resolvers";
import type { VersionedPath } from "../utils";

export const handlers = [
  http.post<never, never, ValidationPhoneNumberResponseBody, VersionedPath<ValidationPhoneNumberPath>>(
    "/api/v3/users/validationPhoneNumber",
    validationPhoneNumberResolver,
  ),
  http.patch<
    never,
    CheckValidationCodeRequestBody,
    CheckValidationCodeResponseBody,
    VersionedPath<CheckValidationCodePath>
  >("/api/v3/users/checkValidationCode", checkValidationCodeResolver),
];
