import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import { RoundIcon } from "@eisox/design-system";
import { ValveIcon } from "@eisox/icons";

import styles from "./Valve.module.scss";

interface ValveProps extends React.ComponentPropsWithRef<"div"> {
  label?: string;
  highlighted?: boolean;
}

const Valve = forwardRef<HTMLDivElement, ValveProps>((props, forwardedRef) => {
  const { label, highlighted = false, ...valveProps } = props;
  return (
    <div {...valveProps} ref={forwardedRef} className={cx(styles.valve, highlighted && styles.valve__highlighted)}>
      <RoundIcon size={20} backgroundColor={"blue"} iconColor={"white"}>
        <ValveIcon />
      </RoundIcon>
      <div className={styles.valve__label}>{label ?? "--"}</div>
    </div>
  );
});

Valve.displayName = "ManualBalancing.valve";

export { Valve };
export type { ValveProps };
