import { forwardRef } from "react";

import clsx from "clsx";

import { Card, Circle } from "@eisox/design-system";
import { GatewayIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import type { GatewaysWithProblem } from "~/UI/screens/House";
import { usePermissionsContext } from "~/UI/screens/House";

import styles from "./Gateway.module.scss";

interface GatewayLogoProps {
  tooltipContent?: string;
  circleClassName?: string;
  logoClassName?: string;
  uid?: number;
  displayUid: boolean;
  displayTooltip: boolean;
  disabled?: boolean;
  selected?: boolean;
  error?: ErrorType;
  size: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  ref?: React.RefObject<HTMLDivElement>;
}

const GatewayLogo = forwardRef<HTMLDivElement, GatewayLogoProps>(
  (
    {
      tooltipContent,
      uid,
      displayUid,
      displayTooltip,
      disabled,
      selected,
      error,
      size,
      onClick,
      circleClassName,
      logoClassName,
    },
    ref,
  ) => (
    <Tooltip content={displayTooltip && tooltipContent}>
      <Circle
        className={circleClassName}
        size={size}
        disabled={disabled}
        selected={selected}
        error={error}
        onClick={onClick}
        ref={ref}
      >
        {(displayUid && uid) || <GatewayIcon className={logoClassName} style={{ width: "70%", height: "70%" }} />}
      </Circle>
    </Tooltip>
  ),
);

interface GatewayProps extends Omit<GatewaysWithProblem, "id" | "errors" | "warnings" | "borderRouterStatus"> {
  expanded?: boolean;
  disabled?: boolean;
  selected?: boolean;
  displayUid?: boolean;
  displayTooltip?: boolean;
  size?: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

export const Gateway = forwardRef<HTMLDivElement, GatewayProps>(
  (
    {
      uid,
      gatewayName = "-",
      expanded = false,
      disabled = false,
      displayUid = false,
      displayTooltip = true,
      selected = false,
      problemStatus,
      size = 20,
      onClick,
      className,
    },
    ref,
  ) => {
    const bem = useBem(styles);
    const gatewayStyle = bem("gateway");

    const { permissions } = usePermissionsContext();

    const problemStatusPermission =
      (permissions.gateway?.error && problemStatus === "error") ||
      (permissions.gateway?.warning && problemStatus === "warning");

    return expanded ? (
      <Card
        className={clsx(gatewayStyle({ disabled: disabled, selected: selected }), className)}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => !disabled && onClick && onClick(e)}
        ref={ref}
      >
        <GatewayLogo
          circleClassName={gatewayStyle("circle", { expanded: expanded })}
          logoClassName={gatewayStyle("logo")}
          disabled={disabled}
          selected={selected}
          size={size}
          displayUid={false}
          displayTooltip={false}
        />
        <p>{`${uid} - ${gatewayName}`}</p>
        {problemStatusPermission && <div className={gatewayStyle("error", { variant: problemStatus })} />}
      </Card>
    ) : (
      <GatewayLogo
        circleClassName={clsx(gatewayStyle("circle"), className)}
        tooltipContent={`${uid} - ${gatewayName}`}
        disabled={disabled}
        selected={selected}
        uid={uid}
        displayUid={displayUid}
        displayTooltip={displayTooltip}
        error={(problemStatusPermission && problemStatus) || undefined}
        size={size}
        onClick={e => !disabled && onClick && onClick(e)}
        ref={ref}
      />
    );
  },
);
