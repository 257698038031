import { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import * as yup from "yup";

import { ActionButton, Button, Modal, Select, Switch, TextInput, Typography } from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { PostHouseType } from "@eisox/webapp-api-specification";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Slider } from "~/UI/components";
import type { AddHouseBody } from "~/apiV2";
import { addHouse, queries } from "~/apiV2";
import { DEFAULT_TEMPERATURES, TEMPERATURE_LIMITS } from "~/constants";

import { PositionDialog } from "../PositionDialog";

import styles from "./StepOne.module.scss";

const schema = (formatMessage: FormatMessageFn) =>
  yup.object({
    type: yup
      .mixed<PostHouseType>()
      .oneOf(Object.values(PostHouseType))
      .required(formatMessage({ id: "error.required" })),
    houseName: yup.string().required(formatMessage({ id: "error.required" })),
    latitude: yup.number().required(formatMessage({ id: "houses.house.position.error" })),
    longitude: yup.number().required(formatMessage({ id: "houses.house.position.error" })),
    frostFreeTemperature: yup.number().required(),
    preComfortTemperature: yup.number().required(),
    absenceTemperature: yup.number().required(),
    isFrostFree: yup.boolean().required(),
  });

type PostHouseTypeSchema = yup.InferType<ReturnType<typeof schema>>;

interface StepOneProps {
  nextStep: (id: string) => void;
}

export const StepOne: React.FC<StepOneProps> = ({ nextStep }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const stepOneStyle = bem("step-one");
  const contentStyle = bem("content");

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: addHouse,
    onSuccess: data => {
      // @ts-expect-error : error in specification
      if (data?.idHouse) {
        // @ts-expect-error : error in specification
        nextStep(data.idHouse as string);
        void queryClient.invalidateQueries({ queryKey: queries.user.roles.queryKey });
      }
    },
  });

  const {
    register,
    control,
    formState: { isDirty, errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm<PostHouseTypeSchema>({
    resolver: yupResolver(schema(formatMessage)),
    defaultValues: {
      houseName: "",
      type: PostHouseType.professional,
      frostFreeTemperature: DEFAULT_TEMPERATURES.FROSTFREE,
      absenceTemperature: DEFAULT_TEMPERATURES.ABSENCE,
      preComfortTemperature: DEFAULT_TEMPERATURES.PRECOMFORT,
      isFrostFree: false,
      latitude: 46.71109,
      longitude: 1.7191036,
    },
  });

  const latitude = watch("latitude");
  const longitude = watch("longitude");
  // TEMPERATURES
  const frostFreeTemperature = watch("frostFreeTemperature");
  const absenceTemperature = watch("absenceTemperature");
  const preComfortTemperature = watch("preComfortTemperature");

  const [address, setAddress] = useState<string>("");

  const onSubmit = (data: PostHouseTypeSchema) => {
    const body: AddHouseBody = {
      ...data,
      frostFreeTemperature: data.frostFreeTemperature * 100,
      absenceTemperature: data.absenceTemperature * 100,
      preComfortTemperature: data.preComfortTemperature * 100,
      latitude: data.latitude.toString(),
      longitude: data.longitude.toString(),
    };
    mutate(body);
  };

  return (
    <>
      <form className={stepOneStyle()} onSubmit={handleSubmit(onSubmit)}>
        <div className={contentStyle()}>
          <TextInput
            {...register("houseName")}
            label={formatMessage({ id: "houses.house.name.label" })}
            placeholder={formatMessage({ id: "houses.house.name.placeholder" })}
            error={errors.houseName}
          />
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                multiple={false}
                label={formatMessage({ id: "houses.house.type.label" })}
                value={value}
                options={(Object.keys(PostHouseType) as (keyof typeof PostHouseType)[]).map(k => ({
                  value: PostHouseType[k],
                  name: formatMessage({ id: `houses.house.type.${PostHouseType[k]}` }),
                }))}
                renderValue={value => (
                  <Typography>
                    {formatMessage({
                      id: value ? `houses.house.type.${value}` : "houses.house.type.placeholder",
                    })}
                  </Typography>
                )}
                onChange={onChange}
                disabled
              />
            )}
          />
          <label className={contentStyle("label", { position: true })}>
            {formatMessage({ id: "houses.house.position.title" })}
            <Modal.Root>
              <Modal.Trigger asChild>
                <Button
                  className={contentStyle("button")}
                  text={formatMessage({ id: "houses.house.position.button" })}
                  icon={<ArrowRightIcon />}
                />
              </Modal.Trigger>
              <PositionDialog
                position={{ latitude, longitude }}
                handleChangePosition={(latitude, longitude, displayedAddress) => {
                  setAddress(displayedAddress);
                  setValue("latitude", latitude, { shouldValidate: true });
                  setValue("longitude", longitude, { shouldValidate: true });
                }}
              />
            </Modal.Root>
            {address && <Typography className={contentStyle("address")}>{address}</Typography>}
            {errors.latitude && <Typography className={contentStyle("error")}>{errors.latitude.message}</Typography>}
          </label>
          <label className={contentStyle("label")}>
            {formatMessage({ id: "houses.house.temperatures.title" })}
            {(
              ["frostFreeTemperature", "absenceTemperature", "preComfortTemperature"] as (keyof PostHouseTypeSchema)[]
            ).map((v, i) => (
              <Controller
                key={i}
                name={v}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Slider
                    min={TEMPERATURE_LIMITS.MIN}
                    max={TEMPERATURE_LIMITS.MAX}
                    step={TEMPERATURE_LIMITS.STEP}
                    label={formatMessage({ id: `houses.house.temperatures.${v}` })}
                    valueLabelDisplay="auto"
                    valueLabelFormat={formatMessage(
                      {
                        id: `houses.house.temperatures.rules.${v}`,
                      },
                      {
                        f: frostFreeTemperature,
                        a: absenceTemperature,
                        p: preComfortTemperature,
                      },
                    )}
                    value={value as number}
                    onChange={(_, value) => {
                      if (typeof value === "number") {
                        switch (v) {
                          case "frostFreeTemperature":
                            if (value > absenceTemperature) value = absenceTemperature - TEMPERATURE_LIMITS.STEP;
                            break;
                          case "absenceTemperature":
                            if (value > preComfortTemperature) value = preComfortTemperature - TEMPERATURE_LIMITS.STEP;
                            if (value < frostFreeTemperature) value = frostFreeTemperature + TEMPERATURE_LIMITS.STEP;
                            break;
                          case "preComfortTemperature":
                            if (value < absenceTemperature) value = absenceTemperature + TEMPERATURE_LIMITS.STEP;
                            break;
                        }
                        onChange(value);
                      }
                    }}
                  />
                )}
              />
            ))}
          </label>
          <div>
            <h3 className={contentStyle("label")}>{formatMessage({ id: `houses.house.isFrostFree.title` })}</h3>
            <Controller
              name={"isFrostFree"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <label className={contentStyle("label", { switch: true })}>
                  {formatMessage({ id: "houses.house.switch" }, { s: value as boolean })}
                  <Switch checked={value as boolean} onCheckedChange={onChange} />
                </label>
              )}
            />
          </div>
        </div>
        <ActionButton
          type="submit"
          rounded
          text={formatMessage({ id: "houses.house.save" })}
          icon={<ArrowRightIcon />}
          disabled={!isDirty}
        />
      </form>
    </>
  );
};
