import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import dayjs from "@eisox/dayjs";
import { ButtonV2 } from "@eisox/design-system";

import type { Error } from "~/socketio/types";

import type { ErrorMessageFormated } from "../../types";
import { ErrorDialog } from "../ErrorDialog";
import { Toast } from "../Toast";

import styles from "./StateToast.module.scss";

const TOAST_DURATION = 2000;
const ERROR_DELAY = 30000;

interface StateToastProps {
  initialized: boolean;
  gatewayName: string;
  boilerRoomNames: string[];
  errors?: Error[];
}

const StateToast: React.FC<StateToastProps> = ({ initialized, gatewayName, boilerRoomNames, errors }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);
  const [variant, setVariant] = useState<Toast.ToastProps["variant"]>("warning");

  const prevErrorMessages = useRef<Error[]>();
  const [errorMessages, setErrorMessages] = useState<ErrorMessageFormated[]>([]);

  const newError = JSON.stringify(errors) !== JSON.stringify(prevErrorMessages.current);

  if (newError) {
    prevErrorMessages.current = errors;
    setErrorMessages(prev => [
      {
        date: dayjs().format("L - LTS"),
        messages: errors,
      },
      ...prev,
    ]);
    setVariant("error");
    setOpen(true);
  }

  useEffect(() => {
    let successTimeout: NodeJS.Timeout | null = null;
    let errorTimeout: NodeJS.Timeout | null = null;

    setVariant("warning");
    setOpen(true);

    if (initialized) {
      setVariant("success");
      successTimeout = setTimeout(() => setOpen(false), TOAST_DURATION);
    } else {
      errorTimeout = setTimeout(() => {
        setVariant("error");
        setOpen(true); // S'assurer qu'il reste ouvert en cas d'erreur
      }, ERROR_DELAY);
    }

    return () => {
      if (successTimeout) clearTimeout(successTimeout);
      if (errorTimeout) clearTimeout(errorTimeout);
    };
  }, [initialized]);

  const handleOpenChange = (open: boolean) => {
    if (!open) setErrorMessages([]);
    setOpen(open);
  };

  return (
    <Toast.Root
      className={styles.stateToast}
      open={open}
      onOpenChange={handleOpenChange}
      duration={variant === "success" ? TOAST_DURATION : Infinity}
      variant={variant}
    >
      <Toast.Title>{gatewayName}</Toast.Title>
      <Toast.Description>{boilerRoomNames.join(", ")}</Toast.Description>
      {errors && errors.length > 0 && (
        <ErrorDialog errors={errorMessages}>
          <ButtonV2 className={styles.stateToast__button}>{t("boilerRoom.errors.display")}</ButtonV2>
        </ErrorDialog>
      )}
      <Toast.Close />
    </Toast.Root>
  );
};

export { StateToast };
export type { StateToastProps };
