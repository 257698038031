import { HistovalvesStatsPost200ResponseMessageInner } from "@eisox/backend_webapp_api";
import { Dayjs } from "@eisox/dayjs";
import { VALVE_HARDWARE_VERSION_ENUM, valveVersionHandler } from "@eisox/valves";

import { ValvesWithProblem } from "~/UI/screens/House";

/**
 * The function checks if a given hardware version is higher or equal to another hardware version.
 * @param {string} hardware - The `hardware` parameter is a string that represents the hardware version
 * you want to compare.
 * @param {string} hardwareToCompare - The `hardwareToCompare` parameter is a string that represents
 * the hardware version that you want to compare with.
 * @returns a boolean value.
 */
export const isValveHardwareIsUpper = (hardware?: string, hardwareToCompare?: VALVE_HARDWARE_VERSION_ENUM) => {
  if (!hardware || !hardwareToCompare) return false;
  const values = Object.values(VALVE_HARDWARE_VERSION_ENUM);
  return (
    values.indexOf(valveVersionHandler(hardware) as VALVE_HARDWARE_VERSION_ENUM) >= values.indexOf(hardwareToCompare)
  );
};

/**
 * The function `getValvesByRoomId` filters an array of valves based on a given room ID.
 * @param {string} roomId - A string representing the ID of a room.
 * @param {ValvesMessageValvesInner[]} valves
 * @returns an array of valves that have a matching roomId value to the provided roomId parameter.
 */
export const getValvesByRoomId = (roomId: string, valves: ValvesWithProblem[]) => {
  return valves.filter(valve => valve.roomId === roomId);
};

type ValveSensorsValues = {
  mac?: string;
  updatedAt?: string;
  temperature?: number;
  humidity?: number;
  indoorAirQuality?: number;
  light?: number;
  closing?: number;
  correctedTemp?: number;
  correction?: number;
};

export const getHistoValvesStats = (
  histoValvesStats?: HistovalvesStatsPost200ResponseMessageInner[],
  startDate?: Dayjs,
): ValveSensorsValues[] => {
  if (histoValvesStats) {
    const data = histoValvesStats.filter(hvs => hvs.key === startDate?.valueOf()).flatMap(hvs => hvs.results?.buckets);
    return data.map(d => ({
      mac: d?.key,
      //@ts-ignore
      temperature: d?.temperature && d?.temperature.value,
      //@ts-ignore
      humidity: d?.humidity && d?.humidity.value,
      //@ts-ignore
      indoorAirQuality: d?.indoorAirQuality && d?.indoorAirQuality.value,
      //@ts-ignore
      light: d?.light && d?.light.value,
      //@ts-ignore
      closing: d?.closing && d?.closing.value,
      //@ts-ignore
      correctedTemp: d?.correctedTemp && d?.correctedTemp.value,
      //@ts-ignore
      correction: d?.correction && d?.correction.value,
    }));
  }
  return [];
};
