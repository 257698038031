import { Circle } from "@eisox/design-system";
import { LeafIcon, UserIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { PlanningTypeKey } from "~/UI/components";

import styles from "./UnusualAbsenceScheme.module.scss";

interface UnusualAbsenceSchemeProps {
  absenceTemperature: number;
  preComfortTemperature?: number;
  comfortTemperature?: number;
}

export const UnusualAbsenceScheme: React.FC<UnusualAbsenceSchemeProps> = ({
  absenceTemperature,
  preComfortTemperature,
  comfortTemperature,
}) => {
  const bem = useBem(styles);
  const unsualAbsenceSchemeStyle = bem("unsual-absence-scheme");
  const planningTypeKeys = bem("planning-type-keys");

  return (
    <div className={unsualAbsenceSchemeStyle()}>
      <div className={planningTypeKeys()}>
        <PlanningTypeKey
          className={planningTypeKeys("key")}
          planningType={"comfort"}
          temperature={{ show: true, value: comfortTemperature }}
        />
        <PlanningTypeKey
          className={planningTypeKeys("key")}
          planningType={"preComfort"}
          temperature={{ show: true, value: preComfortTemperature }}
        />
        <PlanningTypeKey
          className={planningTypeKeys("key")}
          planningType={"absence"}
          temperature={{ show: true, value: absenceTemperature }}
        />
      </div>
      <div className={unsualAbsenceSchemeStyle("temperature", { variant: "comfort-8" })} />
      <div className={unsualAbsenceSchemeStyle("temperature", { variant: "comfort-9" })}>
        <div className={unsualAbsenceSchemeStyle("double-arrow")} />
        <p className={unsualAbsenceSchemeStyle("arrow-key")}>1h</p>
      </div>
      <div className={unsualAbsenceSchemeStyle("temperature", { variant: "comfort-10" })}>
        <LeafIcon className={unsualAbsenceSchemeStyle("leaf")} />
      </div>
      <div className={unsualAbsenceSchemeStyle("temperature", { variant: "comfort-12" })} />
      <div className={unsualAbsenceSchemeStyle("temperature", { variant: "comfort-13" })} />
      <div className={unsualAbsenceSchemeStyle("temperature", { variant: "pre-comfort-8" })}>
        <Circle selected className={unsualAbsenceSchemeStyle("user")}>
          <UserIcon />
        </Circle>
      </div>
      <div className={unsualAbsenceSchemeStyle("temperature", { variant: "pre-comfort-9" })}>
        <Circle selected disabled className={unsualAbsenceSchemeStyle("user")}>
          <UserIcon />
        </Circle>
      </div>
      <div className={unsualAbsenceSchemeStyle("temperature", { variant: "pre-comfort-10" })}>
        <Circle selected disabled className={unsualAbsenceSchemeStyle("user")}>
          <UserIcon />
        </Circle>
      </div>
      <div className={unsualAbsenceSchemeStyle("temperature", { variant: "pre-comfort-12" })}>
        <Circle selected className={unsualAbsenceSchemeStyle("user")}>
          <UserIcon />
        </Circle>
      </div>
      <div className={unsualAbsenceSchemeStyle("temperature", { variant: "pre-comfort-13" })} />
      <div />
      {[...Array(6)].map((_, index) => (
        <p className={unsualAbsenceSchemeStyle("hour")}>{index + 8}h</p>
      ))}
    </div>
  );
};
