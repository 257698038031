import { useEffect, useState } from "react";

import type { Control, UseFormSetValue } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";

import { PostModuleTypeEnum } from "@eisox/backend_webapp_api";
import { SelectV2 as Select } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import { Gateway } from "~/UI/components";
import type { ItemType } from "~/UI/layouts";
import { PlanV2 } from "~/UI/layouts";
import type { houseLoader } from "~/UI/screens";
import { getPlan } from "~/api/plan";
import { env } from "~/configuration";
import { GATEWAY_MIN_SOFTWARE_VERSIONS } from "~/constants";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";
import { idLoaderHouse } from "~/routes/utils";
import { isVersionIsUpper } from "~/utils";

import type { AddBoilerroomType } from "../utils";

import styles from "./Plan.module.scss";

interface PlanProps {
  control: Control<AddBoilerroomType>;
  setValue: UseFormSetValue<AddBoilerroomType>;
  houseId: string;
  gatewayId: string;
  type?: PostModuleTypeEnum;
}

export const Plan: React.FC<PlanProps> = ({ control, setValue, houseId, gatewayId, type }) => {
  const { formatMessage } = useIntl();
  const bem = useBem(styles);
  const planStyle = bem("plan");

  const { plans, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const planId = plans[0]?.id;

  const [planUrl, setPlanUrl] = useState<string>();
  const [plan, setPlan] = useState<string | undefined>(planId);

  useEffect(() => {
    getPlan(plan, houseId).then(response =>
      response.type === SUCCESS_FETCH ? setPlanUrl(response.result.message) : setPlanUrl(undefined),
    );
  }, [plan]);

  const items: ItemType[] = gateways
    .filter(
      g =>
        g.plan?.planId === plan &&
        (!type ||
          type === PostModuleTypeEnum.EclypseBoilerRoom ||
          (type === PostModuleTypeEnum.NiagaraBoilerRoom &&
            isVersionIsUpper(g.softwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.BOILERROOM_NIAGARA))),
    )
    .map(g => ({
      id: g.id!,
      x: g.plan?.x!,
      y: g.plan?.y!,
      renderItem: () => (
        <Controller
          name="gatewayId"
          control={control}
          render={({ field: { onChange } }) => (
            <Gateway
              expanded
              uid={g.uid}
              gatewayName={g.gatewayName}
              problemStatus={undefined}
              selected={g.id === gatewayId}
              onClick={() => {
                onChange(g.id);
                if (
                  !isVersionIsUpper(
                    g.softwareVersion,
                    GATEWAY_MIN_SOFTWARE_VERSIONS.BOILERROOM_CHANGE_OPERATOR_PROTOCOL,
                  )
                )
                  setValue("type", PostModuleTypeEnum.EclypseBoilerRoom);
              }}
            />
          )}
        />
      ),
    }));

  return (
    <div className={planStyle()}>
      <Select
        classNames={{ trigger: planStyle("select") }}
        label={formatMessage({ id: "plan.actions.add.bmsModule.level.label" })}
        options={plans.map(p => ({ value: p.id!, name: p.name! }))}
        value={plan}
        onChange={value => setPlan(value as string)}
      />
      <PlanV2 className={planStyle("plan")} planUrl={planUrl} items={items} />
    </div>
  );
};
