import clsx from "clsx";
import { useIntl } from "react-intl";

import { useBem } from "@eisox/tools";

import { Temperature } from "../Temperature";

import styles from "./Tank.module.scss";

interface TankProps {
  orientation?: "up" | "down";
  tankSanitaryHotWaterTemperature?: number;
  className?: string;
}

export const Tank: React.FC<TankProps> = ({ orientation = "up", tankSanitaryHotWaterTemperature, className }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const tankStyle = bem("tank");
  const balloonStyle = bem("balloon");

  return (
    <div className={clsx(tankStyle({ orientation }), className)}>
      <div className={tankStyle("ecs")} />
      <div className={tankStyle("network")} />
      <div className={tankStyle("link")} />
      <div className={tankStyle("tank")}>
        <div className={balloonStyle({ orientation })}>
          <span className={balloonStyle("title")}>{formatMessage({ id: "boilerroom.balloon" })}</span>
          <Temperature type={"starting"} value={tankSanitaryHotWaterTemperature} />
        </div>
      </div>
    </div>
  );
};
