import { useState } from "react";

import { useIntl } from "react-intl";
import { Navigate, useLoaderData, useParams, useRouteLoaderData } from "react-router-dom";

import { Button } from "@eisox/design-system";
import { ValveIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Header } from "~/UI/layouts";
import { ValveListDrawer } from "~/UI/layouts/ListDrawer/ValveListDrawer/ValveListDrawer";
import type { houseLoader } from "~/UI/screens";
import { idLoaderHouse, routeToPlan } from "~/routes/utils";
import { getValvesByRoomId, isRoomHasValvesV5 } from "~/utils";

import { usePermissionsContext } from "../House";
import { Parameters, Plan, Planning, Stats } from "./components";
import { RoomContext } from "./context";
import type { loader } from "./loader";

import styles from "./Room.module.scss";

export const Room: React.FC = () => {
  const { houseId, planId, roomId } = useParams();
  const { rooms, house, valves } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { planningsRoom } = useLoaderData() as LoaderData<typeof loader>;
  const { formatMessage } = useIntl();
  const { permissions } = usePermissionsContext();

  const bem = useBem(styles);
  const roomStyle = bem("room");
  const headerStyle = bem("header");
  const contentStyle = bem("content");

  const [parametersOpen, setParametersOpen] = useState(false);
  const [listValveDrawerOpen, setListValveDrawerOpen] = useState(false);

  const toggleListValves = () => {
    setListValveDrawerOpen(!listValveDrawerOpen);
  };

  const room = rooms.find(room => roomId && room.id === roomId);
  if (!room) return <Navigate to={routeToPlan(houseId!, planId!)} />;

  const valvesOfRoom = getValvesByRoomId(roomId!, valves);

  const plannings = planningsRoom;

  return (
    <RoomContext.Provider
      value={{
        valves: valvesOfRoom,
        setParametersOpen,
        room,
        isRoomHasValvesV5: isRoomHasValvesV5(roomId!, valvesOfRoom),
        plannings,
      }}
    >
      <div className={roomStyle()}>
        <Header
          title={room.name || formatMessage({ id: "room" })}
          canAccessParameters={permissions.room?.read}
          parameters={{
            title: formatMessage({ id: "room.parameters.title" }),
            content: <Parameters setDisplayParameters={setParametersOpen} />,
            controlParameters: {
              openDrawer: parametersOpen,
              handleDrawer: (open: boolean) => setParametersOpen(open),
            },
          }}
        >
          <div className={headerStyle()}>
            {permissions.valve?.read && valvesOfRoom.length > 0 && (
              <Button
                onClick={toggleListValves}
                text={formatMessage({ id: "drawer.listValves" })}
                icon={<ValveIcon style={{ width: 20, height: 20 }} />}
              />
            )}
          </div>
        </Header>
        <main className={contentStyle()}>
          <Stats />
          <Plan />
          <Planning house={house} room={room} plannings={plannings} />
        </main>

        {listValveDrawerOpen && (
          <ValveListDrawer open={listValveDrawerOpen} onClose={toggleListValves} valves={valvesOfRoom} />
        )}
      </div>
    </RoomContext.Provider>
  );
};
