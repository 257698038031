import { useMemo } from "react";

import { orderBy } from "lodash";

import { KeyFunctionToCompare, filterArrayObj } from "~/utils/searchUtils";

type CustomSearchFilter<T> = {
  key: keyof T;
} & KeyFunctionToCompare<T>;

export const useFilteredItems = <T extends object>(
  items: T[],
  search: string,
  sortBy: Array<keyof T | ((key: T) => any)>,
  filterKeys: CustomSearchFilter<T>[],
) =>
  useMemo(() => {
    const sortedItems = orderBy(items, sortBy, ["asc"]);
    const filteredItems = filterArrayObj<T>(sortedItems, search, filterKeys);
    return filteredItems;
  }, [items, search, sortBy]);
