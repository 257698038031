import { z } from "zod";

const parametersSchema = (ecs: boolean) =>
  z.object({
    name: z.string().min(1),
    reducedTemperatureAbs: ecs ? z.number().optional() : z.number(),
    setpointThermalShock: ecs ? z.number() : z.number().optional(),
  });

type ParametersSchemaType = z.infer<ReturnType<typeof parametersSchema>>;

export { parametersSchema };
export type { ParametersSchemaType };
