import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, useParams, useRouteLoaderData } from "react-router-dom";

import { Breadcrumb, ButtonV2 as Button, DrawerV2 as Drawer } from "@eisox/design-system";
import { NutIcon } from "@eisox/icons";

import { Page } from "~/UI/components";
import type { loader } from "~/UI/screens/House";
import { usePermissionsContext } from "~/UI/screens/House";
import {
  Mode,
  getBoilerRoomsFromModules,
  useBoilerRoomContext,
  useBoilerRoomRealTimeProviderContext,
  useModeContext,
} from "~/features/BoilerRooms";
import { NetworksDialog } from "~/features/HeatingNetworkConfiguration";
import { HeatingCurveCard, Parameters, PlanningCard, SchemeCard } from "~/features/HeatingNetworks";
import { ECSSetpointCard } from "~/features/HeatingNetworks/ECSSetpoint";
import { idLoaderHouse, routeToBoilerroom, routeToPlan } from "~/routes/utils";
import { Event } from "~/socketio/requests";

import styles from "./HeatingNetwork.module.scss";

const NAME = "HeatingNetworkPage";

const HeatingNetworkPage: React.FC = () => {
  const { t } = useTranslation();

  const { houseId, boilerroomId, networkId } = useParams() as {
    houseId: string;
    boilerroomId: string;
    networkId: string;
  };

  const { plans, boilerroomPos, heatingNetworks, gateways, modules, rooms, valves } = useRouteLoaderData(
    idLoaderHouse,
  ) as LoaderData<typeof loader>;

  const { permissions } = usePermissionsContext();

  const { mode } = useModeContext(NAME);
  const { socket } = useBoilerRoomRealTimeProviderContext(NAME);
  const { currentBoilerRoom, boilerRooms } = useBoilerRoomContext(NAME);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const boilerRoom = getBoilerRoomsFromModules(boilerroomPos).find(b => b.id === boilerroomId);
  if (!boilerRoom) throw new Error("Boiler room not found");

  const plan = plans.find(p => p.id === boilerRoom.plan?.planId);

  const heatingNetwork = heatingNetworks.find(hn => hn.id === networkId);
  if (!heatingNetwork) throw new Error("Heating network not found");

  const ecs = heatingNetwork.type === "sanitaryHotWater";

  const heatingNetworkData = currentBoilerRoom?.heatingNetworks?.find(hn => hn.id === networkId);

  useEffect(() => {
    if (mode === Mode.Live && socket) socket.emit(Event.GET_DATA, { type: "hnInit", heatingNetworkId: networkId });
  }, [mode, networkId, socket]);

  return (
    <Drawer.Root open={drawerOpen} onOpenChange={setDrawerOpen}>
      <Page.Header>
        <Breadcrumb.Root>
          <Breadcrumb.List>
            {plan && (
              <>
                <Breadcrumb.Item>
                  <Breadcrumb.Link asChild>
                    <Link to={routeToPlan(houseId, plan.id!)}>{plan.name ?? "--"}</Link>
                  </Breadcrumb.Link>
                </Breadcrumb.Item>
                <Breadcrumb.Separator />
              </>
            )}
            <Breadcrumb.Item>
              <Breadcrumb.Link asChild>
                <Link to={routeToBoilerroom(houseId, boilerroomId)}>{boilerRoom.name}</Link>
              </Breadcrumb.Link>
            </Breadcrumb.Item>
            <Breadcrumb.Separator />
            <Breadcrumb.Item>
              <Breadcrumb.Page asChild>
                <Page.Title>{heatingNetworkData?.name ?? heatingNetwork.name ?? "--"}</Page.Title>
              </Breadcrumb.Page>
            </Breadcrumb.Item>
          </Breadcrumb.List>
        </Breadcrumb.Root>
        <Drawer.Trigger asChild>
          <Button>
            {t("network.header.parameters.title")} <NutIcon />
          </Button>
        </Drawer.Trigger>
        {heatingNetworkData && (
          <>
            <Parameters
              open={drawerOpen}
              onOpenChange={setDrawerOpen}
              boilerRoomId={boilerroomId}
              heatingNetwork={heatingNetworkData}
              ecs={ecs}
            />
            <NetworksDialog>
              <Button className={styles.valvesConfiguration}>
                {t(
                  permissions.valve?.heatingNetworkId?.update
                    ? "network.header.valvesConfiguration"
                    : "network.header.valvesConfigurationView",
                )}
                <NutIcon />
              </Button>
            </NetworksDialog>
          </>
        )}
      </Page.Header>
      <Page.Content className={styles.content}>
        <SchemeCard
          className={styles.content__scheme}
          houseId={houseId}
          boilerRoomId={boilerroomId}
          ecs={ecs}
          heatingNetwork={heatingNetworkData}
          valves={valves}
          gateways={gateways}
          rooms={rooms}
          plans={plans}
        />
        {ecs ? (
          <ECSSetpointCard
            className={styles.content__heatingCurve}
            boilerRoomId={boilerroomId}
            heatingNetworkId={networkId}
            externalTemperature={currentBoilerRoom?.externalTemperature}
            setpointTemperatureEcs={heatingNetworkData?.setpointTemperature}
          />
        ) : (
          <HeatingCurveCard
            className={styles.content__heatingCurve}
            houseId={houseId}
            moduleId={heatingNetwork.moduleId!}
            boilerRoomId={boilerroomId}
            heatingNetworkId={networkId}
            heatingNetworkName={heatingNetworkData?.name ?? "-"}
            gateways={gateways}
            heatingCurveAuto={heatingNetworkData?.isAutoHeatingCurves ?? false}
            heatingCurve={heatingNetworkData?.heatingCurves ?? []}
            boilerRooms={boilerRooms ?? []}
            maxOffsetAdaptation={heatingNetworkData?.maxOffsetAdaptation}
            reducedTemperatureAbs={heatingNetworkData?.reducedTemperatureAbs}
            externalTemperature={currentBoilerRoom?.externalTemperature}
            setpointTemperature={heatingNetworkData?.setpointTemperature}
            targetHslope={heatingNetworkData?.targetHslope}
          />
        )}

        <PlanningCard className={styles.content__planning} ecs={ecs} heatingNetwork={heatingNetworkData} />
      </Page.Content>
    </Drawer.Root>
  );
};

HeatingNetworkPage.displayName = NAME;

export { HeatingNetworkPage };
