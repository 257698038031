import clsx from "clsx";

import { useBem } from "@eisox/tools";

import { Card, CardProps } from "../Card";
import { Copyright, CopyrightProps } from "../Copyright";
import { Eisox } from "../Eisox";

import styles from "./styles.module.scss";

export interface UnProtectedRouteResponsiveCardProps extends CardProps, Pick<CopyrightProps, "version"> {}

export const UnProtectedRouteResponsiveCard: React.FC<UnProtectedRouteResponsiveCardProps> = ({
  version,
  className,
  children,
}) => {
  const bem = useBem(styles);
  const unProtectedRouteResponsiveCardStyle = bem("un-protected-route-responsive-card");

  return (
    <div className={unProtectedRouteResponsiveCardStyle()}>
      <Card className={clsx(unProtectedRouteResponsiveCardStyle("container"), className)}>
        <Eisox className={unProtectedRouteResponsiveCardStyle("logo")} />
        <div className={unProtectedRouteResponsiveCardStyle("children")}>{children}</div>
      </Card>
      <Copyright className={unProtectedRouteResponsiveCardStyle("copyright")} version={version} />
    </div>
  );
};
