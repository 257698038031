import React, { cloneElement, forwardRef } from "react";

import { VariantProps, cva } from "class-variance-authority";

import { primary } from "@eisox/colors";
import { RoundIcon } from "@eisox/design-system";
import type { IconProps } from "@eisox/icons";

import styles from "./OverviewCard.module.scss";

const overviewCardVariants = cva(styles.overviewCard, {
  variants: {
    variants: {
      default: styles.overviewCard_default,
      info: styles.overviewCard_info,
      success: styles.overviewCard_success,
      danger: styles.overviewCard_danger,
    },
    size: {
      small: styles.overviewCard_small,
      large: styles.overviewCard_large,
    },
  },
  defaultVariants: {
    variants: "default",
    size: "small",
  },
});

const titleVariants = cva(styles.overviewCard__title, {
  variants: {
    variants: {
      default: styles.overviewCard__title_default,
      info: styles.overviewCard__title_info,
      success: styles.overviewCard__title_success,
      danger: styles.overviewCard__title_danger,
    },
  },
  defaultVariants: {
    variants: "default",
  },
});

const subtitleVariants = cva(styles.overviewCard__subtitle, {
  variants: {
    variants: {
      default: styles.overviewCard__subtitle_default,
      info: styles.overviewCard__subtitle_info,
      success: styles.overviewCard__subtitle_success,
      danger: styles.overviewCard__subtitle_danger,
    },
  },
  defaultVariants: {
    variants: "default",
  },
});

interface OverviewCardProps extends React.ComponentPropsWithRef<"div">, VariantProps<typeof overviewCardVariants> {
  icon: React.ReactElement<IconProps & React.RefAttributes<SVGSVGElement>>;
  iconBackgroundColor?: keyof typeof primary;
  iconColor?: keyof typeof primary;
  title: string;
  subtitle: string;
  hasDegre?: boolean;
}

export const OverviewCard = forwardRef<HTMLDivElement, OverviewCardProps>((props, forwardedRef) => {
  const {
    icon,
    iconBackgroundColor = "white",
    iconColor = "darkBlue",
    title,
    subtitle,
    hasDegre,
    variants,
    size,
    className,
    children,
    onClick,
    style,
    ...overviewCardProps
  } = props;

  return (
    <div
      {...overviewCardProps}
      ref={forwardedRef}
      className={overviewCardVariants({ variants, size, className })}
      onClick={onClick}
      style={{ ...style, cursor: onClick ? "pointer" : "default" }}
    >
      <RoundIcon size={size === "small" ? 50 : 70} iconColor={iconColor} backgroundColor={iconBackgroundColor}>
        {cloneElement(icon, { style: { width: 30, height: 30 } })}
      </RoundIcon>
      <div className={styles.overviewCard__content}>
        <p className={titleVariants({ variants })}>
          {title} {hasDegre && <span>°C</span>} {children}
        </p>
        <p className={subtitleVariants({ variants })}>{subtitle}</p>
      </div>
    </div>
  );
});

OverviewCard.displayName = "DashboardOverviewCard";
