enum SortOrder {
  ASC,
  DESC,
}

const getSortOrder = <T extends string>(sort: T[], desc: T[] = [], value: T): SortOrder | null => {
  const isDescendingOrder = desc.includes(value);
  const isAsceningOorder = !isDescendingOrder && sort.includes(value);
  return isAsceningOorder ? SortOrder.ASC : isDescendingOrder ? SortOrder.DESC : null;
};

const sortResponse = <T>(array: T[], sort: Array<keyof T>, desc: Array<keyof T> = []): T[] =>
  array.sort((a, b) => {
    for (const key of sort) {
      const isDesc = desc && desc.includes(key);
      if (a[key]! < b[key]!) {
        return isDesc ? 1 : -1;
      }
      if (a[key]! > b[key]!) {
        return isDesc ? -1 : 1;
      }
    }
    return 0;
  });

export { SortOrder, getSortOrder, sortResponse };
