import { useEffect, useMemo, useState } from "react";

import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";

import type { HistovalvesStatsPost200ResponseMessageInner } from "@eisox/backend_webapp_api";
import { PostHistovalvesStatsFieldsEnum as FieldsEnum } from "@eisox/backend_webapp_api";
import type { Dayjs } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";

import { Gateway } from "~/UI/components";
import { Boilerroom } from "~/UI/components/Boilerroom";
import { Valve } from "~/UI/layouts";
import type { ItemType } from "~/UI/layouts/PlanV2/components";
import { Origin } from "~/UI/layouts/PlanV2/components";
import type { ValvesWithProblem, loader as houseLoader } from "~/UI/screens/House";
import { useAction } from "~/hooks";
import { usePlansContext } from "~/providers";
import { idLoaderHouse, routeToBoilerroom, routeToHistovalvesStats, routeToRoom } from "~/routes/utils";
import { DisplaySection } from "~/stores";
import { API } from "~/types/API";
import { getHistoValvesStats, transformHumidity, transformLight, transformTemperature } from "~/utils";
import { getRoomHistoryData } from "~/utils/Room/utils";
import { isOfflineOnPlan } from "~/utils/timeUtils";

import { Room } from "../components";

export const useHistory = () => {
  const {
    rooms: currentRooms,
    gateways: currentGateways,
    valves: currentValves,
    boilerroomPos: currentBoilerroom,
  } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { houseId, planId } = useParams() as { houseId: string; planId: string };
  const navigate = useNavigate();

  const { selectedDataSelector: displayData, selectedDisplaySelector } = usePlansContext("useHistory");
  const { response, submit: submitAction } = useAction<HistovalvesStatsPost200ResponseMessageInner[]>({
    displayLoadingPopup: false,
  });

  const [date, setDate] = useState<Dayjs>();

  useEffect(() => {
    // when we change of plan
    date && submit(date);
  }, [planId]);

  const historyDisplayed = selectedDisplaySelector.includes(DisplaySection.HISTORY);

  const data = response?.message[0].message;
  const histoValveData = useMemo(() => getHistoValvesStats(data, date), [response?.date]);

  // VALVES
  const valvesOnPlan: ValvesWithProblem[] = currentValves
    .filter(v => v.plan?.planId === planId)
    .map(v => {
      if (historyDisplayed && response) {
        const { closing, correctedTemp, correction, temperature, humidity, indoorAirQuality, light } =
          histoValveData.find(hvd => hvd.mac === v.mac) || {};
        return {
          ...v,
          updatedAt: date?.toISOString(),
          closing,
          correctedTemp: correctedTemp ? transformTemperature(correctedTemp) : correctedTemp,
          correction: correction ? transformTemperature(correction) : correction,
          sensors: {
            temperature: temperature ? transformTemperature(temperature) : temperature,
            humidity: humidity ? transformHumidity(humidity) : humidity,
            indoorAirQuality,
            light: light ? transformLight(light) : light,
          },
          stateData: undefined,
          errors: [],
          warnings: [],
          problemStatus: undefined,
        };
      }
      if (isOfflineOnPlan(v.updatedAt)) {
        return {
          ...v,
          closing: undefined,
          correctedTemp: undefined,
          correction: undefined,
          sensors: {
            temperature: undefined,
            humidity: undefined,
            indoorAirQuality: undefined,
            light: undefined,
          },
          stateData: undefined,
        };
      }
      return v;
    });

  const valves: ItemType[] = valvesOnPlan.map(v => ({
    id: v.id!,
    x: v.plan?.x!,
    y: v.plan?.y!,
    renderItem: () => <Valve {...v} selected displayDetails />,
  }));

  // ROOMS
  const roomsOnPlan = currentRooms
    .filter(r => r.plan?.planId === planId)
    .map(r => {
      if (historyDisplayed && response) {
        return {
          ...r,
          problemStatus: undefined,
        };
      }
      return r;
    });

  const rooms: ItemType[] = roomsOnPlan.map(r => ({
    id: r.id!,
    x: r.plan?.x!,
    y: r.plan?.y!,
    origin: Origin.BOTTOM_CENTER,
    renderItem: () => {
      const roomValves = valvesOnPlan.filter(v => v.roomId === r.id);
      const roomHistoryData = getRoomHistoryData(r, roomValves);
      return (
        <Room
          displayed={displayData}
          room={r}
          valves={roomValves}
          onClick={() => navigate(routeToRoom(houseId, planId, r.id!))}
          {...roomHistoryData}
        />
      );
    },
  }));

  // GATEWAYS
  const gateways: ItemType[] = currentGateways
    .filter(g => g.plan?.planId === planId)
    .map(g => ({
      id: g.id!,
      x: g.plan?.x!,
      y: g.plan?.y!,
      renderItem: () => <Gateway {...g} selected expanded={displayData === "name"} />,
    }));

  // BOILERROOMS
  const boilerrooms: ItemType[] = currentBoilerroom
    .flatMap(bpos => bpos.boilerRooms)
    .filter(b => b?.plan?.planId === planId)
    .map(b => ({
      id: b?.id!,
      x: b?.plan?.x!,
      y: b?.plan?.y!,
      renderItem: () => (
        <Boilerroom
          name={b?.name}
          size={20}
          selected
          expanded={displayData === "name"}
          onClick={() => navigate(routeToBoilerroom(houseId, b?.id!))}
        />
      ),
    }));

  const submit = (date: Dayjs) => {
    setDate(date);
    valvesOnPlan.filter(v => v.mac).length > 0 &&
      submitAction(
        {
          fields: [
            FieldsEnum.Temperature,
            FieldsEnum.Humidity,
            FieldsEnum.IndoorAirQuality,
            FieldsEnum.Light,
            FieldsEnum.Closing,
            FieldsEnum.CorrectedTemp,
            "correction",
          ],
          startDate: dayjs(date).subtract(20, "minute").toISOString(),
          endDate: dayjs(date).add(20, "minute").toISOString(),
          valves: [...valvesOnPlan.filter(v => v.mac).flatMap(v => v.mac!)],
        },
        API.HTTP_METHOD.POST,
        routeToHistovalvesStats(),
      );
  };

  return {
    rooms,
    gateways,
    valves,
    boilerrooms,
    submit,
  };
};
