import { faker } from "@faker-js/faker";

import type { HslopeHistory } from "~/apiV2";

export class HslopeHistoryBuilder implements HslopeHistory {
  createdAt?: string | undefined;
  hslope?: number | undefined;
  maxOpening?: number | undefined;
  valveId?: string | undefined;
  valveMac?: string | undefined;

  constructor() {
    this.createdAt = faker.date.past().toISOString();
    this.hslope = faker.number.int({ min: 0, max: 100 });
    this.maxOpening = faker.number.int({ min: 0, max: 100 });
    this.valveId = faker.database.mongodbObjectId();
    this.valveMac = faker.internet.mac();
  }

  withCreatedAt(createdAt: string): HslopeHistoryBuilder {
    this.createdAt = createdAt;
    return this;
  }

  withHslope(hslope: number): HslopeHistoryBuilder {
    this.hslope = hslope;
    return this;
  }

  withMaxOpening(maxOpening: number): HslopeHistoryBuilder {
    this.maxOpening = maxOpening;
    return this;
  }

  withValveId(valveId: string): HslopeHistoryBuilder {
    this.valveId = valveId;
    return this;
  }

  withValveMac(valveMac: string): HslopeHistoryBuilder {
    this.valveMac = valveMac;
    return this;
  }
}
