import { useEffect, useRef, useState } from "react";

import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import { HSLOPE } from "~/constants/appConstantV2";

import type { Valve } from "../../types";

import styles from "./Distribution.module.scss";

const POINT_WIDTH = 10;
const CLUSTER_WIDTH = 20;
const AXIS_MAX = HSLOPE.MAX;

interface Cluster {
  ids: string[];
  value: number;
  count: number;
}

interface AxisProps {
  valves: { id: string; hslope: number }[];
  selectedPoints: string[];
  onClickOnPoint: (ids: string[]) => void;
}

const Axis: React.FC<AxisProps> = ({ valves, selectedPoints, onClickOnPoint }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [clusters, setClusters] = useState<Cluster[]>([]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWith = containerRef.current.offsetWidth;
        const sortedClusters: Cluster[] = [...valves]
          .sort((a, b) => a.hslope - b.hslope)
          .map(valve => ({
            ids: [valve.id],
            value: valve.hslope,
            count: 1,
          }));

        const clusters: Cluster[] = clustersPoints(sortedClusters, containerWith, POINT_WIDTH);
        const mergedClusters: Cluster[] = clustersPoints(clusters, containerWith, CLUSTER_WIDTH);

        setClusters(mergedClusters);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [valves]);

  return (
    <div ref={containerRef} className={styles.axis}>
      <div className={styles.axis__line}>
        {clusters.map((cluster, index) => {
          const selected =
            selectedPoints.length === cluster.ids.length && selectedPoints.every(id => cluster.ids.includes(id));
          return (
            <div
              key={index}
              className={cx(styles.axis__point, selected && styles.axis__point_selected)}
              style={{
                left: `${(cluster.value / 40) * 100}%`,
                width: cluster.count > 1 ? CLUSTER_WIDTH : POINT_WIDTH,
                height: cluster.count > 1 ? CLUSTER_WIDTH : POINT_WIDTH,
                top: cluster.count > 1 ? -CLUSTER_WIDTH / 2 : -POINT_WIDTH / 2,
              }}
              onClick={() => onClickOnPoint(cluster.ids)}
            >
              {cluster.count > 1 ? cluster.count : null}
            </div>
          );
        })}
      </div>
      <div className={styles.axis__legend}>
        {Array.from({ length: Math.floor(AXIS_MAX / 10) }, (_, i) => (i + 1) * 10)
          .filter(n => n < AXIS_MAX)
          .map(v => (
            <p key={`legend-${v}`}>{v}</p>
          ))}
      </div>
    </div>
  );
};

interface DistributionProps {
  valves: Valve[];
  selectedPoints: string[];
  onClickOnPoint: (ids: string[]) => void;
}

const Distribution: React.FC<DistributionProps> = ({ valves: valvesProp, selectedPoints, onClickOnPoint }) => {
  const { t } = useTranslation();

  const hslopeDefined = (valve: Valve): valve is Valve & { hslope: NonNullable<Valve["hslope"]> } => !!valve.hslope;
  const valves = valvesProp.filter(hslopeDefined).map(v => ({ id: v.id, hslope: v.hslope.value }));

  return (
    <div className={styles.distribution}>
      <p className={styles.distribution__title}>
        {t("manualBalancing.distribution")} <span>(min/°C)</span>
      </p>
      <Axis valves={valves} selectedPoints={selectedPoints} onClickOnPoint={onClickOnPoint} />
    </div>
  );
};

Distribution.displayName = "ManualBalancing.Distribution";

const clustersPoints = (sortedClusters: Cluster[], containerWidth: number, distanceThreshold: number): Cluster[] => {
  const clusters: Cluster[] = [];

  sortedClusters.forEach(cluster => {
    if (clusters.length === 0) {
      clusters.push(cluster);
    } else {
      const lastCluster = clusters[clusters.length - 1];
      const distance = (cluster.value - lastCluster.value) * (containerWidth / AXIS_MAX);
      if (distance <= distanceThreshold) {
        lastCluster.count += cluster.count;
        lastCluster.ids.push(...cluster.ids);
      } else {
        clusters.push(cluster);
      }
    }
  });

  return clusters;
};

export { Distribution };
