import { GroupField, GroupedValves, Plan, Room, SortField, SortOrder, Valve } from "../types";

const sort = (valves: Valve[], field: SortField, order: SortOrder): Valve[] => {
  if (field === SortField.noSorting) return valves;
  return [...valves].sort((a, b) => {
    const aValue = a[field]?.value;
    const bValue = b[field]?.value;

    if (aValue === undefined && bValue === undefined) return 0;
    if (aValue === undefined) return order === "asc" ? 1 : -1;
    if (bValue === undefined) return order === "asc" ? -1 : 1;

    if (aValue < bValue) return order === "asc" ? -1 : 1;
    if (aValue > bValue) return order === "asc" ? 1 : -1;
    return 0;
  });
};

const group = (valves: Valve[], field: GroupField): GroupedValves => {
  if (field === GroupField.noGrouping) return { [GroupField.noGrouping]: valves };
  return valves.reduce((acc, valve) => {
    let key = valve[field] ?? GroupField.noGrouping;
    if (typeof key === "object") key = key.id; //plan
    if (!acc[key]) acc[key] = [];
    acc[key].push(valve);
    return acc;
  }, {} as GroupedValves);
};

const getName = (id: string, groupField: GroupField, plans: Plan[], rooms: Room[]): string =>
  ({
    [GroupField.plan]: plans.find(p => p.id === id)?.name ?? GroupField.noGrouping,
    [GroupField.room]: rooms.find(r => r.id === id)?.name ?? GroupField.noGrouping,
    [GroupField.noGrouping]: GroupField.noGrouping,
  })[groupField];

interface GetDisplayedValvesProps {
  valves: Valve[];
  plans: Plan[];
  rooms: Room[];
  sortBy: SortField;
  sortOrder: SortOrder;
  groupedBy: GroupField;
  selectedValves: string[];
}

const getDisplayedValves = (props: GetDisplayedValvesProps): GroupedValves => {
  const { valves, plans, rooms, sortBy, sortOrder, groupedBy: groupBy, selectedValves: selectedValvesProp } = props;

  const selectedValves = selectedValvesProp.length > 0 ? valves.filter(v => selectedValvesProp.includes(v.id)) : valves;

  const sortedValves = sort(selectedValves, sortBy, sortOrder);
  const grouped = group(sortedValves, groupBy);

  const groupedKeys = Object.keys(grouped);
  const displayedValves = groupedKeys.reduce((acc, key) => {
    const name = getName(key, groupBy, plans, rooms);
    acc[name] = grouped[key];
    return acc;
  }, {} as GroupedValves);

  return displayedValves;
};

const getHslopeAverage = (valves: Valve[]): number => {
  const hasHslopeDefined = (v: Valve): v is Valve & { hslope: { value: number } } => !!v.hslope;
  const hslopeValues = valves.filter(hasHslopeDefined).map(valve => valve.hslope.value);
  const sum = hslopeValues.reduce((acc, val) => acc + val, 0);
  return hslopeValues.length !== 0 ? Math.round(sum / hslopeValues.length) : 0;
};

export { getDisplayedValves, getHslopeAverage };
