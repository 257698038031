import type { MutableRefObject } from "react";
import { useEffect, useMemo, useRef } from "react";

import { useParams, useRouteLoaderData } from "react-router-dom";

import type { loader } from "~/UI/screens/House";
import { idLoaderHouse } from "~/routes/utils";
import type { BoilerroomDataRes } from "~/socketio/types";

import { StateToast } from "../components";
import { convertPlanningTypeToType, getBoilerRoomsFromModules, mergeObjects } from "../helpers";
import { BoilerRoomProvider } from "./BoilerRoomProvider";
import { useBoilerRoomRealTimeProviderContext } from "./RealTimeProvider";

const NAME = "BoilerRoomGateway";

interface GatewayProviderProps {
  initialized: MutableRefObject<boolean>;
  data?: BoilerroomDataRes;
  gatewayId: string;
  boilerRoomIds: string[];
  children: React.ReactNode;
}

const GatewayProvider: React.FC<GatewayProviderProps> = ({ initialized, data, gatewayId, boilerRoomIds }) => {
  const { houseId, boilerroomId } = useParams() as { houseId: string; boilerroomId: string };
  const { boilerroomPos, gateways, modules } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof loader>;

  const { setData, socket, dataReceivedTrigger, useUpdateBoilerRoom } = useBoilerRoomRealTimeProviderContext(NAME);

  const prevData = useRef<BoilerroomDataRes | null>(null);

  const gateway = useMemo(() => gateways.find(g => g.id === gatewayId), [gatewayId, gateways]);
  const gatewayName = gateway?.gatewayName ?? "-";
  const gatewayModuleIds: string[] = modules.filter(m => m.gatewayId === gatewayId).map(m => m.id!);
  const boilerRoomNames = useMemo(
    () =>
      getBoilerRoomsFromModules(boilerroomPos)
        .filter(b => boilerRoomIds.includes(b.id!))
        .map(b => b.name ?? "-"),
    [boilerRoomIds, boilerroomPos],
  );
  const displayBoilerRoom = boilerRoomIds.includes(boilerroomId);

  useEffect(() => {
    if (!socket) return;
    const handleGetDataRes = (data: BoilerroomDataRes) => {
      if (
        // Gateway's data ?
        boilerRoomIds.some(id => data.boilerRooms?.map(b => b.id).includes(id)) ||
        // Gateway's error ?
        data.errors?.some(e => e.moduleId && gatewayModuleIds.includes(e.moduleId))
      ) {
        // Initialized ?
        if (initialized.current) {
          setData(prev => {
            return mergeObjects(prev, convertPlanningTypeToType(data));
          });
          dataReceivedTrigger(gatewayId);
        } else if (data.date_prev === undefined) {
          setData(prev =>
            prev ? mergeObjects(prev, convertPlanningTypeToType(data)) : convertPlanningTypeToType(data),
          );
          if (!data.errors?.length) initialized.current = true;
          dataReceivedTrigger(gatewayId);
        }
        prevData.current = data;
      }
    };

    socket.on("GET_DATA_RES", handleGetDataRes);

    return () => {
      socket.off("GET_DATA_RES", handleGetDataRes);
    };
  }, [socket, boilerRoomIds, setData, dataReceivedTrigger, gatewayModuleIds, gatewayId]);

  return (
    <>
      {displayBoilerRoom ? (
        <BoilerRoomProvider
          houseId={houseId}
          boilerRoomId={boilerroomId}
          boilerRooms={data?.boilerRooms}
          errors={data?.errors}
          useUpdateBoilerRoom={useUpdateBoilerRoom}
          history={false}
        />
      ) : null}
      <StateToast
        initialized={initialized.current}
        gatewayName={gatewayName}
        boilerRoomNames={boilerRoomNames}
        errors={data?.errors}
      />
    </>
  );
};

export { GatewayProvider };
export type { GatewayProviderProps };
