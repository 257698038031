import { Controller, FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Link, useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";

import type { PatchHouse } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import type { Option } from "@eisox/design-system";
import { ActionButton, Alert, RoundIcon, SelectV2 as Select } from "@eisox/design-system";
import { isFrostFree } from "@eisox/houses";
import { ArrowRightIcon, QuestionMarkIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Tooltip } from "~/UI/components";
import type { houseLoader } from "~/UI/screens";
import { usePermissionsContext } from "~/UI/screens";
import {
  DEFAULT_MID_SEASON_DELAY,
  DEFAULT_MID_SEASON_HEATING_TEMPERATURE,
  DEFAULT_MID_SEASON_NO_HEATING_TEMPERATURE,
} from "~/constants";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToFrostFreeSettings, routeToHouse } from "~/routes/utils";
import { API } from "~/types/API";

import { SummerWinter, SummerWinterWithHalfSeason } from "./components";
import { getBody, getInitialState, isSummerWinterMode, isSummerWinterWithHalfSeasonAvailable } from "./helpers";
import type { SeasonType } from "./schemas";
import { Mode } from "./schemas";

import styles from "./Season.module.scss";

const schema = z
  .object({
    mode: z.enum([Mode.SUMMER_WINTER, Mode.SUMMER_WINTER_WITH_HALF_SEASON, Mode.WINTER, Mode.SUMMER]),
    summerPeriods: z
      .object({
        begin: z.string(),
        end: z.string(),
      })
      .optional(),
    winterPeriods: z
      .object({
        begin: z.string(),
        end: z.string(),
      })
      .optional(),
    midSeason: z
      .object({
        heatingTemperature: z.number().min(0).max(30),
        noHeatingTemperature: z.number().min(0).max(30),
        delay: z.number().min(0).max(24),
      })
      .optional(),
  })
  .refine(data => {
    if ([Mode.SUMMER_WINTER, Mode.SUMMER_WINTER_WITH_HALF_SEASON].includes(data.mode)) {
      if (
        !data.summerPeriods?.begin ||
        !data.summerPeriods.end ||
        !data.winterPeriods?.begin ||
        !data.winterPeriods.end
      ) {
        return false;
      }
    }
    return true;
  });

export const Season: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const seasonStyle = bem("season");
  const instructionsStyle = bem("instructions");

  const { permissions } = usePermissionsContext();

  const { house, gateways, modules } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { houseId } = useParams() as { houseId: string };

  const summerWinterWithHalfSeasonAvailable = isSummerWinterWithHalfSeasonAvailable(gateways, modules);

  const { state, submit } = useAction({
    onSuccess: () => {
      reset({}, { keepValues: true });
      toast(formatMessage({ id: "settings.content.menu.houses.season.success" }), { type: "success" });
    },
  });

  const methods = useForm<SeasonType>({
    values: getInitialState(house),
    resolver: zodResolver(schema),
  });

  const {
    control,
    formState: { isDirty },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = methods;

  const mode = watch("mode");
  const summerPeriods = watch("summerPeriods");
  const winterPeriods = watch("winterPeriods");

  const ENUM_MODE = {
    [Mode.SUMMER]: (
      <p className={seasonStyle("text")}>{formatMessage({ id: "settings.content.menu.houses.season.summer.text" })}</p>
    ),
    [Mode.WINTER]: (
      <p className={seasonStyle("text")}>{formatMessage({ id: "settings.content.menu.houses.season.winter.text" })}</p>
    ),
    [Mode.SUMMER_WINTER]: <SummerWinter disabled={!permissions.house?.summerModePeriod?.update} />,
    [Mode.SUMMER_WINTER_WITH_HALF_SEASON]: (
      <SummerWinterWithHalfSeason disabled={!permissions.house?.summerModePeriod?.update} />
    ),
  };

  let options: Option[] = Object.keys(ENUM_MODE).map(m => ({
    value: m,
    name: formatMessage({ id: `settings.content.menu.houses.season.${m}.option` }),
  }));
  if (!summerWinterWithHalfSeasonAvailable) {
    options = options.filter(o => (o.value as Mode) !== Mode.SUMMER_WINTER_WITH_HALF_SEASON);
  }

  const handleChangeMode = (value: string | string[], onChange: (...event: unknown[]) => void) => {
    if ((value as string) in ENUM_MODE) {
      onChange(value);
      if (
        value === Mode.SUMMER_WINTER &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        summerPeriods?.begin &&
        summerPeriods.end &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        winterPeriods?.begin &&
        winterPeriods.end &&
        !isSummerWinterMode(summerPeriods, winterPeriods)
      ) {
        setValue("winterPeriods", {
          begin: dayjs(summerPeriods.end, "DD/MM").add(1, "day").format("DD/MM"),
          end: dayjs(summerPeriods.begin, "DD/MM").subtract(1, "day").format("DD/MM"),
        });
      }
      if (value === Mode.SUMMER_WINTER_WITH_HALF_SEASON) {
        setValue("midSeason", {
          heatingTemperature: DEFAULT_MID_SEASON_HEATING_TEMPERATURE,
          noHeatingTemperature: DEFAULT_MID_SEASON_NO_HEATING_TEMPERATURE,
          delay: DEFAULT_MID_SEASON_DELAY,
        });
      }
    }
  };

  const onSubmit = (data: SeasonType) => {
    const body: PatchHouse = getBody(data);
    submit(body, API.HTTP_METHOD.PATCH, routeToHouse(houseId));
  };

  return (
    <FormProvider {...methods}>
      <form className={seasonStyle()} onSubmit={handleSubmit(onSubmit)}>
        {isFrostFree({ ...house }).isFrostFree && (
          <Alert>
            <p>
              {formatMessage(
                { id: "settings.content.menu.houses.season.alert" },
                {
                  a: (
                    <Link to={routeToFrostFreeSettings(houseId)} className={seasonStyle("link")}>
                      {formatMessage({ id: "settings.content.menu.houses.season.holidays" })}
                    </Link>
                  ),
                },
              )}
            </p>
          </Alert>
        )}
        <Controller
          control={control}
          name="mode"
          render={({ field: { value, onChange } }) => (
            <Select
              classNames={{ trigger: seasonStyle("select") }}
              value={value}
              options={options}
              onChange={value => handleChangeMode(value, onChange)}
              disabled={!permissions.house?.summerModePeriod?.update}
            />
          )}
        />
        {[Mode.SUMMER_WINTER, Mode.SUMMER_WINTER_WITH_HALF_SEASON].includes(mode) && (
          <div className={instructionsStyle()}>
            <div className={instructionsStyle("instruction")}>
              <h3 className={instructionsStyle("title")}>
                {formatMessage({ id: "settings.content.menu.houses.season.instruction.summerMode.title" })}
              </h3>
              <p className={instructionsStyle("text")}>
                {formatMessage({ id: "settings.content.menu.houses.season.instruction.summerMode.text" })}
              </p>
            </div>
            <div className={instructionsStyle("instruction")}>
              <h3 className={instructionsStyle("title")}>
                {formatMessage({ id: "settings.content.menu.houses.season.instruction.winterMode.title" })}
              </h3>
              <p className={instructionsStyle("text")}>
                {formatMessage({ id: "settings.content.menu.houses.season.instruction.winterMode.text" })}
              </p>
            </div>
            {mode === Mode.SUMMER_WINTER_WITH_HALF_SEASON && (
              <div className={instructionsStyle("instruction")}>
                <h3 className={instructionsStyle("title")}>
                  {formatMessage({ id: "settings.content.menu.houses.season.instruction.halfSeason.title" })}
                </h3>
                <p className={instructionsStyle("text")}>
                  {formatMessage({ id: "settings.content.menu.houses.season.instruction.halfSeason.text1" })}
                  <Tooltip
                    content={formatMessage({
                      id: "settings.content.menu.houses.season.instruction.halfSeason.tooltip",
                    })}
                  >
                    <span>
                      <RoundIcon backgroundColor="gray" iconColor="darkGray" size={20}>
                        <QuestionMarkIcon style={{ width: 10, height: 10 }} />
                      </RoundIcon>
                    </span>
                  </Tooltip>
                </p>
                <p className={instructionsStyle("text")}>
                  {formatMessage({ id: "settings.content.menu.houses.season.instruction.halfSeason.text2" })}
                </p>
              </div>
            )}
          </div>
        )}
        {ENUM_MODE[mode]}
        <ActionButton
          className={seasonStyle("submit-button")}
          type="submit"
          text={formatMessage({ id: "settings.content.menu.houses.season.save" })}
          icon={<ArrowRightIcon />}
          disabled={!isDirty || ["submitting", "loading"].includes(state)}
        />
      </form>
    </FormProvider>
  );
};
