import { useMemo, useState } from "react";

import clsx from "clsx";
import { uniqueId } from "lodash";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import type * as Yup from "yup";

import { PostRoomRoomsInnerRoomTypeEnum } from "@eisox/backend_webapp_api";
import { ActionButton, Modal } from "@eisox/design-system";
import { PencilIcon, RoomIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { RoomFC } from "~/UI/components";
import type { ItemType } from "~/UI/layouts";
import { Origin } from "~/UI/layouts";
import type { houseLoader } from "~/UI/screens";
import { DEFAULT_TEMPERATURES } from "~/constants";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToRoomsAction } from "~/routes/utils";
import { API } from "~/types/API";

import { ConfirmCancelPopup, Plan } from "../../Preinstall/components";
import { reOrderItemsUid } from "../../Preinstall/utils/uid";
import { RoomForm } from "./RoomsForm/RoomForm";
import { checkGetName, prefixId } from "./utils";
import { schemaErrors } from "./utils/schema";

import styles from "./Rooms.module.scss";

const schema = schemaErrors([], 0, {});
export type RoomFormDto = Yup.InferType<typeof schema>;
export type RoomsDto = Record<string, RoomFormDto[]>;

export const Rooms: React.FC<{ open: boolean; onClose: VoidFunction; className?: string }> = ({
  open,
  onClose,
  className,
}) => {
  const { formatMessage } = useIntl();
  const { houseId, planId } = useParams() as { houseId: string; planId: string };
  const { plans, rooms } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const { Form, submit } = useAction({
    onSuccess: () => onClose && onClose(),
  });

  /* GLOBAL FORM */
  const {
    formState: { isDirty, errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<RoomsDto>({
    values: {},
  });

  const globalRooms = watch(); // {[key: string]: RoomFormDto[]}
  const fields = Object.values(globalRooms).flat(); // RoomFormDto[]

  /* ROOMS FORM */
  const initialRoom = {
    id: uniqueId(prefixId),
    comfortTemperature: DEFAULT_TEMPERATURES.COMFORT,
    nightTemperature: DEFAULT_TEMPERATURES.NIGHT,
    isSwitchEnabled: false,
    plan: { planId },
    groupNames: [],
    roomType: PostRoomRoomsInnerRoomTypeEnum.Office,
    pattern: "(niveau)_(type)_(index)",
    uid: 1,
    name: "",
  };
  const formRoom = useForm<RoomFormDto>({
    defaultValues: {
      ...initialRoom,
      name: checkGetName(plans, initialRoom),
    },
    resolver: yupResolver(schemaErrors([...rooms.map(r => r.name!), ...fields.map(r => r.name)], 1, globalRooms)),
  });

  const currentRoom: RoomFormDto = formRoom.watch();
  const isCurrentRoomIsOnPlan = !!Object.values(fields).find(f => f.id === currentRoom.id);

  const bem = useBem(styles);
  const containerStyle = bem("container");
  const contentStyle = bem("content");

  const [confirmCancelPopup, setConfirmCancelPopup] = useState(false);

  const disabledRoomsItems: ItemType[] = useMemo(
    () =>
      rooms
        .filter(r => r.plan?.x && r.plan.y && r.plan.planId === currentRoom.plan.planId)
        .map(r => ({
          id: r.id!,
          x: r.plan?.x!,
          y: r.plan?.y!,
          disabled: true,
          origin: Origin.BOTTOM_CENTER,
          renderItem: () => <RoomFC name={r.name || "-"} disabled />,
        })),
    [currentRoom.plan.planId],
  );

  const selectablesRoomsItems: ItemType[] = fields
    .filter(r => r.plan.planId === currentRoom.plan.planId && r.plan.x && r.plan.y)
    .map(r => {
      const disabled = isCurrentRoomIsOnPlan && formRoom.formState.isDirty && r.id !== currentRoom.id;
      return {
        id: r.id,
        x: r.plan.x,
        y: r.plan.y,
        draggable: formRoom.formState.isDirty ? r.id === currentRoom.id : true,
        disabled,
        origin: Origin.BOTTOM_CENTER,
        renderItem: () => (
          <RoomFC
            name={r.name}
            selected={r.id === currentRoom.id}
            disabled={disabled}
            onClick={() => r.id !== currentRoom.id && formRoom.reset(r)}
          />
        ),
      };
    });

  const onSubmit = handleSubmit(data => {
    const obj = {
      rooms: Object.values(data)
        .flat()
        .map(({ id, pattern, uid, ...r }) => ({
          ...r,
          nightTemperature: r.nightTemperature! * 100,
          comfortTemperature: r.comfortTemperature * 100,
          //TODO : delete profileId when backend will be ready
          profileId: "6DsqgmS4YDjvLdGy8",
        })),
    };
    submit(obj, API.HTTP_METHOD.POST, routeToRoomsAction(houseId));
  });

  const append = (data: RoomFormDto) => {
    const key = data.pattern ? checkGetName(plans, data, false) : "default";
    const value = globalRooms[key] ? [...globalRooms[key], data] : [data];
    setValue(key, value, { shouldDirty: true });
  };

  const setToNextRoom = (room: RoomFormDto) => {
    const uid = room.uid && room.uid + 1;
    formRoom.reset({
      ...room,
      id: uniqueId(prefixId),
      plan: { planId: room.plan.planId },
      uid,
      name: checkGetName(plans, { ...room, uid }),
    });
  };

  const handleAddRoom = (data: RoomFormDto) => {
    append(data);
    setToNextRoom(data);
  };

  const handleUpdateRoom = (data: RoomFormDto) => {
    let array: RoomFormDto[] = globalRooms[data.pattern ? checkGetName(plans, data, false) : "default"] ?? [];
    const index = array.findIndex(f => f.id === data.id);
    const isChangePattern =
      !!(formRoom.formState.dirtyFields.pattern && data.pattern) ||
      !!formRoom.formState.dirtyFields.plan?.planId ||
      !!formRoom.formState.dirtyFields.roomType;
    const oldRoom = fields.find(f => f.id === data.id);
    let startUid = oldRoom?.uid;
    const targetUid = data.uid;

    if (index !== -1 && !isChangePattern) {
      array[index] = { ...data, uid: array[index].uid };
    } else if (array.length > 0 && formRoom.formState.dirtyFields.uid && isChangePattern) {
      startUid = array[array.length - 1].uid ? array[array.length - 1].uid! + 1 : undefined;
      array = [...array, { ...data, uid: startUid }];
    } else array = [data];

    if (isChangePattern) oldRoom && deleteRoom(oldRoom);

    if (formRoom.formState.dirtyFields.uid && targetUid && startUid && array.length > 0) {
      const orderUid = reOrderItemsUid(array, startUid, targetUid);
      orderUid && (array = orderUid.map(f => ({ ...f, name: checkGetName(plans, f) })));
    }
    setValue(data.pattern ? checkGetName(plans, data, false) : "default", array, { shouldDirty: true });
    setToNextRoom(array[array.length - 1]);
  };

  const deleteRoom = (data: RoomFormDto) => {
    const array = globalRooms[data.pattern ? checkGetName(plans, data, false) : "default"];
    const index = array.findIndex(f => f.id === data.id);
    if (index === -1) return array;
    const newArray = [...array.slice(0, index), ...array.slice(index + 1)];
    const updatedArray = newArray.map(f => {
      if (data.pattern && f.uid && f.uid > array[index].uid!) {
        const newUid = f.uid - 1;
        return { ...f, name: checkGetName(plans, { ...f, uid: newUid }), uid: newUid };
      }
      return f;
    });
    setValue(data.pattern ? checkGetName(plans, data, false) : "default", updatedArray, { shouldDirty: true });
    return updatedArray;
  };

  const handleDeleteRoom = (data: RoomFormDto) => {
    const array = deleteRoom(data);
    setToNextRoom(array[array.length - 1]);
  };

  const handleClickOnPlan = (x: number, y: number) => {
    if (isCurrentRoomIsOnPlan) {
      if (formRoom.formState.isDirty)
        toast.warn(formatMessage({ id: "plan.actions.add.room.warn.changeButHaveModifications" }));
      else setToNextRoom(fields[fields.length - 1]);
    } else {
      formRoom.setValue("plan", { ...currentRoom.plan, x, y }, { shouldDirty: true });
      formRoom.handleSubmit(handleAddRoom)();
    }
  };

  const handleOnChange = (items: ItemType[]) => {
    items.forEach(i => {
      const f = fields.find(f => f.id === i.id);
      if (f && (f.plan.x !== i.x || f.plan.y !== i.y)) {
        const array = globalRooms[f.pattern ? checkGetName(plans, f, false) : "default"];
        const index = array.findIndex(r => r.id === f.id);
        if (index !== -1) {
          array[index] = { ...array[index], plan: { ...array[index].plan, x: i.x, y: i.y } };
          setValue(f.pattern ? checkGetName(plans, f, false) : "default", array, { shouldDirty: true });
        }
      }
    });
  };

  return (
    <Modal.Root open={open} onOpenChange={open => !open && onClose()}>
      <Modal.Content className={clsx(containerStyle(), className)}>
        <Form onSubmit={onSubmit}>
          <Modal.Header
            title={formatMessage({ id: "plan.actions.add.room.title" })}
            subtitle={formatMessage({ id: "plan.actions.add.room.subtitle" })}
            icon={<RoomIcon />}
          >
            <ActionButton
              variant="cancel"
              text={formatMessage({ id: "plan.actions.add.cancel" })}
              onClick={e => {
                e.preventDefault();
                if (isDirty) setConfirmCancelPopup(true);
                else onClose && onClose();
              }}
            />
            <ActionButton
              rounded
              text={formatMessage({ id: "plan.actions.add.save" })}
              icon={<PencilIcon />}
              disabled={!isDirty || Object.keys(errors).length > 0}
              type="submit"
            />
          </Modal.Header>
        </Form>
        <div className={contentStyle()}>
          <RoomForm
            isCurrentRoomIsOnPlan={isCurrentRoomIsOnPlan}
            className={contentStyle("room-form")}
            form={formRoom}
            handleUpdateRoom={handleUpdateRoom}
            handleSetToNextRoom={() => setToNextRoom(fields[fields.length - 1])}
            handleDeleteRoom={handleDeleteRoom}
            fields={globalRooms}
          />
          <div className={contentStyle("plan")}>
            <Plan
              items={[...disabledRoomsItems, ...selectablesRoomsItems]}
              planId={currentRoom.plan.planId || plans[0]?.id!}
              onClickPlan={handleClickOnPlan}
              onChangeOnPlan={handleOnChange}
            />
          </div>
        </div>

        <ConfirmCancelPopup
          open={confirmCancelPopup}
          onClose={() => setConfirmCancelPopup(false)}
          onValidate={onClose}
        />
      </Modal.Content>
    </Modal.Root>
  );
};
