import { useEffect, useState } from "react";

import clsx from "clsx";
import { isEqual } from "lodash";
import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import { Button } from "@eisox/design-system";
import { ArrowRightIcon, CrossIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import type { houseLoader } from "~/UI/screens";
import { idLoaderHouse } from "~/routes/utils";

import { RoomsSelectorDialog } from "..";
import { ParametersSelect } from "../../pages/Function/layouts/ParametersSelect";
import { filterRooms } from "../../utils";
import type { SelectorsType } from "../RoomsSelects";
import { RoomsSelects } from "../RoomsSelects";

import styles from "./RoomsSelectors.module.scss";

export interface ParametersType {
  unusualAbsenceDetection: boolean | null;
  precomfortRangeGeneration: boolean | null;
  buttonSettings: boolean | null;
  frostFreeMode: boolean | null;
  comfortTemperature?: number;
  nightTemperature?: number;
}

interface RoomsSelectorsProps {
  multiple?: boolean;
  initialState?: SelectorsType & { parameters: ParametersType };
  functionPage?: boolean;
  onChange: (rooms: string[]) => void;
  className?: string;
}

const initialFilterState: SelectorsType & { parameters: ParametersType } = {
  types: [],
  plans: [],
  groups: [],
  parameters: {
    unusualAbsenceDetection: null,
    precomfortRangeGeneration: null,
    buttonSettings: null,
    frostFreeMode: null,
  },
};

export const RoomsSelectors: React.FC<RoomsSelectorsProps> = ({
  multiple,
  initialState,
  functionPage = false,
  onChange,
  className,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const roomsSelectorsStyle = bem("rooms-selectors");
  const selectorsStyle = bem("selectors");
  const selectionStyle = bem("selection");

  const { rooms } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const [roomsSelectorDialogOpen, setRoomsSelectorDialogOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<SelectorsType & { parameters: ParametersType }>(
    initialState ?? initialFilterState,
  );
  const [personnalizedRoomIds, setPersonnalizedRoomIds] = useState<string[]>([]);

  const filteredRooms = filterRooms(rooms, filters);
  const filteredRoomsIds = filteredRooms.filter(r => r.id).map(r => r.id!);
  const personalizedRooms = rooms.filter(r => personnalizedRoomIds.includes(r.id!));
  const personnalized = personalizedRooms.length > 0;

  useEffect(() => {
    onChange(personnalized ? personnalizedRoomIds : filteredRoomsIds);
  }, [filters, personnalizedRoomIds]);

  return (
    <div className={clsx(roomsSelectorsStyle(), className)}>
      <h3 className={roomsSelectorsStyle("title")}>
        {formatMessage({ id: "settings.content.menu.rooms.selectors.title" })}
      </h3>
      <div className={selectorsStyle()}>
        <RoomsSelects
          filters={filters}
          onChange={(filters: SelectorsType) =>
            setFilters(prevState => ({
              ...prevState,
              types: filters.types,
              plans: filters.plans,
              groups: filters.groups,
            }))
          }
          disabled={personnalized}
        />
        {functionPage && (
          <ParametersSelect
            filters={filters.parameters}
            onChange={(parameters: ParametersType) =>
              setFilters(prevState => ({ ...prevState, parameters: parameters }))
            }
            disabled={personnalized}
          />
        )}
        <Button
          text={formatMessage({ id: "settings.content.menu.rooms.selectors.personalize" })}
          icon={<ArrowRightIcon />}
          onClick={() => setRoomsSelectorDialogOpen(true)}
        />
      </div>
      <div className={selectionStyle()}>
        <Tooltip
          content={
            filteredRooms.length > 0 || personnalized ? (
              <div className={roomsSelectorsStyle("tooltip")}>
                {personnalized
                  ? personalizedRooms.map((pr, index) => <p key={index}>{pr.name}</p>)
                  : filteredRooms.map((r, index) => <p key={index}>{r.name}</p>)}
              </div>
            ) : undefined
          }
        >
          <p className={selectionStyle("text")}>
            {formatMessage(
              {
                id: `settings.content.menu.rooms.selectors.${
                  personnalized ? "personalized" : filteredRooms.length === rooms.length ? "allSelected" : "selected"
                }`,
              },
              {
                n: personnalized ? personalizedRooms.length : filteredRooms.length,
              },
            )}
          </p>
        </Tooltip>
        {(personnalized || !isEqual(filters, initialFilterState)) && (
          <CrossIcon
            className={selectionStyle("delete-personnalized")}
            onClick={() => (personnalized ? setPersonnalizedRoomIds([]) : setFilters(initialFilterState))}
          />
        )}
      </div>
      {roomsSelectorDialogOpen && (
        <RoomsSelectorDialog
          multiple={multiple}
          open={roomsSelectorDialogOpen}
          value={personnalized ? personnalizedRoomIds : filteredRoomsIds}
          onChange={(rooms: string[]) => setPersonnalizedRoomIds(rooms)}
          onClose={() => setRoomsSelectorDialogOpen(false)}
        />
      )}
    </div>
  );
};
