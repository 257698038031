import React, { useEffect, useState } from "react";

import { cx } from "class-variance-authority";
import { useIntl } from "react-intl";
import { useNavigate, useParams, useRouteLoaderData } from "react-router";
import { Await, useLoaderData } from "react-router-dom";

import type { HousesHouseIdPlanningsStatGet200Response } from "@eisox/backend_webapp_api";
import { Button, Loader } from "@eisox/design-system";
import {
  GatewayIcon,
  NutIcon,
  PlanIcon,
  RoomIcon,
  ThermometerIcon,
  TickIcon,
  ValveIcon,
  WarningIcon,
} from "@eisox/icons";
import { ROOM_ERRORS_ENUM } from "@eisox/problems-handler";

import type { houseLoader } from "~/UI/screens";
import { getPlan } from "~/api/plan";
import { OverviewCard, Plan, PresenceCard, TablePlanningsStat, TemperaturesCard } from "~/features/dashboard";
import type { FetchResponse } from "~/helpers/communication/fetchType";
import { idLoaderHouse, routeToMaintenance, routeToPlans, routeToSettings } from "~/routes/utils";
import { calculateAverageComfort } from "~/utils/Room";
import { isPresenceRecentlyDetected } from "~/utils/houseUtils";

import { SUCCESS_FETCH } from "../../../constants";
import { WHITE_PLAN_EISOX } from "../../../constants";
import { Header } from "../../layouts";
import { usePermissionsContext } from "../House";
import type { loader } from "./loader";

import styles from "./Dashboard.module.scss";

export const Dashboard: React.FC = () => {
  const { planningsStatsPromise, externalTemperatures } = useLoaderData() as LoaderData<typeof loader>;
  const { gateways, valves, rooms, plans, house, date } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    typeof houseLoader
  >;
  const { permissions } = usePermissionsContext();
  const navigate = useNavigate();
  const { houseId } = useParams() as { houseId: string };
  const { formatMessage } = useIntl();

  const presence = isPresenceRecentlyDetected(house, date);
  const comfortAverage = calculateAverageComfort(rooms);

  const valvesProblemsCount = valves.reduce((acc, v) => acc + (v.errors.length > 0 ? 1 : 0), 0);
  const gatewaysProblemsCount = gateways.reduce((acc, g) => acc + (g.errors.length > 0 ? 1 : 0), 0);
  const roomsProblemsCount = rooms.reduce(
    (acc, r) => acc + (r.errors.filter(r => !r.includes(ROOM_ERRORS_ENUM.VALVE_ERROR)).length > 0 ? 1 : 0),
    0,
  );

  const errorsCount = valvesProblemsCount + gatewaysProblemsCount + roomsProblemsCount;

  const planId = plans.find(plan => plan.id && plan.id.length > 0)?.id;

  const [urlPath, setUrlPath] = useState<string>(WHITE_PLAN_EISOX);

  useEffect(() => {
    getPlan(planId, houseId).then(urlPath => {
      if (urlPath.type === SUCCESS_FETCH) {
        setUrlPath(urlPath.result.message);
      }
    });
  }, [houseId, planId]);

  const noRooms = rooms.length === 0;

  return (
    <div className={styles.dashboard}>
      <Header title={house.houseName || "-"}>
        {permissions.page?.settings && (
          <Button
            className={styles.dashboard__header}
            text={formatMessage({ id: "button.settings" })}
            icon={<NutIcon style={{ width: 20, height: 20 }} />}
            onClick={() => navigate(routeToSettings(houseId))}
          />
        )}
      </Header>

      <main className={cx(styles.content)}>
        <div className={styles.content__house}>
          <OverviewCard
            icon={<PlanIcon />}
            title={plans.length.toString()}
            subtitle={formatMessage({ id: "level" }, { n: plans.length })}
            variants="info"
          />
          <OverviewCard
            icon={<RoomIcon />}
            title={rooms.length.toString()}
            subtitle={formatMessage({ id: "rooms.title" }, { n: rooms.length })}
            variants="info"
          />
          <OverviewCard
            icon={<GatewayIcon />}
            title={gateways.length.toString()}
            subtitle={formatMessage({ id: "gateways.title" }, { n: gateways.length })}
            variants="info"
          />
          <OverviewCard
            icon={<ValveIcon />}
            title={valves.length.toString()}
            subtitle={formatMessage({ id: "valves.title" }, { n: valves.length })}
            variants="info"
          />
        </div>
        <div className={cx(styles.content__cards, styles.content__cards_column)}>
          {permissions.page?.maintenance && (
            <OverviewCard
              className={styles.content__maintenance}
              icon={errorsCount > 0 ? <WarningIcon /> : <TickIcon />}
              iconColor={errorsCount > 0 ? "red" : "green"}
              title={formatMessage({ id: "dash.maintenance" }, { e: errorsCount })}
              subtitle={formatMessage({ id: "dash.seeMaintenance" })}
              variants={errorsCount > 0 ? "danger" : "success"}
              size={"large"}
              onClick={() => navigate(routeToMaintenance(houseId))}
            />
          )}
          <TemperaturesCard className={styles.content__temperatures} temperatures={externalTemperatures} />
        </div>
        {!noRooms && (
          <div className={cx(styles.content__planningStats)}>
            <React.Suspense fallback={<Loader className={styles.content__loader} />}>
              <Await resolve={planningsStatsPromise}>
                {(planningsStat: FetchResponse<HousesHouseIdPlanningsStatGet200Response>) => (
                  <TablePlanningsStat data={planningsStat.result.message!} />
                )}
              </Await>
            </React.Suspense>
          </div>
        )}
        <Plan
          className={cx(styles.content__plan, noRooms && styles.content__plan_noRooms)}
          url={urlPath}
          onClick={() => navigate(routeToPlans(houseId))}
        />
        {!noRooms && (
          <div className={styles.content__cards}>
            {permissions.house?.lastPresenceDetected?.read && (
              <PresenceCard className={styles.content__presence} presence={presence} />
            )}
            <OverviewCard
              className={styles.content__comfort}
              icon={<ThermometerIcon />}
              iconColor="white"
              iconBackgroundColor="orange"
              title={comfortAverage.toString()}
              subtitle={formatMessage({ id: "dash.averageComfort" })}
              hasDegre
              size="large"
            />
          </div>
        )}
      </main>
    </div>
  );
};
