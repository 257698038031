import { useState } from "react";

import { useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useBlocker, useParams, useRouteLoaderData } from "react-router-dom";
import * as yup from "yup";

import type { PatchRooms } from "@eisox/backend_webapp_api";
import { ActionButton, TextInput } from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { ModificationPopup } from "~/UI/layouts";
import type { houseLoader } from "~/UI/screens";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToRoomsAction } from "~/routes/utils";
import { API } from "~/types/API";

import { RoomsSelect } from "../..";
import { Plan } from "../../layouts";
import { AddInput, Group } from "./components";
import { getBody } from "./utils";

import styles from "./Groups.module.scss";

export interface GroupDto {
  name: string;
  rooms: string[];
}

export const Groups: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const groupsStyle = bem("groups");
  const inputsStyle = bem("inputs");
  const listStyle = bem("list");
  const editStyle = bem("edit");

  const { houseId } = useParams() as { houseId: string };
  const { plans, rooms, groups } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const { submit, state } = useAction({});

  const { Form } = useAction({});

  const schema = yup.object({
    groups: yup
      .array(
        yup.object({
          name: yup.string().required(formatMessage({ id: "settings.content.menu.rooms.groups.nameRequired" })),
          rooms: yup
            .array()
            .of(yup.string().required())
            .min(1, formatMessage({ id: "settings.content.menu.rooms.groups.min" }))
            .required(),
        }),
      )
      .required()
      .test("uniqueNames", formatMessage({ id: "settings.content.menu.rooms.groups.uniqueNames" }), groups => {
        const namesSet = new Set();

        for (const group of groups) {
          if (namesSet.has(group.name)) {
            return false;
          }
          namesSet.add(group.name);
        }

        return true;
      }),
  });

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
  } = useForm<{ groups: GroupDto[] }>({
    resolver: yupResolver(schema),
    values: {
      groups: groups,
    },
  });
  const { fields, append, update, remove } = useFieldArray({ control, name: "groups" });

  const [newGroup, setNewGroup] = useState<string>("");
  const [currentGroupIndex, setCurrentGroupIndex] = useState<number | null>(fields.length > 0 ? 0 : null);
  const [roomHovered, setRoomHovered] = useState<string | undefined>();

  const blocker = useBlocker(isDirty);

  const handleChangeGroup = (index: number) => {
    setCurrentGroupIndex(index);
  };

  const handleAddGroup = (name: string) => {
    append({ name: name, rooms: [] });
    setCurrentGroupIndex(fields.length > 0 ? fields.length : 0);
  };

  const handleDeleteGroup = (index: number) => {
    remove(index);
    if (currentGroupIndex === index) setCurrentGroupIndex(null);
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentGroupIndex !== null && fields[currentGroupIndex]) {
      update(currentGroupIndex, { name: e.target.value, rooms: fields[currentGroupIndex].rooms });
    }
  };

  const handleChangeRooms = (value: string[]) => {
    if (currentGroupIndex !== null && fields[currentGroupIndex]) {
      update(currentGroupIndex, { name: fields[currentGroupIndex].name, rooms: value });
    }
  };

  const onSubmit = (data: { groups: GroupDto[] }) => {
    const body: PatchRooms[] = getBody(data.groups, groups);
    submit(body, API.HTTP_METHOD.PATCH, routeToRoomsAction(houseId));
  };

  return (
    <>
      <Form className={groupsStyle()} onSubmit={handleSubmit(onSubmit)}>
        <div className={inputsStyle()}>
          <div className={listStyle()}>
            <h2 className={listStyle("title")}>{formatMessage({ id: "settings.content.menu.rooms.groups.title" })}</h2>
            <div className={listStyle("groups")}>
              {fields
                .map(g => g.name)
                .map((name, index) => {
                  const groupErrors = errors && !!errors.groups ? errors.groups[index] : undefined;
                  return (
                    <Group
                      key={index}
                      name={name}
                      onClick={() => {
                        handleChangeGroup(index);
                        setNewGroup("");
                      }}
                      onDelete={() => handleDeleteGroup(index)}
                      selected={index === currentGroupIndex}
                      errors={groupErrors}
                    />
                  );
                })}
            </div>
            <AddInput
              groupNames={fields.map(f => f.name)}
              onAddGroup={name => handleAddGroup(name)}
              value={newGroup}
              handleChangeValue={value => setNewGroup(value)}
              handleFocus={() => setCurrentGroupIndex(null)}
            />
            {errors && errors.groups && <p className={listStyle("error")}>{errors.groups.message}</p>}
          </div>
          {currentGroupIndex !== null && fields[currentGroupIndex] && (
            <div className={editStyle()}>
              <TextInput
                label={formatMessage({ id: "settings.content.menu.rooms.groups.name.label" })}
                placeholder={formatMessage({ id: "settings.content.menu.rooms.groups.name.placeholder" })}
                value={fields[currentGroupIndex].name}
                onChange={handleChangeName}
              />
              <RoomsSelect
                multiple
                label={formatMessage({ id: "settings.content.menu.rooms.groups.select.label" })}
                value={fields[currentGroupIndex].rooms}
                rooms={rooms}
                handleRoomHovered={value => setRoomHovered(value)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "bottom", horizontal: "left" }}
                onChange={(value: string[]) => handleChangeRooms(value)}
              />
            </div>
          )}
        </div>
        <Plan
          selectedRooms={currentGroupIndex !== null && fields[currentGroupIndex] ? fields[currentGroupIndex].rooms : []}
          onChange={(value: string[]) => handleChangeRooms(value)}
          roomHovered={roomHovered}
          disabled={currentGroupIndex === null}
        />
        <ActionButton
          className={groupsStyle("save-button")}
          type="submit"
          text={formatMessage({ id: "settings.content.menu.rooms.groups.save" })}
          icon={<ArrowRightIcon />}
          disabled={!isDirty || ["submitting", "loading"].includes(state)}
        />
      </Form>
      {blocker.state === "blocked" && (
        <ModificationPopup
          onClickContinue={() => blocker.proceed()}
          open={blocker.state === "blocked"}
          onClose={() => blocker.reset()}
        />
      )}
    </>
  );
};
