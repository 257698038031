import { redirect } from "react-router-dom";
import type { LoaderFunctionArgs } from "react-router-dom";

import type {
  Gateways,
  GatewaysMessageGatewaysInner,
  GetRooms,
  HeatingNetwork1,
  HouseMessage,
  House as HouseResponse,
  HousesHouseIdBoilerroomsPosGet200Response,
  Meshs,
  Module,
  Plans,
  Room,
  Valves,
  ValvesMessageValvesInner,
} from "@eisox/backend_webapp_api";
import { GatewaysMessageGatewaysInnerBrModRfEnum } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import type { IGateway } from "@eisox/gateways";
import { BORDER_ROUTER_STATUS } from "@eisox/gateways";
import type {
  GatewayErrors,
  GatewayWarnings,
  RoomErrors,
  RoomTempProblems,
  RoomWarnings,
  ValveErrors,
  ValveWarnings,
} from "@eisox/problems-handler";
import { getGatewayProblem, getValveProblem } from "@eisox/problems-handler";
import { getRoomProblem } from "@eisox/problems-handler";
import type { IValve } from "@eisox/valves";
import type { RoomRoomType } from "@eisox/webapp-api-specification";

import { getBoilerRooms } from "~/api/boilerroom";
import { getGateways } from "~/api/gateway";
import { getHeatingNetworks } from "~/api/heatingNetworks";
import { getHouseWithId } from "~/api/house";
import { getMeshs } from "~/api/mesh";
import { getModules } from "~/api/modules";
import { getPlans } from "~/api/plan";
import { getRooms } from "~/api/room";
import { getValves } from "~/api/valve";
import type { UserRole } from "~/apiV2";
import { DEFAULT_TEMPERATURES } from "~/constants";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";
import type { FetchResponse } from "~/helpers/communication/fetchType";
import { routeToHouses } from "~/routes/utils";
import { useUserStore } from "~/stores";
import type { RoomHistoryData } from "~/utils";
import {
  getRoomHistoryData,
  getRoomsGroups,
  getValvesByRoomId,
  transformHumidity,
  transformLight,
  transformTemperature,
} from "~/utils";

import type { GroupDto } from "../Settings/pages/Rooms";

export type GatewaysWithProblem = Status<GatewayErrors, GatewayWarnings> &
  GatewaysMessageGatewaysInner & {
    borderRouterStatus: BORDER_ROUTER_STATUS[];
    plan?: { planId?: string; x?: number; y?: number; planName?: string };
    uid: number;
    id: string;
    gatewayName: string;
  };
export type ValvesWithProblem = Status<ValveErrors, ValveWarnings> &
  ValvesMessageValvesInner & {
    gatewayName?: string;
    gatewayMac?: string;
    roomName?: string;
    plan?: { planId?: string; x?: number; y?: number; planName?: string };
  };
export type RoomsWithProblem = Status<RoomErrors | RoomTempProblems, RoomWarnings | RoomTempProblems> &
  Omit<Room, "roomType" | "plan"> & { roomType: RoomRoomType } & RoomHistoryData & {
    isAutoPrecomf: boolean;
    isPreComfRoom: boolean;
    isFrostFree: boolean;
    isSwitchEnabled: boolean;
    nightTemperature: number;
    comfortTemperature: number;
  } & { plan?: { planId?: string; x?: number; y?: number; planName?: string } };

export type HouseType = HouseMessage & {
  frostFreeTemperature: number;
  absenceTemperature: number;
  preComfortTemperature: number;
};

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const houseId = params.houseId;

  // house
  const houseResponse = (await getHouseWithId(houseId)) as FetchResponse<HouseResponse>;

  if (houseResponse.type !== SUCCESS_FETCH) throw new Error();
  let house: HouseMessage = houseResponse.result.message!;
  if (house.suspendedAt && dayjs(house.suspendedAt).isBefore()) {
    const roles: UserRole = useUserStore.getState().roles;
    if (!roles.installer?.includes(house._id!)) return redirect(`${routeToHouses()}?suspended=${house._id}`);
  }

  house = {
    ...house,
    absenceTemperature: house.absenceTemperature
      ? transformTemperature(house.absenceTemperature)
      : DEFAULT_TEMPERATURES.ABSENCE,
    frostFreeTemperature: house.frostFreeTemperature
      ? transformTemperature(house.frostFreeTemperature)
      : DEFAULT_TEMPERATURES.FROSTFREE,
    preComfortTemperature: house.preComfortTemperature
      ? transformTemperature(house.preComfortTemperature)
      : DEFAULT_TEMPERATURES.PRECOMFORT,
    isRemoteAccess: house.isRemoteAccess ?? false,
    isFrostFree: house.isFrostFree ?? false,
  };

  const date = dayjs();

  const plansResponsePromise = getPlans(houseId) as Promise<FetchResponse<Plans>>;
  const roomsResponsePromise = getRooms(houseId) as Promise<FetchResponse<GetRooms>>;
  const gatewaysResponsePromise = getGateways(houseId) as Promise<FetchResponse<Gateways>>;
  const valvesResponsePromise = getValves(houseId) as Promise<FetchResponse<Valves>>;

  const meshsResponsePromise = getMeshs(houseId) as Promise<FetchResponse<Meshs>>;
  const boilerroomsResponsePromise = getBoilerRooms(houseId) as Promise<
    FetchResponse<HousesHouseIdBoilerroomsPosGet200Response>
  >;

  const [plansResponse, roomsResponse, gatewaysResponse, valvesResponse, meshsResponse, boilerroomsResponse] =
    await Promise.all([
      plansResponsePromise,
      roomsResponsePromise,
      gatewaysResponsePromise,
      valvesResponsePromise,
      meshsResponsePromise,
      boilerroomsResponsePromise,
    ]);

  // plans
  const plans = plansResponse.type === SUCCESS_FETCH ? plansResponse.result.message! : [];

  // rooms
  const rooms =
    roomsResponse.type === SUCCESS_FETCH
      ? (roomsResponse.result.message?.rooms?.map(r => {
          const plan = plans.find(p => p.id === r.plan?.planId);
          return {
            ...r,
            plan: { ...r.plan, planName: plan?.name },
          };
        }) ?? [])
      : [];

  // gateways
  const gateways: GatewaysWithProblem[] =
    gatewaysResponse.type === SUCCESS_FETCH
      ? gatewaysResponse.result.message?.gateways?.map(g => {
          const plan = plans.find(p => p.id === g.plan?.planId);
          const { errors, warnings, problemStatus } = getGatewayProblem(g as IGateway, date);
          return {
            ...g,
            uid: g.uid!,
            id: g.id!,
            gatewayName: g.gatewayName!,
            isInternetGateway: !!g.isInternetGateway,
            brModRf: g.brModRf || GatewaysMessageGatewaysInnerBrModRfEnum.OqpskRc200,
            borderRouterStatus: (g.borderRouterStatus as BORDER_ROUTER_STATUS[]) ?? [BORDER_ROUTER_STATUS.OK],
            problemStatus,
            errors,
            warnings,
            plan: { ...g.plan, planName: plan?.name },
          };
        }) || []
      : [];

  // vavles
  const valves: ValvesWithProblem[] =
    valvesResponse.type === SUCCESS_FETCH
      ? valvesResponse.result.message?.valves?.map(v => {
          const plan = plans.find(p => p.id === v.plan?.planId);
          const room = rooms.find(r => r.id === v.roomId);
          const gateway = gateways.find(g => g.id === v.gatewayId);
          const { errors, warnings, problemStatus } = getValveProblem(v as IValve, (gateway || {}) as IGateway, date);
          return {
            ...v,
            plan: { ...v.plan, planName: plan?.name },
            roomName: room?.name,
            gatewayName: gateway?.gatewayName,
            gatewayMac: gateway?.mac,
            correction: v.correction ? transformTemperature(v.correction) : undefined,
            correctedTemp: v.correctedTemp ? transformTemperature(v.correctedTemp) : undefined,
            sensors: {
              ...(v.sensors ?? {}),
              temperature: v.sensors?.temperature ? transformTemperature(v.sensors.temperature) : undefined,
              humidity: v.sensors?.humidity ? transformHumidity(v.sensors.humidity) : undefined,
              light: v.sensors?.light ? transformLight(v.sensors.light) : undefined,
            },
            stateData: {
              ...(v.stateData ?? {}),
              swOffset: v.stateData?.swOffset ? transformTemperature(v.stateData.swOffset) : undefined,
            },
            errors,
            warnings,
            problemStatus,
          };
        }) || []
      : [];
  /*
   * This is done like this because the rooms need to be defined to retrieve valve errors
   * but the valves with errors need to be defined to retrieve room errors
   */
  const roomsWithErrors: RoomsWithProblem[] = rooms.map(r => {
    const valvesOfTheRoom = getValvesByRoomId(r.id!, valves);
    const roomData = getRoomHistoryData(r, valvesOfTheRoom);
    const room = { ...r, ...roomData };

    //@ts-expect-error - Doc is wrong, id is required
    const { problemStatus, errors, warnings } = getRoomProblem(room, house, valvesOfTheRoom, gateways, date);
    return {
      ...r,
      ...roomData,
      roomType: r.roomType as RoomRoomType,
      isAutoPrecomf: r.isAutoPrecomf === undefined || r.isAutoPrecomf,
      isPreComfRoom: !!r.isPreComfRoom,
      isFrostFree: r.isFrostFree ?? false,
      isSwitchEnabled: !!r.isSwitchEnabled,
      nightTemperature: r.nightTemperature ? transformTemperature(r.nightTemperature) : DEFAULT_TEMPERATURES.NIGHT,
      comfortTemperature: r.comfortTemperature
        ? transformTemperature(r.comfortTemperature)
        : DEFAULT_TEMPERATURES.COMFORT,
      problemStatus,
      errors,
      warnings,
    };
  });

  // groups
  const groups: GroupDto[] = getRoomsGroups(rooms);

  // meshs
  const meshs = meshsResponse.type === SUCCESS_FETCH ? meshsResponse.result.message?.meshs! : [];

  // boilerroomPos
  const boilerroomPos = boilerroomsResponse.type === SUCCESS_FETCH ? boilerroomsResponse.result.message! : [];

  // modules
  const modules: Module[] = [];
  let heatingNetworks: HeatingNetwork1[] = [];
  if (boilerroomPos.length > 0) {
    const modulesResponse = (await getModules(houseId)) as any;
    modulesResponse.type === SUCCESS_FETCH && modules.push(...modulesResponse.result.message);

    const heatingNetworksResponse = await getHeatingNetworks(houseId);
    heatingNetworks =
      heatingNetworksResponse.type === SUCCESS_FETCH
        ? (heatingNetworksResponse.result?.message?.heatingNetworks ?? [])
        : [];
  }

  return {
    house: house as HouseType,
    plans,
    rooms: roomsWithErrors,
    groups,
    gateways,
    valves,
    boilerroomPos,
    meshs,
    modules,
    heatingNetworks,
    date,
  };
};
