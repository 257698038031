import { Button } from "../Button";

import styles from "./Error404.module.scss";

export interface Error404Props {
  text: string;
  buttonText: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const Error404: React.FC<Error404Props> = ({ text, buttonText, onClick }) => {
  return (
    <div className={styles.error404}>
      <h2>404</h2>
      <p>{text}</p>
      <Button text={buttonText} onClick={onClick} />
    </div>
  );
};
