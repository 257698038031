import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import type { HousesHouseIdBoilerroomsPosGet200ResponseMessageInner, Module } from "@eisox/backend_webapp_api";
import { ButtonV2 as Button, DropdownMenu } from "@eisox/design-system";
import { BracesIcon, ChevronDownIcon, NetworkIcon, NutIcon } from "@eisox/icons";

import type { GatewaysWithProblem } from "~/UI/screens/House";
import { usePermissionsContext } from "~/UI/screens/House";
import { NetworksDialog } from "~/features/HeatingNetworkConfiguration";
import { routeToModulesSettings } from "~/routes/utils";

import { ResetVariablesDialog } from "../ResetVariablesDialog";
import { VariableDetailsDialog } from "../VariableDetailsDialog";

interface ActionsDropdownMenuProps {
  houseId: string;
  boilerroomPos: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner[];
  gateways: GatewaysWithProblem[];
  modules: Module[];
  disabled?: boolean;
}

const ActionsDropdownMenu: React.FC<ActionsDropdownMenuProps> = ({
  houseId,
  boilerroomPos,
  gateways,
  modules,
  disabled,
}) => {
  const { t } = useTranslation();

  const { permissions } = usePermissionsContext();

  const navigate = useNavigate();

  const handleSelectItem = (e: Event) => e.preventDefault();

  const canReadModule = permissions.module?.read;
  const canReadVariables = permissions.module?.boilerroom?.variableData?.read;
  const canResetVariables = permissions.module?.boilerroom?.variableData?.update;

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        <Button>
          {t("boilerRoom.header.actions.actions")} <ChevronDownIcon />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end" sideOffset={10}>
        {canReadVariables && (
          <VariableDetailsDialog>
            <DropdownMenu.Item onSelect={handleSelectItem}>
              <BracesIcon /> {t("boilerRoom.header.actions.variablesDetails.button")}
            </DropdownMenu.Item>
          </VariableDetailsDialog>
        )}
        <NetworksDialog>
          <DropdownMenu.Item onSelect={handleSelectItem}>
            <NetworkIcon /> {t("boilerRoom.header.actions.network.gestion")}
          </DropdownMenu.Item>
        </NetworksDialog>
        {canReadModule && (
          <DropdownMenu.Item onSelect={() => navigate(routeToModulesSettings(houseId))}>
            <NutIcon /> {t("boilerRoom.header.actions.modules.gestion")}
          </DropdownMenu.Item>
        )}
        {canResetVariables && (
          <ResetVariablesDialog houseId={houseId} boilerroomPos={boilerroomPos} gateways={gateways} modules={modules}>
            <DropdownMenu.Item onSelect={handleSelectItem}>
              <BracesIcon /> {t("boilerRoom.header.actions.resetVariables.button")}
            </DropdownMenu.Item>
          </ResetVariablesDialog>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

ActionsDropdownMenu.displayName = "ActionsDropdownMenu";

export { ActionsDropdownMenu };
