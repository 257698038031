import type { FieldError, Merge, UseFormRegisterReturn } from "react-hook-form";
import { useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";
import * as yup from "yup";

import { ActionButton, Button, Circle, Dialog, TextInput } from "@eisox/design-system";
import { BinIcon, CrossIcon, MeshIcon, PencilIcon, PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { Tooltip } from "~/UI/components";
import type { houseLoader } from "~/UI/screens";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToMeshs } from "~/routes/utils";
import { API } from "~/types/API";

import styles from "./WifiMesh.module.scss";

const schema = (formatMessage: FormatMessageFn) =>
  yup.object({
    meshs: yup
      .array(
        yup
          .object({
            meshId: yup.string(),
            name: yup.string().required(formatMessage({ id: "error.required" })),
            gatewayNumber: yup.number().required(),
            new: yup.boolean().required(),
            updated: yup.boolean().required(),
            deleted: yup.boolean().required(),
          })
          .test("unique", formatMessage({ id: "plans.manage.wifiMesh.unique" }), (value, context) => {
            const names: string[] = context.parent.map((item: { name: string }) => item.name);
            return names.indexOf(value.name) === names.lastIndexOf(value.name);
          }),
      )
      .required(),
  });

export type PutMeshType = yup.InferType<ReturnType<typeof schema>>;

interface WifiNetworkProps {
  deleted: boolean;
  gatewayNumber: number;
  register: UseFormRegisterReturn;
  setFocus: VoidFunction;
  onDelete: VoidFunction;
  error?: FieldError | Merge<FieldError, (FieldError | undefined)[]>;
}

const WifiNetwork: React.FC<WifiNetworkProps> = ({ deleted, gatewayNumber, register, setFocus, onDelete, error }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const wifiNetworkStyle = bem("wifi-network");

  return (
    <div className={wifiNetworkStyle()}>
      <Circle selected size={44} disabled={deleted}>
        <MeshIcon className={wifiNetworkStyle("mesh")} />
      </Circle>
      <input {...register} className={wifiNetworkStyle("name", { deleted })} disabled={deleted} />
      <span className={wifiNetworkStyle("gateway-number")}>
        {gatewayNumber < 9 ? `0${gatewayNumber}` : gatewayNumber}
      </span>
      <PencilIcon
        className={wifiNetworkStyle("button", { disabled: deleted })}
        onClick={() => !deleted && setFocus()}
      />
      {deleted ? (
        <CrossIcon className={wifiNetworkStyle("button")} onClick={() => deleted && onDelete()} />
      ) : (
        <Tooltip content={gatewayNumber > 0 && formatMessage({ id: "plans.manage.wifiMesh.gateways" })}>
          <BinIcon
            className={wifiNetworkStyle("button", { disabled: deleted || gatewayNumber > 0 })}
            onClick={() => !deleted && gatewayNumber === 0 && onDelete()}
          />
        </Tooltip>
      )}
      {deleted && <div className={wifiNetworkStyle("strikethrough")} />}
      {error && <p className={wifiNetworkStyle("error")}>{error.message}</p>}
    </div>
  );
};

const addFormSchema = (formatMessage: FormatMessageFn, names: string[]) =>
  yup.object({
    name: yup
      .string()
      .required(formatMessage({ id: "error.required" }))
      .test(
        "unique",
        formatMessage({ id: "plans.manage.wifiMesh.unique" }),
        value => [...names, value].length === new Set([...names, value]).size,
      ),
  });

type AddFormType = yup.InferType<ReturnType<typeof addFormSchema>>;

interface AddFormProps {
  names: string[];
  onAddMesh: (name: string) => void;
}

const AddForm: React.FC<AddFormProps> = ({ names, onAddMesh }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const addFormStyle = bem("add-form");

  const {
    register,
    formState: { isDirty, errors },
    handleSubmit,
    reset,
  } = useForm<AddFormType>({
    resolver: yupResolver(addFormSchema(formatMessage, names)),
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = (data: AddFormType) => {
    onAddMesh(data.name);
    reset({ name: "" });
  };

  return (
    <div className={addFormStyle()}>
      <TextInput
        {...register("name")}
        className={addFormStyle("input")}
        placeholder={formatMessage({ id: "plans.manage.wifiMesh.name" })}
        error={errors.name}
      />
      <Button
        text={formatMessage({ id: "plans.manage.wifiMesh.add" })}
        icon={<PlusIcon className={addFormStyle("plus")} />}
        disabled={!isDirty}
        onClick={() => handleSubmit(onSubmit)()}
      />
    </div>
  );
};

interface WifiMeshProps {
  open: boolean;
  onClose: VoidFunction;
}

export const WifiMesh: React.FC<WifiMeshProps> = ({ open, onClose }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const wifiMeshStyle = bem("wifi-mesh");

  const { houseId } = useParams() as { houseId: string };
  const { meshs, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const initialState: PutMeshType = {
    meshs: meshs.map(m => ({
      meshId: m.id,
      name: m.name!,
      gatewayNumber: gateways.filter(g => g.meshs?.some(gm => gm.id === m.id)).length,
      new: false,
      updated: false,
      deleted: false,
    })),
  };

  const { submit } = useAction({
    onSuccess: onClose,
  });

  const {
    register,
    setFocus,
    control,
    formState: { dirtyFields, isDirty, errors },
    handleSubmit,
  } = useForm<PutMeshType>({
    resolver: yupResolver(schema(formatMessage)),
    values: initialState,
  });
  const { fields, append, update, remove } = useFieldArray({ control: control, name: "meshs" });

  const onSubmit = (data: PutMeshType) => {
    const body: PutMeshType = {
      meshs: data.meshs.map((m, i) => ({ ...m, updated: !!dirtyFields.meshs?.at(i)?.name && !m.deleted && !m.new })),
    };
    submit(body, API.HTTP_METHOD.PUT, routeToMeshs(houseId));
  };

  return (
    <Dialog.Root open={open} onOpenChange={open => !open && onClose()}>
      <Dialog.Content
        className={wifiMeshStyle()}
        title={formatMessage({ id: "plans.manage.wifiMesh.title" })}
        icon={<MeshIcon className={wifiMeshStyle("icon")} />}
      >
        <form className={wifiMeshStyle("form")} onSubmit={handleSubmit(onSubmit)}>
          <div className={wifiMeshStyle("meshs")}>
            {fields.length === 0 && <p>{formatMessage({ id: "plans.manage.wifiMesh.noMesh" })}</p>}
            {fields.map((field, index) => (
              <WifiNetwork
                key={index}
                deleted={field.deleted}
                gatewayNumber={field.gatewayNumber}
                register={register(`meshs.${index}.name`)}
                setFocus={() => setFocus(`meshs.${index}.name`)}
                onDelete={() =>
                  // Can't spread otherwise field id is catched as a dirty value
                  field.new
                    ? remove(index)
                    : update(index, {
                        meshId: field.meshId,
                        name: field.name,
                        gatewayNumber: field.gatewayNumber,
                        new: field.new,
                        updated: false,
                        deleted: !field.deleted,
                      })
                }
                error={errors.meshs && (errors.meshs[index]?.name || errors.meshs[index])}
              />
            ))}
          </div>
          <AddForm
            names={fields.map(f => f.name)}
            onAddMesh={name => append({ name, gatewayNumber: 0, new: true, updated: false, deleted: false })}
          />
          <div className={wifiMeshStyle("footer")}>
            <ActionButton
              variant="cancel"
              text={formatMessage({ id: "plans.manage.wifiMesh.cancel" })}
              onClick={onClose}
            />
            <ActionButton
              type="submit"
              rounded
              text={formatMessage({ id: "plans.manage.wifiMesh.save" })}
              disabled={!isDirty}
            />
          </div>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
};
