import { DefaultBodyType, HttpResponse, HttpResponseResolver, PathParams } from "msw";

type HigherOrderResolver = <
  Params extends PathParams,
  RequestBodyType extends DefaultBodyType,
  ResponseBodyType extends DefaultBodyType,
>(
  resolver: HttpResponseResolver<Params, RequestBodyType, ResponseBodyType>,
) => HttpResponseResolver<Params, RequestBodyType, ResponseBodyType>;

const withAuth: HigherOrderResolver = resolver => {
  return input => {
    const { request } = input;

    if (!request.headers.get("X-Auth-Token")) {
      return HttpResponse.json(null, { status: 401 });
    }

    return resolver(input);
  };
};

export { withAuth };
