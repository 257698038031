import { http } from "msw";

import type {
  HeatingCurveHistoryPath,
  HeatingCurveHistoryPathParams,
  HeatingCurveHistoryRequestBody,
  HeatingCurveHistoryResponseBody,
  HeatingCurveHslopeHistoryPath,
  HeatingCurveHslopeHistoryPathParams,
  HeatingCurveHslopeHistoryRequestBody,
  HeatingCurveHslopeHistoryResponseBody,
} from "~/apiV2";
import type { HslopeHistoryPath, HslopeHistoryRequestBody, HslopeHistoryResponseBody } from "~/apiV2";

import { getHslopeHistoryResolver, heatingCurveHistoryResolver, heatingCurveHslopeHistory } from "../resolvers";
import type { VersionedPath } from "../utils";

export const handlers = [
  http.post<never, HslopeHistoryRequestBody, HslopeHistoryResponseBody, VersionedPath<HslopeHistoryPath>>(
    "/api/v3/history/manualBalancing",
    getHslopeHistoryResolver,
  ),
  http.post<
    HeatingCurveHistoryPathParams,
    HeatingCurveHistoryRequestBody,
    HeatingCurveHistoryResponseBody,
    VersionedPath<HeatingCurveHistoryPath>
  >(
    "/api/v3/history/houses/:houseId/modules/:moduleId/heatingNetworks/:heatingNetworkId/heatingCurves",
    heatingCurveHistoryResolver,
  ),
  http.post<
    HeatingCurveHslopeHistoryPathParams,
    HeatingCurveHslopeHistoryRequestBody,
    HeatingCurveHslopeHistoryResponseBody,
    VersionedPath<HeatingCurveHslopeHistoryPath>
  >(
    "/api/v3/history/houses/:houseId/modules/:moduleId/heatingNetworks/:heatingNetworkId/hslopes",
    heatingCurveHslopeHistory,
  ),
];
