import { useMemo } from "react";

import clsx from "clsx";
import { useIntl } from "react-intl";

import { PumpHorizontalIcon, PumpVerticalIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import Warning from "~/assets/svg/boilerroomWarning.svg?react";
import Commutator from "~/assets/svg/commutator.svg?react";
import type { PumpCommandAutomate, PumpCommandMan, PumpState, Pumps as PumpsType } from "~/socketio/types";
import { getPumpState, isOnlyOnePump, pumpsAutoOrManual, shouldDisplayPumpCommutator } from "~/utils/schemeUtils";

import styles from "./Pumps.module.scss";

enum Side {
  RIGHT = "right",
  LEFT = "left",
}

interface PumpProps {
  vertical?: boolean;
  side?: Side;
  state?: PumpState;
  commandAutomate?: PumpCommandAutomate;
  commandMan?: PumpCommandMan;
  defect?: boolean;
}

const Pump: React.FC<PumpProps> = ({ vertical = false, side, state, commandAutomate, commandMan, defect }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const pumpStyle = bem("pump");

  const pumpState = useMemo(
    () => getPumpState(state, commandAutomate, commandMan, defect),
    [state, commandAutomate, commandMan, defect],
  );

  const mismatchError = pumpState.state === "mismatch";

  const SideElement = (
    <div
      className={pumpStyle("side", {
        vertical: vertical,
        state: `${pumpState.state}`,
        error: mismatchError || !!defect,
      })}
    >
      <p>{side && formatMessage({ id: `pumps.${side}` })}</p>
    </div>
  );

  return (
    <div className={pumpStyle({ vertical: vertical })}>
      {vertical ? (
        <PumpHorizontalIcon className={pumpStyle("icon", { vertical: true })} />
      ) : (
        <PumpVerticalIcon className={pumpStyle("icon")} />
      )}
      {mismatchError || !!defect ? (
        <Tooltip
          content={formatMessage(
            { id: `pumps.tootltip.${mismatchError ? "mismatchError" : "defect"}` },
            { s: state, ca: commandAutomate },
          )}
        >
          {SideElement}
        </Tooltip>
      ) : (
        SideElement
      )}
    </div>
  );
};

export interface PumpsProps extends PumpsType {
  vertical?: boolean;
  right?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

export const Pumps: React.FC<PumpsProps> = ({
  vertical = false,
  right = false,
  id,
  stateP1,
  stateP2,
  commandAutomateP1,
  commandAutomateP2,
  commandManP1,
  commandManP2,
  command,
  defectP1,
  defectP2,
  className,
  onClick,
}) => {
  const { formatMessage } = useIntl();

  const pumps = useMemo(
    () => ({
      id,
      stateP1,
      stateP2,
      commandAutomateP1,
      commandAutomateP2,
      commandManP1,
      commandManP2,
      command,
      defectP1,
      defectP2,
    }),
    [
      id,
      stateP1,
      stateP2,
      commandAutomateP1,
      commandAutomateP2,
      commandManP1,
      commandManP2,
      command,
      defectP1,
      defectP2,
    ],
  );

  const onlyOnePump = useMemo(() => isOnlyOnePump(pumps), [pumps]);

  const displayCommutator = useMemo(
    () => shouldDisplayPumpCommutator(stateP1, commandAutomateP1, commandManP1, command),
    [stateP1, commandAutomateP1, commandManP1, command],
  );
  const autoOrManual = useMemo(
    () => pumpsAutoOrManual(command, commandManP1, commandManP2),
    [command, commandManP1, commandManP2],
  );
  const pumpStateP1 = useMemo(
    () => getPumpState(stateP1, commandAutomateP1, commandManP1, defectP1),
    [stateP1, commandAutomateP1, commandManP1, defectP1],
  );

  let pumpStateP2: { state: PumpState | PumpCommandAutomate | "mismatch" | null; error?: string } = { state: null };

  if (!onlyOnePump) {
    pumpStateP2 = getPumpState(stateP2, commandAutomateP2, commandManP2, defectP2);
  }

  return (
    <div
      className={clsx(
        styles.pumps,
        vertical && styles["pumps--vertical"],
        onClick && styles["pumps--clickable"],
        className,
      )}
      onClick={onClick}
    >
      <div
        className={clsx(
          styles.pumps__command,
          vertical && styles["pumps__command--vertical"],
          right && styles["pumps__command--right"],
        )}
      >
        {autoOrManual ? (
          <p className={styles.pumps__command__state}>{formatMessage({ id: `pumps.state.${autoOrManual}` })}</p>
        ) : (
          <Tooltip
            content={formatMessage(
              { id: "pumps.tootltip.commandManP1P2Differ" },
              {
                cmdMan1: commandManP1,
                cmdMan2: commandManP2,
              },
            )}
          >
            <p className={clsx(styles.pumps__command__state, styles["pumps__command__state--error"])}>
              {formatMessage({ id: "pumps.default" })}
            </p>
          </Tooltip>
        )}
        {displayCommutator && (
          <Tooltip content={formatMessage({ id: "pumps.commutator" })}>
            <Commutator className={styles.pumps__command__commutator} />
          </Tooltip>
        )}
        {(pumpStateP1.error || pumpStateP2.error) && (
          <Tooltip
            className={styles.pumps__tooltip}
            content={
              <>
                {pumpStateP1.error && <p>{pumpStateP1.error}</p>}
                {pumpStateP2.error && <p>{pumpStateP2.error}</p>}
              </>
            }
          >
            <Warning className={styles.pumps__command__warning} />
          </Tooltip>
        )}
      </div>
      <Pump
        vertical={vertical}
        side={onlyOnePump ? undefined : Side.LEFT}
        state={stateP1}
        commandAutomate={commandAutomateP1}
        commandMan={commandManP1}
        defect={defectP1}
      />
      {!isOnlyOnePump(pumps) && (
        <Pump
          vertical={vertical}
          side={onlyOnePump ? undefined : Side.RIGHT}
          state={stateP2}
          commandAutomate={commandAutomateP2}
          commandMan={commandManP2}
          defect={defectP2}
        />
      )}
    </div>
  );
};
