import { useEffect, useState } from "react";

import { isDesktop } from "react-device-detect";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";

import { Select } from "@eisox/design-system";
import { ShiftIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { RoomFC } from "~/UI/components";
import { PlanV2 } from "~/UI/layouts/PlanV2";
import type { ItemType } from "~/UI/layouts/PlanV2/components";
import type { houseLoader } from "~/UI/screens";
import { getPlan } from "~/api/plan";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";
import { selectionItems } from "~/utils/planUtils";

import styles from "./Plan.module.scss";

interface PlanProps {
  multiple?: boolean;
  selectedRooms: string[];
  onChange: (selectedItems: string[]) => void;
  roomHovered?: string;
  disabled?: boolean;
}

export const Plan: React.FC<PlanProps> = ({
  multiple = true,
  selectedRooms,
  onChange,
  roomHovered,
  disabled = false,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const planStyle = bem("plan");
  const headerStyle = bem("header");
  const roomStyle = bem("room");

  const { plans, rooms } = useRouteLoaderData("house") as LoaderData<typeof houseLoader>;
  const { houseId } = useParams() as { houseId: string };

  const [plan, setPlan] = useState(plans[0]);
  const [imgUrl, setImgUrl] = useState<string>("");

  useEffect(() => {
    // update plan image when plan change
    getPlan(plan.id, houseId).then(response => response.type === SUCCESS_FETCH && setImgUrl(response.result.message));
  }, [plan.id]);

  const handleChangePlan = (value: string) => {
    const newPlan = plans.find(p => p.id === value);
    newPlan && setPlan(newPlan);
  };

  const items: ItemType[] = rooms
    .filter(r => r.plan?.planId === plan.id)
    .map(r => ({
      id: r.id!,
      x: r.plan?.x!,
      y: r.plan?.y!,
      renderItem: () => (
        <RoomFC
          name={r.name ?? "-"}
          selected={selectedRooms.includes(r.id!)}
          className={roomStyle({ hovered: roomHovered === r.id })}
          disabled={disabled}
          onClick={() => !multiple && onChange([r.id!])}
        />
      ),
      selected: selectedRooms.includes(r.id!),
      disabled: disabled,
    }));

  const handleChangeItems = (items: ItemType[]) => {
    const selectedRoomsFromOtherPlans = rooms
      .filter(r => r.plan?.planId !== plan.id)
      .filter(r => selectedRooms.includes(r.id!))
      .map(r => r.id!);
    onChange(
      items
        .filter(i => i.selected)
        .map(i => i.id)
        .concat(selectedRoomsFromOtherPlans),
    );
  };

  return (
    <div className={planStyle()}>
      <div className={headerStyle()}>
        <Select
          label={formatMessage({ id: "settings.content.menu.rooms.groups.plan.level" })}
          triggerClassName={headerStyle("select")}
          value={plan.id}
          options={plans.map(p => {
            return {
              name: p.name ?? "--",
              value: p.id!,
            };
          })}
          renderValue={(value?: string) => <p>{plans.find(p => p.id === value)?.name}</p>}
          onChange={handleChangePlan}
        />
        {isDesktop && multiple && (
          <p className={headerStyle("text")}>
            {formatMessage(
              { id: "settings.content.menu.rooms.groups.plan.instructions" },
              {
                i: (
                  <span style={{ display: "inline-flex", alignItems: "center", gap: 5 }}>
                    <ShiftIcon style={{ width: 12, height: 12 }} />
                    MAJ
                  </span>
                ),
              },
            )}
          </p>
        )}
      </div>
      <PlanV2
        className={planStyle("plan")}
        planUrl={imgUrl}
        rectangleSelection={multiple}
        items={items}
        selectionFunction={multiple ? selectionItems : undefined}
        onChange={handleChangeItems}
      />
    </div>
  );
};
