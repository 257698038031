import { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { BoilerRoomsPosInner } from "@eisox/backend_webapp_api";
import {
  ActionButtonV2 as ActionButton,
  ButtonV2 as Button,
  DrawerV2 as Drawer,
  Slider,
  TextField,
} from "@eisox/design-system";
import { ArrowRightIcon, NutIcon } from "@eisox/icons";
import { zodResolver } from "@hookform/resolvers/zod";

import { FieldContainer, usePermissionsContext } from "~/UI";
import { MoveDialog } from "~/UI/screens/Plans/components";
import { DEFAULT_HIGH_SET_TEMPERATURE, DEFAULT_LOW_SET_TEMPERATURE } from "~/constants/appConstantV2";
import type { BoilerRoom } from "~/socketio/types";
import { UserRole } from "~/utils";

import type { SchemaType } from "../../helpers/parameters";
import { schema } from "../../helpers/parameters";
import { useBoilerRoomRealTimeProviderContext } from "../../providers";

import styles from "./Parameters.module.scss";

const NAME = "BoilerRoomParameters";

interface ParametersProps {
  boilerRoomId: string;
  name?: string;
  noHeatingTemperature?: number;
  lowSetpointTemperature?: number;
  highSetpointTemperature?: number;
  boilerroomPos: BoilerRoomsPosInner[];
  disabled?: boolean;
}

const Parameters: React.FC<ParametersProps> = ({
  boilerRoomId,
  name,
  lowSetpointTemperature,
  highSetpointTemperature,
  boilerroomPos,
  disabled,
}) => {
  const { t } = useTranslation();

  const { role } = usePermissionsContext();

  const { useUpdateBoilerRoom } = useBoilerRoomRealTimeProviderContext(NAME);

  const [open, setOpen] = useState(false);
  // TODO : Delete when MoveDialog refactoring is done
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<Partial<SchemaType>>({
    resolver: zodResolver(schema),
    values: {
      name,
      lowSetpointTemperature,
      highSetpointTemperature,
    },
    defaultValues: {
      lowSetpointTemperature: DEFAULT_LOW_SET_TEMPERATURE,
      highSetpointTemperature: DEFAULT_HIGH_SET_TEMPERATURE,
    },
  });

  const currentLowSetTemperature = watch("lowSetpointTemperature");
  const currentHighSetTemperature = watch("highSetpointTemperature");

  const { mutate } = useUpdateBoilerRoom({
    onSuccess: () => {
      reset();
      setOpen(false);
    },
  });

  const onSubmit = (data: Partial<SchemaType>) => {
    const updates: BoilerRoom = { id: boilerRoomId };
    if (dirtyFields.name) updates.name = data.name;
    if (dirtyFields.lowSetpointTemperature) updates.lowSetpointTemperature = data.lowSetpointTemperature;
    if (dirtyFields.highSetpointTemperature) updates.highSetpointTemperature = data.highSetpointTemperature;
    mutate([updates]);
  };

  return (
    <>
      <Drawer.Root open={open} onOpenChange={setOpen}>
        <Drawer.Trigger asChild disabled={disabled}>
          <Button>
            {t("boilerRoom.settings.title")} <NutIcon />
          </Button>
        </Drawer.Trigger>
        <Drawer.Content>
          <Drawer.Header>{t("boilerRoom.settings.title")}</Drawer.Header>
          <form className={styles.parameters} onSubmit={handleSubmit(onSubmit)}>
            <FieldContainer label={t("boilerRoom.settings.name")} error={errors.name}>
              <TextField.Root {...register("name")} placeholder={t("boilerRoom.settings.name")} />
            </FieldContainer>
            <Drawer.Group className={styles.parameters__temperatures}>
              <Drawer.Label>{t("boilerRoom.settings.temperatures")}</Drawer.Label>
              <Controller
                control={control}
                name="lowSetpointTemperature"
                render={({ field: { value, onChange } }) => (
                  <Slider
                    label={t("boilerRoom.settings.lowSetpointTemperature")}
                    min={0}
                    max={100}
                    step={1}
                    value={value}
                    valueLabelFormat={t("boilerRoom.settings.maximumUpperMinimum", {
                      max: currentHighSetTemperature,
                      min: value,
                    })}
                    onValueChange={value => {
                      if (value) {
                        const maxTemp = currentHighSetTemperature;
                        if (maxTemp && value >= maxTemp) value = maxTemp - 1;
                        onChange(value);
                      }
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="highSetpointTemperature"
                render={({ field: { value, onChange } }) => (
                  <Slider
                    label={t("boilerRoom.settings.highSetpointTemperature")}
                    min={0}
                    max={100}
                    step={1}
                    value={value}
                    defaultValue={DEFAULT_HIGH_SET_TEMPERATURE}
                    valueLabelFormat={t("boilerRoom.settings.maximumUpperMinimum", {
                      max: value,
                      min: currentLowSetTemperature,
                    })}
                    onValueChange={value => {
                      if (value) {
                        const minTemp = currentLowSetTemperature;
                        if (minTemp && value <= minTemp) value = minTemp + 1;
                        onChange(value);
                      }
                    }}
                  />
                )}
              />
            </Drawer.Group>
            {role === UserRole.INSTALLER && (
              <Drawer.Group>
                <Drawer.Label>{t("boilerRoom.settings.position")}</Drawer.Label>
                <Button className={styles.parameters__move} onClick={() => setMoveDialogOpen(true)}>
                  {t("boilerRoom.settings.move")}
                  <ArrowRightIcon />
                </Button>
              </Drawer.Group>
            )}
            <ActionButton className={styles.parameters__save} type="submit" rounded disabled={!isDirty}>
              {t("boilerRoom.settings.save")}
            </ActionButton>
          </form>
        </Drawer.Content>
      </Drawer.Root>
      {moveDialogOpen && (
        <MoveDialog ressource={"boilerroom"} items={boilerroomPos} open onClose={() => setMoveDialogOpen(false)} />
      )}
    </>
  );
};

export { Parameters };
export type { ParametersProps };
