import { http } from "msw";

import type { News, NewsPath, NewsQueryParams } from "~/apiV2";

import { getNewsResolver, getResourcesNewsResolver } from "../resolvers";

export const handlers = [
  http.post<NewsQueryParams, never, News[], NewsPath>("https://www.eisox.com/wp-json/wp/v2/posts", getNewsResolver),
  http.get<never, never, News[], "/resources_news.json">("/resources_news.json", getResourcesNewsResolver),
];
