import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";

import { Button } from "@eisox/design-system";
import { ChevronRightIcon } from "@eisox/icons";

import type { houseLoader } from "~/UI/screens";
import { idLoaderHouse, routeToPlan } from "~/routes/utils";

import styles from "./Breadcrumbs.module.scss";

export const Breadcrumbs: React.FC = () => {
  const houseContext = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const { houseId, planId, roomId } = useParams();
  const navigate = useNavigate();

  const planPlanName = planId && houseContext.plans.find(p => p.id === planId)?.name;

  return (
    <>
      {planPlanName && roomId && (
        <Button
          className={styles.breadcrumbs}
          text={planPlanName || ""}
          icon={<ChevronRightIcon />}
          onClick={() => navigate(routeToPlan(houseId!, planId))}
        />
      )}
    </>
  );
};
