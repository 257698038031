import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";

const getPlannings = async (houseId: string, roomId: string) => {
  const { data, error } = await client.GET("/houses/{houseId}/plannings/rooms/{roomId}", {
    params: { path: { houseId, roomId } },
  });
  if (error) throw new Error(error.message);
  return data?.message ?? { plannings: [] };
};

export const planning = createQueryKeys("planning", {
  room: (houseId: string, roomId: string) => ({
    queryKey: [houseId, roomId],
    queryFn: () => getPlannings(houseId, roomId),
  }),
});
