import { MeshsMessageMeshsInner } from "@eisox/backend_webapp_api";

import { GatewaysWithProblem } from "~/UI/screens/House";

/**
 * Retrieves the names of the meshs associated with a gateway.
 * @param gateway - The gateway object.
 * @param meshs - The array of meshs.
 * @returns An array of strings representing the names of the meshs associated with the gateway.
 */
export const getMeshsName = (gateway: GatewaysWithProblem, meshs: MeshsMessageMeshsInner[]): string[] => {
  return (
    gateway?.meshs?.map(m => {
      const findMesh = meshs?.find(mesh => mesh.id === m.id);
      return findMesh?.name || findMesh?.id || "-/-";
    }) || []
  );
};

/**
 * Toggles the suffix of a gateway name based on whether it is an internet gateway or not.
 * If the gateway is an internet gateway, it adds the suffix "_M" if it doesn't already have it,
 * otherwise it removes the suffix "_M" if it exists.
 * If the gateway is not an internet gateway, it removes the suffix "_M" if it exists,
 * otherwise it returns the original gateway name.
 *
 * @param gatewayName - The name of the gateway.
 * @param isInternetGateway - A boolean indicating whether the gateway is an internet gateway.
 * @returns The modified gateway name.
 */
export const toggleGatewaySuffix = (gatewayName: string, isInternetGateway: boolean) => {
  return isInternetGateway
    ? gatewayName.endsWith("_M")
      ? gatewayName
      : gatewayName.concat("_M")
    : gatewayName.endsWith("_M")
      ? gatewayName.slice(0, -2)
      : gatewayName;
};
