import type { Resource } from "i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { createIntl } from "react-intl";
import en_US_ZOD from "zod-i18n-map/locales/en/zod.json";
import fr_FR_ZOD from "zod-i18n-map/locales/fr/zod.json";

import en_US from "./locales/en_US.json";
import fr_FR from "./locales/fr_FR.json";
import { flattenMessages } from "./utils";

const resources: Resource = {
  en: {
    translation: { ...en_US },
    zod: { ...en_US_ZOD },
  },
  fr: {
    translation: { ...fr_FR },
    zod: { ...fr_FR_ZOD },
  },
};

enum PhonesCodes {
  FR = "FR",
}

/* NEW */
void i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    detection: {
      order: ["navigator"],
      convertDetectedLanguage: lng => lng.split("-")[0],
    },
    supportedLngs: ["en", "fr"],
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false,
    },
  });

export { i18next, PhonesCodes };

/* OLD */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const oldMessages: Record<string, any> = {
  en: {
    ...en_US,
  },
  fr: {
    ...fr_FR,
  },
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const oldMessage = flattenMessages(oldMessages[i18next.language]);

export const intl = createIntl({
  locale: i18next.language,
  messages: oldMessage,
});
