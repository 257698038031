import type {
  BatteryHistory200ResponseMessageInner,
  HouseMessage,
  HousesHouseIdBoilerroomsPosGet200ResponseMessageInner,
  MeshsMessageMeshsInner,
  Planning,
  PlansMessageInner,
} from "@eisox/backend_webapp_api";
import { RoomRoomType } from "@eisox/webapp-api-specification";

import type { GatewaysWithProblem, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import type { BoilerroomDataRes } from "~/socketio/types";
import { Type } from "~/socketio/types";
import { addTime } from "~/utils/timeUtils";

export const house: HouseMessage = {
  _id: "64f74754056500bd545d086d",
  frostFreeTemperature: 7,
  absenceTemperature: 16,
  houseName: "test_chaufferie5",
  isFrostFree: false,
  longitude: "1.784226171305501",
  latitude: "46.73003663291044",
  type: "professional",
  isRemoteAccess: true,
  preComfortTemperature: 19,
  isSummerMode: false,
  summerModePeriod: {
    begin: "15-05",
    end: "15-09",
  },
  nextUidGateway: 2,
  nextUidValve: 5,
};

export const plans: PlansMessageInner[] = [
  {
    name: "test4",
    id: "64f9a4056deef7c2637b24a4",
  },
  {
    name: "tests",
    id: "64f74754056500bd545d086e",
  },
];

export const rooms: RoomsWithProblem[] = [
  {
    name: "test_BUR00",
    roomType: RoomRoomType.Office,
    comfortTemperature: 27.5,
    isFrostFree: false,
    isSwitchEnabled: true,
    plan: {
      planId: "64f74754056500bd545d086e",
      x: 23.575,
      y: 40.333333333333336,
    },
    isPreComfRoom: false,
    id: "64f747bc056500bd545d086f",
    profile: {
      _id: "9cgSoYwX5XPKKwL8q",
      name: "officePro",
    },
    autoConfStart: `${new Date()}`,
    anticipationStart: `${new Date()}`,
    openWinStart: `${new Date()}`,
    autoPreConfStart: `${new Date()}`,
    isAutoPrecomf: true,
    nightTemperature: 18,
    problemStatus: undefined,
    errors: [],
    warnings: [],
  },
  {
    name: "test4_BUR01",
    roomType: RoomRoomType.Office,
    comfortTemperature: 13,
    isFrostFree: false,
    isSwitchEnabled: false,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 77.84992001796707,
      y: 33.982681385269,
    },
    isPreComfRoom: false,
    id: "64fb10e56deef7c2637b24ac",
    profile: {
      _id: "9cgSoYwX5XPKKwL8q",
      name: "officePro",
    },
    isAutoPrecomf: false,
    nightTemperature: 18,
    problemStatus: undefined,
    errors: [],
    warnings: [],
  },
  {
    name: "test4_BUR02",
    roomType: RoomRoomType.Office,
    comfortTemperature: 22.5,
    isFrostFree: false,
    isSwitchEnabled: true,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 50.24166666666667,
      y: 41.4,
    },
    isPreComfRoom: true,
    id: "6503240878afbd78fde588e7",
    profile: {
      _id: "9cgSoYwX5XPKKwL8q",
      name: "officePro",
    },
    problemStatus: undefined,
    nightTemperature: 18,
    isAutoPrecomf: false,
    errors: [],
    warnings: [],
  },
  {
    name: "test_versions",
    roomType: RoomRoomType.Office,
    comfortTemperature: 20,
    isFrostFree: false,
    isSwitchEnabled: false,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 22.97980137152948,
      y: 41.197688282549606,
    },
    id: "6536a1f9e2e11c0cc14fc155",
    profile: {
      _id: "9cgSoYwX5XPKKwL8q",
      name: "officePro",
    },
    problemStatus: undefined,
    nightTemperature: 18,
    isAutoPrecomf: false,
    isPreComfRoom: false,
    errors: [],
    warnings: [],
  },
  {
    name: "test4_BUR04",
    roomType: RoomRoomType.Office,
    comfortTemperature: 20,
    isFrostFree: false,
    isSwitchEnabled: false,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 18.226668294270834,
      y: 29.839998372395833,
    },
    id: "65394e521305545503bb333e",
    profile: {
      _id: "9cgSoYwX5XPKKwL8q",
      name: "officePro",
    },
    problemStatus: undefined,
    nightTemperature: 18,
    isAutoPrecomf: false,
    isPreComfRoom: false,
    errors: [],
    warnings: [],
  },
  {
    name: "test4_BUR05",
    roomType: RoomRoomType.Office,
    comfortTemperature: 20,
    isFrostFree: false,
    isSwitchEnabled: false,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 19.960001627604168,
      y: 51.3066650390625,
    },
    id: "65394e521305545503bb333f",
    profile: {
      _id: "9cgSoYwX5XPKKwL8q",
      name: "officePro",
    },
    problemStatus: undefined,
    nightTemperature: 18,
    isAutoPrecomf: false,
    isPreComfRoom: false,
    errors: [],
    warnings: [],
  },
  {
    name: "test4_BUR06",
    roomType: RoomRoomType.Office,
    comfortTemperature: 20,
    isFrostFree: false,
    isSwitchEnabled: false,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 29.6933349609375,
      y: 30.9066650390625,
    },
    id: "65394e521305545503bb3340",
    profile: {
      _id: "9cgSoYwX5XPKKwL8q",
      name: "officePro",
    },
    problemStatus: undefined,
    nightTemperature: 18,
    isAutoPrecomf: false,
    isPreComfRoom: false,
    errors: [],
    warnings: [],
  },
  {
    name: "test4_BUR07",
    roomType: RoomRoomType.Office,
    comfortTemperature: 20,
    isFrostFree: false,
    isSwitchEnabled: false,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 86.4933349609375,
      y: 66.37333170572917,
    },
    id: "653a1f0698f7570b31b4ba14",
    profile: {
      _id: "9cgSoYwX5XPKKwL8q",
      name: "officePro",
    },
    problemStatus: undefined,
    nightTemperature: 18,
    isAutoPrecomf: false,
    isPreComfRoom: false,
    errors: [],
    warnings: [],
  },
  {
    name: "test4_BUR08",
    roomType: RoomRoomType.Office,
    comfortTemperature: 20,
    isFrostFree: false,
    isSwitchEnabled: false,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 77.6933349609375,
      y: 67.3066650390625,
    },
    id: "653a1f0698f7570b31b4ba15",
    profile: {
      _id: "9cgSoYwX5XPKKwL8q",
      name: "officePro",
    },
    problemStatus: undefined,
    nightTemperature: 18,
    isAutoPrecomf: false,
    isPreComfRoom: false,
    errors: [],
    warnings: [],
  },
  {
    name: "test4_BUR09",
    roomType: RoomRoomType.Office,
    comfortTemperature: 20,
    isFrostFree: false,
    isSwitchEnabled: false,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 69.56000162760417,
      y: 67.03999837239583,
    },
    id: "653a1f0698f7570b31b4ba16",
    profile: {
      _id: "9cgSoYwX5XPKKwL8q",
      name: "officePro",
    },
    problemStatus: undefined,
    nightTemperature: 18,
    isAutoPrecomf: false,
    isPreComfRoom: false,
    errors: [],
    warnings: [],
  },
];

export const gateways: GatewaysWithProblem[] = [
  {
    gatewayName: "Box dev bap",
    meshs: [
      {
        id: "6kEiW9bYWw59NaMmd",
      },
      {
        id: "r3KajAt94MgqpjksS",
      },
    ],
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 68.89369858927957,
      y: 33.4471386074614,
    },
    isWifiEnabled: true,
    isInternetGateway: false,
    uid: 1,
    mac: "9c:65:f9:36:2b:e1",
    status: "idle",
    lastGatewayActivity: `${new Date().toISOString()}`,
    hardwareVersion: "3.8",
    ip: "192.168.0.153",
    softwareVersion: "5.0.0",
    isEthUp: true,
    id: "64f747d5056500bd545d0881",
    expireAt: "2062-06-26T07:34:56Z",
    errors: [],
    warnings: [],
    borderRouterStatus: [],
    problemStatus: undefined,
  },
  {
    gatewayName: "test4_BOX06",
    meshs: [
      {
        id: "6kEiW9bYWw59NaMmd",
      },
    ],
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 78.040625,
      y: 33.86666666666667,
    },
    isWifiEnabled: false,
    isInternetGateway: false,
    lastGatewayActivity: "2023-08-22T19:13:32.847Z",
    uid: 6,
    status: "idle",
    id: "abcd",
    errors: [],
    warnings: [],
    borderRouterStatus: [],
    problemStatus: undefined,
  },
  {
    gatewayName: "test4_BOX06",
    meshs: [
      {
        id: "6kEiW9bYWw59NaMmd",
      },
    ],
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 78.040625,
      y: 33.86666666666667,
    },
    isInternetGateway: false,
    mac: "9c:65:f9:36:2b:e3",
    uid: 7,
    status: "idle",
    id: "abcdef",
    errors: [],
    warnings: [],
    borderRouterStatus: [],
    problemStatus: undefined,
  },
  {
    gatewayName: "test4_BOX06",
    meshs: [
      {
        id: "6kEiW9bYWw59NaMmd",
      },
    ],
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 78.040625,
      y: 33.86666666666667,
    },
    isWifiEnabled: true,
    isInternetGateway: false,
    uid: 8,
    ip: "127.0.0.2",
    mac: "9c:65:f9:36:2b:e4",
    lastGatewayActivity: addTime(-2, "days").toISOString(),
    status: "idle",
    id: "reheeh",
    errors: [],
    warnings: [],
    borderRouterStatus: [],
    problemStatus: undefined,
  },
  {
    gatewayName: "test4_BOX06",
    meshs: [
      {
        id: "6kEiW9bYWw59NaMmd",
      },
    ],
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 78.040625,
      y: 33.86666666666667,
    },
    softwareVersion: "4.16.0",
    isWifiEnabled: true,
    isInternetGateway: false,
    uid: 9,
    mac: "9c:65:f9:36:2b:e5",
    lastGatewayActivity: addTime(-15, "minutes").toISOString(),
    status: "idle",
    id: "lkpjohigu",
    errors: [],
    warnings: [],
    borderRouterStatus: [],
    problemStatus: undefined,
  },
  {
    gatewayName: "test4_BOX06",
    meshs: [
      {
        id: "6kEiW9bYWw59NaMmd",
      },
    ],
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 78.040625,
      y: 33.86666666666667,
    },
    isWifiEnabled: true,
    isInternetGateway: false,
    hardwareVersion: "3.9",
    uid: 10,
    mac: "9c:65:f9:36:2b:e3",
    lastGatewayActivity: "2023-08-22T19:13:32.847Z",
    status: "idle",
    isEthUp: true,
    id: "mlkjohiguvhjbk",
    errors: [],
    warnings: [],
    borderRouterStatus: [],
    problemStatus: undefined,
  },
];

export const valves: ValvesWithProblem[] = [
  {
    gatewayId: "64f747d5056500bd545d0881",
    uid: 1,
    plan: {
      planId: "64f74754056500bd545d086e",
      x: 17.014583333333334,
      y: 49.4,
    },
    roomId: "64f747bc056500bd545d086f",
    status: "toAutoInstall",
    id: "64f747ea056500bd545d0882",
    stateData: {},
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "64f747d5056500bd545d0881",
    uid: 2,
    plan: {
      planId: "64f74754056500bd545d086e",
      x: 43.014583333333334,
      y: 28.733333333333334,
    },
    roomId: "64f747bc056500bd545d086f",
    status: "toAutoInstall",
    id: "64f747ea056500bd545d0883",
    stateData: {},
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "64f747d5056500bd545d0881",
    uid: 3,
    plan: {
      planId: "64f74754056500bd545d086e",
      x: 17.147916666666667,
      y: 34.46666666666667,
    },
    roomId: "64f747bc056500bd545d086f",
    status: "toAutoInstall",
    id: "64f9cbf46deef7c2637b24a6",
    stateData: {},
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "64f747d5056500bd545d0881",
    uid: 9,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 71.65461348875421,
      y: 28.901882237386072,
    },
    roomId: "5dZaxNp8yY7gH98vi",
    status: "testPluggedOK",
    mac: "FCC23D00000C3C88",
    lqi: 255,
    rssi: -51,
    updatedAt: "2023-10-16T08:07:57.473Z",
    hardwareVersion: "samr30-Valve5",
    softwareVersion: "4.44.0",
    closing: 0,
    correction: 0,
    sensors: {
      IRCounter: 0,
      humidity: 39.83,
      indoorAirQuality: 0,
      light: 138,
      pressure: 0,
      soundCounter: 3,
      temperature: 21.84,
    },
    voltage: 3600,
    mecaProblems: [
      {
        name: "PLUGGED_FALSE",
        updatedAt: "2023-10-16T07:26:54.000Z",
      },
    ],
    id: "652ce48529f16fef747ec881",
    stateData: {},
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "64f747d5056500bd545d0456",
    hardwareVersion: "samr30-Valve4_1",
    uid: 15,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 81.90729166666667,
      y: 42.266666666666666,
    },
    roomId: "64fb10e56deef7c2637b24ac",
    status: "toAutoInstall",
    id: "65323de5e2e11c0cc14fc14f",
    stateData: {},
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "64f747d5056500bd545d0456",
    uid: 16,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 77.50729166666666,
      y: 42.53333333333333,
    },
    roomId: "64fb10e56deef7c2637b24ac",
    status: "toAutoInstall",
    id: "65323de5e2e11c0cc14fc150",
    stateData: {},
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "64f747d5056500bd545d0881",
    uid: 17,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 72.97395833333333,
      y: 42.4,
    },
    roomId: "64fb10e56deef7c2637b24ac",
    status: "toAutoInstall",
    id: "65323de5e2e11c0cc14fc151",
    stateData: {},
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "64f747d5056500bd545d0881",
    uid: 18,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 72.57395833333334,
      y: 45.46666666666667,
    },
    roomId: "64fb10e56deef7c2637b24ac",
    status: "toAutoInstall",
    id: "65323de5e2e11c0cc14fc152",
    stateData: {},
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "64f747d5056500bd545d0881",
    uid: 19,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 78.97395833333333,
      y: 45.333333333333336,
    },
    roomId: "64fb10e56deef7c2637b24ac",
    status: "toAutoInstall",
    id: "65323de5e2e11c0cc14fc153",
    stateData: {},
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "64f747d5056500bd545d0881",
    uid: 20,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 82.440625,
      y: 45.2,
    },
    roomId: "64fb10e56deef7c2637b24ac",
    status: "toAutoInstall",
    id: "65323de5e2e11c0cc14fc154",
    stateData: {},
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "64f747d5056500bd545d0881",
    uid: 22,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 28.731146144013636,
      y: 57.78781423093311,
    },
    roomId: "64fb10e56deef7c2637b24ac",
    status: "toAutoInstall",
    mac: "1111111111111111",
    id: "653a8d1c98f7570b31b4ba4b",
    stateData: {},
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "64f747d5056500bd545d0881",
    uid: 10,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 16.33597518079903,
      y: 27.05025932233805,
    },
    roomId: "64f747bc056500bd545d086f",
    status: "testPluggedOK",
    mac: "FCC23D00000BB532",
    hardwareVersion: "samr30-Valve4",
    softwareVersion: "4.44.3",
    updatedAt: new Date().toISOString(),
    lqi: 255,
    rssi: -46,
    closing: 100,
    correction: 0.3,
    sensors: {
      IRCounter: 0,
      humidity: 30.0,
      indoorAirQuality: 56,
      light: 167.8,
      pressure: 0,
      soundCounter: 3,
      temperature: 19.05,
    },
    stateData: {
      closePos: 1097,
      openPos: 492,
      posCounter: 10,
      posUpdatedAt: "2023-10-23T08:38:46.607Z",
      batZ: 3072,
      batZUpdatedAt: "2023-10-23T12:24:54.807Z",
      swOffset: -2,
    },
    voltage: 3489,
    mecaProblems: [],
    id: "65314c2de2e11c0cc14fc13c",
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
  {
    gatewayId: "abcd",
    uid: 21,
    plan: {
      planId: "64f9a4056deef7c2637b24a4",
      x: 10.984792681727894,
      y: 27.132542494253762,
    },
    roomId: "64f747bc056500bd545d086f",
    status: "testPluggedOK",
    mac: "FCC23D000004753B",
    lqi: 255,
    rssi: -50,
    updatedAt: new Date().toISOString(),
    hardwareVersion: "samr30-Valve4_1",
    softwareVersion: "4.44.3",
    closing: 97,
    correction: 0.41,
    sensors: {
      IRCounter: 0,
      humidity: 56.58,
      indoorAirQuality: 0,
      light: 20.3,
      pressure: 99804,
      soundCounter: 4,
      temperature: 21.08,
    },
    voltage: 3537,
    mecaProblems: [],
    stateData: {
      closePos: 1080,
      openPos: 704,
      posCounter: 5,
      posUpdatedAt: "2023-10-24T14:14:33.883Z",
      batZ: 3484,
      batZUpdatedAt: "2023-10-24T14:14:33.883Z",
    },
    id: "6536a206e2e11c0cc14fc167",
    errors: [],
    warnings: [],
    problemStatus: undefined,
    gatewayName: "Box dev bap",
  },
];

export const plannings: Planning[] = [
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "friday",
    hourStart: "00:00",
    hourEnd: "08:00",
    userDefined: false,
    planningType: "preComfort",
    id: "64fb10e56deef7c2637b24b2",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "friday",
    hourStart: "08:00",
    hourEnd: "19:00",
    userDefined: false,
    planningType: "comfort",
    id: "64fb10e56deef7c2637b24b8",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "friday",
    hourStart: "19:00",
    hourEnd: "00:00",
    userDefined: false,
    planningType: "preComfort",
    id: "64fb10e56deef7c2637b24bd",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "monday",
    hourStart: "00:00",
    hourEnd: "08:00",
    userDefined: false,
    planningType: "preComfort",
    id: "64fb10e56deef7c2637b24ad",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "monday",
    hourStart: "08:00",
    hourEnd: "19:00",
    userDefined: false,
    planningType: "comfort",
    id: "64fb10e56deef7c2637b24ae",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "monday",
    hourStart: "19:00",
    hourEnd: "00:00",
    userDefined: false,
    planningType: "preComfort",
    id: "64fb10e56deef7c2637b24b9",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "saturday",
    hourStart: "00:00",
    hourEnd: "00:00",
    userDefined: false,
    planningType: "absence",
    id: "64fb10e56deef7c2637b24b3",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "sunday",
    hourStart: "00:00",
    hourEnd: "00:00",
    userDefined: false,
    planningType: "absence",
    id: "64fb10e56deef7c2637b24b4",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "thursday",
    hourStart: "00:00",
    hourEnd: "08:00",
    userDefined: false,
    planningType: "preComfort",
    id: "64fb10e56deef7c2637b24b1",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "thursday",
    hourStart: "08:00",
    hourEnd: "19:00",
    userDefined: false,
    planningType: "comfort",
    id: "64fb10e56deef7c2637b24b7",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "thursday",
    hourStart: "19:00",
    hourEnd: "00:00",
    userDefined: false,
    planningType: "preComfort",
    id: "64fb10e56deef7c2637b24bc",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "tuesday",
    hourStart: "00:00",
    hourEnd: "08:00",
    userDefined: false,
    planningType: "preComfort",
    id: "64fb10e56deef7c2637b24af",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "tuesday",
    hourStart: "08:00",
    hourEnd: "19:00",
    userDefined: false,
    planningType: "comfort",
    id: "64fb10e56deef7c2637b24b5",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "tuesday",
    hourStart: "19:00",
    hourEnd: "00:00",
    userDefined: false,
    planningType: "preComfort",
    id: "64fb10e56deef7c2637b24ba",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "wednesday",
    hourStart: "00:00",
    hourEnd: "08:00",
    userDefined: false,
    planningType: "preComfort",
    id: "64fb10e56deef7c2637b24b0",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "wednesday",
    hourStart: "08:00",
    hourEnd: "19:00",
    userDefined: false,
    planningType: "comfort",
    id: "64fb10e56deef7c2637b24b6",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "wednesday",
    hourStart: "19:00",
    hourEnd: "00:00",
    userDefined: false,
    planningType: "preComfort",
    id: "64fb10e56deef7c2637b24bb",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "monday",
    hourStart: "01:00",
    hourEnd: "07:00",
    userDefined: true,
    planningType: "absence",
    id: "6509570f392748f36dcca016",
  },
  {
    roomId: "64fb10e56deef7c2637b24ac",
    day: "monday",
    hourStart: "13:00",
    hourEnd: "17:00",
    userDefined: true,
    planningType: "absence",
    id: "6509570f392748f36dcca017",
  },
];

export const modules: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner[] = [
  {
    _id: "64132b352025a2ac0d6097ee",
    boilerRooms: [
      {
        _id: "64ba7680d44e9860ffacf968",
        name: "RIE",
        objectName: "64132b352025a2ac0d6097ee_a8",
        id: "64ba7680d44e9860ffacf968",
        plan: {
          planId: "64f9a4056deef7c2637b24a4",
          x: 50,
          y: 50,
        },
      },
      {
        _id: "64ba7680d44e9860ffacf969",
        name: "SS1",
        objectName: "64132b352025a2ac0d6097ee_a50",
        id: "64ba7680d44e9860ffacf969",
      },
    ],
    moduleId: "64132b352025a2ac0d6097ee",
  },
  {
    _id: "64132ba02025a2ac0d6097f0",
    boilerRooms: [
      {
        _id: "64ba767dd44e9860ffacf942",
        name: "R+3",
        objectName: "64132ba02025a2ac0d6097f0_a179",
        id: "64ba767dd44e9860ffacf942",
      },
      {
        _id: "64ba767dd44e9860ffacf943",
        name: "SS3",
        objectName: "64132ba02025a2ac0d6097f0_a189",
        id: "64ba767dd44e9860ffacf943",
        plan: {
          planId: "64f74754056500bd545d086e",
          x: 50,
          y: 50,
        },
      },
      {
        _id: "64ba767dd44e9860ffacf944",
        name: "SS4",
        objectName: "64132ba02025a2ac0d6097f0_a196",
        id: "64ba767dd44e9860ffacf944",
      },
    ],
    moduleId: "64132ba02025a2ac0d6097f0",
  },
];

export const boilerroomDataRes: BoilerroomDataRes = {
  boilerRooms: [
    {
      boilers: [
        {
          id: "64deff399a3d73535986cfd5",
          name: "RCU",
          type: Type.RCU,
        },
      ],
      externalTemperature: 21.4,
      frostFreeModeState: 0,
      heatingNetworks: [
        {
          ambientSensorIds: ["k3bNZEX5tNwYveo7P"],
          ambientTemperature: 23.4,
          anticipationMax: 360,
          heatingCurves: [
            {
              con: 80,
              ext: -10,
              id: "64deff399a3d73535986cfe9",
            },
            {
              con: 60,
              ext: 0,
              id: "64deff399a3d73535986cfea",
            },
            {
              con: 50,
              ext: 10,
              id: "64deff399a3d73535986cfeb",
            },
            {
              con: 40,
              ext: 20,
              id: "64deff399a3d73535986cfec",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986cfed",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986cfee",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986cfef",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986cff0",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986cff1",
            },
          ],
          heatingSlope: 30,
          id: "64deff399a3d73535986cfe8",
          idValves: [
            "k3bNZEX5tNwYveo7P",
            "ns8Qu3Gv4gfBDCAJm",
            "jsnQuJxnWRAHumHFG",
            "5bkQcJ92obxBZjneP",
            "6gHR9rL5EhLFfMApv",
            "Wgr5A6ctjRT9z4Et9",
            "Myk96g7JSkDJvNE36",
            "E2dTHBjbnYjXiWpoc",
            "nECMBZguJ6ZuED6bH",
            "sSqwrbTm2vextS39g",
            "JRqrtsBgfZRD3WQMD",
            "w73cF8uMu4bTiRe4m",
            "hDiqKrHaqJnuBqnnP",
            "EycWBWMcfAiwCLq9y",
            "XCkYypE3SvNDn2Q82",
            "sAAfsT6qKFdwMWr3A",
            "xQkvtH3GZypTLwnqE",
            "As8Fv5363XAobQF5Z",
            "zbTHkBmc86YMod6GX",
            "5vMudPmW9FRk2fA8j",
            "dCHbnikzvcbK9Jwbj",
            "3LExrsEgNiyyqmtcz",
            "LjEFLgEX4wP873QGN",
            "EWFS8RDiZWLF2ydMz",
            "ryg5NBu9EMLm67CSC",
            "5sn3KKoR9dSr9SKRp",
            "spFzqSSW8Y94He2DY",
            "TR5sRxt8Y4BDp8Thn",
            "smWxbKAe9C9r83fyT",
            "WqXRpzBXGSzY9pvPh",
            "bEDARBJuoYRRHRsr7",
            "kaWJq7rfTznhB96q4",
            "qSe2HbxF2bxi9imw4",
            "XdxpEDuSgPsb7ciyr",
            "Wjky6Tgx94o3Bvzip",
            "PtRFLG6eD3ASvbMst",
            "fpCJyEQgsED4hk4zg",
            "WtRtijzkRzKGAq7x9",
            "DPExn446SFTWPQ7is",
            "gDpjFPPGZo9DJccQA",
            "4CimxbCabLYKpGhp4",
            "9iNeaLzTiFxoqf3GG",
            "tkdWiQMaKJxLpM5Xi",
            "r8sHrmDtJwgksmDDy",
            "qQgbtfXpiYgS842QJ",
            "Nijjcj7Wc97HGd62y",
            "woWnyapa3jkCfxxtK",
            "NM67hr7fqeo27LLtf",
            "xWfXs5jAuvFsZubmd",
            "G9g4n5qpqM8ouDyD4",
            "QHLDeswAzuYhnCmtT",
            "2tD9a8fzZQFMuFdwm",
            "9tsuzdLmAZcicrLri",
            "pfSpLCFr7Cwqy93yr",
            "ZvqPW8rYi6BDffEPq",
            "Zd5bcAHcfcdGZu6Ze",
            "6Qotp5WF8J3qz5zw4",
            "yrezDWQLycMGmLw6t",
            "6emKcizs5X9Qyvnu6",
            "NNS9yNiSSLDE6Wyrb",
            "WTtzRHmFSfeWtZ5Fs",
            "2ogQzjXNWyA2wHFmX",
            "f5QstqnCLMAFdr9qZ",
            "HLYhrkFtRhLHpFYhB",
            "6BppSmM3fKhubEazf",
            "w4mbaEY2J5BCpW5z4",
            "uDegHq4pvBN7rEukM",
            "vsc2zJ2xfm3xTPH33",
            "uiKSWeEJBi4LLuvc2",
            "DK6iS4MnJhNmAK3PB",
            "4J4oLYqJJDgWwWwpT",
            "HBTPYwkn82ExgazXy",
            "knu4kYPgbyCN4fsNP",
            "4Dk87fdDuETfEQW7k",
            "kK7WHpYFoAun9HxGJ",
            "wtQYHMPW4j73b7qQ5",
            "FoJybu99LC3KKscnv",
            "ysNPFKukc3tygqyYr",
            "qk3BJvJmjsBsFhpZE",
            "4SxxbgAygdDp9oh4H",
            "scfd8ArbR5TxQCTLQ",
            "792FrBtci8NsDTZcD",
            "RFz7oNRRqXbEeFZnL",
            "dH5M3xGfTdiHNCTiE",
            "ytx7CcZ4ceRz3EAWd",
            "fs3TdRfhqAamZs48P",
            "yoR3ofCgNQRgaCg24",
            "LQQCeMZdwS6ShqizJ",
            "aodsLE5KvFHRmW4u4",
            "Fx6w3cWHFvgpzDybz",
            "Xm939jECjyHEyisKZ",
            "uavLatxoyzX58n6nt",
            "Cw3JCmhXDeex2cQwt",
            "3XrrTdMfepsMkyFe9",
            "hh4PG7hhPkcvmAFxf",
            "c4ceHioGQWPwnGzRJ",
            "tFFtnnr8pdWEyTXvE",
            "JHu3R3iiRJ3X9e7CF",
            "rkwweyRttt868ZTjX",
            "uGcLJ5tkTB9ovK6Ea",
            "DAhYoYe8n2fQRsxBq",
            "T7bocvxq9kJqNEv8y",
            "tdQae8fwQSPpwxA74",
            "xT5ZPoYaLxWBSdkBd",
            "T9oQNWwjAtDvrKcwE",
            "BwhyeQtad3ts8vYJ5",
            "epN25gWZmQkHbpufg",
            "saQfuDEd8w7YGCJpr",
            "bQPNx4LPApoYNhett",
            "ZZoyLgErHB6LAbixC",
            "K2Le2yFimNRBm4cP4",
            "jLGP7PS3m6aLSgSTD",
            "wowhmwP3D83zbAoAT",
            "Dyk8tcMR4CcjfWdPT",
            "kPxzupTHEL7Qiup5L",
            "ZciRs7Yj7MYYPpqny",
            "4kS2E5jEeXDrcSy3M",
            "akTdkJxZAYcv2Wb4b",
            "WusJJYYZ3YiALthBx",
            "YW2K9DJQc62nEtPx5",
            "8Grm2vejX6ncRHbhY",
            "kmQdDu8cY47cu5Y4v",
            "KSdTbPDupsL6tHf3S",
            "ouMepznwBR5cN2use",
          ],
          name: "BÃTIMENT_1_OUEST",
          objectName: "63ff79abe91a7aaf7c4c6e9e_a50",
          pumps: {
            command: 4,
            commandAutomateP1: 0,
            commandAutomateP2: 0,
            defectP1: false,
            defectP2: false,
            id: "64deff399a3d73535986cfd6",
            stateP1: 0,
            stateP2: 0,
          },
          reducedTemperatureAbs: 5,
          setpointTemperature: 37.9,
          setpointTemperatureOnOccupation: 21,
          startingTemperature: 21.5,
          threeWaysValve: {
            command: 1,
            forcing: 15,
            id: "64deff399a3d73535986cfe0",
            opening: 0,
          },
          type: "radiator",
        },
        {
          ambientSensorIds: ["6DPGaWCRifxKcbcSk"],
          ambientTemperature: 21.4,
          anticipationMax: 360,
          heatingCurves: [
            {
              con: 80,
              ext: -10,
              id: "64deff399a3d73535986cff3",
            },
            {
              con: 60,
              ext: 0,
              id: "64deff399a3d73535986cff4",
            },
            {
              con: 50,
              ext: 10,
              id: "64deff399a3d73535986cff5",
            },
            {
              con: 40,
              ext: 20,
              id: "64deff399a3d73535986cff6",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986cff7",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986cff8",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986cff9",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986cffa",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986cffb",
            },
          ],
          heatingSlope: 30,
          id: "64deff399a3d73535986cff2",
          idValves: [
            "6DPGaWCRifxKcbcSk",
            "NNTaNyGiJfvuLxAXe",
            "XMDrn5HgnDTaWP2A6",
            "gj4Ef5bfg6X7qbzfQ",
            "jrJXa9Zw3SmXcaZAm",
            "3NWTSo2YDgpbpbAch",
            "NwJ5fhRtZv4fsCfSw",
            "Mwyc4fRQEvWgYg7CS",
            "ppRqzMxgbCjzgHYdK",
            "9ARSvs7RF8yfoNpJC",
            "Q6jK9ZragMJTziXD8",
            "knTYNa9oHt4pJxFFy",
            "gZNANMCC2TJHgdwGQ",
            "XBWdySYe9Zbr8eqLN",
            "RcEwqBgstBt8rv2Hk",
            "8XX7sFnHBd3GT4qwt",
            "XPsCnngb6bo8cL3Hu",
            "ALugFjWQvYbJCh2sP",
            "bJGTHiwk7QBfDjrNg",
            "prvG7BT7Rmj2XY8E6",
            "EM97zYc78zNreR2RD",
            "TaDGdQyanJyuvwtAH",
            "WqaMfrQxwsAYtsqPg",
            "xnEtX9fbaT9uHfEKo",
            "snwB9fxPNH4RTWPpv",
            "t43mk6w5AQpHYPNyZ",
            "mdxkq3mxkDYu2FFiM",
            "XrcWcKMEKoqPSfyFu",
            "4dZvBKiqfrxfYwiYK",
            "W9boF4zZd9ri3SnfW",
            "fp5awiLg5kxMagQmw",
            "YoZhMAe9tqp6EztqP",
            "m59wXs9PTqdfpkGbA",
            "mBTRDup5RPMYQDSyk",
            "W5adJSYXct7tCQqgt",
            "3YSE6Kf7KMg3JXQ5R",
            "dHaXoJLpwTSShWFfg",
            "qYr5SHLSKacPQSrMh",
            "jWGfKGuEaYm2WceKz",
            "pmimNLXmXoZFY3T25",
            "9z2y999NgNRLfLLbg",
            "tsjvoGcJhCLTdRbgp",
            "XDFDp99RHhcpjb4DL",
            "zGTrDzRpjdaDNAi8A",
            "8zSYFi3NsMKpwko8Q",
            "yaRMscKSDMBEoNLCS",
            "JgSYd7dqSxx7G8GMx",
            "3Pfj244ykYcko5oKs",
            "HFug8g29r8rkKouZf",
            "K2sbKA9qbCy4ybNGz",
            "xoZ22m9YEdADPv968",
            "NoS5d49svu3ZKamHt",
            "qkprJ2epm5Jd7yF7F",
            "4MYNpNWewmf598uEt",
            "5oLJMiZQqC9tHdFrC",
            "fNLCFgGyrvWAMP4pM",
            "uH8f83bmF2Z6rdikJ",
            "DQ2JGhjwBoRTeWZdo",
            "oKwTYBrde9pLEmYKJ",
            "mFQGCdfmLwoa93tE4",
            "Mr4owrM8ndCobuyNk",
            "desGeb29EE5cHKjRj",
            "gTuqwAoYtAxLgic2G",
            "XegMG9k2txM6JXs9b",
            "kZWqWbMFGX3KFCKBs",
            "9xBi9LuAdeh5z7ZBt",
            "fWujdp3HkEcoAyCp6",
            "ZTRhe6KRp4P7gYyt9",
            "znhYvsvKMu3bsdCjh",
            "stTLh2TEN6G5r6X6j",
            "Pf4A67HKT7Hnr9DYL",
            "7LRe8a38Kc8cP9Eqz",
            "6F92Xdrg428Zf5hdT",
            "nEetbQNunJa5YJX5X",
            "qWQT6w6EzovfgJSev",
            "CswboYdHM9CPD7F7p",
            "uYJYbTyNiQapWQmEv",
            "A266XyuT6zTZBE9wY",
            "RDJYPqcJGGeb98w6q",
            "nrMLxj65EZbn94NxN",
            "7FXRiwNACcDu95Dm7",
            "red5cqWig2BniPfzH",
            "Jqhi3EiMvkWXvv45e",
            "mq7YqWuRhzPQivJPq",
            "MPhbNuLYhh5tS9jS8",
          ],
          name: "BÃTIMENT_1_SUD",
          objectName: "63ff79abe91a7aaf7c4c6e9e_a87",
          pumps: {
            command: 4,
            commandAutomateP1: 0,
            commandAutomateP2: 0,
            defectP1: true,
            defectP2: true,
            id: "64deff399a3d73535986cfd7",
            stateP1: 0,
            stateP2: 0,
          },
          reducedTemperatureAbs: 5,
          setpointTemperature: 37.9,
          setpointTemperatureOnOccupation: 21,
          startingTemperature: 21.1,
          threeWaysValve: {
            command: 1,
            forcing: 15,
            id: "64deff399a3d73535986cfe1",
            opening: 0,
          },
          type: "radiator",
        },
        {
          ambientSensorIds: [
            "XMDrn5HgnDTaWP2A6",
            "gj4Ef5bfg6X7qbzfQ",
            "jrJXa9Zw3SmXcaZAm",
            "3NWTSo2YDgpbpbAch",
            "NwJ5fhRtZv4fsCfSw",
            "Mwyc4fRQEvWgYg7CS",
          ],
          ambientTemperature: 21.3,
          anticipationMax: 360,
          heatingCurves: [
            {
              con: 80,
              ext: -10,
              id: "64deff399a3d73535986cffd",
            },
            {
              con: 60,
              ext: 0,
              id: "64deff399a3d73535986cffe",
            },
            {
              con: 50,
              ext: 10,
              id: "64deff399a3d73535986cfff",
            },
            {
              con: 40,
              ext: 20,
              id: "64deff399a3d73535986d000",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d001",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d002",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d003",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d004",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d005",
            },
          ],
          heatingSlope: 30,
          id: "64deff399a3d73535986cffc",
          idValves: [
            "XMDrn5HgnDTaWP2A6",
            "gj4Ef5bfg6X7qbzfQ",
            "jrJXa9Zw3SmXcaZAm",
            "3NWTSo2YDgpbpbAch",
            "NwJ5fhRtZv4fsCfSw",
            "Mwyc4fRQEvWgYg7CS",
            "Hi4LLWmKtj4v6d4zy",
            "m4t9SYQGCFw9pmb2H",
            "zgq7rwJBGQQ7pivS4",
            "TnPSYTLkD9kPCoCbk",
            "aT69Tsh4Gbh5dEJc4",
            "MYDHRknaDqF5hpH8B",
            "MMZMuQ8K6AbB8t7GD",
            "a38uaWEcEDycfpAdd",
            "pKPo8i3mQjteQSg6p",
            "HGfx9RrkkAi4oYh4p",
            "LKZwtfEC7pf9TrtW2",
            "DMQxbt7wDsgom9DrK",
            "gMQP4ZBK6BJcZjGeP",
            "3YSE6Kf7KMg3JXQ5R",
            "dHaXoJLpwTSShWFfg",
            "qYr5SHLSKacPQSrMh",
            "jWGfKGuEaYm2WceKz",
            "pmimNLXmXoZFY3T25",
            "9z2y999NgNRLfLLbg",
            "tsjvoGcJhCLTdRbgp",
            "XDFDp99RHhcpjb4DL",
            "zGTrDzRpjdaDNAi8A",
            "8zSYFi3NsMKpwko8Q",
            "yaRMscKSDMBEoNLCS",
            "JgSYd7dqSxx7G8GMx",
            "3Pfj244ykYcko5oKs",
            "HFug8g29r8rkKouZf",
            "K2sbKA9qbCy4ybNGz",
            "xoZ22m9YEdADPv968",
            "NoS5d49svu3ZKamHt",
            "qkprJ2epm5Jd7yF7F",
            "4MYNpNWewmf598uEt",
            "5oLJMiZQqC9tHdFrC",
            "fNLCFgGyrvWAMP4pM",
            "uH8f83bmF2Z6rdikJ",
            "DQ2JGhjwBoRTeWZdo",
            "oKwTYBrde9pLEmYKJ",
            "mFQGCdfmLwoa93tE4",
            "Mr4owrM8ndCobuyNk",
            "desGeb29EE5cHKjRj",
            "gTuqwAoYtAxLgic2G",
            "XegMG9k2txM6JXs9b",
            "kZWqWbMFGX3KFCKBs",
            "9xBi9LuAdeh5z7ZBt",
            "fWujdp3HkEcoAyCp6",
            "ZTRhe6KRp4P7gYyt9",
            "znhYvsvKMu3bsdCjh",
            "stTLh2TEN6G5r6X6j",
            "Pf4A67HKT7Hnr9DYL",
            "7LRe8a38Kc8cP9Eqz",
            "6F92Xdrg428Zf5hdT",
            "nEetbQNunJa5YJX5X",
            "qWQT6w6EzovfgJSev",
            "CswboYdHM9CPD7F7p",
            "uYJYbTyNiQapWQmEv",
            "A266XyuT6zTZBE9wY",
            "RDJYPqcJGGeb98w6q",
            "nrMLxj65EZbn94NxN",
            "7FXRiwNACcDu95Dm7",
          ],
          name: "BÃTIMENT_1_OUEST_QUAI_ST_CYR",
          objectName: "63ff79abe91a7aaf7c4c6e9e_a124",
          pumps: {
            command: 4,
            commandAutomateP1: 0,
            commandAutomateP2: 0,
            defectP1: true,
            defectP2: true,
            id: "64deff399a3d73535986cfd8",
            stateP1: 0,
            stateP2: 0,
          },
          reducedTemperatureAbs: 5,
          setpointTemperature: 37.9,
          setpointTemperatureOnOccupation: 21,
          startingTemperature: 21.6,
          threeWaysValve: {
            command: 1,
            forcing: 15,
            id: "64deff399a3d73535986cfe2",
            opening: 0,
          },
          type: "radiator",
        },
        {
          ambientSensorIds: ["F7hTWCkkH3gFsx7xm"],
          ambientTemperature: 21.7,
          anticipationMax: 360,
          heatingCurves: [
            {
              con: 80,
              ext: -10,
              id: "64deff399a3d73535986d007",
            },
            {
              con: 60,
              ext: 0,
              id: "64deff399a3d73535986d008",
            },
            {
              con: 50,
              ext: 10,
              id: "64deff399a3d73535986d009",
            },
            {
              con: 40,
              ext: 20,
              id: "64deff399a3d73535986d00a",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d00b",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d00c",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d00d",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d00e",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d00f",
            },
          ],
          heatingSlope: 30,
          id: "64deff399a3d73535986d006",
          idValves: [
            "F7hTWCkkH3gFsx7xm",
            "MKrJkHnXomSeMgCLo",
            "2JwQBNXJxoxnCxm4F",
            "RyBf74oXbvMmrfoJj",
            "oBnymECBPaTiRLvrC",
            "abHY2MKk9MLunNJQH",
            "HMPi44LZL9eC6Tvih",
            "5EmhMfgEcvwta3eHh",
            "5bkQcJ92obxBZjneP",
            "6gHR9rL5EhLFfMApv",
            "8swxHgu2N3NyFhgyS",
            "3HA76H2n6WkZKHXsb",
            "pw9B3W9kacRa2Jkky",
            "2gm3BRn5sviEykWsJ",
            "ox3E3xtgTYJKZ6Xjt",
            "moEhH7L8tZDzeazft",
            "qvDCEs28mR4ZT8BdM",
            "9GrxpKgr2S4CHWshq",
            "CJyhfmKfdC6nocWpk",
            "cbCP4qAEjxkfT3sjh",
            "Gs2gPKbxSxmJHxmGj",
            "LZfoDpuBvkSTFwigC",
            "xGXoDDZbQjNNE7Q6z",
            "CKXsphjdZJWMoXo8a",
            "Zx2D82o2RPeeinhqZ",
            "YsoKuHsSkWbSX33kk",
            "QsXmgHwuq3bDLtAg8",
            "9depfyTFoK44pX2Ep",
            "fHQFyeTfJ6oe7MMfn",
            "XMnsgB2mjZxnW9cRy",
            "NBwtwyZkgvF7B8KoJ",
            "QB8bvdeSv56NDDQ9F",
            "zEF7QJ4kJiZPsAeto",
            "nXnmNnX7faBzf9Hg6",
            "XfELhYe4TQPytntEa",
            "qZA92bo3KDuNwgDiM",
            "4n8s7YPX3uhtwYDCj",
            "sYHryjdXHCpqtRCK6",
            "sbX6JQJM3m2KrY7dg",
            "tc4oa5SL6QZyeoxMS",
            "wAuo9o4aZsoDewEWu",
            "Tg9NToSyZDMAk8u8s",
            "9sHbqqhtpHDa7FnGf",
            "wYoBz9DjxZQ9SZx2i",
            "oWT8ABnQCXZbxSNJR",
            "WYcdRsicBtPqHEybM",
            "kEMRxdtoCRF2BCAf9",
            "ho69nxAg7wFHJvQPD",
            "qjEi7WC9P76GjEy4Y",
            "GQjZZw2JuqbeFhJxx",
            "Qvp3gNuH4SH4jFiBi",
            "vANEw5TbQijA6ykN2",
            "itBaigAEdqLW5BQ7y",
            "XFLgEDcwYcZjbJSW4",
            "gTYLGivCmkwM2h5oe",
            "BpQDkZDDs8iWAHtd2",
            "qj5RNJHc6PDJRbmHv",
            "2CYZecLDTsfvyCEFg",
            "P2eB3QDDjCmmhrW37",
            "GKCAAtZ36v2PrPYLq",
            "HNEHBMcuaRG3eXywv",
            "hjQannhYtAkPuAmJJ",
            "GBqrEiFuHgaCZD66A",
            "sdNYQPeXZGdBj7Yxo",
            "G9g4n5qpqM8ouDyD4",
            "QHLDeswAzuYhnCmtT",
            "2tD9a8fzZQFMuFdwm",
            "9tsuzdLmAZcicrLri",
            "pfSpLCFr7Cwqy93yr",
            "ZvqPW8rYi6BDffEPq",
            "Zd5bcAHcfcdGZu6Ze",
            "6Qotp5WF8J3qz5zw4",
            "yrezDWQLycMGmLw6t",
            "6emKcizs5X9Qyvnu6",
            "NNS9yNiSSLDE6Wyrb",
            "WTtzRHmFSfeWtZ5Fs",
            "2ogQzjXNWyA2wHFmX",
            "f5QstqnCLMAFdr9qZ",
            "HLYhrkFtRhLHpFYhB",
            "6BppSmM3fKhubEazf",
            "w4mbaEY2J5BCpW5z4",
            "ZXQ47DMTxwgQtm2dY",
            "dDGFDmchtRR5yX57f",
            "aCEkHHhFWFokG88Ct",
            "SABbLB4bjZfvjehjb",
            "fCeBkigwfHTQvaAuX",
            "JJjMdKWpqvFSAZeoQ",
            "wxQ4hh2AJ2jHFeEAe",
            "nyxrWrYrurWtZQYgY",
            "KCtk9bcuYHw9ZE7xf",
            "iwcYCPXvrsRoEJB6Y",
            "89JAmLvGQQPnHHYQm",
            "FoJybu99LC3KKscnv",
            "ysNPFKukc3tygqyYr",
            "qk3BJvJmjsBsFhpZE",
            "4SxxbgAygdDp9oh4H",
            "scfd8ArbR5TxQCTLQ",
            "792FrBtci8NsDTZcD",
            "RFz7oNRRqXbEeFZnL",
            "dH5M3xGfTdiHNCTiE",
            "ytx7CcZ4ceRz3EAWd",
            "fs3TdRfhqAamZs48P",
            "yoR3ofCgNQRgaCg24",
            "LQQCeMZdwS6ShqizJ",
            "aodsLE5KvFHRmW4u4",
            "Fx6w3cWHFvgpzDybz",
            "Xm939jECjyHEyisKZ",
            "uavLatxoyzX58n6nt",
            "Cw3JCmhXDeex2cQwt",
            "3XrrTdMfepsMkyFe9",
            "LnQ833ombNQKwuWJn",
            "SmGmjsf8vaTzwYQ3j",
            "tFFtnnr8pdWEyTXvE",
            "JHu3R3iiRJ3X9e7CF",
            "rkwweyRttt868ZTjX",
            "uGcLJ5tkTB9ovK6Ea",
            "DAhYoYe8n2fQRsxBq",
            "T7bocvxq9kJqNEv8y",
            "tdQae8fwQSPpwxA74",
            "xT5ZPoYaLxWBSdkBd",
            "T9oQNWwjAtDvrKcwE",
            "BwhyeQtad3ts8vYJ5",
            "red5cqWig2BniPfzH",
            "Jqhi3EiMvkWXvv45e",
            "mq7YqWuRhzPQivJPq",
            "MPhbNuLYhh5tS9jS8",
            "qoy2GqCc6AAnFh5nD",
            "AD6Ltuxze2SRjGQaK",
            "ptJHDYRjiM3Jmai7M",
            "EFsS93TD7pTp4bN3y",
            "dwbdMHCFhYFKNqPsF",
            "czqe9D6eG2BDfg5Db",
            "tL7eriC4GFrbR9Dnn",
            "4CEXYHiHeSYsCHGvu",
            "3RyX6qspPLjyB2wT2",
            "6cxxc3Pr53Bbb3NwK",
            "2HJfNuwY9GAeRx9tp",
            "erPfGAC9CSeGgmxXR",
            "zSdhrPuDypwpGxdkW",
            "9NreAExotSM6AEjoD",
            "iietzTMNpd8RCJJYL",
            "5txDG4nPMuJyaNETu",
            "KK6WufDgzbi9TKcur",
            "itJYbsjfn5vSzqx5P",
            "FWCGWaYq9PufCJang",
            "qAQHohRkNsvWWAC4t",
            "YMJJjKmoFHvNdryMu",
            "vfpoziGon2Yj2JbNS",
            "qP8chbN7MQc4aJwca",
          ],
          name: "BÃTIMENT_1_EST",
          objectName: "63ff79abe91a7aaf7c4c6e9e_a161",
          pumps: {
            command: 4,
            commandAutomateP1: 0,
            commandAutomateP2: 0,
            defectP1: true,
            defectP2: true,
            id: "64deff399a3d73535986cfd9",
            stateP1: 0,
            stateP2: 0,
          },
          reducedTemperatureAbs: 5,
          setpointTemperature: 37.9,
          setpointTemperatureOnOccupation: 21,
          startingTemperature: 21.6,
          threeWaysValve: {
            command: 1,
            forcing: 15,
            id: "64deff399a3d73535986cfe3",
            opening: 0,
          },
          type: "radiator",
        },
        {
          ambientSensorIds: ["8p9LQp3kniWDC8cqR"],
          ambientTemperature: 21.1,
          anticipationMax: 360,
          heatingCurves: [
            {
              con: 80,
              ext: -10,
              id: "64deff399a3d73535986d011",
            },
            {
              con: 60,
              ext: 0,
              id: "64deff399a3d73535986d012",
            },
            {
              con: 50,
              ext: 10,
              id: "64deff399a3d73535986d013",
            },
            {
              con: 40,
              ext: 20,
              id: "64deff399a3d73535986d014",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d015",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d016",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d017",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d018",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d019",
            },
          ],
          heatingSlope: 30,
          id: "64deff399a3d73535986d010",
          idValves: [
            "8p9LQp3kniWDC8cqR",
            "6wa6TTucrxFf8qCpH",
            "8Q7nJQa9rApaJwJ2F",
            "3CBc4zfCKfGbm9v2f",
            "7gkpYXQ4Xgj9TLWZr",
            "YXDXArqYr6jGn5Q6s",
            "ckPiYaecXXQ4AEzek",
            "WRLfdZ4nyiHW89rnX",
            "FmNtPgwh53tBa6qYr",
            "gXbdTEHBCNYotQ2Eh",
            "7NSLKTBGppZAKojsy",
            "g6MgBTKGgKasGLfGW",
            "sQdBBGC9BYdmv5Qdd",
            "82RARScwZgdPfyhN5",
            "Xsdv4GqQQZTQv2rhH",
            "GkYigPkcSAf9xeEzy",
            "vo2jCKzxyv5w3KLRa",
            "GkckwJxzYxuKZ8ADf",
            "ckfzaLQTQLE7MChWm",
            "Aq9zPexNuXPARCN2X",
            "nq2R4SRC3JSxqgPDu",
            "XwoY8t67izCEEr9bj",
            "FZbjWPAjPxiifxy4z",
            "a5b8NRW7C5pANEinp",
            "3TrR94MRs7HLk4Jaq",
            "4ywfofaTsrasxAHSW",
            "BwbRtzgYApnEXPQMQ",
            "yEkz9gNtxxQKeh8gX",
            "sZ2TFZN2e7r2YzBY4",
            "bkNMztazsKZQqqLEf",
            "JMmXtBjJQ89BoraaW",
            "74nkKPQaPmAdgTQFF",
            "oebkvByTxJegq5ZAw",
            "LQmNW7DPY3ugzPHuo",
            "heqsTgnzbeyyfTCkH",
            "ayq9kaxXXhjj2nM3h",
            "rDrzMbxLC6RhkG2LC",
            "4QgKyPFdNdPAPmSbh",
            "QfLaRqYQLyZvjJSjB",
            "GBFHFqtGKX7D23KvC",
            "xvZpG4fojyc5NNog4",
            "yuhrRPd5CkEASfJ8j",
            "gWGcpYbDGxrvt8Txp",
            "AuiLGf8J7eNDGF7b3",
            "kkTK3amrTygy7w8ud",
            "AGMtEb3xMmfCMaFKQ",
            "ew3mRfKi73W6Zy9WR",
            "CxGzD4kLiAXbJeJ8M",
            "hm8SwPxth4datxweW",
            "dXx7AQ7t7taw64krJ",
            "ginwDFW8jLoE5mngG",
            "xuxATf6PYmPn8qPNz",
            "a42L8CZpru9cudeaG",
            "QthRMrdDQvm29aCnR",
            "4f4XayZbdjQ45a7CW",
            "HqrLgMMcAfqSFrGGR",
            "mHkBGz5dioNPWvz7M",
            "GcgghtBtrfYdKycN3",
            "jvKdRrDesAmT5aQdw",
            "GoijFbuWpXL33rQHm",
            "ajcuEKdezcZW2X7FZ",
            "a4qoPrPNfDsk3SvDh",
            "JakfPyYdXsgcpyfNa",
            "WPFXgcLfQiKPWSwux",
            "DkrEPBmgfngrSC6eo",
            "SsKNEpsvJiXFQgqii",
            "xDpQ8A5XjT6azdcya",
            "fKTJJexKoYfPZuRpE",
            "QSgcbZGwn8QDhYjMN",
            "GhmgMZHZzkZjXp7wp",
            "pTBw7ivyfm6uPGzm8",
            "XbtfLFaSnBnLABYN7",
            "LHaRh7YdXqzAHC5xd",
          ],
          name: "BÃTIMENT_2_EST",
          objectName: "63ff79abe91a7aaf7c4c6e9e_a198",
          pumps: {
            command: 4,
            commandAutomateP1: 0,
            commandAutomateP2: 0,
            defectP1: true,
            defectP2: true,
            id: "64deff399a3d73535986cfda",
            stateP1: 0,
            stateP2: 0,
          },
          reducedTemperatureAbs: 5,
          setpointTemperature: 37.9,
          setpointTemperatureOnOccupation: 21,
          startingTemperature: 21.8,
          threeWaysValve: {
            command: 1,
            forcing: 15,
            id: "64deff399a3d73535986cfe4",
            opening: 0,
          },
          type: "radiator",
        },
        {
          ambientSensorIds: ["ns8Qu3Gv4gfBDCAJm"],
          ambientTemperature: 24.8,
          anticipationMax: 360,
          heatingCurves: [
            {
              con: 80,
              ext: -10,
              id: "64deff399a3d73535986d01b",
            },
            {
              con: 60,
              ext: 0,
              id: "64deff399a3d73535986d01c",
            },
            {
              con: 50,
              ext: 10,
              id: "64deff399a3d73535986d01d",
            },
            {
              con: 40,
              ext: 20,
              id: "64deff399a3d73535986d01e",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d01f",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d020",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d021",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d022",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d023",
            },
          ],
          heatingSlope: 30,
          id: "64deff399a3d73535986d01a",
          idValves: ["ns8Qu3Gv4gfBDCAJm"],
          name: "BÃTIMENT_2_ARCHIVES",
          objectName: "63ff79abe91a7aaf7c4c6e9e_a235",
          pumps: {
            command: 4,
            commandAutomateP1: 0,
            commandAutomateP2: 0,
            defectP1: true,
            defectP2: true,
            id: "64deff399a3d73535986cfdb",
            stateP1: 0,
            stateP2: 0,
          },
          reducedTemperatureAbs: 5,
          setpointTemperature: 37.9,
          setpointTemperatureOnOccupation: 21,
          startingTemperature: 20.6,
          threeWaysValve: {
            command: 1,
            forcing: 15,
            id: "64deff399a3d73535986cfe5",
            opening: 0,
          },
          type: "radiator",
        },
        {
          ambientSensorIds: ["peauYED6WjCd5Ztbc"],
          ambientTemperature: 25,
          anticipationMax: 360,
          heatingCurves: [
            {
              con: 80,
              ext: -10,
              id: "64deff399a3d73535986d025",
            },
            {
              con: 60,
              ext: 0,
              id: "64deff399a3d73535986d026",
            },
            {
              con: 50,
              ext: 10,
              id: "64deff399a3d73535986d027",
            },
            {
              con: 40,
              ext: 20,
              id: "64deff399a3d73535986d028",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d029",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d02a",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d02b",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d02c",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d02d",
            },
          ],
          heatingSlope: 30,
          id: "64deff399a3d73535986d024",
          idValves: [
            "peauYED6WjCd5Ztbc",
            "fSeXCNbZmEgAR9bmm",
            "EZyRrjvN7deNegeqm",
            "YXDXArqYr6jGn5Q6s",
            "DmkpCu9zrsyf85a7R",
            "NkkD9BDKX7am8kEuH",
            "PtWPwd7iMDz5LLYqJ",
            "PZYgiA3QDHBz3Jn7D",
            "mqCD2z326Cy7SRCBg",
            "q8pTfutTFwdpArrmC",
          ],
          name: "BÃTIMENT_2_RESTO",
          objectName: "63ff79abe91a7aaf7c4c6e9e_a272",
          pumps: {
            command: 4,
            commandAutomateP1: 0,
            commandAutomateP2: 0,
            defectP1: true,
            defectP2: true,
            id: "64deff399a3d73535986cfdc",
            stateP1: 0,
            stateP2: 0,
          },
          reducedTemperatureAbs: 5,
          setpointTemperature: 37.9,
          setpointTemperatureOnOccupation: 21,
          startingTemperature: 21.5,
          threeWaysValve: {
            command: 1,
            forcing: 15,
            id: "64deff399a3d73535986cfe6",
            opening: 0,
          },
          type: "radiator",
        },
        {
          heatingCurves: [
            {
              con: 80,
              ext: -10,
              id: "64deff399a3d73535986d02f",
            },
            {
              con: 60,
              ext: 0,
              id: "64deff399a3d73535986d030",
            },
            {
              con: 50,
              ext: 10,
              id: "64deff399a3d73535986d031",
            },
            {
              con: 40,
              ext: 20,
              id: "64deff399a3d73535986d032",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d033",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d034",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d035",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d036",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d037",
            },
          ],
          id: "64deff399a3d73535986d02e",
          name: "BÃTIMENT_2_CTA",
          objectName: "63ff79abe91a7aaf7c4c6e9e_a309",
          pumps: {
            command: 4,
            commandAutomateP1: 0,
            commandAutomateP2: 0,
            defectP1: true,
            defectP2: true,
            id: "64deff399a3d73535986cfdd",
            stateP1: 0,
            stateP2: 0,
          },
          reducedTemperatureAbs: 5,
          setpointTemperature: 0,
          startingTemperature: 22.1,
          type: "centralAirHandlingUnit",
        },
        {
          ambientSensorIds: ["PGKhdttoe92CKNm2L"],
          ambientTemperature: 20.6,
          anticipationMax: 360,
          heatingCurves: [
            {
              con: 80,
              ext: -10,
              id: "64deff399a3d73535986d039",
            },
            {
              con: 60,
              ext: 0,
              id: "64deff399a3d73535986d03a",
            },
            {
              con: 50,
              ext: 10,
              id: "64deff399a3d73535986d03b",
            },
            {
              con: 40,
              ext: 20,
              id: "64deff399a3d73535986d03c",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d03d",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d03e",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d03f",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d040",
            },
            {
              con: 25,
              ext: 30,
              id: "64deff399a3d73535986d041",
            },
          ],
          heatingSlope: 14700,
          id: "64deff399a3d73535986d038",
          idValves: [
            "PGKhdttoe92CKNm2L",
            "46r9mNHmBbeQHKkBh",
            "FfktWo3tqozRKwgg9",
            "uo88cKXzmnZKSF2Fz",
            "3CBc4zfCKfGbm9v2f",
            "7gkpYXQ4Xgj9TLWZr",
            "oLaDYJYBjKWSZFnc5",
            "rogNDAYtvGN7aemjy",
            "jYjHzgkCmQ8rh42EG",
            "GssKCd9rGj2bq5JTJ",
            "SpniERgqXg583F5g9",
            "wK6QTQg2yETcw8yHn",
            "HAxQYjangjqdRZiQM",
            "8eZp7WXPvR8b6M8uJ",
            "YwqCN9CqHwEDsgP2d",
            "hH7QMLQSZbxPAhpGe",
            "9dxTpvbngdw3K83tQ",
            "jTxCnCreNwmnY8Qgy",
            "WeEJomRPuwQfnzfZ4",
            "rW5x3cL3ZNH4n3Xiu",
            "ckfzaLQTQLE7MChWm",
            "dSASZmckXBMpTW2cw",
            "34Hy4cLHWxCmBhcH2",
            "auz9TWg4SmCQ9FKk9",
            "XTDkdsPMJD2FfBrMZ",
            "TcBKKTfYAQEBGJ93i",
            "fkhQLHG7vXy7QDtWq",
            "w3jbGELCiap8aS7HG",
            "Rqjd2xq92TXWpWJPq",
            "waGHn9DhCJLQwW34H",
            "BwbRtzgYApnEXPQMQ",
            "yEkz9gNtxxQKeh8gX",
            "sZ2TFZN2e7r2YzBY4",
            "bkNMztazsKZQqqLEf",
            "JMmXtBjJQ89BoraaW",
            "74nkKPQaPmAdgTQFF",
            "28hFvMbAZXepDfAMD",
            "q7RHZqpKzx5zbd7L6",
            "CSEzbFBqZtYM2YpJo",
            "wm46fvsDt2cD9EHTj",
            "bKrFXhqScnZGfkziF",
            "EBkLZmTFJLuKu5ef9",
            "6d5tyH7WFeRagzfxS",
            "DBZsGLaWBjnBcRWoD",
            "4fXGoihRHghLMPZLN",
            "f4kH7SJw8GbJzjQiT",
            "CpGcegnFa6gpfAWWH",
            "gLteT3bbAzGn3oYZD",
            "FWPxWRgqaFqeFRKgW",
            "N28gWe6arKLzdPAwY",
            "TEfzPfozRAs32YDc2",
            "zvcTKz6bMbX4cwKPd",
            "iCAHpeSwpFpWcpvCj",
            "ZRPeHbnNKGiLPXm5D",
            "ew3mRfKi73W6Zy9WR",
            "CxGzD4kLiAXbJeJ8M",
            "hm8SwPxth4datxweW",
            "dXx7AQ7t7taw64krJ",
            "ginwDFW8jLoE5mngG",
            "xuxATf6PYmPn8qPNz",
            "a42L8CZpru9cudeaG",
            "QthRMrdDQvm29aCnR",
            "4f4XayZbdjQ45a7CW",
            "HqrLgMMcAfqSFrGGR",
            "mHkBGz5dioNPWvz7M",
            "GcgghtBtrfYdKycN3",
            "jvKdRrDesAmT5aQdw",
            "GoijFbuWpXL33rQHm",
            "ajcuEKdezcZW2X7FZ",
            "uaEEdhmPpFSjazfiC",
            "9JZ3nnMQoFiJrSbzJ",
            "5b8hZmsuXftXbRpTc",
            "eEsPQZNuj8u6EM9Ho",
            "J2BcESW2YSzQvSs5B",
            "Xu38YeZTjwuSR2uZY",
            "8E2tsqaWwCiF5CyQE",
            "t4mLpSZaEJzSAQjd9",
            "tP9kQ3msErPpkAQWh",
            "TYiuFQMopsWsugHGa",
            "kvGtWPS5tF3v8GDWd",
            "n738kF6dZiQbdEKCy",
          ],
          name: "BÃTIMENT_2_OUEST",
          objectName: "63ff79abe91a7aaf7c4c6e9e_a346",
          pumps: {
            command: 4,
            commandAutomateP1: 1,
            commandAutomateP2: 0,
            defectP1: true,
            defectP2: true,
            id: "64deff399a3d73535986cfde",
            stateP1: 0,
            stateP2: 0,
          },
          reducedTemperatureAbs: 5,
          setpointTemperature: 37.9,
          setpointTemperatureOnOccupation: 21,
          startingTemperature: 20.5,
          threeWaysValve: {
            command: 1,
            forcing: 15,
            id: "64deff399a3d73535986cfe7",
            opening: 0,
          },
          type: "radiator",
        },
        {
          id: "64deff399a3d73535986d042",
          name: "ECS",
          objectName: "63ff79abe91a7aaf7c4c6e9e_a412",
          recyclePumpsECS: {
            command: 4,
            commandAutomateP1: 0,
            commandAutomateP2: 0,
            defectP2: false,
            id: "64deff399a3d73535986cfdf",
            stateP1: 0,
          },
          startingTemperature: 58.6,
          tankSanitaryHotWaterTemperature: 0,
          type: "sanitaryHotWater",
        },
      ],
      heatingTemperature: 16,
      id: "64deff399a3d73535986d043",
      name: "Chaufferie",
      noHeatingTemperature: 20,
      permutationDay: 4,
      permutationHour: 14,
      reducedFFTemperature: 15,
      returnTemperature: 20.4,
      setpointStartFrostFree: 4,
      startingTemperature: 22.2,
      summerModeState: 0,
      temporisationFrostFreeMode: 4,
      temporisationSummerMode: 8,
      waterDefect: true,
    },
  ],
  date: "2023-09-25T09:23:26.000Z",
};

export const meshs: MeshsMessageMeshsInner[] = [
  {
    name: "mesh",
    id: "6kEiW9bYWw59NaMmd",
  },
  {
    name: "test1",
    id: "r3KajAt94MgqpjksS",
  },
  {
    name: "test2",
    id: "CQKTH6cbEbvdGEqrm",
  },
  {
    name: "test3",
    id: "3vyMrJTfJtZoow9sq",
  },
  {
    id: "3vyMrJTbvdGEqrm",
  },
];

export const batteryHistory: BatteryHistory200ResponseMessageInner[] = [
  {
    createdAt: "2023-01-18T14:07:48.721Z",
    macValve: "FCC23D00000C3C88",
    batZ: 6279,
  },
  {
    createdAt: "2023-01-19T14:07:50.264Z",
    macValve: "FCC23D00000C3C88",
    batZ: 6076,
  },
  {
    createdAt: "2023-01-17T14:07:39.229Z",
    macValve: "FCC23D00000C3C88",
    batZ: 6264,
  },
  {
    createdAt: "2023-01-18T14:07:48.721Z",
    dateNewBat: "2023-01-18T14:07:48.721Z",
    macValve: "FCC23D00000C3C88",
    batZ: 1000,
  },
  {
    createdAt: "2023-01-19T14:07:50.264Z",
    batLowDate: "2023-01-19T14:07:50.264Z",
    macValve: "FCC23D00000C3C88",
    batZ: 6076,
  },
  {
    createdAt: "2023-01-17T14:07:39.229Z",
    batLowDate: "2023-01-17T14:07:39.229Z",
    macValve: "FCC23D00000C3C88",
    batZ: 6264,
  },
];
