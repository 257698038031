import type { ComponentProps, ElementRef } from "react";
import { forwardRef } from "react";

import { cx } from "class-variance-authority";
import { Command } from "cmdk";
import * as RPNInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import { ButtonV2, Dropdown } from "..";

import styles from "./PhoneInput.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Flag
 * -----------------------------------------------------------------------------------------------*/
const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
  const Flag = flags[country];

  return <span className={styles.flag}>{Flag && <Flag title={countryName} />}</span>;
};

/* -------------------------------------------------------------------------------------------------
 * ContrySelect
 * -----------------------------------------------------------------------------------------------*/
interface CountryEntry {
  label: string;
  value: RPNInput.Country | undefined;
}

interface CountrySelectProps {
  disabled?: boolean;
  value: RPNInput.Country;
  options: CountryEntry[];
  onChange: (country: RPNInput.Country) => void;
  noDataMessage?: string;
  placeholder?: string;
}

const CountrySelect = ({
  disabled,
  value: selectedCountry,
  options: countryList,
  noDataMessage,
  placeholder,
  onChange,
}: CountrySelectProps) => (
  <Dropdown.Root>
    <Dropdown.Trigger asChild>
      <ButtonV2 disabled={disabled}>
        <FlagComponent country={selectedCountry} countryName={selectedCountry} />
      </ButtonV2>
    </Dropdown.Trigger>
    <Dropdown.Content align="start" sideOffset={15}>
      <Command className={styles.dropwdown__command}>
        <Command.Input placeholder={placeholder} className={styles.dropwdown__input} />
        <Command.List className={styles.dropwdown__list}>
          {noDataMessage && <Command.Empty>{noDataMessage}</Command.Empty>}
          {countryList.map(({ value, label }) =>
            value ? (
              <Command.Item className={styles.dropwdown__item} onSelect={() => onChange(value)}>
                <FlagComponent country={value} countryName={label} />
                <span>{label}</span>
                <span>{`+${RPNInput.getCountryCallingCode(value)}`}</span>
              </Command.Item>
            ) : null,
          )}
        </Command.List>
      </Command>
    </Dropdown.Content>
  </Dropdown.Root>
);

/* -------------------------------------------------------------------------------------------------
 * Input
 * -----------------------------------------------------------------------------------------------*/
const InputComponent = forwardRef<HTMLInputElement, ComponentProps<"input">>(({ ...props }, ref) => (
  <input {...props} ref={ref} className={styles.input} />
));

/* -------------------------------------------------------------------------------------------------
 * PhoneInput
 * -----------------------------------------------------------------------------------------------*/
interface PhoneInputProps extends Omit<RPNInput.Props<typeof RPNInput.default>, "onChange"> {
  onChange?: (value: RPNInput.Value) => void;
  error?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = forwardRef<ElementRef<typeof RPNInput.default>, PhoneInputProps>(
  ({ className, error, onChange, ...props }, ref) => (
    <RPNInput.default
      {...props}
      ref={ref}
      className={cx(styles.phoneInput, error && styles.phoneInput_error, className)}
      flagComponent={FlagComponent}
      countrySelectComponent={CountrySelect}
      inputComponent={InputComponent}
      smartCaret={false}
      onChange={value => onChange?.(value ?? ("" as RPNInput.Value))}
    />
  ),
);
PhoneInput.displayName = "PhoneInput";

export { PhoneInput, FlagComponent };
export type { PhoneInputProps };
