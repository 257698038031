import { env } from "~/configuration";
import { useUserStore } from "~/stores";

import { GET_METHOD, POST_METHOD } from "../constants/fetchConstants";
import { internalFetchByUrl } from "../helpers/communication/FetchApi";
import { boilerRoomsPos, routeBoilerRooms, routeHouses } from "../routesStatic/routesExpress";

const ServerUrl = env.SERVER_URL;

export async function createBoilerRoom(br) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteAddBoilerRoom = ServerUrl + routeBoilerRooms;
  if (br !== undefined && br !== null && Object.keys(br).length !== 0) {
    fetchingResponse = internalFetchByUrl(RouteAddBoilerRoom, POST_METHOD, customHeaders, br);
  }
  return fetchingResponse;
}

export async function getBoilerRooms(idHouse) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteGetBoilerRoom = ServerUrl + routeHouses + "/" + idHouse + boilerRoomsPos;
  fetchingResponse = internalFetchByUrl(RouteGetBoilerRoom, GET_METHOD, customHeaders);
  return fetchingResponse;
}
