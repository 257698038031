import { useEffect, useRef, useState } from "react";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";

import type { Planning as PlanningType, PutPlanning } from "@eisox/backend_webapp_api";
import { ActionButton, Alert, Button, Steps } from "@eisox/design-system";
import { PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { RoomRoomType } from "@eisox/webapp-api-specification";

import { Week } from "~/UI/layouts";
import type { houseLoader } from "~/UI/screens";
import { getPlanningsOfRoom } from "~/api/planning";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToPlannings } from "~/routes/utils";
import { API } from "~/types/API";

import { RoomsSelectors } from "../../layouts";
import { PlanningSelectorDialog, TimeslotPopup } from "./components";

import styles from "./Planning.module.scss";

export interface PlanningSettingDto {
  roomIds: string[];
  plannings: PlanningType[];
}

export const Planning: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const planningStyle = bem("planning");
  const stepsStyle = bem("steps");
  const createStyle = bem("create");
  const selectStyle = bem("select");
  const applyStyle = bem("apply");

  const formRef = useRef<HTMLFormElement>(null);

  const { plans, rooms } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { houseId } = useParams() as { houseId: string };

  const { Form, state, submit } = useAction({
    onSuccess: () => {
      reset({}, { keepValues: true });
      toast(formatMessage({ id: "settings.content.menu.rooms.planning.success" }), { type: "success" });
    },
  });

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    watch,
    reset,
  } = useForm<PlanningSettingDto>({
    values: {
      roomIds: rooms.map(r => r.id!),
      plannings: [],
    },
  });
  const { fields, replace, remove } = useFieldArray({ control: control, name: "plannings" });

  const [copiedRoomId, setCopiedRoomId] = useState<string>();
  const [timeslotPopupOpen, setTimeslotPopupOpen] = useState<boolean>(false);
  const [importRoomDialogOpen, setImportRoomDialogOpen] = useState<boolean>(false);
  const [timeslotToUpdateId, setTimeslotToUpdateId] = useState<number | undefined>(undefined);

  const roomIds = watch("roomIds");

  useEffect(() => {
    copiedRoomId &&
      getPlanningsOfRoom(houseId, copiedRoomId).then((response: any) => {
        const userDefinedPlannings = response.result.message.plannings.filter((p: PlanningType) => p.userDefined);
        if (userDefinedPlannings.length > 0) {
          replace(userDefinedPlannings);
        } else {
          replace([]);
          toast(formatMessage({ id: "settings.content.menu.rooms.planning.create.noPlanning" }), { type: "info" });
        }
      });
  }, [copiedRoomId]);

  const onSubmit = (data: PlanningSettingDto) => {
    const body: PutPlanning = {
      ...data,
      plannings: [
        ...data.plannings.map(p => ({
          day: p.day,
          hourStart: p.hourStart,
          hourEnd: p.hourEnd,
          planningType: p.planningType,
        })),
      ],
    };
    submit(body, API.HTTP_METHOD.PUT, routeToPlannings(houseId));
  };

  return (
    <>
      <Form
        ref={formRef}
        className={planningStyle()}
        onSubmit={handleSubmit((data, event) => {
          event?.target === formRef.current && onSubmit(data);
        })}
      >
        <Steps className={stepsStyle()}>
          <Steps.Step
            title={
              <p>
                {formatMessage(
                  { id: "settings.content.menu.rooms.planning.create.title" },
                  {
                    b: (
                      <button
                        type="button"
                        className={createStyle("import")}
                        onClick={() => setImportRoomDialogOpen(true)}
                      >
                        {formatMessage({ id: "settings.content.menu.rooms.planning.create.import" })}
                      </button>
                    ),
                  },
                )}
              </p>
            }
          >
            <Button
              text={formatMessage({ id: "settings.content.menu.rooms.planning.create.add" })}
              icon={<PlusIcon style={{ width: 10, height: 10 }} />}
              onClick={() => setTimeslotPopupOpen(true)}
            />
          </Steps.Step>
          <Steps.Step title={formatMessage({ id: "settings.content.menu.rooms.planning.select.title" })}>
            <Controller
              control={control}
              name="roomIds"
              rules={{
                validate: v =>
                  v.length > 0 || formatMessage({ id: "settings.content.menu.rooms.planning.select.error" }),
              }}
              render={({ field: { onChange } }) => (
                <RoomsSelectors className={selectStyle("selectors")} onChange={onChange} />
              )}
            />
            {errors && <p className={selectStyle("error")}>{errors.roomIds?.message}</p>}
          </Steps.Step>
          <Steps.Step title={formatMessage({ id: "settings.content.menu.rooms.planning.apply.title" })}>
            <Alert severity="warning" className={applyStyle("alert")}>
              <p>{formatMessage({ id: "settings.content.menu.rooms.planning.apply.warning" })}</p>
            </Alert>
            <ActionButton
              type="submit"
              text={formatMessage({ id: "settings.content.menu.rooms.planning.apply.save" })}
              disabled={!isDirty || ["submitting", "loading"].includes(state)}
            />
          </Steps.Step>
        </Steps>
        <Week
          className={planningStyle("week")}
          plannings={fields.map((p, i) => ({
            ...p,
            planningType: p.planningType!,
            onClick: () => {
              setTimeslotToUpdateId(i);
              setTimeslotPopupOpen(true);
            },
          }))}
        />
      </Form>
      {timeslotPopupOpen && (
        <TimeslotPopup
          open={timeslotPopupOpen}
          roomType={
            roomIds.map(id => rooms.find(r => r.id === id)?.roomType).every(type => type === RoomRoomType.Room)
              ? RoomRoomType.Room
              : RoomRoomType.Office
          }
          onClose={() => {
            setTimeslotPopupOpen(false);
            timeslotToUpdateId !== undefined && setTimeslotToUpdateId(undefined);
          }}
          timeslot={timeslotToUpdateId !== undefined ? fields[timeslotToUpdateId] : undefined}
          timeslots={fields}
          replace={timeslots => {
            replace(timeslots as PlanningType[]);
            setTimeslotPopupOpen(false);
            timeslotToUpdateId !== undefined && setTimeslotToUpdateId(undefined);
          }}
          remove={timeslotIds => {
            remove(timeslotIds);
            setTimeslotPopupOpen(false);
            timeslotToUpdateId !== undefined && setTimeslotToUpdateId(undefined);
          }}
        />
      )}
      {importRoomDialogOpen && (
        <PlanningSelectorDialog
          open
          onOpenChange={() => setImportRoomDialogOpen(prev => !prev)}
          houseId={houseId}
          rooms={rooms}
          plans={plans}
          selectedRoomId={copiedRoomId}
          onSelectedRoomChange={roomId => setCopiedRoomId(roomId)}
        />
      )}
    </>
  );
};
