import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";
import type { HistovalvesStatsBody } from "../types";

const getHistoValveStats = async (body: HistovalvesStatsBody) => {
  const { data, error } = await client.POST("/histovalvesStats", { body });
  if (error) throw new Error(error.message);
  return data?.message ?? [];
};

export const valve = createQueryKeys("valve", {
  history: (body: HistovalvesStatsBody) => ({
    queryKey: [body],
    queryFn: () => getHistoValveStats(body),
  }),
});
