import type { Meteo2Inner } from "@eisox/backend_webapp_api";

import { env } from "~/configuration";
import { GET_METHOD } from "~/constants";
import { internalFetchByUrl } from "~/helpers/communication/FetchApi";
import type { FetchResponse } from "~/helpers/communication/fetchType";
import { version, weatherRoute } from "~/routesStatic/routesExpress";
import { useUserStore } from "~/stores";

const ServerUrl = env.SERVER_URL;

export const getWeather = async (houseId: string): Promise<FetchResponse<Meteo2Inner[]>> => {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const routeWeather = ServerUrl + version + weatherRoute + houseId;
  return internalFetchByUrl(routeWeather, GET_METHOD, customHeaders);
};
