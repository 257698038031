import { env } from "~/configuration";
import { GET_METHOD } from "~/constants/fetchConstants";
import { internalFetchByUrl } from "~/helpers/communication/FetchApi";
import { heatingNetworkRouteConcat, routeHouses } from "~/routesStatic/routesExpress";
import { useUserStore } from "~/stores";

const ServerUrl = env.SERVER_URL;

export async function getHeatingNetworks(houseId) {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const routeGetHeatingNetworks = ServerUrl + routeHouses + "/" + houseId + heatingNetworkRouteConcat;
  return internalFetchByUrl(routeGetHeatingNetworks, GET_METHOD, customHeaders);
}
