import type { ZodObject, ZodRawShape } from "zod";
import type { StoreApi, UseBoundStore } from "zustand";

import { z } from "@eisox/zod";

type WithSelectors<S> = S extends { getState: () => infer T } ? S & { use: { [K in keyof T]: () => T[K] } } : never;

/**
 * Enhances a Zustand store with selector hooks for each state property.
 *
 * @template S - The type of the Zustand store.
 * @param _store - The Zustand store to enhance with selectors.
 * @returns The enhanced store with selector hooks.
 */
const createSelectors = <S extends UseBoundStore<StoreApi<any>>>(_store: S): WithSelectors<S> => {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {} as any;

  for (const k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store(s => s[k as keyof typeof s]);
  }

  return store;
};

/**
 * Validates a given key and its value against a Zod schema. If the key is nested and the value is an object,
 * it recursively validates the nested keys and values. If the validation fails, it returns the initial value
 * for that key.
 *
 * @template T - The shape of the Zod schema.
 * @param {ZodObject<T>} schema - The Zod schema to validate against.
 * @param {z.infer<ZodObject<T>>} initialValues - The initial values to fall back on if validation fails.
 * @param {string} key - The key to validate.
 * @param {unknown} value - The value to validate.
 * @returns {unknown} - The validated value or the initial value if validation fails.
 */
const validateKeyWithSchema = <T extends ZodRawShape>(
  schema: ZodObject<T>,
  initialValues: z.infer<ZodObject<T>>,
  key: string,
  value: unknown,
): unknown => {
  const schemaForKey = schema.shape[key];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!schemaForKey || !(key in initialValues)) return value;

  if (schemaForKey instanceof z.ZodObject) {
    const nestedInitialValues = initialValues[key as keyof typeof initialValues] as object;
    const nestedValue = value;

    if (typeof nestedValue !== "object" || nestedValue === null) return nestedInitialValues;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return Object.entries(schemaForKey.shape).reduce<object>((acc, [nestedKey, nestedSchema]) => {
      const validatedValue = validateKeyWithSchema(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        nestedSchema instanceof z.ZodObject ? nestedSchema : schemaForKey,
        nestedInitialValues,
        nestedKey,
        nestedValue[nestedKey as keyof typeof nestedValue],
      );
      return { ...acc, [nestedKey]: validatedValue };
    }, {});
  }

  const parsed = schemaForKey.safeParse(value);
  return parsed.success ? parsed.data : initialValues[key as keyof typeof initialValues];
};

export { createSelectors, validateKeyWithSchema };
