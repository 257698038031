import { Navigate, createBrowserRouter } from "react-router-dom";

import type { QueryClient } from "@tanstack/react-query";

import { Error404 } from "~/UI/components";
import { route as authenticatedRoutes } from "~/app/(authenticated)/route";
import { route as publicRoutes } from "~/app/(public)/route";
import { Root } from "~/app/_root";

import { routeToHouses } from "./utils";

export const router = (queryClient: QueryClient) =>
  createBrowserRouter([
    {
      element: <Root />,
      errorElement: <Error404 />,
      children: [
        {
          path: "*",
          element: <Error404 />,
        },
        {
          path: "/",
          element: <Navigate to={routeToHouses()} />,
        },

        ...publicRoutes(queryClient),
        { ...authenticatedRoutes(queryClient) },
      ],
    },
  ]);
