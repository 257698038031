
import type { paths } from "@eisox/webapp-api-specification";

/* -------------------------------------------------------------------------------------------------
 * User
 * -----------------------------------------------------------------------------------------------*/
type UserPath = "/user/";

type UserResponseBody = Required<paths[UserPath]["get"]["responses"]["200"]["content"]["application/json"]>;

type UpdateUserBody = paths[UserPath]["patch"]["requestBody"]["content"]["application/json"];

/* -------------------------------------------------------------------------------------------------
 * Roles
 * -----------------------------------------------------------------------------------------------*/
type UserRole = NonNullable<
  NonNullable<paths["/user/roles/"]["get"]["responses"]["200"]["content"]["application/json"]["message"]>["roles"]
>;

/* -------------------------------------------------------------------------------------------------
 * EnrollAccount
 * -----------------------------------------------------------------------------------------------*/
type EnrollAccountPath = "/users/{token}/activateaccount";

type EnrollAccountRequestParams = Required<paths[EnrollAccountPath]["patch"]["parameters"]["path"]>;

type EnrollAccountRequestBody = RequiredExcept<
  paths[EnrollAccountPath]["patch"]["requestBody"]["content"]["application/json"],
  "phoneNumber"
>;

/* -------------------------------------------------------------------------------------------------
 * ResetPassword
 * -----------------------------------------------------------------------------------------------*/

type ResetPasswordPath = "/users/{token}/resetPassword";

type ResetPasswordRequestParams = Required<paths[ResetPasswordPath]["patch"]["parameters"]["path"]>;

type ResetPasswordRequestBody = Required<
  paths[ResetPasswordPath]["patch"]["requestBody"]["content"]["application/json"]
>;

/* -------------------------------------------------------------------------------------------------
 * Login
 * -----------------------------------------------------------------------------------------------*/

type LoginPath = "/login/";

type LoginRequestBody = Required<paths[LoginPath]["post"]["requestBody"]["content"]["application/json"]>;

type LoginResponseBody = Required<paths[LoginPath]["post"]["responses"]["200"]["content"]["application/json"]>;

/* -------------------------------------------------------------------------------------------------
 * RecoverAccount
 * -----------------------------------------------------------------------------------------------*/

type RecoverAccountPath = "/users/{email}/resetPassword";

type RecoverAccountRequestParams = Required<paths[RecoverAccountPath]["post"]["parameters"]["path"]>;

type RecoverAccountResponseBody = Required<
  paths[RecoverAccountPath]["post"]["responses"]["202"]["content"]["application/json"]
>;

/* -------------------------------------------------------------------------------------------------
 * ValidationPhoneNumber
 * -----------------------------------------------------------------------------------------------*/

type ValidationPhoneNumberPath = "/users/validationPhoneNumber";

type ValidationPhoneNumberResponseBody = Required<
  paths[ValidationPhoneNumberPath]["post"]["responses"]["200"]["content"]["application/json"]
>;

/* -------------------------------------------------------------------------------------------------
 * CheckValidationCode
 * -----------------------------------------------------------------------------------------------*/

type CheckValidationCodePath = "/users/checkValidationCode";

type CheckValidationCodeRequestBody = Required<
  paths[CheckValidationCodePath]["post"]["requestBody"]["content"]["application/json"]
>;

type CheckValidationCodeResponseBody = Required<
  paths[CheckValidationCodePath]["post"]["responses"]["200"]["content"]["application/json"]
>;

/* -------------------------------------------------------------------------------------------------
 * UpdateRoles
 * -----------------------------------------------------------------------------------------------*/

type UpdateRolesPath = "/users/{email}/roles/";

type UpdateRolesRequestBody = Required<paths[UpdateRolesPath]["patch"]["requestBody"]["content"]["application/json"]>;

type UpdateRolesResponseBody = Required<
  paths[UpdateRolesPath]["patch"]["responses"]["207"]["content"]["application/json"]
>;

type UsersRoles = UpdateRolesResponseBody["message"];

/* -------------------------------------------------------------------------------------------------
 * RolesInHouses
 * -----------------------------------------------------------------------------------------------*/

type RolesInHousesPath = "/users/rolesInHouses";

type RolesInHousesResponseBody = Required<
  paths[RolesInHousesPath]["get"]["responses"]["200"]["content"]["application/json"]
>;

type RolesInHouses = RolesInHousesResponseBody["message"];

/* -------------------------------------------------------------------------------------------------
 * HouseUsers
 * -----------------------------------------------------------------------------------------------*/

type HouseUsersPath = "/houses/{houseId}/users";

type HouseUsersResponseBody = Required<paths[HouseUsersPath]["get"]["responses"]["200"]["content"]["application/json"]>;

type HouseUsers = HouseUsersResponseBody["message"];

/* -------------------------------------------------------------------------------------------------
 * Creators
 * -----------------------------------------------------------------------------------------------*/

type CreatorsPath = "/users/roles/creators";

type CreatorsResponseBody = Required<paths[CreatorsPath]["get"]["responses"]["200"]["content"]["application/json"]>;

type Creators = CreatorsResponseBody["message"];

enum TokenStatus {
  VALID = "valid",
  EXPIRED = "expired",
  ALREADY_USED = "alreadyUsed",
  INVALID = "invalid",
}

enum InvalidToken {
  ACCOUNT = "account",
  PASSWORD = "password",
}

export { TokenStatus, InvalidToken };
export type {
  CheckValidationCodePath,
  CheckValidationCodeRequestBody,
  CheckValidationCodeResponseBody,
  Creators,
  CreatorsPath,
  CreatorsResponseBody,
  EnrollAccountPath,
  EnrollAccountRequestBody,
  EnrollAccountRequestParams,
  HouseUsers,
  HouseUsersPath,
  HouseUsersResponseBody,
  LoginPath,
  LoginRequestBody,
  LoginResponseBody,
  RecoverAccountPath,
  RecoverAccountRequestParams,
  RecoverAccountResponseBody,
  ResetPasswordPath,
  ResetPasswordRequestBody,
  ResetPasswordRequestParams,
  RolesInHouses,
  RolesInHousesPath,
  RolesInHousesResponseBody,
  UpdateRolesPath,
  UpdateRolesRequestBody,
  UpdateRolesResponseBody,
  UpdateUserBody,
  UserPath,
  UserResponseBody,
  UserRole,
  UsersRoles,
  ValidationPhoneNumberPath,
  ValidationPhoneNumberResponseBody,
};
