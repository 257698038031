import { useState } from "react";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Link, useParams, useRouteLoaderData } from "react-router-dom";
import * as yup from "yup";

import { RoomRoomTypeEnum } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import { ActionButton, Alert, Button, Select, Slider, Switch, TextInput, Typography } from "@eisox/design-system";
import { isFrostFree as isFrostFreeHouse } from "@eisox/houses";
import { ArrowRightIcon, HeatIcon, LeafIcon, PencilIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { DeletePopup } from "~/UI/layouts";
import type { houseLoader } from "~/UI/screens";
import { usePermissionsContext } from "~/UI/screens";
import { MoveDialog } from "~/UI/screens/Plans/components/Actions";
import { Periods } from "~/UI/screens/Settings/layouts";
import {
  PlanningsGenerationPopup,
  UnusualAbsencePopup,
} from "~/UI/screens/Settings/pages/Rooms/pages/Function/layouts";
import {
  DEFAULT_TEMPERATURES,
  GATEWAY_MIN_SOFTWARE_VERSIONS,
  ROOM_TYPES,
  TEMPERATURE_LIMITS,
} from "~/constants/appConstantV2";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToFrostFreeSettings, routeToRoom } from "~/routes/utils";
import { API } from "~/types/API";
import type { ErrorType } from "~/types/ReactHookFormType";
import { isVersionIsUpper } from "~/utils";

import { useRoomContext } from "../../context";
import { getUpdatedData, roomToPatchRoomMapper } from "../../utils";

import styles from "./Parameters.module.scss";

const DATE_FORMAT = "YYYY-MM-DD";

const schema = (formatMessage: FormatMessageFn) =>
  yup.object({
    name: yup.string().required(formatMessage({ id: "error.emptyField" })),
    roomType: yup.mixed<RoomRoomTypeEnum>().oneOf(Object.values(RoomRoomTypeEnum)).required(),
    comfortTemperature: yup.number().min(TEMPERATURE_LIMITS.MIN).max(TEMPERATURE_LIMITS.MAX).required(),
    nightTemperature: yup
      .number()
      .min(TEMPERATURE_LIMITS.MIN)
      .max(TEMPERATURE_LIMITS.MAX)
      .when("roomType", {
        is: RoomRoomTypeEnum.Room,
        then: schema => schema.required(),
      }),
    isSwitchEnabled: yup.boolean().required(),
    isFrostFree: yup.boolean().required(),
    frostFreePeriods: yup
      .array()
      .of(
        yup
          .object({
            begin: yup.string().required(),
            end: yup.string().required(),
            added: yup.boolean().default(false),
            removed: yup.boolean().default(false),
          })
          .test(
            "isBeginBeforeEnd",
            formatMessage({ id: "settings.content.addPeriod.startDateAfterEndDate" }),
            value => {
              const { begin, end, removed } = value;
              return removed || begin === end || dayjs(begin, DATE_FORMAT).isBefore(dayjs(end, DATE_FORMAT));
            },
          ),
      )
      .required(),
    isAutoPrecomf: yup.boolean().required(),
    isPreComfRoom: yup.boolean().required(),
  });

export type FormType = yup.InferType<ReturnType<typeof schema>>;

interface ParametersProps {
  setDisplayParameters: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Parameters: React.FC<ParametersProps> = ({ setDisplayParameters }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const parametersStyle = bem("parameters");
  const contentStyle = bem("content");
  const temperatureStyle = bem("temperature");
  const frostFreeModeStyle = bem("frost-free");
  const unusualAbsenceDetectionStyle = bem("unusual-absence-detection");
  const preComfortRangeGenerationStyle = bem("pre-comfort-range-generation");
  const footerStyle = bem("footer");

  const { room } = useRoomContext();

  const { house, rooms, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { houseId, planId, roomId } = useParams() as { houseId: string; planId: string; roomId: string };

  const { Form, submit } = useAction({
    onSuccess: () => setDisplayParameters(false),
  });

  const {
    register,
    control,
    formState: { isDirty, dirtyFields, errors },
    watch,
    handleSubmit,
  } = useForm<FormType>({
    values: roomToPatchRoomMapper(room),
    resolver: yupResolver(schema(formatMessage)),
  });

  const { fields, append, remove, update } = useFieldArray({ control, name: "frostFreePeriods" });

  const isSwitchEnabled = watch("isSwitchEnabled");
  const isFrostFree = watch("isFrostFree");
  const unusualAbsenceDetectionEnabled = watch("isAutoPrecomf");
  const preComfortRangeGenerationEnabled = watch("isPreComfRoom");
  const roomType = watch("roomType");
  const nightTemperature = watch("nightTemperature");
  const comfortTemperature = watch("comfortTemperature");

  const { permissions } = usePermissionsContext();

  const [unsualAbsencePopupOpen, setUnsualAbsencePopupOpen] = useState(false);
  const [planningGenerationPopupOpen, setPlanningGenerationPopupOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);

  const onSubmit = (data: FormType) => {
    const body = getUpdatedData(data, dirtyFields);
    submit(body, API.HTTP_METHOD.PATCH, routeToRoom(houseId, planId, roomId));
  };

  const temperatures = {
    absenceTemperature: house.absenceTemperature,
    preComfortTemperature: house.preComfortTemperature,
    comfortTemperature,
  };

  const isFrostFreePeriodsAllowed = gateways.every(g =>
    isVersionIsUpper(g.softwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.FROST_FREE_PERIODS),
  );

  return (
    <>
      <Form className={parametersStyle()} onSubmit={handleSubmit(onSubmit)}>
        <div className={contentStyle()}>
          <TextInput
            {...register("name")}
            label={formatMessage({ id: "room.parameters.drawer.name.label" })}
            placeholder={formatMessage({ id: "room.parameters.drawer.name.placeholder" })}
            error={errors.name}
          />
          <Controller
            control={control}
            name="roomType"
            render={({ field: { value, onChange } }) => (
              <Select
                label={formatMessage({ id: "room.parameters.drawer.type.label" })}
                value={value}
                options={ROOM_TYPES.map(t => ({
                  value: t,
                  name: formatMessage({ id: `room.type.${t}` }),
                }))}
                renderValue={(value?: string) => (
                  <Typography>
                    {value
                      ? formatMessage({ id: `room.type.${value}` })
                      : formatMessage({ id: "room.parameters.drawer.type.placeholder" })}
                  </Typography>
                )}
                onChange={(value: string) => onChange(value)}
              />
            )}
          />
          <div className={temperatureStyle()}>
            <h3 className={contentStyle("title")}>
              {formatMessage({ id: "room.parameters.drawer.temperatures.title" })}
            </h3>
            <Controller
              control={control}
              name="comfortTemperature"
              render={({ field: { value, onChange } }) => (
                <Slider
                  label={formatMessage({ id: "room.parameters.drawer.temperatures.comfort.label" })}
                  value={value}
                  defaultValue={DEFAULT_TEMPERATURES.COMFORT}
                  min={TEMPERATURE_LIMITS.MIN}
                  max={TEMPERATURE_LIMITS.MAX}
                  step={TEMPERATURE_LIMITS.STEP}
                  valueLabelDisplay={roomType === RoomRoomTypeEnum.Room ? "auto" : "off"}
                  valueLabelFormat={formatMessage(
                    { id: "error.temperature.comfUpperNight" },
                    { c: value, n: nightTemperature ?? DEFAULT_TEMPERATURES.NIGHT },
                  )}
                  onValueChange={value => {
                    if (typeof value === "number") {
                      const minTemp = nightTemperature ?? DEFAULT_TEMPERATURES.NIGHT;
                      if (roomType === RoomRoomTypeEnum.Room && value < minTemp)
                        value = minTemp + TEMPERATURE_LIMITS.STEP;
                      onChange(value);
                    }
                  }}
                />
              )}
            />
            {roomType === RoomRoomTypeEnum.Room && (
              <Controller
                control={control}
                name="nightTemperature"
                render={({ field: { value, onChange } }) => (
                  <Slider
                    label={formatMessage({ id: "room.parameters.drawer.temperatures.night.label" })}
                    value={value}
                    defaultValue={DEFAULT_TEMPERATURES.NIGHT}
                    min={TEMPERATURE_LIMITS.MIN}
                    max={TEMPERATURE_LIMITS.MAX}
                    step={TEMPERATURE_LIMITS.STEP}
                    valueLabelDisplay="auto"
                    valueLabelFormat={formatMessage(
                      { id: "error.temperature.nightLowerComf" },
                      { c: comfortTemperature, n: value },
                    )}
                    onValueChange={value => {
                      if (typeof value === "number") {
                        const maxTemp = comfortTemperature;
                        if (value > maxTemp) value = maxTemp - TEMPERATURE_LIMITS.STEP;
                        onChange(value);
                      }
                    }}
                  />
                )}
              />
            )}
          </div>
          {permissions.room?.update && (
            <div>
              <h3 className={contentStyle("title")}>
                {formatMessage({ id: "room.parameters.drawer.position.title" })}
              </h3>
              <Button
                className={contentStyle("button")}
                text={formatMessage({ id: "room.parameters.drawer.position.change" })}
                icon={<ArrowRightIcon />}
                onClick={() => setMoveDialogOpen(true)}
              />
            </div>
          )}
          <div>
            <h3 className={contentStyle("title")}>{formatMessage({ id: "room.parameters.drawer.buttons.title" })}</h3>
            <label className={contentStyle("label")}>
              {formatMessage({ id: "room.parameters.drawer.state" }, { activate: isSwitchEnabled })}
              <Controller
                control={control}
                name="isSwitchEnabled"
                render={({ field: { value, onChange } }) => (
                  <Switch checked={value} onCheckedChange={checked => onChange(checked)} />
                )}
              />
            </label>
          </div>
          <div className={frostFreeModeStyle()}>
            <h3 className={contentStyle("title")}>
              {formatMessage({ id: "room.parameters.drawer.frostFreeMode.title" })}
            </h3>
            {isFrostFreePeriodsAllowed ? (
              <Controller
                control={control}
                name="isFrostFree"
                render={({ field: { value, onChange } }) => {
                  const options = [
                    { value: "true", name: formatMessage({ id: "room.parameters.drawer.frostFreeMode.forced" }) },
                    { value: "false", name: formatMessage({ id: "room.parameters.drawer.frostFreeMode.periods" }) },
                  ];
                  return (
                    <>
                      <Select
                        triggerClassName={frostFreeModeStyle("select")}
                        value={value.toString()}
                        options={options}
                        renderValue={(value?: string) => (
                          <Typography>{options.find(o => o.value === value)?.name}</Typography>
                        )}
                        onChange={(value?: string) => onChange(value === "true")}
                      />
                      {!value && (
                        <Periods
                          periods={fields}
                          onAddPeriod={append}
                          onRemovePeriod={remove}
                          onUpdatePeriod={update}
                          errors={errors.frostFreePeriods as ErrorType[]}
                        />
                      )}
                    </>
                  );
                }}
              />
            ) : (
              <label className={contentStyle("label")}>
                {formatMessage({ id: "room.parameters.drawer.state" }, { activate: isFrostFree })}
                <Controller
                  control={control}
                  name="isFrostFree"
                  render={({ field: { value, onChange } }) => (
                    <Switch checked={value} onCheckedChange={checked => onChange(checked)} />
                  )}
                />
              </label>
            )}
            {isFrostFreeHouse({ ...house }).isFrostFree && (
              <Alert className={frostFreeModeStyle("alert")} severity="warning">
                {formatMessage(
                  { id: "room.parameters.drawer.frostFreeMode.alert" },
                  {
                    l: (
                      <Link className={frostFreeModeStyle("link")} to={routeToFrostFreeSettings(houseId)}>
                        {formatMessage({ id: "room.parameters.drawer.frostFreeMode.link" })}
                      </Link>
                    ),
                  },
                )}
              </Alert>
            )}
          </div>
          <div className={unusualAbsenceDetectionStyle()}>
            <h3 className={contentStyle("title")}>
              {formatMessage({ id: "room.parameters.drawer.unusualAbsenceDetection.title" })}
              <LeafIcon className={unusualAbsenceDetectionStyle("logo")} />
            </h3>
            <p className={contentStyle("text")}>
              {formatMessage(
                { id: "room.parameters.drawer.unusualAbsenceDetection.text" },
                {
                  l: (
                    <a className={contentStyle("text", { link: true })} onClick={() => setUnsualAbsencePopupOpen(true)}>
                      {formatMessage({
                        id: "room.parameters.drawer.more",
                      })}
                    </a>
                  ),
                },
              )}
            </p>
            <label className={contentStyle("label")}>
              {formatMessage({ id: "room.parameters.drawer.state" }, { activate: unusualAbsenceDetectionEnabled })}
              <Controller
                control={control}
                name="isAutoPrecomf"
                render={({ field: { value, onChange } }) => (
                  <Switch checked={value} onCheckedChange={checked => onChange(checked)} />
                )}
              />
            </label>
          </div>
          <div className={preComfortRangeGenerationStyle()}>
            <h3 className={contentStyle("title")}>
              {formatMessage({ id: "room.parameters.drawer.precomfortRangeGeneration.title" })}
              <HeatIcon className={preComfortRangeGenerationStyle("logo")} />
            </h3>
            <p className={contentStyle("text")}>
              {formatMessage(
                { id: "room.parameters.drawer.precomfortRangeGeneration.text" },
                {
                  l: (
                    <a
                      className={contentStyle("text", { link: true })}
                      onClick={() => setPlanningGenerationPopupOpen(true)}
                    >
                      {formatMessage({
                        id: "room.parameters.drawer.more",
                      })}
                    </a>
                  ),
                },
              )}
            </p>
            <label className={contentStyle("label")}>
              {formatMessage({ id: "room.parameters.drawer.state" }, { activate: preComfortRangeGenerationEnabled })}
              <Controller
                control={control}
                name="isPreComfRoom"
                render={({ field: { value, onChange } }) => (
                  <Switch checked={value} onCheckedChange={checked => onChange(checked)} />
                )}
              />
            </label>
          </div>
        </div>
        <div className={footerStyle()}>
          <ActionButton
            type="submit"
            rounded
            text={formatMessage({ id: "room.parameters.drawer.save" })}
            icon={<PencilIcon />}
            disabled={!isDirty}
          />
          {permissions.room?.delete && (
            <ActionButton
              variant="cancel"
              text={formatMessage({ id: "room.parameters.drawer.delete.button" })}
              onClick={() => setDeleteOpen(true)}
            />
          )}
        </div>
      </Form>
      {planningGenerationPopupOpen && (
        <PlanningsGenerationPopup
          open={planningGenerationPopupOpen}
          onClose={() => setPlanningGenerationPopupOpen(false)}
          {...temperatures}
        />
      )}
      {unsualAbsencePopupOpen && (
        <UnusualAbsencePopup
          open={unsualAbsencePopupOpen}
          onClose={() => setUnsualAbsencePopupOpen(false)}
          {...temperatures}
        />
      )}
      {deleteOpen && permissions.room?.delete && (
        <DeletePopup
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          elementToDelete={room.name!}
          text={formatMessage({ id: "room.parameters.drawer.delete.warning" })}
          route={routeToRoom(houseId, planId, roomId)}
        />
      )}
      {moveDialogOpen && (
        <MoveDialog
          ressource={"room"}
          items={rooms}
          initialSelectedItemId={room.id}
          open={moveDialogOpen}
          onClose={() => setMoveDialogOpen(false)}
        />
      )}
    </>
  );
};
