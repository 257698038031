import { useState } from "react";

import dayjs, { Dayjs } from "@eisox/dayjs";
import { Card, Option, Select } from "@eisox/design-system";
import { useBem } from "@eisox/tools";
import { Mark } from "@mui/base";

import { Slider } from "~/UI/components";

import { getLastNDays, getLastQuarter } from "./utils";

import styles from "./HistorySlider.module.scss";

interface HistoryProps {
  initialDate?: Dayjs;
  marks?: Mark[];
  onChange?: (date: Dayjs) => void;
  options?: Option[];
}

export const HistorySlider: React.FC<HistoryProps> = ({ initialDate, marks, onChange, options }) => {
  const bem = useBem(styles);
  const historyStyle = bem("history-slider");

  const [date, setDate] = useState<Dayjs>(initialDate ?? getLastQuarter());
  const [committedDate, setCommittedDate] = useState<Dayjs>(date);
  const [hour, setHour] = useState<number>(date.valueOf());

  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout>();

  const handleDayChange = (value: string) => {
    const hour = dayjs(value)
      .set("hour", dayjs(committedDate).hour())
      .set("minute", dayjs(committedDate).minute())
      .set("second", dayjs(committedDate).second())
      .set("millisecond", dayjs(committedDate).millisecond());
    if (dayjs().isBefore(hour)) {
      setDate(dayjs());
      setHour(getLastQuarter().valueOf());
      onChange && onChange(getLastQuarter());
      setCommittedDate(getLastQuarter());
    } else {
      setDate(dayjs(value));
      setHour(hour.valueOf());
      onChange && onChange(hour);
    }
  };

  const handleChangeCommitted = (_: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
    if (typeof value === "number" && dayjs().valueOf() < value) value = getLastQuarter().valueOf();
    if (typeof value === "number" && !dayjs(value).isSame(committedDate)) {
      onChange && onChange(dayjs(value));
      setCommittedDate(dayjs(value));
    }
    clearTimeout(currentTimeout);
  };

  const handleHourChange = (_: Event, value: number | number[]) => {
    if (typeof value === "number" && dayjs().valueOf() < value) value = getLastQuarter().valueOf();
    if (currentTimeout) clearTimeout(currentTimeout);
    if (typeof value === "number") {
      setHour(value);
      const timeout = setTimeout(() => {
        onChange && onChange(dayjs(value as number));
        setCommittedDate(dayjs(value as number));
      }, 500);
      setCurrentTimeout(timeout);
    }
  };

  return (
    <Card className={historyStyle()}>
      <Select
        triggerClassName={historyStyle("select")}
        options={
          options ??
          getLastNDays(30).map(d => ({
            value: d.toISOString(),
            name: d.format("ddd DD MMMM"),
          }))
        }
        value={date.toISOString()}
        renderValue={(value?: string) => <p>{dayjs(value).format("ddd DD MMMM")}</p>}
        onChange={handleDayChange}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <p className={historyStyle("hour", { variant: "current" })}>{(committedDate ?? date).format("HH:mm")}</p>
      <p className={historyStyle("hour", { variant: "key" })}>00:00</p>
      <Slider
        className={historyStyle("slider")}
        displayButtons={false}
        displayValue={false}
        value={hour}
        min={date.startOf("day").valueOf()}
        max={date.set("hour", 23).set("minute", 45).set("second", 0).set("millisecond", 0).valueOf()}
        step={marks ? null : 900000}
        onChange={handleHourChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelDisplay="auto"
        valueLabelFormat={value => dayjs(value).format("HH:mm")}
        marks={marks ?? [6, 12, 18].map(h => date.startOf("day").add(h, "hour")).map(m => ({ value: m.valueOf() }))}
      />
      <p className={historyStyle("hour", { variant: "key" })}>23:45</p>
    </Card>
  );
};
