import type { LoaderFunctionArgs } from "react-router-dom";
import { defer } from "react-router-dom";

import type { HousesHouseIdPlanningsStatGet200Response } from "@eisox/backend_webapp_api";

import { getPlanningsStat } from "~/api/planning";
import { getWeather } from "~/api/weather";
import { SUCCESS_FETCH } from "~/constants";
import type { FetchResponse } from "~/helpers/communication/fetchType";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const houseId = params.houseId!;

  const planningsStatsPromise = getPlanningsStat(houseId) as Promise<
    FetchResponse<HousesHouseIdPlanningsStatGet200Response>
  >;

  const externalTemperaturesResponse = await getWeather(houseId);
  const externalTemperatures =
    externalTemperaturesResponse.type === SUCCESS_FETCH ? externalTemperaturesResponse.result : [];

  const data = {
    planningsStatsPromise,
    externalTemperatures,
  };

  return defer(data) as unknown as Promise<typeof data>;
};
