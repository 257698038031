import { intl } from "~/i18n";

import {
  Boiler,
  Command,
  CommandAutomate,
  CommandMan,
  PumpCommand,
  PumpCommandAutomate,
  PumpCommandMan,
  PumpState,
  Pumps,
  State,
} from "~/socketio/types";

// BOILER

export const shouldDisplayBoilerCommutator = (
  state?: State,
  commandAutomate?: CommandAutomate,
  commandMan?: CommandMan,
  command?: Command,
): boolean => {
  if (
    (state !== undefined &&
      commandAutomate !== undefined &&
      commandMan === CommandMan.MANUAL &&
      command !== undefined) ||
    (state === undefined &&
      commandAutomate !== undefined &&
      commandMan === CommandMan.MANUAL &&
      command !== undefined) ||
    (state === undefined && commandAutomate === undefined && commandMan === CommandMan.MANUAL && command !== undefined)
  ) {
    return true;
  }
  return false;
};

export const isBoilerAutoOrManual = (command?: Command, commandMan?: CommandMan): "auto" | "manual" => {
  if (commandMan === CommandMan.MANUAL) {
    return "manual";
  } else {
    if (command && [Command.FAST, Command.NORMAL, Command.OFF].includes(command)) {
      return "manual";
    }
    if (command === Command.AUTO) {
      return "auto";
    }
  }
  return "auto";
};

export const getBoilerState = (
  state?: State,
  commandAutomate?: CommandAutomate,
  commandMan?: CommandMan,
  defect?: boolean,
): { state: State | CommandAutomate | "mismatch" | null; error?: string } => {
  if (defect) return { state: null };
  if (commandMan === CommandMan.MANUAL) {
    if (state !== undefined) {
      return { state: state };
    }
    return {
      state: null,
      error: intl.formatMessage({ id: "boiler.tootltip.warning.1" }),
    };
  }
  if (
    state !== undefined &&
    commandAutomate !== undefined &&
    ((state === State.ON && commandAutomate === CommandAutomate.OFF) ||
      (state === State.OFF && commandAutomate === CommandAutomate.ON))
  ) {
    return {
      state: "mismatch",
      error: commandMan == undefined ? intl.formatMessage({ id: "boiler.tootltip.warning.3" }) : undefined,
    };
  }
  if (state !== undefined) {
    return {
      state: state,
      error: commandMan === undefined ? intl.formatMessage({ id: "boiler.tootltip.warning.3" }) : undefined,
    };
  }
  if (commandAutomate !== undefined) {
    return {
      state: commandAutomate,
      error: intl.formatMessage({
        id: `${commandMan === undefined ? "boiler.tootltip.warning.4" : "boiler.tootltip.warning.2"}`,
      }),
    };
  }
  return {
    state: null,
    error: intl.formatMessage({ id: "boiler.tootltip.warning.5" }),
  };
};

export const getWaterlineLength = (boilers: Boiler[]): "none" | "one" | "both" => {
  if (boilers.some(boiler => boiler.pumps && boiler.twoWaysValve)) {
    return "both";
  } else if (boilers.some(boiler => boiler.pumps || boiler.twoWaysValve)) {
    return "one";
  } else {
    return "none";
  }
};

// PUMP

export const isOnlyOnePump = (pumps?: Pumps) => {
  let conditionState =
    pumps?.stateP1 !== undefined && pumps.stateP1 !== null && pumps.stateP2 !== undefined && pumps.stateP2 !== null;
  let conditionDefect =
    pumps?.defectP1 !== undefined && pumps.defectP1 !== null && pumps.defectP2 !== undefined && pumps.defectP2 !== null;
  let conditionCommandMan =
    pumps?.commandManP1 !== undefined &&
    pumps?.commandManP1 !== null &&
    pumps?.commandManP2 !== undefined &&
    pumps?.commandManP2 !== null;
  return !conditionState && !conditionDefect && !conditionCommandMan;
};

export const shouldDisplayPumpCommutator = (
  state?: PumpState,
  commandAutomate?: PumpCommandAutomate,
  commandMan?: PumpCommandMan,
  command?: PumpCommand,
) => {
  if (
    (state !== undefined &&
      commandAutomate !== undefined &&
      commandMan === PumpCommandMan.MANUAL &&
      command !== undefined) ||
    (state === undefined &&
      commandAutomate !== undefined &&
      commandMan === PumpCommandMan.MANUAL &&
      command !== undefined)
  ) {
    return true;
  }
  return false;
};

export const getPumpState = (
  state?: PumpState,
  commandAutomate?: PumpCommandAutomate,
  commandMan?: PumpCommandMan,
  defect?: boolean,
): { state: PumpState | PumpCommandAutomate | "mismatch" | null; error?: string } => {
  if (defect) return { state: null };
  if (commandMan === PumpCommandMan.MANUAL) {
    if (state !== undefined) {
      return { state: state };
    }
    return {
      state: null,
      error: intl.formatMessage({ id: "pumps.tootltip.warning.1" }),
    };
  }
  if (
    state !== undefined &&
    commandAutomate !== undefined &&
    ((state === PumpState.ON && commandAutomate === PumpCommandAutomate.OFF) ||
      (state === PumpState.OFF && commandAutomate === PumpCommandAutomate.ON))
  )
    return {
      state: "mismatch",
      error: commandMan === undefined ? intl.formatMessage({ id: "pumps.tootltip.warning.3" }) : undefined,
    };
  if (state !== undefined)
    return {
      state: state,
      error: commandMan === undefined ? intl.formatMessage({ id: "pumps.tootltip.warning.3" }) : undefined,
    };
  if (commandAutomate !== undefined)
    return {
      state: commandAutomate,
      error: intl.formatMessage({ id: `pumps.tootltip.warning.${commandMan === undefined ? "4" : "2"}` }),
    };
  return {
    state: null,
    error: intl.formatMessage({ id: "pumps.tootltip.warning.5" }),
  };
};

export const pumpsAutoOrManual = (
  command?: PumpCommand,
  commandMan1?: PumpCommandMan,
  commandMan2?: PumpCommandMan,
): string | undefined => {
  if (commandMan1 && commandMan2 && commandMan1 !== commandMan2) {
    return undefined;
  }
  if (commandMan1 === PumpCommandMan.MANUAL || commandMan2 === PumpCommandMan.MANUAL) {
    return "manual";
  } else {
    if (command && [PumpCommand.OFF, PumpCommand.PUMP_1, PumpCommand.PUMP_2].includes(command)) {
      return "manual";
    }
    if (command === PumpCommand.AUTO) {
      return "auto";
    }
  }
  return "auto";
};

export const isLeftOrRight = (
  state1?: PumpState | PumpCommandAutomate | "mismatch" | null,
  state2?: PumpState | PumpCommandAutomate | "mismatch" | null,
) => {
  if ((state1 === null || state1 === 0) && state2 !== null && state2 === 1) {
    return "right";
  }
  if ((state2 === null || state2 === 0) && state1 !== null && state1 === 1) {
    return "left";
  }
  if (state1 !== null && state1 === 0 && state2 !== null && state2 === 0) {
    return "off";
  }
  return null;
};
