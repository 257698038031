import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useMutation } from "@tanstack/react-query";

import { login } from "~/apiV2";
import { SignInPage } from "~/pages";
import { routeToHouses, routeToRecoverAccount } from "~/routes/utils";
import { useUserStore } from "~/stores";

const SignIn = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const setToken = useUserStore.use.setToken();

  const from = (location.state?.from?.pathname ?? searchParams.get("redirectTo") ?? routeToHouses()) as string;
  const email = searchParams.get("email") ?? undefined;

  const { mutate } = useMutation({
    mutationFn: login,
    onSuccess: data => {
      if (data.authToken) setToken(data.authToken);
      navigate(from);
    },
    onError: e =>
      toast.error(t(e.message === "unauthorized" ? "signin.error.unauthorized" : "error.unKnowError.message")),
  });

  return <SignInPage email={email} onClickRecoverPassword={() => navigate(routeToRecoverAccount())} submit={mutate} />;
};

export { SignIn };
