import { ComponentPropsWithRef, forwardRef } from "react";

import { cx } from "class-variance-authority";
import { useIntl } from "react-intl";

import { ArrowRightIcon } from "@eisox/icons";

import styles from "./Plan.module.scss";

interface PlanProps extends ComponentPropsWithRef<"div"> {
  url: string;
}

export const Plan = forwardRef<HTMLDivElement, PlanProps>((props, forwardedRef) => {
  const { url, style, className, ...planProps } = props;

  const { formatMessage } = useIntl();

  return (
    <div
      {...planProps}
      ref={forwardedRef}
      className={cx(styles.plan, className)}
      style={{ backgroundImage: `url(${url})`, ...style }}
    >
      <div className={styles.plan__overlay} />
      <p className={styles.plan__title}>{formatMessage({ id: "dash.accessArea" })}</p>
      <ArrowRightIcon className={styles.plan__arrow} />
    </div>
  );
});

Plan.displayName = "DashboardPlan";
