import { env } from "~/configuration";
import { useUserStore } from "~/stores";

import { DELETE_METHOD, GET_METHOD, PATCH_METHOD, POST_METHOD, PUT_METHOD } from "../constants/fetchConstants";
import { internalFetchByUrl } from "../helpers/communication/FetchApi";
import { routeHouses, valvesRouteConcat } from "../routesStatic/routesExpress";

const ServerUrl = env.SERVER_URL;

export async function deleteValveById(idValve, idHouse) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteDeleteValve = ServerUrl + routeHouses + "/" + idHouse + valvesRouteConcat + idValve;
  fetchingResponse = internalFetchByUrl(RouteDeleteValve, DELETE_METHOD, customHeaders);
  return fetchingResponse;
}

export async function getValves(idHouse) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteGetValve = ServerUrl + routeHouses + "/" + idHouse + valvesRouteConcat;
  fetchingResponse = internalFetchByUrl(RouteGetValve, GET_METHOD, customHeaders);
  return fetchingResponse;
}

export function addValves(idHouse, valves) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteAddValve = ServerUrl + routeHouses + "/" + idHouse + valvesRouteConcat;
  const body = {};
  if (valves !== undefined && valves !== null && valves.length !== 0) {
    body.valves = valves;
  }

  if (Object.keys(body).length !== 0) {
    fetchingResponse = internalFetchByUrl(RouteAddValve, POST_METHOD, customHeaders, body);
  }
  return fetchingResponse;
}

export function editValves(idHouse, valves) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteAddValve = ServerUrl + routeHouses + "/" + idHouse + valvesRouteConcat;
  const body = {};
  if (valves !== undefined && valves.length !== 0 && valves !== null) {
    body.valves = valves;
  }
  if (Object.keys(body).length !== 0) {
    fetchingResponse = internalFetchByUrl(RouteAddValve, PATCH_METHOD, customHeaders, body);
  }
  return fetchingResponse;
}

export function preinstallValves(idHouse, valves) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RoutePreinstallValve = ServerUrl + routeHouses + "/" + idHouse + valvesRouteConcat + "presInstall";
  fetchingResponse = internalFetchByUrl(RoutePreinstallValve, PUT_METHOD, customHeaders, valves);
  return fetchingResponse;
}
