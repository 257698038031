import { useIntl } from "react-intl";
import { Outlet, useParams, useRouteLoaderData } from "react-router-dom";

import { useBem } from "@eisox/tools";

import { Header } from "~/UI/layouts";
import type { houseLoader } from "~/UI/screens";
import { usePermissionsContext } from "~/UI/screens";
import { GATEWAY_MIN_SOFTWARE_VERSIONS } from "~/constants";
import {
  idLoaderHouse,
  routeToFrostFreeSettings,
  routeToFunctionsSettings,
  routeToGeneralSettings,
  routeToGroupsSettings,
  routeToModulesSettings,
  routeToPlanningSettings,
  routeToRemoteAccessSettings,
  routeToRoomsFrostFreeSettings,
  routeToSeasonSettings,
  routeToTemperaturesSettings,
} from "~/routes/utils";
import { isVersionIsUpper } from "~/utils";

import type { MenuItemProps } from "./components";
import { MenuItem } from "./components";

import styles from "./Settings.module.scss";

type MenuItemType = MenuItemProps & {
  hasPermissions: boolean;
};

export const Settings: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const settingsStyle = bem("settings");
  const contentStyle = bem("content");

  const { permissions } = usePermissionsContext();

  const { houseId } = useParams() as { houseId: string };

  const { rooms, gateways, modules } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  if (!permissions.page?.settings) throw new Error("Unauthorized");

  const menuItems: MenuItemType[] = [
    {
      title: formatMessage({ id: "settings.content.menu.houses.title" }),
      subItems: [
        {
          title: formatMessage({ id: "settings.content.menu.houses.general.title" }),
          url: routeToGeneralSettings(houseId),
          hasPermissions: true,
        },
        {
          title: formatMessage({ id: "settings.content.menu.houses.temperatures.title" }),
          url: routeToTemperaturesSettings(houseId),
          hasPermissions: true,
        },
        {
          title: formatMessage({ id: "settings.content.menu.houses.frostFree.title" }),
          url: routeToFrostFreeSettings(houseId),
          hasPermissions: true,
        },
        {
          title: formatMessage({ id: "settings.content.menu.houses.season.title" }),
          url: routeToSeasonSettings(houseId),
          hasPermissions: true,
        },
        {
          title: formatMessage({ id: "settings.content.menu.houses.remoteAccess.title" }),
          url: routeToRemoteAccessSettings(houseId),
          hasPermissions: permissions.house?.isRemoteAccess?.update,
        },
      ],
      hasPermissions: true,
    },
    {
      title: formatMessage({ id: "settings.content.menu.gtb.title" }),
      subItems: [
        {
          title: formatMessage({ id: "settings.content.menu.gtb.modules.title" }),
          url: routeToModulesSettings(houseId),
          hasPermissions: true,
        },
      ],
      hasPermissions: !!permissions.module?.read,
    },
    {
      title: formatMessage({ id: "settings.content.menu.rooms.title" }),
      subItems: [
        {
          title: formatMessage({ id: "settings.content.menu.rooms.frostFree.title" }),
          url: routeToRoomsFrostFreeSettings(houseId),
          hasPermissions: gateways
            .filter(g => g.softwareVersion)
            .every(g => isVersionIsUpper(g.softwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.FROST_FREE_PERIODS)),
        },
        {
          title: formatMessage({ id: "settings.content.menu.rooms.function.title" }),
          url: routeToFunctionsSettings(houseId),
          hasPermissions: true,
        },
        {
          title: formatMessage({ id: "settings.content.menu.rooms.planning.title" }),
          url: routeToPlanningSettings(houseId),
          hasPermissions: true,
        },
        {
          title: formatMessage({ id: "settings.content.menu.rooms.groups.title" }),
          url: routeToGroupsSettings(houseId),
          hasPermissions: true,
        },
      ],
      hasPermissions: rooms.length > 0,
    },
  ];

  return (
    <div className={settingsStyle()}>
      <Header title={formatMessage({ id: "settings.title" })} />
      <main className={contentStyle()}>
        <div className={contentStyle("menu")}>
          {menuItems
            .filter(i => i.hasPermissions)
            .map((item, index) => (
              <MenuItem key={index} title={item.title} subItems={item.subItems.filter(sbi => !!sbi.hasPermissions)} />
            ))}
        </div>
        <div className={contentStyle("outlet")}>
          <Outlet />
        </div>
      </main>
    </div>
  );
};
