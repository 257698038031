import type { RolesMessageRoles } from "@eisox/backend_webapp_api";

import { intl } from "~/i18n";

import { installerPermissions } from "./role/installer";
import { managerPermissions } from "./role/manager";
import { managerBoilerRoomPermissions } from "./role/managerBoilerRoom";
import { ownerPermissions } from "./role/owner";
import type { Permission, WebappRolePermission } from "./type";
import { UserRole } from "./type";

/**
 * Retrieves the permissions for a user based on their roles and a specific house ID.
 * @param roles - The roles of the user.
 * @param houseId - The ID of the house.
 * @returns The permissions for the user.
 */
export const getPermissions = (
  roles: RolesMessageRoles,
  houseId: string,
): { permissions: Permission; role?: WebappRolePermission } => {
  const rolePermissions: {
    [key in WebappRolePermission]: Permission;
  } = {
    [UserRole.MANAGER]: managerPermissions,
    [UserRole.MANAGER_BOILERROOM]: managerBoilerRoomPermissions,
    [UserRole.OWNER]: ownerPermissions,
    [UserRole.INSTALLER]: installerPermissions,
    [UserRole.VISITOR]: {},
  };
  const role = Object.entries(roles).find(([_, houses]) => houses.includes(houseId))?.[0] as
    | WebappRolePermission
    | undefined;
  return {
    permissions: role ? rolePermissions[role] : {},
    role: role,
  };
};

/**
 * Returns the localized role name based on the UserRole.
 * @param role - The UserRole to get the role name for.
 * @returns The localized role name.
 */
export const getRoleName = (role?: UserRole): string => {
  switch (role) {
    case UserRole.INSTALLER:
      return intl.formatMessage({ id: "role.isInstaller" });
    case UserRole.OWNER:
      return intl.formatMessage({ id: "role.isOwner" });
    case UserRole.MANAGER_BOILERROOM:
      return intl.formatMessage({ id: "role.isManager" });
    case UserRole.MANAGER:
      return intl.formatMessage({ id: "role.isManager" });
    case UserRole.VISITOR:
      return intl.formatMessage({ id: "role.isVisitor" });
    case UserRole.API:
      return intl.formatMessage({ id: "role.isApi" });
    default:
      return intl.formatMessage({ id: "role.unknown" });
  }
};
