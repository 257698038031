import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";

import { configureLocaleAndTimezone, env, validateEnvConfig } from "~/configuration";

import App from "./App";

void validateEnvConfig();
configureLocaleAndTimezone();

Sentry.init({
  environment: env.MODE,
  dsn: "https://1ea06e298ecf1fb6e4b6f5a700c82d4e@o4508691997130752.ingest.de.sentry.io/4508692000014416",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api-web-dev\.portaileisox\.com/,
    /^https:\/\/api-web-staging\.portaileisox\.com/,
    /^https:\/\/api-web-prod\.portaileisox\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  sendDefaultPii: false,
  enabled: env.MODE !== "test" && env.MODE !== "development",
});
Sentry.setUser(null);

createRoot(document.getElementById("app")!).render(<App />);
