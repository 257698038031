import type { HttpResponseResolver } from "msw";
import { HttpResponse } from "msw";

import dayjs from "@eisox/dayjs";
import { PostHeatingCurvesHistoryFields, PostHslopeHistoryFields } from "@eisox/webapp-api-specification";
import { faker } from "@faker-js/faker";

import type {
  HeatingCurveHistory,
  HeatingCurveHistoryPathParams,
  HeatingCurveHistoryRequestBody,
  HeatingCurveHistoryResponseBody,
  HeatingCurveHslopeHistory,
  HeatingCurveHslopeHistoryPathParams,
  HeatingCurveHslopeHistoryRequestBody,
  HeatingCurveHslopeHistoryResponseBody,
  HslopeHistory,
  HslopeHistoryRequestBody,
  HslopeHistoryResponseBody,
} from "~/apiV2";
import { HSLOPE } from "~/constants/appConstantV2";

import { HslopeHistoryBuilder } from "../builders";
import { SortOrder, getContentRange, getSortOrder, sortResponse } from "../utils";

const getHslopeHistoryResolver: HttpResponseResolver<
  never,
  HslopeHistoryRequestBody,
  HslopeHistoryResponseBody
> = async ({ request }) => {
  const url = new URL(request.url);

  const pageNumberParam = url.searchParams.get("pageNumber");
  const pageSizeParam = url.searchParams.get("pageSize");
  const sortParam = url.searchParams.get("sort");
  const descParam = url.searchParams.get("desc");

  const pageNumber = pageNumberParam ? parseInt(pageNumberParam) : undefined;
  const pageSize = pageSizeParam ? parseInt(pageSizeParam) : undefined;
  const sort = sortParam ? (sortParam.split(",") as (keyof HslopeHistory)[]) : undefined;
  const desc = descParam ? (descParam.split(",") as (keyof HslopeHistory)[]) : undefined;

  if (!pageNumber || !pageSize || !sort) throw new Error("Missing parameters");

  const { startDate, endDate, valvesMac } = await request.json();

  const lastValuesKey = "hslopeHistoryLastValues";
  if (pageNumber === 1) sessionStorage.setItem(lastValuesKey, "{}");
  const lastValues = JSON.parse(sessionStorage.getItem(lastValuesKey) ?? "{}");

  const lastValue = lastValues[`${startDate}, ${endDate}, ${valvesMac[0]}`] as
    | NonNullable<HslopeHistoryResponseBody["message"]>[0]
    | undefined;

  const valveId = lastValue?.valveId ?? faker.database.mongodbObjectId();

  const responseData: HslopeHistory[] = Array.from({ length: pageSize }).reduce<HslopeHistory[]>((acc, _, i) => {
    const prevItem = acc[i - 1] as HslopeHistory | undefined;

    const createdDateSortOrder = getSortOrder(sort, desc, "createdDate");
    const hslopeSortOrder = getSortOrder(sort, desc, "hslope");
    const maxOpeningSortOrder = getSortOrder(sort, desc, "maxOpening");

    const randomDate = generateRandomDate(
      startDate,
      endDate,
      createdDateSortOrder,
      prevItem?.createdAt ?? lastValue?.createdAt,
    );
    const randomHslope = generateRandomNumber(3, 50, hslopeSortOrder, prevItem?.hslopeMinC ?? lastValue?.hslopeMinC);
    const randomMaxOpening = generateRandomNumber(
      HSLOPE.MIN,
      HSLOPE.MAX,
      maxOpeningSortOrder,
      prevItem?.maxOpening ?? lastValue?.maxOpening,
    );
    return [
      ...acc,
      new HslopeHistoryBuilder()
        .withCreatedAt(randomDate)
        .withHslope(randomHslope)
        .withMaxOpening(randomMaxOpening)
        .withValveId(valveId),
    ];
  }, []);

  const sortedResponseData = sortResponse(responseData, sort, desc);

  const key = [startDate, endDate, valvesMac[0]].join(", ");
  sessionStorage.setItem(lastValuesKey, JSON.stringify({ [key]: sortedResponseData[sortedResponseData.length - 1] }));

  const contentRange = getContentRange(pageNumber, pageSize, 1200);

  return HttpResponse.json({ message: sortedResponseData }, { headers: { "Content-Range": contentRange } });
};

const generateRandomDate = (
  startDate: string,
  endDate: string,
  sortOrder: SortOrder | null,
  prevValue?: string,
): string => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  if (sortOrder === SortOrder.DESC && prevValue) {
    return faker.date.recent({ days: 1, refDate: prevValue }).toISOString();
  }
  if (sortOrder === SortOrder.ASC && prevValue) {
    return faker.date.soon({ days: 1, refDate: new Date(prevValue) }).toISOString();
  }
  if (sortOrder === SortOrder.DESC) return endDateObj.toISOString();
  if (sortOrder === SortOrder.ASC) return startDateObj.toISOString();
  const randomDate = faker.date.between({ from: startDate, to: endDate });
  return (randomDate > endDateObj ? endDateObj : randomDate).toISOString();
};

const generateRandomNumber = (min: number, max: number, sortOrder: SortOrder | null, prevValue?: number) => {
  let randomNumber: number;
  if (sortOrder === SortOrder.DESC && prevValue) {
    randomNumber = faker.number.int({ min, max: prevValue });
  } else if (sortOrder === SortOrder.ASC && prevValue) {
    randomNumber = faker.number.int({ min: prevValue, max });
  } else {
    randomNumber = faker.number.int({ min, max });
  }
  return randomNumber;
};

const heatingCurveHistoryResolver: HttpResponseResolver<
  HeatingCurveHistoryPathParams,
  HeatingCurveHistoryRequestBody,
  HeatingCurveHistoryResponseBody
> = async ({ request }) => {
  const url = new URL(request.url);

  const pageNumberParam = url.searchParams.get("pageNumber");
  const pageSizeParam = url.searchParams.get("pageSize");

  const pageNumber = pageNumberParam ? parseInt(pageNumberParam) : undefined;
  const pageSize = pageSizeParam ? parseInt(pageSizeParam) : undefined;

  if (!pageNumber || !pageSize) throw new Error("Missing parameters");

  const { date, fields } = await request.json();

  const contentRange = getContentRange(pageNumber, pageSize, 1000);

  const data: HeatingCurveHistory[] = Array.from({ length: pageSize }, () => ({
    id: faker.database.mongodbObjectId(),
    con: fields.includes(PostHeatingCurvesHistoryFields.con) ? faker.number.int({ min: 30, max: 80 }) : undefined,
    ext: fields.includes(PostHeatingCurvesHistoryFields.ext)
      ? faker.helpers.arrayElement([-20, -15, -10, -5, 0, 5, 10, 15, 20])
      : undefined,
    offset: fields.includes(PostHeatingCurvesHistoryFields.createdAt)
      ? faker.number.int({ min: 0, max: 5 })
      : undefined,
    createdAt: fields.includes(PostHeatingCurvesHistoryFields.createdAt)
      ? faker.date.between({ from: dayjs(date).toDate(), to: new Date() }).toISOString()
      : undefined,
  }));

  return HttpResponse.json({ message: data }, { headers: { "Content-Range": contentRange } });
};

const heatingCurveHslopeHistory: HttpResponseResolver<
  HeatingCurveHslopeHistoryPathParams,
  HeatingCurveHslopeHistoryRequestBody,
  HeatingCurveHslopeHistoryResponseBody
> = async ({ request }) => {
  const url = new URL(request.url);

  const pageNumberParam = url.searchParams.get("pageNumber");
  const pageSizeParam = url.searchParams.get("pageSize");

  const pageNumber = pageNumberParam ? parseInt(pageNumberParam) : undefined;
  const pageSize = pageSizeParam ? parseInt(pageSizeParam) : undefined;

  if (!pageNumber || !pageSize) throw new Error("Missing parameters");

  const { fields } = await request.json();

  const contentRange = getContentRange(pageNumber, pageSize, 1000);

  const data: HeatingCurveHslopeHistory[] = Array.from({ length: pageSize }, () => ({
    macValve: fields.includes(PostHslopeHistoryFields.macValve) ? faker.internet.mac() : undefined,
    softwareVersion: fields.includes(PostHslopeHistoryFields.softwareVersion) ? faker.system.semver() : undefined,
    hardwareVersion: fields.includes(PostHslopeHistoryFields.hardwareVersion) ? faker.system.semver() : undefined,
    heatingNetworkOpeningAverage: fields.includes(PostHslopeHistoryFields.heatingNetworkOpeningAverage)
      ? faker.number.float({ min: 0, max: 100 })
      : undefined,
    hslopeMinC: fields.includes(PostHslopeHistoryFields.hslopeMinC)
      ? faker.number.float({ min: HSLOPE.MIN, max: HSLOPE.MAX })
      : undefined,
    maxOpening: fields.includes(PostHslopeHistoryFields.maxOpening)
      ? faker.number.float({ min: 0, max: 100 })
      : undefined,
    realExtTemp: fields.includes(PostHslopeHistoryFields.realExtTemp)
      ? faker.number.float({ min: -20, max: 40 })
      : undefined,
    heatingCurve: fields.includes(PostHslopeHistoryFields.heatingCurve)
      ? {
          con: faker.number.float({ min: 0, max: 100 }),
          ext: faker.number.float({ min: 0, max: 100 }),
          offset: faker.number.float({ min: 0, max: 100 }),
        }
      : undefined,
    heatingNetworkStartingTemperature: fields.includes(PostHslopeHistoryFields.heatingNetworkStartingTemperature)
      ? faker.number.float({ min: 0, max: 100 })
      : undefined,
    createdAt: fields.includes(PostHslopeHistoryFields.createdAt) ? faker.date.past().toISOString() : undefined,
  }));

  return HttpResponse.json({ message: data }, { headers: { "Content-Range": contentRange } });
};

export { getHslopeHistoryResolver, heatingCurveHistoryResolver, heatingCurveHslopeHistory };
