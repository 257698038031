import type { GatewaysWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import { getGatewayProblemTranslation, getValveProblemTranslation } from "~/utils";

import type { ItemProps, ItemWithResolutionProps } from "../types";
import { getResolutionFromGatewayProblems } from "./gateway";
import { getResolutionFromValveProblems, unglog } from "./valve";

const getItems = (
  valves: ValvesWithProblem[],
  gateways: GatewaysWithProblem[],
): Record<string, ItemProps<"gateway" | "valve">[]> => {
  const items: ItemProps<"gateway" | "valve">[] = [];

  for (const gateway of gateways) {
    const { errors, warnings } = getGatewayProblemTranslation(gateway);
    items.push({
      id: gateway.id!,
      uid: gateway.uid!,
      name: gateway.gatewayName!,
      type: "gateway",
      errors,
      warnings,
      resolution: getResolutionFromGatewayProblems([...gateway.errors, ...gateway.warnings]),
      plan: {
        id: gateway.plan?.planId!,
        x: gateway.plan?.x!,
        y: gateway.plan?.y!,
      },
    });
  }

  for (const valve of valves) {
    const { errors, warnings } = getValveProblemTranslation(valve);
    items.push({
      id: valve.id!,
      uid: valve.uid!,
      name: valve.mac!,
      type: "valve",
      errors,
      warnings,
      resolution: getResolutionFromValveProblems([...valve.errors, ...valve.warnings]),
      unclogging: unglog([...valve.errors, ...valve.warnings]),
      plan: {
        id: valve.plan?.planId!,
        x: valve.plan?.x!,
        y: valve.plan?.y!,
      },
    });
  }

  const itemsByPlan = items.reduce<Record<string, ItemProps<"gateway" | "valve">[]>>((acc, item) => {
    const planId = item.plan.id;
    if (!acc[planId]) {
      acc[planId] = [];
    }
    acc[planId].push(item);
    return acc;
  }, {});

  return itemsByPlan;
};

const getItemsWithProblem = (
  items: Record<string, ItemProps<"gateway" | "valve">[]>,
): Record<string, ItemWithResolutionProps<"valve" | "gateway">[]> => {
  const resolutionDefined = (
    item: ItemProps<"gateway" | "valve">,
  ): item is ItemWithResolutionProps<"gateway" | "valve"> => item.resolution !== undefined || !!item.unclogging;

  const itemsWithProblem = Object.entries(items).reduce<Record<string, ItemWithResolutionProps<"valve" | "gateway">[]>>(
    (acc, [planId, items]) => {
      const itemsWithProblem = items.filter(resolutionDefined);
      if (itemsWithProblem.length) {
        acc[planId] = itemsWithProblem;
      }
      return acc;
    },
    {},
  );
  return itemsWithProblem;
};

export { getItems, getItemsWithProblem };
