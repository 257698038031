import clsx from "clsx";
import type { SubmitHandler, UseFormReturn } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import { ActionButton, Button, SelectV2 as Select, Switch, TextInput } from "@eisox/design-system";
import { PencilIcon, PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { houseLoader } from "~/UI/screens";
import { idLoaderHouse } from "~/routes/utils";
import { toggleGatewaySuffix } from "~/utils";

import type { GatewayFormDto } from "..";
import { getGatewayName } from "../utils";

import styles from "./GatewayForm.module.scss";

interface GatewayFormProps extends HTMLProps<"div"> {
  isCurrentGatewayIsOnPlan: boolean;
  form: UseFormReturn<GatewayFormDto, any, undefined>;
  handleUpdateGateway: SubmitHandler<GatewayFormDto>;
  handleSetToNextGateway: () => void;
  handleDeleteGateway: (g: GatewayFormDto) => void;
  toAdd: boolean;
}

export const GatewayForm: React.FC<GatewayFormProps> = ({
  isCurrentGatewayIsOnPlan,
  className,
  form,
  handleUpdateGateway,
  handleSetToNextGateway,
  handleDeleteGateway,
  toAdd,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const { plans, meshs } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const {
    control,
    formState: { isDirty, errors },
    setValue,
    handleSubmit,
    register,
    reset,
    watch,
  } = form;

  const currentGateway = watch();

  const bem = useBem(styles);
  const containerStyle = bem("container");
  const buttonContainerStyle = bem("button-container");

  return (
    <div {...props} className={clsx(containerStyle(), className)}>
      <h3>
        {formatMessage({
          id: `plan.actions.preinstall.gateways.gatewayForm.title.${
            isCurrentGatewayIsOnPlan ? "editGateway" : "newGateway"
          }`,
        })}
      </h3>
      <TextInput
        className={containerStyle("input")}
        placeholder={currentGateway.gatewayName || getGatewayName(currentGateway, plans)}
        disabled
        label={formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.name" })}
      />
      <Controller
        control={control}
        name="plan.planId"
        render={({ field: { onChange, value } }) => (
          <Select
            label={formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.level.label" })}
            placeholder={formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.level.placeholder" })}
            options={plans.map(p => ({ name: p.name || "--", value: p.id! }))}
            value={value}
            onChange={onChange}
            error={errors.plan?.planId}
          />
        )}
      />
      <TextInput
        {...register("uid")}
        label={formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.number" })}
        error={errors.uid}
        type="number"
      />
      {toAdd && (
        <Controller
          name="mac"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextInput
              label={formatMessage({
                id: "plan.actions.add.gateways.serialNumber.label",
              })}
              placeholder={formatMessage({ id: "plan.actions.add.gateways.serialNumber.placeholder" })}
              value={value}
              onChange={e => onChange(e.target.value.toLowerCase())}
              error={errors.mac}
              type={"string"}
            />
          )}
        />
      )}

      <label>
        <p>{formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.isInternetGateway.label" })}</p>
        <div>
          <p>
            {formatMessage(
              { id: "plan.actions.preinstall.gateways.gatewayForm.isInternetGateway.switch" },
              { s: currentGateway.isInternetGateway },
            )}
          </p>
          <Controller
            control={control}
            name="isInternetGateway"
            render={({ field: { value, onChange } }) => (
              <Switch
                checked={value}
                onCheckedChange={checked => {
                  currentGateway.gatewayName &&
                    setValue("gatewayName", toggleGatewaySuffix(currentGateway.gatewayName, checked!));
                  onChange(checked);
                }}
              />
            )}
          />
        </div>
        {errors.isInternetGateway && <p className={containerStyle("error")}>{errors.isInternetGateway.message}</p>}
      </label>
      <div className={containerStyle("meshs")}>
        <label>
          <p>{formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.meshs.label" })}</p>
          <div>
            <p>
              {formatMessage(
                { id: "plan.actions.preinstall.gateways.gatewayForm.meshs.switch" },
                { m: currentGateway.isWifiEnabled },
              )}
            </p>
            <Controller
              control={control}
              name="isWifiEnabled"
              render={({ field: { value, onChange } }) => (
                <Switch
                  checked={value}
                  onCheckedChange={checked => {
                    onChange(checked);
                    if (!checked) setValue("meshs", []);
                  }}
                />
              )}
            />
          </div>
        </label>
        {currentGateway.isWifiEnabled && (
          <Controller
            control={control}
            name="meshs"
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder={formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.meshs.placeholder" })}
                options={meshs.map(m => ({ name: m.name || "--", value: m.id! }))}
                value={value?.map(v => v.id) || []}
                onChange={v => onChange((v as string[]).map(v => ({ id: v })))}
                error={errors.meshs as any}
              />
            )}
          />
        )}
      </div>

      {isCurrentGatewayIsOnPlan && (
        <div className={buttonContainerStyle()}>
          <Button
            text={formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.delete" })}
            className={buttonContainerStyle({ type: "delete" })}
            onClick={() => handleDeleteGateway(currentGateway)}
          />
          <div className={buttonContainerStyle("edit")}>
            {isDirty && (
              <Button
                text={formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.cancel" })}
                className={buttonContainerStyle({ type: "cancel" })}
                onClick={() => reset()}
              />
            )}
            <ActionButton
              text={formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.edit" })}
              onClick={() => handleSubmit(handleUpdateGateway)()}
              variant="primary"
              disabled={!isDirty}
              icon={<PencilIcon />}
            />
          </div>
        </div>
      )}

      <div className={containerStyle("infoNewGateway", { disabled: isDirty })}>
        {isCurrentGatewayIsOnPlan ? (
          <Button
            text={formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.newGateway" })}
            icon={<PlusIcon />}
            disabled={isDirty}
            onClick={handleSetToNextGateway}
          />
        ) : (
          <span>{formatMessage({ id: "plan.actions.preinstall.gateways.gatewayForm.infoPlanAddNewGateway" })}</span>
        )}
      </div>
    </div>
  );
};
