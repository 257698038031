import { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";

import type { PostModule } from "@eisox/backend_webapp_api";
import { PostModuleOperatorProtocolTypeEnum, PostModuleTypeEnum } from "@eisox/backend_webapp_api";
import { ActionButton, Modal, PasswordInput, TextField, TextInput } from "@eisox/design-system";
import { SelectV2 as Select } from "@eisox/design-system";
import { BoilerRoomIcon, MagnifyingGlassIcon, PencilIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { FieldContainer } from "~/UI";
import type { houseLoader } from "~/UI/screens";
import { GATEWAY_MIN_SOFTWARE_VERSIONS } from "~/constants";
import { useAction } from "~/hooks";
import { idLoaderHouse } from "~/routes/utils";
import { API } from "~/types/API";
import { isVersionIsUpper } from "~/utils";

import { Plan } from "./Plan";
import type { AddBoilerroomType } from "./utils";
import { schema } from "./utils";

import styles from "./AddDialog.module.scss";

interface AddDialogProps {
  open: boolean;
  onClose: VoidFunction;
}

export const AddDialog: React.FC<AddDialogProps> = ({ open, onClose }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const boilerroomStyle = bem("addDialog");
  const inputsStyle = bem("inputs");

  const { houseId } = useParams() as { houseId: string; planId: string };
  const { gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const [searchGateway, setSearchGateway] = useState<string>();

  const { submit } = useAction({
    onSuccess: () => onClose(),
  });

  const {
    register,
    control,
    formState: { isDirty, errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm<AddBoilerroomType>({
    resolver: yupResolver(schema(formatMessage)),
    defaultValues: {
      type: PostModuleTypeEnum.EclypseBoilerRoom,
      port: 443,
      operatorPort: 443,
      operatorProtocolType: PostModuleOperatorProtocolTypeEnum.Http,
    },
  });

  const type = watch("type");
  const gatewayId = watch("gatewayId");

  const onSubmit = (data: AddBoilerroomType) => {
    const body: PostModule = { ...data, isSsl: true };
    submit(body, API.HTTP_METHOD.POST, `/app/houses/${houseId}/modules`);
  };

  const selectedGateway = gateways.find(({ id }) => id === gatewayId);
  const versionAuthorizedTypeChoice = isVersionIsUpper(
    selectedGateway?.softwareVersion,
    GATEWAY_MIN_SOFTWARE_VERSIONS.BOILERROOM_CHANGE_OPERATOR_PROTOCOL,
  );

  const gatewayOptions = gateways
    .map(g => ({ value: g.id!, name: g.gatewayName! }))
    .filter(g => g.name.toLowerCase().includes(searchGateway?.toLowerCase() ?? ""));

  return (
    <Modal.Root open={open} onOpenChange={onClose}>
      <Modal.Content className={boilerroomStyle()}>
        <form className={boilerroomStyle("form")} onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            icon={<BoilerRoomIcon />}
            title={formatMessage({ id: "plan.actions.add.bmsModule.title" })}
            subtitle={formatMessage({ id: "plan.actions.add.bmsModule.subtitle" })}
          >
            <Modal.Close asChild>
              <ActionButton variant="cancel" text={formatMessage({ id: "plan.actions.add.cancel" })} />
            </Modal.Close>
            <ActionButton
              type="submit"
              rounded
              text={formatMessage({ id: "plan.actions.add.save" })}
              icon={<PencilIcon className={boilerroomStyle("icon")} />}
              disabled={!isDirty}
            />
          </Modal.Header>
          <div className={boilerroomStyle("content")}>
            <div className={inputsStyle()}>
              <h3 className={inputsStyle("title")}>{formatMessage({ id: "plan.actions.add.bmsModule.boilerroom" })}</h3>
              <Controller
                name="type"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={formatMessage({ id: "plan.actions.add.bmsModule.type.label" })}
                    options={[
                      { value: PostModuleTypeEnum.EclypseBoilerRoom, name: "Eclypse" },
                      { value: PostModuleTypeEnum.NiagaraBoilerRoom, name: "Niagara" },
                    ]}
                    value={value}
                    onChange={value => {
                      onChange(value);
                      setValue("operatorPort", value === PostModuleTypeEnum.EclypseBoilerRoom ? 443 : 4911);
                      setValue(
                        "operatorProtocolType",
                        value === PostModuleTypeEnum.NiagaraBoilerRoom
                          ? PostModuleOperatorProtocolTypeEnum.Http
                          : PostModuleOperatorProtocolTypeEnum.Stream,
                      );
                    }}
                    disabled={selectedGateway && !versionAuthorizedTypeChoice}
                  />
                )}
              />
              <Controller
                name="gatewayId"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={formatMessage({ id: "plan.actions.add.bmsModule.gateway.label" })}
                    placeholder={formatMessage({ id: "plan.actions.add.bmsModule.gateway.placeholder" })}
                    options={gatewayOptions}
                    value={value}
                    onChange={value => {
                      onChange(value);
                      const gateway = gateways.find(g => g.id === value);
                      if (
                        gateway &&
                        !isVersionIsUpper(
                          gateway.softwareVersion,
                          GATEWAY_MIN_SOFTWARE_VERSIONS.BOILERROOM_CHANGE_OPERATOR_PROTOCOL,
                        )
                      )
                        setValue("type", PostModuleTypeEnum.EclypseBoilerRoom);
                    }}
                    unscrollableContent={
                      <TextField.Root
                        value={searchGateway}
                        onChange={e => setSearchGateway(e.target.value)}
                        placeholder=""
                      >
                        <TextField.Slot>
                          <MagnifyingGlassIcon />
                        </TextField.Slot>
                      </TextField.Root>
                    }
                    error={errors.gatewayId}
                  />
                )}
              />
              <TextInput
                {...register("url")}
                label={formatMessage({ id: "plan.actions.add.bmsModule.plcIP.label" })}
                placeholder={formatMessage({ id: "plan.actions.add.bmsModule.plcIP.placeholder" })}
                error={errors.url}
              />
              <TextInput
                {...register("userName")}
                autoComplete="new-password"
                label={formatMessage({ id: "plan.actions.add.bmsModule.plcUser.label" })}
                placeholder={formatMessage({ id: "plan.actions.add.bmsModule.plcUser.placeholder" })}
                error={errors.userName}
              />
              <FieldContainer
                label={formatMessage({ id: "plan.actions.add.bmsModule.plcPassword.label" })}
                error={errors.password}
              >
                <PasswordInput
                  {...register("password")}
                  autoComplete="new-password"
                  placeholder={formatMessage({ id: "plan.actions.add.bmsModule.plcPassword.placeholder" })}
                  error={!!errors.password}
                />
              </FieldContainer>
            </div>
            <Plan control={control} setValue={setValue} houseId={houseId} gatewayId={gatewayId} type={type} />
          </div>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
