import type { Meteo2Inner as ExternalTemperature } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

const getMeasuredTemperatures = (externalTemperatures: ExternalTemperature[]): ExternalTemperature[] =>
  externalTemperatures.filter(t => !!t.name);

const getMeasuredTemperaturesNumber = (externalTemperatures: ExternalTemperature[]): number =>
  getMeasuredTemperatures(externalTemperatures).length;

const isTemperatureValid = (temperature: ExternalTemperature): boolean =>
  !!temperature.updatedAt && dayjs(temperature.updatedAt).add(40, "minutes").isAfter(dayjs());

const getAPITemperature = (externalTemperatures: ExternalTemperature[]): ExternalTemperature | undefined =>
  externalTemperatures.find(t => !t.name);

const getExternalTemperatureAverage = (externalTemperatures: ExternalTemperature[]): number => {
  const apiTemperature = getAPITemperature(externalTemperatures);
  const measuredTemperatures = getMeasuredTemperatures(externalTemperatures);

  if (measuredTemperatures.length === 0) return Math.round(apiTemperature!.externalTemperature! * 10) / 10;

  const temperatures = measuredTemperatures.map(t => (isTemperatureValid(t) ? t : apiTemperature)).filter(t => !!t);
  return (
    (Math.round(temperatures.reduce((acc, curr) => acc + curr!.externalTemperature!, 0) / temperatures.length) * 10) /
    10
  );
};

export type { ExternalTemperature };
export {
  getMeasuredTemperatures,
  getMeasuredTemperaturesNumber,
  isTemperatureValid,
  getAPITemperature,
  getExternalTemperatureAverage,
};
