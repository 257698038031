import type { PropsWithChildren } from "react";
import { useState } from "react";

import clsx from "clsx";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import dayjs from "@eisox/dayjs";
import type { Option } from "@eisox/design-system";
import { Button, Modal, RoundIcon, Select, TextInput } from "@eisox/design-system";
import { BoilerRoomIcon, PencilIcon, QuestionMarkIcon } from "@eisox/icons";

import { Tooltip } from "~/UI/components";
import { FORMAT_DATE } from "~/constants/timeConstants";
import type { Boiler, BoilerRoom } from "~/socketio/types";
import { PermutationCommand } from "~/socketio/types";
import { getDirtyValues } from "~/utils";
import { getNextDay, getPreviousDay } from "~/utils/timeUtils";

import { useBoilerRoomContextWithCurrentBoilerRoom } from "../../providers";
import { PumpsDialog } from "../PumpsDialog";

import styles from "./CascadeDialog.module.scss";

const NAME = "CascadeDialog";

type CascadeDialogProps = PropsWithChildren;

export const CascadeDialog: React.FC<CascadeDialogProps> = ({ children }) => {
  const { formatMessage } = useIntl();

  const { currentBoilerRoom, useUpdateBoilerRoom, history } = useBoilerRoomContextWithCurrentBoilerRoom(NAME);

  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors, isDirty, dirtyFields },
    control,
    reset,
  } = useForm<BoilerRoom>({
    mode: "onChange",
    defaultValues: {
      lossTempDelta: currentBoilerRoom.lossTempDelta,
      permutationCmd: currentBoilerRoom.permutationCmd,
      permutationDay: currentBoilerRoom.permutationDay,
      permutationHour: currentBoilerRoom.permutationHour,
      boilers: currentBoilerRoom.boilers,
    },
  });
  const { fields } = useFieldArray({ control, name: "boilers" });

  const watchFields = watch(["permutationCmd", "permutationDay", "permutationHour", "boilers"]);

  const { mutate } = useUpdateBoilerRoom({ onSuccess: () => handleOpenChange(false) });

  const renderOption = (option: Option) => <p className={styles.cascadeDialog__item}>{option.name}</p>;

  const onSubmit = (data: BoilerRoom) => {
    const dirtyValues = getDirtyValues(dirtyFields, data as any) as Partial<BoilerRoom>;
    const updatedBoilers = Object.keys({ ...dirtyValues.boilers }).map(key => {
      return {
        ...(dirtyValues.boilers as unknown as Record<string, Boiler>)[key],
        id: data.boilers?.at(parseInt(key))?.id,
      };
    });
    const updatedValues = updatedBoilers.length > 0 ? { ...dirtyValues, boilers: updatedBoilers } : dirtyValues;
    const updatedBoilerrooms = { ...updatedValues, id: currentBoilerRoom.id } as BoilerRoom;
    mutate([updatedBoilerrooms]);
  };

  const handleOpenChange = (open: boolean) => {
    reset(currentBoilerRoom);
    setOpen(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content className={styles.cascadeDialog}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            title={currentBoilerRoom.name ?? ""}
            subtitle={formatMessage({ id: "boilerRoom.dialog.cascade.header.subtitle" })}
            icon={<BoilerRoomIcon />}
          >
            <div className={styles.cascadeDialog__header}>
              <Modal.Close asChild>
                <Button
                  className={styles["cascadeDialog__button--cancel"]}
                  text={formatMessage({ id: "boilerRoom.dialog.cascade.header.cancel" })}
                />
              </Modal.Close>
              <Button
                className={clsx(
                  styles["cascadeDialog__button--save"],
                  !isDirty && styles["cascadeDialog__button--save--disabled"],
                )}
                icon={<PencilIcon />}
                type="submit"
                text={formatMessage({ id: "boilerRoom.dialog.cascade.header.save" })}
                disabled={!isDirty || history}
              />
            </div>
          </Modal.Header>
          <div className={styles.cascadeDialog__content}>
            {currentBoilerRoom.lossTempDelta !== undefined && (
              <div className={styles.cascadeDialog__lossTempDelta}>
                <label htmlFor="lossTempDelta">
                  {formatMessage({ id: "boilerRoom.dialog.cascade.lossTempDelta.label" })}
                </label>
                <Tooltip content={<p>{formatMessage({ id: "boilerRoom.dialog.cascade.lossTempDelta.help" })}</p>}>
                  <RoundIcon size={26} backgroundColor="gray" iconColor="darkGray">
                    <QuestionMarkIcon className={styles.cascadeDialog__help} />
                  </RoundIcon>
                </Tooltip>
                <TextInput
                  className={styles.cascadeDialog__input}
                  id="lossTempDelta"
                  {...register("lossTempDelta", { valueAsNumber: true, required: true })}
                  error={errors.lossTempDelta}
                  disabled={history}
                />
                <span>°C</span>
              </div>
            )}
            {currentBoilerRoom.boilers && currentBoilerRoom.boilers.length > 1 && (
              <>
                <div className={styles.cascadeDialog__contentPart}>
                  {currentBoilerRoom.permutationCmd && (
                    <>
                      <h2 className={styles.cascadeDialog__title}>
                        {formatMessage({ id: "boilerRoom.dialog.cascade.boilerPriorities.title" })}
                      </h2>
                      <div className={styles.cascadeDialog__mode}>
                        <label htmlFor="mode">
                          {formatMessage({ id: "boilerRoom.dialog.cascade.boilerPriorities.mode.label" })}
                        </label>
                        <Controller
                          control={control}
                          name="permutationCmd"
                          render={({ field: { value, onChange } }) => (
                            <Select
                              triggerClassName={clsx(
                                styles.cascadeDialog__trigger,
                                styles["cascadeDialog__trigger--long"],
                              )}
                              options={[
                                {
                                  name: formatMessage({ id: "boilerRoom.dialog.cascade.boilerPriorities.mode.auto" }),
                                  value: "2",
                                },
                                {
                                  name: formatMessage({ id: "boilerRoom.dialog.cascade.boilerPriorities.mode.manual" }),
                                  value: "1",
                                },
                              ]}
                              renderOption={renderOption}
                              value={value?.toString() ?? ""}
                              renderValue={(value?: string) => (
                                <p>
                                  {value === "1"
                                    ? formatMessage({ id: "boilerRoom.dialog.cascade.boilerPriorities.mode.manual" })
                                    : formatMessage({ id: "boilerRoom.dialog.cascade.boilerPriorities.mode.auto" })}
                                </p>
                              )}
                              onChange={(value: string) => {
                                onChange(parseInt(value));
                                value !== "1" &&
                                  currentBoilerRoom.boilers?.forEach((_, index) =>
                                    resetField(`boilers.${index}.priority`),
                                  );
                              }}
                              disabled={history}
                            />
                          )}
                        />
                      </div>
                      {watchFields[0] === PermutationCommand.AUTO && (
                        <>
                          <div className={styles["cascadeDialog__mode--auto"]}>
                            <label>
                              {formatMessage({ id: "boilerRoom.dialog.cascade.boilerPriorities.permutationDay.day" })}
                              <Controller
                                control={control}
                                name="permutationDay"
                                render={({ field: { value, onChange } }) => (
                                  <Select
                                    options={[...Array(7)].map((_, index) => {
                                      return {
                                        name: formatMessage({
                                          id: `boilerRoom.dialog.cascade.boilerPriorities.permutationDay.${index + 1}`,
                                        }),
                                        value: (index + 1).toString(),
                                      };
                                    })}
                                    renderOption={renderOption}
                                    value={value?.toString() ?? ""}
                                    triggerClassName={clsx(
                                      styles.cascadeDialog__trigger,
                                      styles["cascadeDialog__trigger--long"],
                                    )}
                                    renderValue={(value?: string) => (
                                      <p>
                                        {formatMessage({
                                          id: `boilerRoom.dialog.cascade.boilerPriorities.permutationDay.${value}`,
                                        })}
                                      </p>
                                    )}
                                    onChange={(value: string) => onChange(parseInt(value))}
                                    disabled={history}
                                  />
                                )}
                              />
                            </label>
                            <label>
                              {formatMessage({ id: "boilerRoom.dialog.cascade.boilerPriorities.permutationHour" })}
                              <Controller
                                control={control}
                                name="permutationHour"
                                render={({ field: { value, onChange } }) => (
                                  <Select
                                    options={[...Array(24)].map((_, index) => {
                                      return {
                                        name: `${index}h`,
                                        value: index.toString(),
                                      };
                                    })}
                                    renderOption={renderOption}
                                    triggerClassName={clsx(
                                      styles.cascadeDialog__trigger,
                                      styles["cascadeDialog__trigger--short"],
                                    )}
                                    value={value?.toString() ?? ""}
                                    renderValue={(value?: string) => <p>{value}h</p>}
                                    onChange={(value: string) => onChange(parseInt(value))}
                                    disabled={history}
                                  />
                                )}
                              />
                            </label>
                          </div>
                        </>
                      )}
                      <h2 className={styles.cascadeDialog__title}>
                        {formatMessage(
                          { id: "boilerRoom.dialog.cascade.boilerPriorities.priority" },
                          {
                            manual: watchFields[0] === PermutationCommand.MANUAL,
                            p1: dayjs(getPreviousDay(watchFields[1], watchFields[2])).format(
                              FORMAT_DATE.cascadeHandler,
                            ),
                            h1: watchFields[2],
                            p2: dayjs(getNextDay(watchFields[1], watchFields[2])).format(FORMAT_DATE.cascadeHandler),
                            h2: watchFields[2],
                          },
                        )}
                      </h2>
                      <div className={styles.cascadeDialog__boilers}>
                        {fields.map((b, index) => (
                          <div key={index} className={styles.cascadeDialog__boiler}>
                            <label>{b.name}</label>
                            <Controller
                              control={control}
                              name={
                                watchFields[0] === 2 ? `boilers.${index}.priority` : `boilers.${index}.priorityForcing`
                              }
                              rules={{ required: true }}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  options={[...Array(fields.length)].map((_, index) => {
                                    return {
                                      name: `${index + 1}`,
                                      value: `${index + 1}`,
                                    };
                                  })}
                                  renderOption={renderOption}
                                  triggerClassName={clsx(
                                    styles.cascadeDialog__trigger,
                                    styles["cascadeDialog__trigger--short"],
                                  )}
                                  value={value?.toString() ?? ""}
                                  renderValue={(value?: string) => <p>{value}</p>}
                                  onChange={(value: string) => onChange(parseInt(value))}
                                  disabled={watchFields[0] === 2 || history}
                                />
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                {watchFields[0] === PermutationCommand.AUTO && (
                  <div className={styles.cascadeDialog__contentPart}>
                    <h2 className={styles.cascadeDialog__title}>
                      {formatMessage({ id: "boilerRoom.dialog.cascade.pumpsPermutation.title" })}
                    </h2>
                    <p className={styles.cascadeDialog__text}>
                      {formatMessage(
                        { id: "boilerRoom.dialog.cascade.pumpsPermutation.text" },
                        {
                          link: (
                            <PumpsDialog>
                              <span className={clsx(styles.cascadeDialog__text, styles.cascadeDialog__text_type_link)}>
                                {formatMessage({ id: "boilerRoom.dialog.cascade.pumpsPermutation.pumpsPermutation" })}
                              </span>
                            </PumpsDialog>
                          ),
                        },
                      )}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
