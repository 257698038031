import type {
  BoilerRoomsPosInner,
  GatewaysMessageGatewaysInner,
  HouseMessage,
  MeshsMessageMeshsInner,
  Module,
  Planning,
  PlansMessageInner,
  Room,
  UsersByHouseIdMessageInner,
  ValvesMessageValvesInner,
} from "@eisox/backend_webapp_api";
import type {
  PostBatteryHistoryFields,
  PostHistovalvesStatsFields,
  PostMecaHistoryFields,
  PostStatedataHistoryFields,
} from "@eisox/webapp-api-specification";

import type { HeatingNetwork, RawDataResV2 } from "~/socketio/types";

export enum UserRole {
  INSTALLER = "installer", //2
  OWNER = "owner", //1
  MANAGER_BOILERROOM = "managerBoilerRoom", //4
  MANAGER = "manager", //5
  VISITOR = "visitor", //6
  API = "api", //3
}

interface maintenance {
  error?: boolean;
  warning?: boolean;
}

export interface Permission {
  valve?: Role<ValvesMessageValvesInner> & maintenance;
  gateway?: Role<GatewaysMessageGatewaysInner> & maintenance;
  user?: Role<UsersByHouseIdMessageInner>;
  room?: Role<Room> & maintenance;
  module?: Role<Partial<Module>> & {
    boilerroom?: Role<BoilerRoomsPosInner> & {
      variableData?: Role<Partial<RawDataResV2>>;
      heatingNetwork?: Role<Partial<HeatingNetwork>>;
    };
  };
  house?: Role<HouseMessage>;
  mesh?: Role<MeshsMessageMeshsInner>;
  plan?: Role<PlansMessageInner>;
  planning?: Role<Planning>;
  history?: {
    valve: {
      battery?: Role<Partial<typeof PostBatteryHistoryFields>>;
      meca?: Role<Partial<typeof PostMecaHistoryFields>>;
      stateData?: Role<Partial<typeof PostStatedataHistoryFields>>;
      histoValve?: Role<Partial<typeof PostHistovalvesStatsFields>>;
    };
    gateway?: {
      lastGatewayActivity?: Role<boolean>;
    };
  };
  page?: {
    maintenance?: boolean;
    consumption?: boolean;
    users?: boolean;
    ressource?: boolean;
    settings?: boolean;
    boilerroom?: boolean;
    gtb?: boolean;
  };
}

/*
 * The visitor role is the basic role, all other roles inherit automatically,
 * so no specific authorization is required for this role.
 * The api role on the webapp is a visitor
 */
export type WebappRolePermission = Exclude<UserRole, UserRole.API>;

export enum PasswordRules {
  MIN_LENGTH = "minLength",
  UPPER_CASE = "upperCase",
  LOWER_CASE = "lowerCase",
  NUMBER = "number",
  SPECIAL_CHARACTER = "specialCharacter",
}
