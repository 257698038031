import { useIntl } from "react-intl";

import { Meteo2Inner } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import { Circle } from "@eisox/design-system";
import { ArrowRightIcon, ThermometerIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";

import {
  ExternalTemperature,
  getAPITemperature,
  getExternalTemperatureAverage,
  getMeasuredTemperatures,
  getMeasuredTemperaturesNumber,
  isTemperatureValid,
} from "../../helpers";
import { OverviewCard } from "../OverviewCard";

import styles from "./TemperaturesCard.module.scss";

interface TemperatureRowProps {
  measuredTemperature: ExternalTemperature;
  apiTemperature?: ExternalTemperature;
}

const TemperatureRow: React.FC<TemperatureRowProps> = ({ measuredTemperature, apiTemperature }) => {
  const bem = useBem(styles);
  const temperatureRowStyle = bem("temperature-row");

  const isValid = isTemperatureValid(measuredTemperature);

  return (
    <div className={temperatureRowStyle()}>
      <Circle className={temperatureRowStyle("indicator", { valid: isValid })} size={10} />
      <p className={temperatureRowStyle("temperature")}>
        {measuredTemperature.name}
        {measuredTemperature.externalTemperature !== undefined &&
          `: ${measuredTemperature.externalTemperature.toFixed(1)}°C`}
      </p>
      {measuredTemperature.updatedAt && (
        <p className={temperatureRowStyle("date")}>
          ({dayjs(measuredTemperature.updatedAt).format("HH:mm - DD/MM/YYYY")})
        </p>
      )}
      {!isValid && (
        <>
          <ArrowRightIcon />
          <p className={temperatureRowStyle("temperature")}>
            OpenWeatherMap: {apiTemperature?.externalTemperature?.toFixed(1)}°C
          </p>
        </>
      )}
    </div>
  );
};

interface TooltipContentProps {
  temperatures: ExternalTemperature[];
}

const TooltipContent: React.FC<TooltipContentProps> = ({ temperatures }) => {
  const bem = useBem(styles);
  const tooltipContentStyle = bem("tooltip-content");

  const measuredTemperatures = getMeasuredTemperatures(temperatures);
  const apiTemperature = getAPITemperature(temperatures);

  return (
    <div className={tooltipContentStyle()}>
      {measuredTemperatures.map((temperature, key) => (
        <TemperatureRow key={key} measuredTemperature={temperature} apiTemperature={apiTemperature} />
      ))}
    </div>
  );
};

interface TemperaturesCardProps {
  temperatures: Meteo2Inner[];
  className?: string;
}

export const TemperaturesCard: React.FC<TemperaturesCardProps> = ({ temperatures, className }) => {
  const { formatMessage } = useIntl();

  const externalTemperatureNumber = getMeasuredTemperaturesNumber(temperatures);

  return (
    <Tooltip
      content={<TooltipContent temperatures={temperatures} />}
      openingCondition={externalTemperatureNumber >= 1}
      position="left"
      keepHoverOpen={false}
    >
      <OverviewCard
        className={className}
        icon={<ThermometerIcon />}
        iconColor="white"
        iconBackgroundColor="blue"
        title={getExternalTemperatureAverage(temperatures).toString()}
        subtitle={formatMessage({ id: "dash.externalTemp" })}
        hasDegre
        size="large"
      >
        {externalTemperatureNumber > 0 && (
          <Circle className={styles.temperaturesCard__number} size={18}>
            {externalTemperatureNumber}
          </Circle>
        )}
      </OverviewCard>
    </Tooltip>
  );
};

TemperaturesCard.displayName = "DashboardTemperaturesCard";
