import type { HttpResponseResolver } from "msw";
import { HttpResponse, delay } from "msw";

import type { News, NewsQueryParams } from "~/apiV2";

import { NewsBuilder } from "../builders";

const getNewsResolver: HttpResponseResolver<NewsQueryParams, never, News[]> = () =>
  HttpResponse.json(Array.from({ length: 10 }).map(() => new NewsBuilder()));

const getResourcesNewsResolver: HttpResponseResolver<never, never, News[]> = async () => {
  await delay(10000);
  return HttpResponse.json(Array.from({ length: 10 }).map(() => new NewsBuilder().withDocusaurus(true)));
};

export { getNewsResolver, getResourcesNewsResolver };
