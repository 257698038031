import { useState } from "react";

import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import { ActionButton, Card, Modal, Radio, Typography } from "@eisox/design-system";
import { ArrowRightIcon, RoomIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { houseLoader } from "~/UI";
import type { RoomsWithProblem } from "~/UI/screens/House";
import { idLoaderHouse } from "~/routes/utils";

import { Plan, SelectFilters } from "..";
import { useFilterRooms } from "../../hooks";

import styles from "./RoomsSelectorDialog.module.scss";

interface RoomProps {
  multiple?: boolean;
  rooms: RoomsWithProblem;
  selected: boolean;
  onClick: (id: string, selected: boolean) => void;
  handleRoomHovered: (roomId: string | undefined) => void;
}

const Room: React.FC<RoomProps> = ({ multiple, rooms, selected, onClick, handleRoomHovered }) => {
  const bem = useBem(styles);
  const roomStyle = bem("room");

  return (
    <Card
      className={roomStyle()}
      onClick={() => onClick(rooms.id!, selected)}
      onMouseEnter={() => handleRoomHovered(rooms.id)}
      onMouseLeave={() => handleRoomHovered(undefined)}
    >
      <Radio multiple={multiple} checked={selected} />
      <Typography className={roomStyle("name", { selected: selected })}>
        {rooms.name}
        <span className={roomStyle("plan-name")}>{rooms.plan?.planName}</span>
      </Typography>
    </Card>
  );
};

interface RoomsSelectorDialogProps {
  multiple?: boolean;
  open: boolean;
  value: string[];
  onChange: (roomIds: string[]) => void;
  onClose: VoidFunction;
}

export const RoomsSelectorDialog: React.FC<RoomsSelectorDialogProps> = ({
  open,
  multiple = true,
  value,
  onChange,
  onClose,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const roomsSelectorDialogStyle = bem("rooms-selector-dialog");
  const contentStyle = bem("content");
  const leftStyle = bem("left");

  const { rooms } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const [selectedRooms, setSelectedRooms] = useState(value);
  const [roomHovered, setRoomHovered] = useState<string | undefined>();

  const { roomOptions, filters, handleSearch, handleChangeSelector } = useFilterRooms({ rooms });

  const isDirty = value.length !== selectedRooms.length || !selectedRooms.every(r => value.includes(r));

  const handleClickRoom = (id: string, selected: boolean) => {
    if (selected) {
      setSelectedRooms(selectedRooms.filter(r => r !== id));
    } else {
      setSelectedRooms(prevState => (multiple ? [...prevState, id] : [id]));
    }
  };

  const handleSubmit = () => {
    isDirty && onChange(selectedRooms);
    onClose();
  };

  return (
    <Modal.Root open={open} onOpenChange={open => !open && onClose()}>
      <Modal.Content className={roomsSelectorDialogStyle()}>
        <Modal.Header
          title={formatMessage({ id: "settings.content.menu.rooms.selectors.dialog.title" })}
          subtitle={formatMessage({ id: "settings.content.menu.rooms.selectors.dialog.subtitle" })}
          icon={<RoomIcon />}
        >
          <ActionButton
            variant="cancel"
            text={formatMessage({ id: "settings.content.menu.rooms.selectors.dialog.cancel" })}
            onClick={() => onClose && onClose()}
          />
          <ActionButton
            onClick={handleSubmit}
            text={formatMessage({ id: "settings.content.menu.rooms.selectors.dialog.select" })}
            icon={<ArrowRightIcon />}
            rounded
            disabled={!isDirty || selectedRooms.length === 0}
          />
        </Modal.Header>
        <div className={contentStyle()}>
          <div className={leftStyle()}>
            <h3 className={leftStyle("title")}>
              {formatMessage({ id: "settings.content.menu.rooms.selectors.dialog.rooms" })}
            </h3>
            <SelectFilters
              groups={[]}
              state={filters}
              handleSearch={handleSearch}
              handleChangeSelector={handleChangeSelector}
            />
            <div className={leftStyle("rooms")}>
              {roomOptions.map((r, index) => (
                <Room
                  key={index}
                  multiple={multiple}
                  rooms={r}
                  selected={selectedRooms.includes(r.id!)}
                  onClick={handleClickRoom}
                  handleRoomHovered={roomHovered => setRoomHovered(roomHovered)}
                />
              ))}
            </div>
          </div>
          <Plan
            multiple={multiple}
            selectedRooms={[...selectedRooms]}
            onChange={(value: string[]) => setSelectedRooms(value)}
            roomHovered={roomHovered}
          />
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
