import { faker } from "@faker-js/faker";

import type { News } from "~/apiV2";

export class NewsBuilder implements News {
  id: number;
  date: string;
  link: string;
  title: { rendered: string };
  excerpt: { rendered: string; protected?: boolean };
  docusaurus?: boolean | undefined;

  constructor() {
    this.id = faker.number.int();
    this.date = faker.date.past().toISOString();
    this.link = faker.internet.url();
    this.title = { rendered: faker.lorem.sentence() };
    this.excerpt = { rendered: faker.lorem.paragraph() };
    this.docusaurus = false;
  }

  withId(id: number) {
    this.id = id;
    return this;
  }

  withDate(date: string) {
    this.date = date;
    return this;
  }

  withLink(link: string) {
    this.link = link;
    return this;
  }

  withTitle(title: string) {
    this.title = { rendered: title };
    return this;
  }

  withExcerpt(excerpt: string) {
    this.excerpt = { rendered: excerpt };
    return this;
  }

  withDocusaurus(docusaurus: boolean) {
    this.docusaurus = docusaurus;
    return this;
  }
}
