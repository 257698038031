import { useState } from "react";

import { orderBy } from "lodash";
import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import type { BoilerRoomsPosInner, GatewaysMessageGatewaysInner, Room } from "@eisox/backend_webapp_api";
import { Circle, Modal, Select } from "@eisox/design-system";
import { BoilerRoomIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Gateway, RoomFC } from "~/UI/components";
import { Origin } from "~/UI/layouts";
import type { houseLoader } from "~/UI/screens";
import type { RessourceWithPlan } from "~/UI/screens/Plan/hooks";
import { useMoving } from "~/UI/screens/Plan/hooks";
import { idLoaderHouse } from "~/routes/utils";
import { filterArrayObj } from "~/utils/searchUtils";

import { DialogContent } from "../DialogContent";
import { DialogHeader } from "../DialogHeader";
import type { ItemOnPlan } from "../Plan";

import styles from "./MoveDialog.module.scss";

interface MoveDialogProps {
  ressource: "gateway" | "room" | "boilerroom";
  items: RessourceWithPlan[];
  initialSelectedItemId?: string;
  open: boolean;
  onClose: VoidFunction;
}

export const MoveDialog: React.FC<MoveDialogProps> = ({
  ressource,
  items: initialItems,
  initialSelectedItemId,
  open,
  onClose,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const moveDialogStyle = bem("move-dialog");

  const { plans } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const items = initialItems.map(i => ({
    ...i,
    plan: {
      planId: i.plan?.planId ?? plans[0].id,
      x: i.plan?.x ?? 50,
      y: i.plan?.y ?? 50,
    },
  }));

  const { fields, isDirty, update, onChange, Form, onSubmit } = useMoving(items, ressource, onClose);

  const sorts = {
    gateway: ["gatewayName", "uid", "meshs", "mac"],
    room: ["name"],
    boilerroom: ["name"],
  };

  const customFilters = {
    gateway: [{ key: "gatewayName" }, { key: "mac" }, { key: "uid" }],
    room: [{ key: "name" }],
    boilerroom: [{ key: "name" }],
  };

  const [hovered, setHovered] = useState<Partial<Record<"gateway" | "room" | "boilerroom", string | undefined>>>();
  const [currentItemId, setCurrentItemId] = useState<string | undefined>(initialSelectedItemId ?? items[0].id);
  const [filters, setFilters] = useState({ search: "", sort: sorts[ressource][0] });

  const currentItem = fields.find(i => i.id === currentItemId);
  //@ts-ignore : Need to delete fieldId otherwise, fieldId appear in dirtyFields
  delete currentItem?.fieldId;

  const sortedItems = orderBy(fields, [filters.sort], "asc");
  const filteredItems = filterArrayObj(sortedItems, filters.search, customFilters[ressource]);

  const currentFilteredValve = filteredItems.find(i => i.id === currentItem?.id);

  const renderItem = {
    gateway: (i: GatewaysMessageGatewaysInner) => () => (
      <Gateway
        {...i}
        uid={i.uid ?? 0}
        gatewayName={i.gatewayName ?? ""}
        problemStatus={undefined}
        displayUid
        displayTooltip={false}
        className={moveDialogStyle("item", { hovered: hovered?.gateway === i.id })}
        selected={i.id === currentItemId}
        onClick={() => setCurrentItemId(i.id)}
      />
    ),
    room: (i: Room) => () => (
      <RoomFC
        className={moveDialogStyle("item", { hovered: hovered?.room === i.id })}
        name={i.name!}
        selected={i.id === currentItemId}
        onMouseDown={() => setCurrentItemId(i.id)}
      />
    ),
    boilerroom: (i: BoilerRoomsPosInner) => () => (
      <Circle
        className={moveDialogStyle("item", { hovered: hovered?.boilerroom === i.id })}
        size={20}
        selected={i.id === currentItemId}
        onMouseDown={() => setCurrentItemId(i.id)}
      >
        <BoilerRoomIcon />
      </Circle>
    ),
  };

  const itemsOnPlan: ItemOnPlan[] = filteredItems.map(i => ({
    id: i.id!,
    x: i.plan?.x!,
    y: i.plan?.y!,
    planId: i.plan?.planId!,
    origin: ressource === "room" ? Origin.BOTTOM_CENTER : undefined,
    draggable: true,
    renderItem: renderItem[ressource](i),
  }));

  const listItems = {
    gateway: filteredItems.map((i: GatewaysMessageGatewaysInner) => ({
      id: i.id!,
      title: `${i.uid ?? "-"} - ${i.gatewayName ?? "-"}`,
      subtitle: i.mac ?? "-",
      selected: i.id === currentItem?.id,
      onClick: () => setCurrentItemId(i.id),
    })),
    room: filteredItems.map((i: Room) => ({
      id: i.id!,
      title: i.name ?? "-",
      subtitle: plans.find(p => p.id === i.plan?.planId)?.name ?? "-",
      selected: i.id === currentItem?.id,
      onClick: () => setCurrentItemId(i.id),
    })),
    boilerroom: filteredItems.map((i: BoilerRoomsPosInner) => ({
      id: i.id!,
      title: i.name ?? "",
      subtitle: plans.find(p => p.id === i.plan?.planId)?.name ?? "-",
      selected: i.id === currentItem?.id,
      onClick: () => setCurrentItemId(i.id),
    })),
  };

  return (
    <Modal.Root open={open} onOpenChange={open => !open && onClose()}>
      <Modal.Content>
        <Form className={moveDialogStyle()} onSubmit={onSubmit}>
          <DialogHeader item={ressource} disabled={!isDirty} />
          <DialogContent
            ressource={ressource}
            sorts={sorts[ressource]}
            currentItemId={currentItemId}
            items={listItems[ressource]}
            itemsOnPlan={itemsOnPlan}
            onChangeFilters={(search, sort) => setFilters({ search, sort })}
            onChangeItemsOnPlan={onChange}
            handleItemHovered={i => setHovered({ [ressource]: i })}
          >
            <Select
              label={formatMessage({ id: `plan.actions.move.${ressource}.changePlan` })}
              value={currentFilteredValve?.plan?.planId}
              options={plans.map(p => ({ value: p.id!, name: p.name! }))}
              renderValue={(value?: string) => (
                <p>
                  {value
                    ? plans.find(p => p.id === value)?.name
                    : formatMessage({ id: `plan.actions.move.${ressource}.noItemSelected` })}
                </p>
              )}
              transformOrigin={{ vertical: "bottom", horizontal: "right" }}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              onChange={(value: string) =>
                update(
                  fields.findIndex(f => f.id === currentItemId),
                  { ...currentItem, plan: { ...currentItem?.plan, planId: value } },
                )
              }
              disabled={!currentFilteredValve}
            />
          </DialogContent>
        </Form>
      </Modal.Content>
    </Modal.Root>
  );
};
