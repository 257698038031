import { mergeQueryKeys } from "@lukemorales/query-key-factory";

import { auth } from "./auth";
import { history } from "./history";
import { house } from "./house";
import { mapQuest } from "./mapQuest";
import { news } from "./news";
import { plan } from "./plan";
import { planning } from "./planning";
import { user } from "./user";
import { valve } from "./valve";

export const queries = mergeQueryKeys(auth, history, house, mapQuest, news, plan, planning, user, valve);
