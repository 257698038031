import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";
import * as yup from "yup";

import { PlansMessageInner } from "@eisox/backend_webapp_api";
import { ActionButton, TextInput } from "@eisox/design-system";
import { PencilIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { DeletePopup } from "~/UI/layouts";
import { houseLoader } from "~/UI/screens";
import { usePermissionsContext } from "~/UI/screens/House";
import { getPlan } from "~/api/plan";
import { WHITE_PLAN_EISOX } from "~/constants";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";
import { FetchResponse } from "~/helpers/communication/fetchType";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToPlan } from "~/routes/utils";
import { API } from "~/types/API";

import { UpdatePlan } from "./UpdatePlan/UpdatePlan";

import styles from "./Parameters.module.scss";

const schema = (formatMessage: FormatMessageFn) =>
  yup.object({
    name: yup.string().required(formatMessage({ id: "error.required" })),
    plan: yup.mixed(),
  });

type PatchPlanType = yup.InferType<ReturnType<typeof schema>>;

interface ParametersProps {
  plan: PlansMessageInner;
}

export const Parameters: React.FC<ParametersProps> = ({ plan }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const parametersStyle = bem("parameters");
  const contentStyle = bem("content");
  const footerStyle = bem("footer");

  const { houseId } = useParams() as { houseId: string };
  const { gateways, valves, rooms, boilerroomPos } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    typeof houseLoader
  >;

  const { permissions } = usePermissionsContext();

  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const {
    register,
    control,
    formState: { dirtyFields, isDirty, errors },
    setValue,
    handleSubmit,
    getValues,
    reset,
  } = useForm<PatchPlanType>({
    resolver: yupResolver(schema(formatMessage)),
    values: {
      name: plan.name!,
    },
  });

  const { name, plan: planValue } = getValues();

  const { submit } = useAction({
    convertFormDataToJson: false,
    onSuccess: () => {
      reset({ name, plan: planValue });
    },
  });

  useEffect(() => {
    getPlan(plan.id, houseId).then((response: FetchResponse<{ message: string }>) => {
      if (response.type === SUCCESS_FETCH) {
        fetch(response.result.message)
          .then(response => response.blob())
          .then((blob: Blob) => {
            setValue("plan", new File([blob], `${plan.id}.${blob.type.split("/")[1]}`, { type: blob.type }));
          });
      }
    });
  }, []);

  const handleOpenDeletePopup = () => setDeletePopupOpen(prevState => !prevState);

  const onSubmit = (data: PatchPlanType) => {
    const body = new FormData();
    if (dirtyFields.name) body.append("name", data.name);
    if (dirtyFields.plan) {
      data.plan
        ? body.append("image", data.plan)
        : body.append(
            "image",
            new Blob([Uint8Array.from(atob(WHITE_PLAN_EISOX.split(",")[1]), c => c.charCodeAt(0))], {
              type: WHITE_PLAN_EISOX.split(",")[0].split(":")[1].split(";")[0],
            }),
          );
    }
    submit(body, API.HTTP_METHOD.PATCH, routeToPlan(houseId, plan.id!));
  };

  const planItems = [...rooms, ...gateways, ...valves, ...boilerroomPos.flatMap(bpos => bpos.boilerRooms)].filter(
    i => i?.plan?.planId === plan.id,
  );
  const canDeletePlan = !!permissions.plan?.delete && planItems.length === 0;

  return (
    <>
      <div className={parametersStyle()}>
        <div className={contentStyle()}>
          <TextInput
            {...register("name")}
            label={formatMessage({ id: "plans.parameters.level.placeholder" })}
            error={errors.name}
          />
          <Controller
            control={control}
            name="plan"
            render={({ field: { value, onChange } }) => value && <UpdatePlan value={value} onChange={onChange} />}
          />
        </div>
        <div className={footerStyle()}>
          <ActionButton
            rounded
            text={formatMessage({ id: "plans.parameters.save" })}
            disabled={!isDirty}
            icon={<PencilIcon className={footerStyle("pencil")} />}
            onClick={() => handleSubmit(onSubmit)()}
          />
          {canDeletePlan && (
            <ActionButton
              className={footerStyle("delete")}
              variant="cancel"
              text={formatMessage({ id: "plans.parameters.delete" })}
              onClick={handleOpenDeletePopup}
            />
          )}
        </div>
      </div>
      {deletePopupOpen && (
        <DeletePopup
          open={deletePopupOpen}
          onClose={handleOpenDeletePopup}
          elementToDelete={plan.name!}
          route={routeToPlan(houseId, plan.id!)}
        />
      )}
    </>
  );
};
