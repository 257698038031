import { forwardRef } from "react";

import { cx } from "class-variance-authority";
import { useIntl } from "react-intl";

import { RoundIcon } from "@eisox/design-system";
import { UserIcon } from "@eisox/icons";

import styles from "./PresenceCard.module.scss";

interface PresenceCardProps extends React.ComponentPropsWithRef<"div"> {
  presence: boolean;
}

export const PresenceCard = forwardRef<HTMLDivElement, PresenceCardProps>((props, forwardedRef) => {
  const { presence, className, ...presenceCardProps } = props;

  const { formatMessage } = useIntl();

  return (
    <div {...presenceCardProps} ref={forwardedRef} className={cx(styles.presenceCard, className)}>
      <RoundIcon size={70} backgroundColor="gray">
        <UserIcon className={styles.presenceCard__icon} />
      </RoundIcon>
      <p>{formatMessage({ id: "dash.presence" }, { p: presence })}</p>
    </div>
  );
});

PresenceCard.displayName = "DashboardPresenceCard";
