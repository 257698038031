import { useState } from "react";

import { LatLng } from "leaflet";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";

import type { PatchHouse } from "@eisox/backend_webapp_api";
import { ActionButton, TextInput } from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Map } from "~/UI/components";
import { DeletePopup, SearchLocation } from "~/UI/layouts";
import type { houseLoader } from "~/UI/screens";
import { usePermissionsContext } from "~/UI/screens";
import type { Location } from "~/apiV2";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToHouse } from "~/routes/utils";
import { API } from "~/types/API";

import { getHousePosition } from "./utils";

import styles from "./General.module.scss";

interface GeneralParametersDto {
  houseName: string;
  location: Location;
}

export const General: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const generalStyle = bem("general");

  const { permissions } = usePermissionsContext();

  const { house } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { houseId } = useParams() as { houseId: string };
  const { submit, state } = useAction<string>({
    onSuccess: () => {
      reset({}, { keepValues: true });
      toast(formatMessage({ id: "settings.content.menu.houses.general.sucess" }), { type: "success" });
    },
  });

  const {
    control,
    register,
    formState: { isDirty, dirtyFields, errors },
    handleSubmit,
    reset,
  } = useForm<GeneralParametersDto>({
    mode: "onChange",
    defaultValues: {
      houseName: house.houseName,
      location: { latLng: getHousePosition(house.latitude, house.longitude) },
    },
  });

  const [deleteOpen, setDeleteOpen] = useState(false);

  const onSubmit = (data: GeneralParametersDto) => {
    const body: PatchHouse = {};
    if (dirtyFields.houseName) body.houseName = data.houseName;
    if (dirtyFields.location) {
      body.latitude = data.location.latLng.lat.toString();
      body.longitude = data.location.latLng.lng.toString();
    }
    submit(body, API.HTTP_METHOD.PATCH, routeToHouse(houseId));
  };

  return (
    <div className={generalStyle()}>
      <TextInput
        {...register("houseName", { required: true })}
        className={generalStyle("input")}
        label={formatMessage({ id: "settings.content.menu.houses.general.name" })}
        disabled={!permissions.house?.houseName?.update}
        error={errors.houseName}
      />
      <TextInput
        className={generalStyle("input")}
        label={formatMessage({ id: "settings.content.menu.houses.general.type" })}
        value={formatMessage({ id: "settings.content.menu.houses.general.professional" })}
        disabled
      />
      <label>
        <span className={generalStyle("label")}>
          {formatMessage({ id: "settings.content.menu.houses.general.position" })}
        </span>
        <Controller
          control={control}
          name="location"
          render={({ field: { value, onChange } }) => (
            <SearchLocation
              className={generalStyle("search")}
              value={value}
              onChange={location => onChange(location)}
              disabled={!permissions.house?.longitude?.update}
            />
          )}
        />
      </label>

      <Controller
        control={control}
        name="location"
        render={({ field: { value, onChange } }) => (
          <Map
            className={generalStyle("map")}
            positions={[
              {
                position: new LatLng(value.latLng.lat, value.latLng.lng),
                draggable: permissions.house?.longitude?.update,
                onChangePosition: latLng => onChange({ ...value, latLng: latLng }),
              },
            ]}
            onMapClick={
              permissions.house?.longitude?.update ? latLng => onChange({ ...value, latLng: latLng }) : undefined
            }
          />
        )}
      />

      <ActionButton
        onClick={handleSubmit(onSubmit)}
        className={generalStyle("button", { variant: "primary" })}
        text={formatMessage({ id: "settings.content.menu.houses.general.save" })}
        icon={<ArrowRightIcon />}
        disabled={!isDirty || ["submitting", "loading"].includes(state)}
      />
      {permissions.house?.delete && (
        <ActionButton
          className={generalStyle("button", { variant: "danger" })}
          text={formatMessage({ id: "settings.content.menu.houses.general.delete.delete" })}
          variant="danger"
          onClick={() => setDeleteOpen(true)}
        />
      )}
      {deleteOpen && (
        <DeletePopup
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          elementToDelete={house.houseName!}
          text={formatMessage({ id: "settings.content.menu.houses.general.delete.warning" })}
          route={routeToHouse(houseId)}
        />
      )}
    </div>
  );
};
