import { useState } from "react";

import { orderBy } from "lodash";
import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import type { GatewaysMessageGatewaysInner, ValvesMessageValvesInner } from "@eisox/backend_webapp_api";
import { GatewaysMessageGatewaysInnerBrModRfEnum } from "@eisox/backend_webapp_api";
import type { Option } from "@eisox/design-system";
import { Modal, Select } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import { Gateway, RoomFC } from "~/UI/components";
import { Origin } from "~/UI/layouts";
import { Valve } from "~/UI/layouts/Valve";
import type { houseLoader } from "~/UI/screens";
import { useMoving } from "~/UI/screens/Plan/hooks";
import { RoomsSelect } from "~/UI/screens/Settings/pages/Rooms";
import { VAVLE_MIN_SOFTWARE_VERSIONS } from "~/constants";
import { idLoaderHouse } from "~/routes/utils";
import { isVersionIsUpper } from "~/utils";
import type { KeyFunctionToCompare } from "~/utils/searchUtils";
import { filterArrayObj } from "~/utils/searchUtils";

import { DialogContent } from "../DialogContent";
import { DialogHeader } from "../DialogHeader";
import type { ItemOnPlan } from "../Plan";

import styles from "./Valve.module.scss";

type SortFilterBy = "roomId" | "uid" | "mac";

interface CustomSearchFilter extends KeyFunctionToCompare<ValvesMessageValvesInner> {
  key: SortFilterBy;
}

interface ValvesProps {
  open: boolean;
  initialSelectedValveId?: string;
  onClose: VoidFunction;
}

export const Valves: React.FC<ValvesProps> = ({ open, initialSelectedValveId, onClose }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const valvesDialogStyle = bem("moving-valves");

  const { valves, rooms, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;

  const { fields, isDirty, update, onChange, Form, onSubmit } = useMoving(valves, "valve", onClose);

  const [displayed, setDisplayed] = useState<Record<"gateway" | "room", boolean>>({ gateway: true, room: true });
  const [hovered, setHovered] = useState<Partial<Record<"valve" | "gateway" | "room", string | undefined>>>();
  const [currentValveId, setCurrentValveId] = useState<string | undefined>(initialSelectedValveId ?? valves[0].id);
  const [filters, setFilters] = useState({ search: "", sort: "uid" });

  const customFilters: CustomSearchFilter[] = [
    { key: "uid" },
    { key: "mac" },
    {
      key: "roomId",
      function: (v, filterValue) =>
        rooms
          .find(r => r.id === v.roomId)
          ?.name?.toUpperCase()
          .includes(filterValue) ?? false,
    },
  ];

  const isGatewayIsDisabledForModuleRF = (gateway: GatewaysMessageGatewaysInner, valve: ValvesMessageValvesInner) =>
    gateway.brModRf === GatewaysMessageGatewaysInnerBrModRfEnum.Bpsk20 && valve.softwareVersion
      ? !isVersionIsUpper(valve.softwareVersion, VAVLE_MIN_SOFTWARE_VERSIONS.MODE_RF)
      : false;

  const sortedValves = orderBy(fields, [filters.sort], "asc");
  const filteredValves = filterArrayObj(sortedValves, filters.search, customFilters);

  const currentFilteredValve = filteredValves.find(v => v.id === currentValveId);
  //@ts-ignore
  delete currentFilteredValve?.fieldId;

  const gatewayItems: ItemOnPlan[] = gateways.map(g => ({
    id: g.id,
    x: g.plan?.x!,
    y: g.plan?.y!,
    planId: g.plan?.planId,
    renderItem: () => (
      <Gateway
        problemStatus={undefined}
        className={valvesDialogStyle("gateway", { hovered: hovered?.gateway === g.id })}
        uid={g.uid}
        gatewayName={g.gatewayName}
        disabled={isGatewayIsDisabledForModuleRF(g, currentFilteredValve!)}
        expanded
        selected={g.id === currentFilteredValve?.gatewayId}
      />
    ),
  }));

  const roomItems = rooms.map(r => ({
    id: r.id!,
    x: r.plan?.x!,
    y: r.plan?.y!,
    planId: r.plan?.planId,
    origin: Origin.BOTTOM_CENTER,
    renderItem: () => (
      <RoomFC
        className={valvesDialogStyle("room", { hovered: hovered?.room === r.id })}
        name={r.name!}
        selected={r.id === currentFilteredValve?.roomId}
      />
    ),
  }));

  const valvesItems = filteredValves.map(v => ({
    id: v.id!,
    x: v.plan?.x!,
    y: v.plan?.y!,
    planId: v.plan!.planId!,
    draggable: true,
    renderItem: () => (
      <Valve
        errors={[]}
        warnings={[]}
        problemStatus={undefined}
        className={valvesDialogStyle("valve", { hovered: hovered?.valve === v.id })}
        selected={currentFilteredValve?.id === v.id}
        onMouseDown={() => setCurrentValveId(v.id)}
      />
    ),
  }));

  const items: ItemOnPlan[] = [];

  displayed.gateway && items.push(...gatewayItems);
  displayed.room && items.push(...roomItems);
  items.push(...valvesItems);

  return (
    <Modal.Root open={open} onOpenChange={open => !open && onClose()}>
      <Modal.Content>
        <Form className={valvesDialogStyle()} onSubmit={onSubmit}>
          <DialogHeader item="valve" disabled={!isDirty} />
          <DialogContent
            ressource="valve"
            sorts={["uid", "mac", "roomName"]}
            currentItemId={currentValveId}
            items={filteredValves.map(v => ({
              id: v.id!,
              title: `${v.uid ?? "-"} - ${v.mac ?? "-"}`,
              subtitle: rooms.find(r => r.id === v.roomId)?.name ?? "-",
              selected: v.id === currentFilteredValve?.id,
              onClick: () => setCurrentValveId(v.id),
            }))}
            itemsOnPlan={items}
            planFilters={{
              gateway: {
                selected: displayed.gateway,
                onClick: () => setDisplayed(prevState => ({ ...prevState, gateway: !prevState.gateway })),
              },
              room: {
                selected: displayed.room,
                onClick: () => setDisplayed(prevState => ({ ...prevState, room: !prevState.room })),
              },
            }}
            onChangeFilters={(search, sort) => setFilters({ search, sort })}
            onChangeItemsOnPlan={onChange}
            handleItemHovered={itemId => {
              const hoveredValve = filteredValves.find(v => v.id === itemId);
              setHovered({
                room: roomItems.find(r => r.id === hoveredValve?.roomId)?.id,
                gateway: gatewayItems.find(g => g.id === hoveredValve?.gatewayId)?.id,
                valve: hoveredValve?.id,
              });
            }}
          >
            <RoomsSelect
              label={formatMessage({ id: "plan.actions.move.valve.changeRoom" })}
              multiple={false}
              rooms={rooms}
              value={currentFilteredValve?.roomId as string}
              onChange={(value: string) => {
                const plan = rooms.find(r => r.id === value)?.plan;
                update(
                  fields.findIndex(f => f.id === currentValveId),
                  {
                    ...currentFilteredValve,
                    roomId: value,
                    plan: { planId: plan?.planId, x: plan?.x, y: plan?.y },
                  },
                );
              }}
              handleRoomHovered={roomId => setHovered({ room: roomId })}
              transformOrigin={{ vertical: "bottom", horizontal: "right" }}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              disabled={!currentFilteredValve}
            />
            <Select
              label={formatMessage({ id: "plan.actions.move.valve.changeGateway" })}
              value={currentFilteredValve?.gatewayId}
              options={gateways.map(g => ({
                value: g.id,
                name: g.gatewayName,
                disabled: isGatewayIsDisabledForModuleRF(g, currentFilteredValve!),
              }))}
              renderOption={(option: Option) => (
                <p
                  style={{ width: "100%" }}
                  onMouseEnter={() => setHovered({ gateway: option.value })}
                  onMouseLeave={() => setHovered({})}
                >
                  {option.name}
                </p>
              )}
              renderValue={(value?: string) => (
                <p>
                  {value
                    ? gateways.find(g => g.id === value)?.gatewayName
                    : formatMessage({ id: "plan.actions.move.valve.noValveSelected" })}
                </p>
              )}
              transformOrigin={{ vertical: "bottom", horizontal: "right" }}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              onChange={(value: string) =>
                update(
                  fields.findIndex(f => f.id === currentValveId),
                  {
                    ...currentFilteredValve,
                    gatewayId: value,
                  },
                )
              }
              disabled={!currentFilteredValve}
            />
          </DialogContent>
        </Form>
      </Modal.Content>
    </Modal.Root>
  );
};
