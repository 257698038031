import { z } from "@eisox/zod";

const heatingCurveSchema = z.object({
  auto: z.boolean(),
  hslopeTarget: z.number(),
  maxOffsetAdaptation: z.number(),
  heatingCurve: z.array(z.object({ ext: z.number(), con: z.number(), offset: z.number().optional() })),
});

type HeatingCurveSchemaType = z.infer<typeof heatingCurveSchema>;

export { heatingCurveSchema };
export type { HeatingCurveSchemaType };
