import { useLayoutEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import type { ReactZoomPanPinchRef, ReactZoomPanPinchState } from "react-zoom-pan-pinch";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { Circle } from "@eisox/design-system";
import { BoilerRoomIcon, ChevronLeftIcon, ChevronRightIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { routeToBoilerroom } from "~/routes/utils";
import { BoilerromLayout } from "~/stores";

import { getParentBoilerRoom } from "../../helpers";
import { useBoilerRoomContextWithCurrentBoilerRoom } from "../../providers";
import { Boilers } from "../Boilers";
import { Card } from "../Card";
import { HeatingNetworks } from "../HeatingNetworks";
import { SetpointTemperature } from "../SetpointTemperature";
import { Temperature } from "../Temperature";

import styles from "./Boilerroom.module.scss";

export interface Parent {
  id: string;
  name: string;
  boilerroomName: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

interface BoilerroomProps {
  layoutState: BoilerromLayout;
}

const NAME = "Boilerroom";

export const Boilerroom: React.FC<BoilerroomProps> = ({ layoutState }) => {
  const bem = useBem(styles);
  const boilerroomStyle = bem("boilerroom");
  const contentStyle = bem("content");
  const waterlineStyle = bem("waterline");

  const { houseId, currentBoilerRoom, boilerRooms } = useBoilerRoomContextWithCurrentBoilerRoom(NAME);
  const id = currentBoilerRoom.id;

  const parent: Parent | undefined = getParentBoilerRoom(currentBoilerRoom, boilerRooms ?? []);

  const containerRef = useRef<HTMLDivElement>(null);
  const boilerroomRef = useRef<HTMLDivElement>(null);

  const [{ scale, positionX, positionY }, setState] = useState<Omit<ReactZoomPanPinchState, "previousScale">>({
    scale: 1,
    positionX: 0,
    positionY: 0,
  });
  const [displayTranslateLeftButton, setDisplayTranslateLeftButton] = useState(false);
  const [displayTranslateRightButton, setDisplayTranslateRightButton] = useState(false);

  useLayoutEffect(() => {
    const container = containerRef.current;
    const boilerroom = boilerroomRef.current;
    if (container === null || boilerroom === null) return;
    const containerHeight = container.getBoundingClientRect().height;
    const containerWidth = container.getBoundingClientRect().width;
    const boilerroomHeight = boilerroom.getBoundingClientRect().height;
    const boilerroomWidth = boilerroom.getBoundingClientRect().width;
    const scale = containerHeight / boilerroomHeight > 1 ? 1 : containerHeight / boilerroomHeight;

    setState({
      scale,
      positionX: containerWidth > boilerroomWidth ? (containerWidth - boilerroomWidth) / 2 : 0,
      positionY: (containerHeight - boilerroomHeight * scale) / 2,
    });
    setDisplayTranslateRightButton(containerWidth < boilerroomWidth);
  }, [containerRef, boilerroomRef, id, layoutState]);

  const handleMouseClick = (
    side: "right" | "left",
    setTransform: (newPositionX: number, newPositionY: number, newScale: number) => void,
    context: ReactZoomPanPinchRef,
  ) => {
    const container = containerRef.current;
    const boilerroom = boilerroomRef.current;
    if (container === null || boilerroom === null) return;
    const containerWidth = container.getBoundingClientRect().width;
    const boilerroomWidth = boilerroom.getBoundingClientRect().width;
    const translation = containerWidth - boilerroomWidth;
    setTransform(side === "right" ? translation : 0, context.state.positionY, context.state.scale);
  };

  const handleTransform = (
    _: ReactZoomPanPinchRef,
    state: {
      scale: number;
      positionX: number;
      positionY: number;
    },
  ) => {
    const container = containerRef.current;
    const boilerroom = boilerroomRef.current;
    if (container !== null && boilerroom !== null) {
      const containerWidth = container.getBoundingClientRect().width;
      const boilerroomWidth = boilerroom.getBoundingClientRect().width;
      const conditionLeft = state.positionX < 0;
      const conditionRight = Math.round(boilerroomWidth - containerWidth + state.positionX) > 0;
      setDisplayTranslateLeftButton(containerWidth < boilerroomWidth && conditionLeft);
      setDisplayTranslateRightButton(containerWidth < boilerroomWidth && conditionRight);
    }
  };

  const { setpointTemperature, startingTemperature, returnTemperature } = currentBoilerRoom;
  const boilers = currentBoilerRoom.boilers ?? [];

  const heatingNetworks = currentBoilerRoom.heatingNetworks ?? [];
  const layout = heatingNetworks.length === 1 ? BoilerromLayout.PARALLEL : layoutState;

  return (
    <div className={boilerroomStyle()} ref={containerRef}>
      <TransformWrapper
        key={`${id}x${layout}x${scale}x${positionX}x${positionY}`}
        initialScale={scale}
        initialPositionX={positionX}
        initialPositionY={positionY}
        minScale={scale}
        maxScale={1}
        onTransformed={handleTransform}
      >
        {({ setTransform, instance: { getContext } }) => (
          <>
            <TransformComponent
              wrapperStyle={{
                width: "100%",
                height: "100%",
                cursor: "grab",
                willChange: "transform",
              }}
            >
              <div ref={boilerroomRef} className={contentStyle({ layout })}>
                {parent && (
                  <Link className={contentStyle("link")} to={routeToBoilerroom(houseId, parent.id)}>
                    <Card
                      className={contentStyle("parent", { layout })}
                      title={parent.name}
                      subtitle={parent.boilerroomName}
                      icon={<BoilerRoomIcon className={contentStyle("boilerroom-icon")} />}
                    />
                  </Link>
                )}
                {boilers.length > 0 && <Boilers layout={layout} boilers={boilers} />}
                <div className={waterlineStyle({ layout })}>
                  {setpointTemperature && (
                    <SetpointTemperature
                      className={waterlineStyle("setPointTemperature")}
                      setpointTemperature={setpointTemperature}
                    />
                  )}
                  {startingTemperature !== undefined ? (
                    <Temperature type={"starting"} orientation="vertical" value={startingTemperature} />
                  ) : (
                    <div className={waterlineStyle("temperature")} />
                  )}
                  <div className={waterlineStyle("hot")} />
                  <div className={waterlineStyle("cold", { layout, parent: !!parent })} />
                  {returnTemperature !== undefined ? (
                    <Temperature type={"returning"} orientation="vertical" value={returnTemperature} />
                  ) : (
                    <div className={waterlineStyle("temperature")} />
                  )}
                </div>
                <HeatingNetworks layout={layout} heatingNetworks={heatingNetworks} />
              </div>
            </TransformComponent>
            {displayTranslateLeftButton && (
              <Circle
                className={boilerroomStyle("button", { left: true })}
                onClick={() => handleMouseClick("left", setTransform, getContext())}
              >
                <ChevronLeftIcon />
              </Circle>
            )}
            {displayTranslateRightButton && (
              <Circle
                className={boilerroomStyle("button", { right: true })}
                onClick={() => handleMouseClick("right", setTransform, getContext())}
              >
                <ChevronRightIcon />
              </Circle>
            )}
          </>
        )}
      </TransformWrapper>
    </div>
  );
};
