import React, { useState } from "react";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import type { Planning as ApiPlanning, HouseMessage, PutPlanning } from "@eisox/backend_webapp_api";
import { PlanningPlanningTypeEnum } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import { Alert, Button, Card, DropdownMenu, Radio } from "@eisox/design-system";
import { ArrowRightIcon, OptionIcon, PlusIcon } from "@eisox/icons";
import { isRoomFrostFree } from "@eisox/rooms";

import { PlanningTypeKey } from "~/UI/components";
import { Week } from "~/UI/layouts/Week";
import type { RoomsWithProblem } from "~/UI/screens/House";
import { usePermissionsContext } from "~/UI/screens/House";
import { TimeslotPopup } from "~/UI/screens/Settings/pages/Rooms/pages/Planning/components";
import { useAction } from "~/hooks";
import { routeToFrostFreeSettings, routeToPlannings } from "~/routes/utils";
import { API } from "~/types/API";
import type { TempType } from "~/utils";
import { getTemperature } from "~/utils";

import { useRoomContext } from "../../context";

import styles from "./Planning.module.scss";

interface PlanningProps {
  house: HouseMessage;
  room: RoomsWithProblem;
  plannings: ApiPlanning[];
  className?: string;
}

export const Planning: React.FC<PlanningProps> = ({ house, room, plannings, className }) => {
  const { t } = useTranslation();

  const { houseId } = useParams() as { houseId: string };
  const { permissions } = usePermissionsContext();
  const { setParametersOpen } = useRoomContext();

  const navigate = useNavigate();

  const [displayUserDefinedPlanning, setDisplayUserDefinedPlanning] = useState<boolean>(true);
  const [openTimeslotPopup, setOpenTimeslotPopup] = useState(false);
  const [planningToUpdateId, setPlanningToUpdateId] = useState<number | undefined>(undefined);

  const { isFrostFree, type, frostFreePeriod } = isRoomFrostFree({ ...room }, { ...house });

  const handleClosePopup = () => {
    setOpenTimeslotPopup(false);
    planningToUpdateId !== undefined && setPlanningToUpdateId(undefined);
  };

  const { submit } = useAction({ onSuccess: () => handleClosePopup() });

  const handleReplacePlannings = (plannings: ApiPlanning[]) => {
    const body: PutPlanning = { roomIds: [room.id!] };
    body.plannings = [
      ...plannings.map(p => ({
        day: p.day,
        hourStart: p.hourStart,
        hourEnd: p.hourEnd,
        planningType: p.planningType,
      })),
    ];
    submit(body, API.HTTP_METHOD.PUT, routeToPlannings(houseId));
  };

  const handleRemovePlanning = (planningIndexes: number[]) => {
    const body: PutPlanning = { roomIds: [room.id!] };
    body.plannings = [
      ...plannings
        .filter(p => p.userDefined)
        .filter((_, index) => !planningIndexes.includes(index))
        .map(p => ({
          day: p.day,
          hourStart: p.hourStart,
          hourEnd: p.hourEnd,
          planningType: p.planningType,
        })),
    ];
    submit(body, API.HTTP_METHOD.PUT, routeToPlannings(houseId));
  };

  return (
    <>
      <Card className={clsx(styles.planning, className)}>
        <div className={styles.planning__header}>
          <h2>{t("plan.planning")}</h2>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <OptionIcon
                className={styles.planning__header__options}
                style={{ marginLeft: "auto", width: 20, height: 20, cursor: "pointer" }}
              />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content style={{ zIndex: 1300 }} sideOffset={10} align="end">
              <DropdownMenu.Group>
                <DropdownMenu.Label>{t("plannings.popup.header.options.display")}</DropdownMenu.Label>
                <DropdownMenu.Item onSelect={() => setDisplayUserDefinedPlanning(!displayUserDefinedPlanning)}>
                  <Radio multiple checked={displayUserDefinedPlanning} style={{ marginRight: 10 }} />
                  {t("plannings.popup.header.options.userDefinedPopup")}
                </DropdownMenu.Item>
              </DropdownMenu.Group>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
        <div className={styles.planning__control}>
          {permissions.planning?.create && (
            <Button
              className={clsx(styles.planning__control__add)}
              text={t("plannings.add")}
              icon={<PlusIcon style={{ width: 10, height: 10 }} />}
              onClick={() => setOpenTimeslotPopup(true)}
            />
          )}
        </div>
        <div className={styles.planning__content}>
          <Week
            plannings={plannings.map((p, i) => {
              if (p.userDefined && permissions.planning?.update) {
                return {
                  ...p,
                  onClick: () => {
                    setPlanningToUpdateId(i);
                    setOpenTimeslotPopup(true);
                  },
                };
              }
              return { ...p, temperature: getTemperature(house, room, p.planningType!) };
            })}
            displayUserDefined={displayUserDefinedPlanning}
          />
          {isFrostFree && (
            <div className={styles.planning__content__alert}>
              <Alert
                className={styles.planning__content__isFrostFree}
                onClick={() => {
                  if (permissions.room?.isFrostFree?.update) {
                    if (type === "room") setParametersOpen(true);
                    else if (type === "house") navigate(routeToFrostFreeSettings(houseId));
                  }
                }}
              >
                <p>
                  {t(`plan.frostFree.${type}`, {
                    context: frostFreePeriod && "withPeriod",
                    begin: frostFreePeriod?.begin && dayjs(frostFreePeriod.begin).format("DD/MM/YYYY"),
                    end: frostFreePeriod?.end && dayjs(frostFreePeriod.end).format("DD/MM/YYYY"),
                  })}
                </p>
                <ArrowRightIcon />
              </Alert>
            </div>
          )}
        </div>

        <div className={styles.planning__legend}>
          {[...Object.values(PlanningPlanningTypeEnum), "frostFree", "userDefined"].map((v, index) => {
            const isUserDefined = v === "userDefined";
            const temp = !isUserDefined ? getTemperature(house, room, v as TempType) : undefined;

            return (
              <PlanningTypeKey
                key={index}
                planningType={v as PlanningPlanningTypeEnum}
                temperature={{ show: !isUserDefined, value: temp }}
              />
            );
          })}
        </div>
      </Card>
      {openTimeslotPopup && (
        <TimeslotPopup
          timeslot={planningToUpdateId ? plannings[planningToUpdateId] : undefined}
          timeslots={plannings.filter(p => p.userDefined)}
          roomType={room.roomType}
          onClose={() => {
            setOpenTimeslotPopup(false);
            planningToUpdateId !== undefined && setPlanningToUpdateId(undefined);
          }}
          open={openTimeslotPopup}
          replace={plannings => handleReplacePlannings(plannings as ApiPlanning[])}
          remove={planningIndexes => handleRemovePlanning(planningIndexes)}
        />
      )}
    </>
  );
};
