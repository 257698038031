import { Controller, Control as RHFControl } from "react-hook-form";
import { useIntl } from "react-intl";

import { Select, Typography } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import CommutatorIcon from "~/assets/svg/commutator.svg?react";
import { HeatingNetwork, PumpCommand, PumpCommandMan, PumpState, Pumps } from "~/socketio/types";
import { getPumpState, isOnlyOnePump } from "~/utils/schemeUtils";

import { ControlState } from "../ControlState";

import styles from "./PumpControlState.module.scss";

interface CommutatorProps {
  cm1: PumpCommandMan;
  cm2?: PumpCommandMan;
}

const Commutator: React.FC<CommutatorProps> = ({ cm1, cm2 }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const commutatorStyle = bem("commutator");

  const inconsistency = !!cm2 && cm1 !== cm2;

  const autoOrManual = (cm: PumpCommandMan) => (cm === PumpCommandMan.AUTO ? "auto" : "manual");

  const cm1State = formatMessage({ id: `boilerRoom.dialog.network.content.pumps.commandMan.${autoOrManual(cm1)}` });

  return (
    <p className={commutatorStyle()}>
      {inconsistency ? (
        <>
          {formatMessage(
            { id: "boilerRoom.dialog.network.content.pumps.commandMan.inconsistentCommutator" },
            {
              cm1: cm1State,
              cm2: formatMessage({ id: `boilerRoom.dialog.network.content.pumps.commandMan.${autoOrManual(cm2)}` }),
            },
          )}
          <span className={commutatorStyle("defect")} />
        </>
      ) : (
        <>
          {cm1State}
          <Tooltip
            content={formatMessage(
              { id: "boilerRoom.dialog.network.content.pumps.commandMan.commutator" },
              { cm: cm1State.toLowerCase() },
            )}
          >
            <CommutatorIcon className={commutatorStyle("icon")} />
          </Tooltip>
        </>
      )}
    </p>
  );
};

interface ControlProps {
  type: "pumps" | "recyclePumpsECS";
  control: RHFControl<Omit<HeatingNetwork, "id">, any>;
  pumps: Pumps;
  history: boolean;
}

const Control: React.FC<ControlProps> = ({ type, control, pumps, history }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const selectStyle = bem("select");

  const displayCommutator = !!pumps.commandManP1;
  const inconsistency = !!pumps.commandManP2 && pumps.commandManP1 !== pumps.commandManP2;

  return (
    <>
      {displayCommutator && <Commutator cm1={pumps.commandManP1!} cm2={pumps.commandManP2} />}
      {pumps.commandManP1 !== PumpCommandMan.MANUAL && !inconsistency && (
        <Controller
          control={control}
          name={`${type}.command`}
          render={({ field: { value, onChange } }) => (
            <>
              <Select
                className={selectStyle()}
                value={value?.toString()}
                options={[
                  {
                    name: formatMessage({ id: "boilerRoom.dialog.network.auto" }),
                    value: "4",
                  },
                  {
                    name: formatMessage({ id: "boilerRoom.dialog.network.manual" }),
                    value: "1",
                  },
                ]}
                renderValue={(value?: string) => (
                  <Typography>
                    {formatMessage({
                      id: `pumps.state.${parseInt(value!) !== 4 ? "manual" : "auto"}`,
                    })}
                  </Typography>
                )}
                onChange={(value: string) => onChange(parseInt(value))}
                disabled={history}
              />
              {value !== PumpCommand.AUTO && (
                <Select
                  className={selectStyle()}
                  value={value?.toString()}
                  options={[PumpCommand.OFF, PumpCommand.PUMP_1, PumpCommand.PUMP_2]
                    .filter(v => (isOnlyOnePump(pumps) ? v !== PumpCommand.PUMP_2 : true))
                    .map(v => ({
                      value: v.toString(),
                      name: formatMessage(
                        { id: `boilerRoom.dialog.network.pumps.command.${v}` },
                        { single: isOnlyOnePump(pumps) },
                      ),
                    }))}
                  renderValue={(value?: string) => (
                    <Typography>
                      {formatMessage(
                        { id: `boilerRoom.dialog.network.pumps.command.${value}` },
                        { single: isOnlyOnePump(pumps) },
                      )}
                    </Typography>
                  )}
                  onChange={(value: string) => onChange(parseInt(value))}
                  disabled={history}
                />
              )}
            </>
          )}
        />
      )}
    </>
  );
};

interface StateProps {
  pumps: Pumps;
}

const State: React.FC<StateProps> = ({ pumps }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const stateStyle = bem("state");
  const defectStyle = bem("defect");

  const displayedPumpState = (state: Pumps) => {
    if (
      (isOnlyOnePump(state) && state.stateP1 === undefined) ||
      (state.stateP1 === undefined && state.stateP2 === undefined)
    )
      return "-";
    if (isOnlyOnePump(state))
      return formatMessage({ id: `boilerRoom.dialog.network.pumps.state.${state.stateP1 ? "on" : "off"}` });
    let doublePumpState = "";
    if (state.stateP1 === PumpState.ON && state.stateP2 === PumpState.ON) doublePumpState = "on";
    if (state.stateP1 === PumpState.ON && state.stateP2 !== PumpState.ON) doublePumpState = "left";
    if (state.stateP1 !== PumpState.ON && state.stateP2 === PumpState.ON) doublePumpState = "right";
    if (state.stateP1 === PumpState.OFF && state.stateP2 === PumpState.OFF) doublePumpState = "off";
    return formatMessage({ id: `boilerRoom.dialog.network.pumps.state.${doublePumpState}` });
  };

  let mismatchP1 =
    getPumpState(pumps.stateP1, pumps.commandAutomateP1, pumps.commandManP1, pumps.defectP1).state === "mismatch";
  let mismatchP2 = false;
  if (!isOnlyOnePump()) {
    mismatchP2 =
      getPumpState(pumps.stateP2, pumps.commandAutomateP2, pumps.commandManP2, pumps.defectP2).state === "mismatch";
  }

  const defect = !!pumps.defectP1 || !!pumps.defectP2;
  const mismatch = mismatchP1 || mismatchP2;

  return (
    <>
      <span className={stateStyle()}>{displayedPumpState(pumps)}</span>
      {(defect || mismatch) &&
        (mismatchP1 || mismatchP2 ? (
          <Tooltip
            content={
              <>
                {mismatchP1 && (
                  <p>
                    {formatMessage(
                      {
                        id: "pumps.tootltip.mismatchError",
                      },
                      { s: pumps.stateP1, ca: pumps.commandAutomateP1 },
                    )}
                  </p>
                )}
                {mismatchP2 && (
                  <p>
                    {formatMessage(
                      {
                        id: "pumps.tootltip.mismatchError",
                      },
                      { s: pumps.stateP2, ca: pumps.commandAutomateP2 },
                    )}
                  </p>
                )}
              </>
            }
          >
            <div className={defectStyle()} />
          </Tooltip>
        ) : (
          <div className={defectStyle()} />
        ))}
    </>
  );
};

interface PumpControlStateProps {
  type: "pumps" | "recyclePumpsECS";
  control: RHFControl<Omit<HeatingNetwork, "id">, any>;
  pumps: Pumps;
  history: boolean;
}

export const PumpControlState: React.FC<PumpControlStateProps> = ({ type, control, pumps, history }) => {
  const { formatMessage } = useIntl();

  return (
    <ControlState
      title={formatMessage({ id: `boilerRoom.dialog.network.${type}.title` })}
      control={<Control control={control} type={type} pumps={pumps} history={history} />}
      state={<State pumps={pumps} />}
    />
  );
};
