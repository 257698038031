import { forwardRef } from "react";

import clsx from "clsx";
import type { FieldError, Merge } from "react-hook-form";

import { useBem } from "@eisox/tools";

import styles from "./styles.module.scss";

export interface TextInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  error?: Merge<FieldError, (FieldError | undefined)[]> | FieldError | undefined | boolean;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  inputClassName?: string;
  fixValue?: string;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { label, name, icon, disabled, iconPosition = "right", inputClassName, type, className, error, fixValue, ...props },
    ref,
  ) => {
    const bem = useBem(styles);
    const textInputStyle = bem("text-input");
    const containerInput = bem("container-input");
    return (
      <div className={clsx(textInputStyle(), className)}>
        {label && (
          <label className={textInputStyle("label")} htmlFor={name}>
            {label}
          </label>
        )}
        <div
          className={clsx(
            containerInput({ iconPosition: iconPosition, disabled: !!disabled, error: !!error }),
            inputClassName,
          )}
        >
          <div className={containerInput("input")}>
            {fixValue && <p>{fixValue}</p>}
            <input {...props} ref={ref} type={type} name={name} id={name} disabled={disabled} />
          </div>
          {icon && icon}
        </div>
        {typeof error === "object" && error.message && <p className={textInputStyle("error")}>{error.message}</p>}
      </div>
    );
  },
);
