import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Link, useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import { $enum } from "ts-enum-util";
import * as yup from "yup";

import type { PatchHouse } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import { ActionButton, Alert, Select } from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { Slider } from "~/UI/components";
import type { houseLoader } from "~/UI/screens";
import { usePermissionsContext } from "~/UI/screens";
import {
  DEFAULT_SETPOINTS,
  DEFAULT_TEMPERATURES,
  DEFAULT_TEMPORISATION,
  GATEWAY_MIN_SOFTWARE_VERSIONS,
  TEMPERATURE_LIMITS,
} from "~/constants";
import { useAction } from "~/hooks";
import {
  idLoaderHouse,
  routeToFrostFreeSettings,
  routeToFunctionsSettings,
  routeToRoomsFrostFreeSettings,
  routeToSeasonSettings,
} from "~/routes/utils";
import { API } from "~/types/API";
import type { ErrorType } from "~/types/ReactHookFormType";
import { canAccessBoilerroom, isVersionIsUpper } from "~/utils";

import { Periods } from "../../layouts";
import type { ParametersType, SelectorsType } from "../Rooms";
import { getFrostFreeRoomsNumber, getInitialState } from "./utils";

import styles from "./FrostFree.module.scss";

export enum Mode {
  ON = "on",
  OFF = "off",
  PERIODS = "periods",
}

const DATE_FORMAT = "YYYY-MM-DD";

const schema = (formatMessage: FormatMessageFn) =>
  yup.object({
    mode: yup.mixed<Mode>().oneOf(Object.values(Mode)).required(),
    frostFreePeriods: yup
      .array()
      .of(
        yup
          .object({
            begin: yup.string().required(),
            end: yup.string().required(),
            added: yup.boolean().default(false),
            removed: yup.boolean().default(false),
          })
          .test(
            "isBeginBeforeEnd",
            formatMessage({ id: "settings.content.addPeriod.startDateAfterEndDate" }),
            value => {
              const { begin, end, removed } = value;
              return removed || begin === end || dayjs(begin, DATE_FORMAT).isBefore(dayjs(end, DATE_FORMAT));
            },
          ),
      )
      .required(),
    frostFreeTemperature: yup.number(),
    setpointStartFrostFree: yup.number(),
    setpointStartFrostFreeAmb: yup.number(),
    temporisationFrostFreeMode: yup.number(),
    reducedFFTemperature: yup.number(),
  });

export type FormType = yup.InferType<ReturnType<typeof schema>>;

export const FrostFree: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const frostFreeStyle = bem("frost-free");
  const rightStyle = bem("right");
  const leftStyle = bem("left");
  const footerStyle = bem("footer");

  const { permissions } = usePermissionsContext();

  const { house, rooms, boilerroomPos, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const { houseId } = useParams() as { houseId: string };

  const isFrostFreePeriodsAllowed = gateways
    .filter(g => g.softwareVersion)
    .every(g => isVersionIsUpper(g.softwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.FROST_FREE_PERIODS));

  const seasonUpdateDto = getInitialState(house, isFrostFreePeriodsAllowed);

  const { Form, submit, state } = useAction({
    displayLoadingPopup: false,
    onSuccess: () => {
      reset(getInitialState(house, isFrostFreePeriodsAllowed));
      toast(formatMessage({ id: "settings.content.menu.houses.frostFree.frostFreeMode.sucess" }), { type: "success" });
    },
  });

  const {
    control,
    watch,
    formState: {
      isDirty,
      dirtyFields,
      errors: { frostFreePeriods: frostFreePeriodsErrors },
    },
    handleSubmit,
    reset,
  } = useForm<FormType>({
    defaultValues: seasonUpdateDto,
    resolver: yupResolver(schema(formatMessage)),
  });

  const { fields, append, remove, update } = useFieldArray({ control, name: "frostFreePeriods" });

  const mode = watch("mode");
  const frostFreeTemperature = watch("frostFreeTemperature");
  const setpointStartFrostFreeAmb = watch("setpointStartFrostFreeAmb");
  const temporisationFrostFreeMode = watch("temporisationFrostFreeMode");
  const reducedFFTemperature = watch("reducedFFTemperature");

  const paragraph = (
    <p className={leftStyle("text")}>
      {formatMessage(
        { id: "settings.content.menu.houses.frostFree.frostFreeMode.text" },
        {
          l: (
            <Link className={leftStyle("text", { link: true })} to={routeToSeasonSettings(houseId)}>
              {formatMessage({ id: "settings.content.menu.houses.season.title" })}
            </Link>
          ),
        },
      )}
    </p>
  );
  const ENUM_MODE = {
    [Mode.ON]: paragraph,
    [Mode.OFF]: rooms.length > 0 && !isFrostFreePeriodsAllowed && (
      <p className={leftStyle("text")}>
        {formatMessage(
          { id: "settings.content.menu.houses.frostFree.frostFreeMode.roomsText" },
          {
            nb: getFrostFreeRoomsNumber(rooms),
            l: (
              <Link
                className={leftStyle("text", { link: true })}
                to={routeToFunctionsSettings(houseId)}
                state={
                  {
                    types: [],
                    plans: [],
                    groups: [],
                    parameters: {
                      frostFreeMode: true,
                      buttonSettings: null,
                      precomfortRangeGeneration: null,
                      precomfortRange: null,
                      unusualAbsenceDetection: null,
                    },
                  } as SelectorsType & { parameters: ParametersType }
                }
              >
                {formatMessage({ id: "settings.content.menu.houses.frostFree.frostFreeMode.function" })}
              </Link>
            ),
          },
        )}
      </p>
    ),
    [Mode.PERIODS]: isFrostFreePeriodsAllowed && (
      <>
        {paragraph}
        <p className={leftStyle("text")}>
          {formatMessage(
            { id: "settings.content.menu.houses.frostFree.frostFreeMode.roomsText" },
            {
              nb: getFrostFreeRoomsNumber(rooms),
              l: (
                <Link className={leftStyle("text", { link: true })} to={routeToRoomsFrostFreeSettings(houseId)}>
                  {formatMessage({ id: "settings.content.menu.houses.frostFree.frostFreeMode.rooms" })}
                </Link>
              ),
            },
          )}
        </p>
        <label>{formatMessage({ id: "settings.content.menu.houses.frostFree.frostFreeMode.periods.title" })}</label>
        <Periods
          periods={fields}
          onAddPeriod={append}
          onRemovePeriod={remove}
          onUpdatePeriod={update}
          errors={frostFreePeriodsErrors as ErrorType[]}
        />
      </>
    ),
  };

  const onSubmit = (data: FormType) => {
    const body: PatchHouse = {};
    if (dirtyFields.mode) body.isFrostFree = data.mode === Mode.ON;
    if (dirtyFields.frostFreePeriods)
      body.frostFreePeriods = data.frostFreePeriods
        .filter(ffp => !ffp.removed)
        .map(ffp => ({ begin: ffp.begin, end: ffp.end }));
    if (dirtyFields.frostFreeTemperature)
      body.frostFreeTemperature = data.frostFreeTemperature ? data.frostFreeTemperature * 100 : undefined;
    if (dirtyFields.setpointStartFrostFree) body.setpointStartFrostFree = data.setpointStartFrostFree;
    if (dirtyFields.setpointStartFrostFreeAmb) body.setpointStartFrostFreeAmb = data.setpointStartFrostFreeAmb;
    if (dirtyFields.temporisationFrostFreeMode) body.temporisationFrostFreeMode = data.temporisationFrostFreeMode;
    if (dirtyFields.reducedFFTemperature) body.reducedFFTemperature = data.reducedFFTemperature;
    submit(body, API.HTTP_METHOD.PATCH, routeToFrostFreeSettings(houseId));
  };

  return (
    <>
      <Form className={frostFreeStyle()} onSubmit={handleSubmit(onSubmit)}>
        <div className={leftStyle()}>
          <Controller
            control={control}
            name="mode"
            render={({ field: { value, onChange } }) => (
              <Select
                className={leftStyle("select")}
                label={formatMessage({ id: "settings.content.menu.houses.frostFree.frostFreeMode.title" })}
                value={value}
                options={$enum(Mode)
                  .getValues()
                  .filter(k => (isFrostFreePeriodsAllowed ? k !== Mode.OFF : k !== Mode.PERIODS))
                  .map(m => ({
                    value: m,
                    name: formatMessage({ id: `settings.content.menu.houses.frostFree.frostFreeMode.options.${m}` }),
                  }))}
                renderValue={(value?: string) => (
                  <p>
                    {formatMessage({ id: `settings.content.menu.houses.frostFree.frostFreeMode.options.${value}` })}
                  </p>
                )}
                onChange={(value: string) => onChange(value)}
              />
            )}
          />
          {ENUM_MODE[mode]}
        </div>
        <div className={rightStyle()}>
          <h3 className={rightStyle("title")}>
            {formatMessage({ id: "settings.content.menu.houses.frostFree.title" })}
          </h3>
          <p className={rightStyle("text")}>
            {formatMessage({ id: "settings.content.menu.houses.frostFree.valves.text" }, { t: frostFreeTemperature })}
          </p>
          <Controller
            control={control}
            name="frostFreeTemperature"
            render={({ field: { value, onChange } }) => (
              <Slider
                className={rightStyle("slider")}
                label={formatMessage({ id: "settings.content.menu.houses.frostFree.frostFreeTemperature.label" })}
                min={TEMPERATURE_LIMITS.MIN}
                max={TEMPERATURE_LIMITS.MAX}
                step={TEMPERATURE_LIMITS.STEP}
                value={value}
                defaultValue={DEFAULT_TEMPERATURES.FROSTFREE}
                valueLabelDisplay="auto"
                valueLabelFormat={formatMessage(
                  {
                    id: "settings.content.menu.houses.frostFree.frostFreeTemperature.frostFreeLowerNight",
                  },
                  {
                    g: value,
                    a: house.absenceTemperature,
                  },
                )}
                onChange={(_, value) => {
                  if (typeof value === "number") {
                    const maxTemp = house.absenceTemperature;
                    if (value > maxTemp) value = maxTemp - TEMPERATURE_LIMITS.STEP;
                    onChange(value);
                  }
                }}
                disabled={!permissions.house?.frostFreeTemperature?.update}
              />
            )}
          />
          {permissions.module?.boilerroom?.read && canAccessBoilerroom(boilerroomPos) && isFrostFreePeriodsAllowed && (
            <>
              <h3 className={rightStyle("title")}>
                {formatMessage({ id: "settings.content.menu.houses.frostFree.boilerroom.title" })}
              </h3>
              <p className={rightStyle("text")}>
                {formatMessage(
                  { id: "settings.content.menu.houses.frostFree.boilerroom.text" },
                  {
                    s: formatMessage({ id: `settings.content.menu.houses.frostFree.frostFreeMode.options.${mode}` }),
                    tAmb: setpointStartFrostFreeAmb ?? DEFAULT_SETPOINTS.START_FROST_FREE_AMB,
                    nbH: temporisationFrostFreeMode ?? DEFAULT_SETPOINTS.START_FROST_FREE,
                    tFrostFree: reducedFFTemperature ?? DEFAULT_TEMPERATURES.REDUCED_FFT,
                  },
                )}
              </p>
              <Controller
                control={control}
                name="setpointStartFrostFree"
                render={({ field: { value, onChange } }) => (
                  <Slider
                    className={rightStyle("slider")}
                    label={formatMessage({ id: "settings.content.menu.houses.frostFree.setpointStartFrostFree" })}
                    value={value}
                    onChange={(_, value) => onChange(value)}
                    defaultValue={DEFAULT_SETPOINTS.START_FROST_FREE}
                    min={TEMPERATURE_LIMITS.MIN}
                    max={TEMPERATURE_LIMITS.MAX}
                    step={TEMPERATURE_LIMITS.STEP}
                  />
                )}
              />
              <Controller
                control={control}
                name="setpointStartFrostFreeAmb"
                render={({ field: { value, onChange } }) => (
                  <Slider
                    className={rightStyle("slider")}
                    label={formatMessage({ id: "settings.content.menu.houses.frostFree.setpointStartFrostFreeAmb" })}
                    value={value}
                    onChange={(_, value) => onChange(value)}
                    defaultValue={DEFAULT_SETPOINTS.START_FROST_FREE_AMB}
                    min={TEMPERATURE_LIMITS.MIN}
                    max={TEMPERATURE_LIMITS.MAX}
                    step={TEMPERATURE_LIMITS.STEP}
                  />
                )}
              />
              <Controller
                control={control}
                name="temporisationFrostFreeMode"
                render={({ field: { value, onChange } }) => (
                  <Slider
                    className={rightStyle("slider")}
                    label={formatMessage({ id: "settings.content.menu.houses.frostFree.temporisationFrostFreeMode" })}
                    value={value}
                    onChange={(_, value) => onChange(value)}
                    defaultValue={DEFAULT_TEMPORISATION.FROST_FREE_MODE}
                    min={0}
                    max={20}
                  />
                )}
              />
              <Controller
                control={control}
                name="reducedFFTemperature"
                render={({ field: { value, onChange } }) => (
                  <Slider
                    className={rightStyle("slider")}
                    label={formatMessage({ id: "settings.content.menu.houses.frostFree.reducedFFTemperature" })}
                    value={value}
                    onChange={(_, value) => onChange(value)}
                    defaultValue={DEFAULT_TEMPERATURES.REDUCED_FFT}
                    step={TEMPERATURE_LIMITS.STEP}
                    min={0}
                    max={100}
                  />
                )}
              />
            </>
          )}
        </div>
        <div className={footerStyle()}>
          {mode === Mode.ON && (
            <Alert className={footerStyle("warning")} severity="warning">
              {formatMessage({ id: "settings.content.menu.houses.frostFree.warning" })}
            </Alert>
          )}
          <ActionButton
            className={footerStyle("button", { variant: "primary" })}
            type="submit"
            text={formatMessage({ id: "settings.content.menu.houses.frostFree.frostFreeMode.save" })}
            icon={<ArrowRightIcon />}
            disabled={!isDirty || ["submitting", "loading"].includes(state)}
          />
        </div>
      </Form>
    </>
  );
};
