import { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";

import { useIsFetching, useIsMutating } from "@tanstack/react-query";

import { LoadingPopup } from "~/UI/components";
import { queries } from "~/apiV2";

export const Root = () => {
  const isFetching = useIsFetching({ predicate: query => query.queryKey !== queries.news.all.queryKey });
  const isMutating = useIsMutating();

  const [displayFetchingLoader, setDisplayFetchingLoader] = useState(false);
  const displayMutatingLoader = isMutating > 0;

  const type = displayMutatingLoader ? "mutating" : "fetching";

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayFetchingLoader(isFetching > 0);
    }, 500);

    if (isFetching === 0) {
      setDisplayFetchingLoader(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isFetching]);

  return (
    <>
      <Outlet />
      <LoadingPopup open={displayFetchingLoader || displayMutatingLoader} type={type} />
    </>
  );
};
