import type { HttpResponseResolver } from "msw";
import { HttpResponse } from "msw";

import type {
  CheckValidationCodeRequestBody,
  CheckValidationCodeResponseBody,
  ValidationPhoneNumberResponseBody,
} from "~/apiV2";

const validationPhoneNumberResolver: HttpResponseResolver<never, never, ValidationPhoneNumberResponseBody> = () =>
  HttpResponse.json({ message: "send code to user" });

const checkValidationCodeResolver: HttpResponseResolver<
  never,
  CheckValidationCodeRequestBody,
  CheckValidationCodeResponseBody
> = () => HttpResponse.json({ message: "code is valid" });

export { validationPhoneNumberResolver, checkValidationCodeResolver };
