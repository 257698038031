import { cx } from "class-variance-authority";
import type { FieldErrors, UseFormRegister } from "react-hook-form";

import type { GroupedValves } from "../../types";
import { GroupField } from "../../types";
import { Group } from "../Group";
import { Item } from "../Item";

import styles from "./List.module.scss";

interface ListProps {
  groupedValves: Record<string, Omit<GroupedValves[string][number], "room" | "plan" | "gateway">[]>;
  register?: UseFormRegister<Record<string, number>>;
  errors?: FieldErrors<Record<string, number>>;
}

const List: React.FC<ListProps> = ({ groupedValves, register, errors }) => {
  const groups = Object.keys(groupedValves);

  if (groups.every(group => group === GroupField.noGrouping)) {
    return (
      <div className={cx(styles.list)}>
        {groupedValves[GroupField.noGrouping].map(valve => (
          <Item
            key={valve.id}
            id={valve.id}
            uid={valve.uid}
            mac={valve.mac}
            hslope={valve.hslope}
            maxOpening={valve.maxOpening}
            softwareVersion={valve.softwareVersion}
            gatewaySoftwareVersion={valve.gatewaySoftwareVersion}
            register={register}
            error={errors?.[valve.id]}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={styles.list}>
      {Object.entries(groupedValves).map(([group, valves]) => (
        <Group.Root key={group} defaultOpen>
          <Group.Trigger>{group}</Group.Trigger>
          <Group.Content className={styles.list__content}>
            {valves.map(valve => (
              <Item
                key={valve.id}
                id={valve.id}
                uid={valve.uid}
                mac={valve.mac}
                hslope={valve.hslope}
                maxOpening={valve.maxOpening}
                softwareVersion={valve.softwareVersion}
                gatewaySoftwareVersion={valve.gatewaySoftwareVersion}
                register={register}
                error={errors?.[valve.id]}
              />
            ))}
          </Group.Content>
        </Group.Root>
      ))}
    </div>
  );
};

export { List };
