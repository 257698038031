import React from "react";

import { convert } from "html-to-text";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";

import { Dialog, Divider, Loader } from "@eisox/design-system";
import { useQuery } from "@tanstack/react-query";

import { queries } from "~/apiV2";
import { env } from "~/configuration";
import { routeToRessources } from "~/routes/utils";

import styles from "./NewsPopup.module.scss";

export const NewsPopup: React.FC = () => {
  const { formatMessage } = useIntl();

  const { houseId } = useParams();

  const { data: news, isLoading } = useQuery(queries.news.all);

  return (
    <Dialog.Content className={styles.newsPopup} title={formatMessage({ id: "news.title" })}>
      <div className={styles.content}>
        {news?.map((n, index) => {
          const to = n.docusaurus ? (houseId ? routeToRessources(houseId) + n.link : "/resources" + n.link) : n.link;
          return (
            <>
              <Dialog.Close asChild key={index}>
                <Link
                  className={styles.news}
                  to={to}
                  target={n.docusaurus ? undefined : "_blank"}
                  rel={n.docusaurus ? undefined : "noopener noreferrer"}
                >
                  <h2 className={styles.news__title}>{convert(n.title.rendered)}</h2>
                  <p className={styles.news__content}>{convert(n.excerpt.rendered)}</p>
                </Link>
              </Dialog.Close>
              {index + 1 < news.length && <Divider className={styles.content__divider} />}
            </>
          );
        })}
        {isLoading && <Loader />}
      </div>
      <a className={styles.newsPopup__link} href={env.NEWS_BLOG_URL} target="_blank">
        {formatMessage({ id: "news.blog" })}
      </a>
    </Dialog.Content>
  );
};
