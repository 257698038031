import { useTranslation } from "react-i18next";
import { Outlet, useMatches, useNavigate, useParams, useRevalidator, useRouteLoaderData } from "react-router-dom";

import { useIsMobile } from "@eisox/tools";

import type { houseLoader } from "~/UI";
import { Page, usePermissionsContext } from "~/UI";
import { ActionDropdown, DisplayDropdown } from "~/features/Maintenance";
import { idLoaderHouse, routeToMaintenance, routeToPlan, routeToRoom } from "~/routes/utils";
import { useUserPreferenceStore } from "~/stores";

export default () => {
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const { role } = usePermissionsContext();

  const { houseId } = useParams() as { houseId: string };
  const { house, valves, gateways, rooms, date } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof houseLoader>;
  const navigate = useNavigate();
  const matches = useMatches();
  const { revalidate } = useRevalidator();

  const setMaintenanceAdvanced = useUserPreferenceStore.use.setMaintenanceAdvanced();

  const advanced = matches.some(match => match.pathname.includes("advanced"));

  const handleAdvancedCheckedChange = (checked: boolean | null) => {
    setMaintenanceAdvanced(checked ?? false);
    navigate(routeToMaintenance(houseId) + (checked ? "/advanced" : ""));
  };

  const handleClickOnRoom = (planId: string, roomId: string) => navigate(routeToRoom(houseId, planId, roomId));
  const handleClickOnPlan = (planId: string) => navigate(routeToPlan(houseId, planId));
  const handleRefresh = () => revalidate();

  return (
    <>
      <Page.Header>
        <Page.Title>{t("advancedMaintenance.title")}</Page.Title>
        {!isMobile && (
          <>
            <DisplayDropdown advancedChecked={advanced} onAdvancedCheckedChange={handleAdvancedCheckedChange} />
            <ActionDropdown
              role={role}
              house={house}
              valves={valves}
              gateways={gateways}
              rooms={rooms}
              udpatedAt={date}
              onClickOnRoom={handleClickOnRoom}
              onClickOnPlan={handleClickOnPlan}
              onRefresh={handleRefresh}
            />
          </>
        )}
      </Page.Header>
      <Outlet />
    </>
  );
};
