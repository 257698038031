import { cloneElement, forwardRef } from "react";

import clsx from "clsx";

import { useBem } from "@eisox/tools";

import { ButtonProps } from "../Button";
import { Typography } from "../Typography";

import styles from "./styles.module.scss";

export type ActionButtonProps = ButtonProps & {
  rounded?: boolean;
  variant?: "primary" | "danger" | "cancel";
};

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    {
      text,
      icon,
      iconPosition = "right",
      type = "button",
      rounded = false,
      variant = "primary",
      className,
      disabled,
      ...props
    },
    ref,
  ) => {
    const bem = useBem(styles);
    const actionButtonStyle = bem("action-button");

    return (
      <button
        ref={ref}
        {...props}
        type={type}
        disabled={disabled}
        className={clsx(
          actionButtonStyle({ rounded: rounded, disabled: !!disabled }),
          actionButtonStyle("variant", { type: variant }),
          className,
        )}
      >
        {icon &&
          (typeof icon === "string" ? (
            <img className={clsx(actionButtonStyle("icon", { position: iconPosition }))} src={icon} />
          ) : (
            cloneElement(icon as React.ReactElement, {
              className: clsx(
                actionButtonStyle("icon", { position: iconPosition }),
                (icon as React.ReactElement).props.className,
              ),
            })
          ))}
        {text && <Typography keepHoverOpen={false}>{text}</Typography>}
      </button>
    );
  },
);
