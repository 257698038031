import clsx from "clsx";
import "dayjs/locale/en";
import "dayjs/locale/fr";

import type { Dayjs } from "@eisox/dayjs";
import { CalendarIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { IconButton } from "@mui/material";
import type { DateTimePickerProps } from "@mui/x-date-pickers";
import { DesktopDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { i18next } from "~/i18n";

import styles from "./DateTimePickerInput.module.scss";
import "./DateTimePicketInputCustom.scss";

export interface DateTimePickerInputProps extends DateTimePickerProps<Dayjs> {
  error?: string;
}

export const DateTimePickerInput: React.FC<DateTimePickerInputProps> = ({ error, className, label, ...props }) => {
  const bem = useBem(styles);
  const dateTimePickerInputStyle = bem("date-time-picker-input");

  return (
    <div className={clsx(dateTimePickerInputStyle(), className)}>
      {label && <label>{label}</label>}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18next.language}>
        <DesktopDateTimePicker
          {...props}
          className="date-time-input"
          slotProps={{
            textField: {
              error: !!error,
              helperText: error,
            },
          }}
          slots={{
            openPickerButton: props => (
              <IconButton {...props}>
                <CalendarIcon className="date-time-input__calendar" />
              </IconButton>
            ),
          }}
        />
      </LocalizationProvider>
    </div>
  );
};
