import { StatusCodes } from "http-status-codes";

import { createQueryKeys } from "@lukemorales/query-key-factory";

import { useUserStore } from "~/stores";

import { client } from "../api";
import { TokenStatus } from "../types";
import type { UserRole } from "../types";

const getUserRoles = async (): Promise<UserRole> => {
  const { data, error } = await client.GET("/users/roles/");
  if (error || data?.message?.roles === undefined) throw new Error(error);
  const roles = data.message.roles;
  useUserStore.getState().setRoles(roles);
  return roles;
};

const getUser = async () => {
  const { data, error } = await client.GET("/user/");
  if (error) throw new Error(error);
  return data?.message ?? {};
};

const getTokenStatus = async (token: string) => {
  const { response, error } = await client.GET("/users/{token}/isValidToken", {
    params: { path: { token } },
  });

  if ((response.status as StatusCodes) === StatusCodes.OK) return TokenStatus.VALID;

  const statusMap: Record<string, TokenStatus> = {
    "jwt expired": TokenStatus.EXPIRED,
    "Invalid token": TokenStatus.ALREADY_USED,
  };

  if (error) {
    const errorMessage = (error as { message?: string }).message;
    return statusMap[error as unknown as string] ?? (errorMessage ? statusMap[errorMessage] : TokenStatus.INVALID);
  }
  return TokenStatus.INVALID;
};

const getUsersRolesInHouses = async () => {
  const { data, error } = await client.GET("/users/rolesInHouses");
  if (error || !data?.message) throw new Error(error);
  return data.message;
};

const getHouseUsers = async (houseId: string) => {
  const { data, error } = await client.GET("/houses/{houseId}/users", {
    params: { path: { houseId } },
  });
  if (error || !data?.message) throw new Error(error);
  return data.message;
};

const getCreators = async () => {
  const { data, error } = await client.GET("/users/roles/creators");
  if (error || !data?.message) throw new Error(error);
  return data.message;
};

export const user = createQueryKeys("user", {
  creators: {
    queryKey: null,
    queryFn: getCreators,
  },
  houseUsers: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getHouseUsers(houseId),
  }),
  me: {
    queryKey: null,
    queryFn: getUser,
  },
  roles: {
    queryKey: null,
    queryFn: getUserRoles,
  },
  rolesInHouses: {
    queryKey: null,
    queryFn: getUsersRolesInHouses,
  },
  tokenStatus: (token: string) => ({
    queryKey: [token],
    queryFn: () => getTokenStatus(token),
  }),
});
